import React, { useMemo } from 'react';
import { Card, TCardProps } from '@generics/surfaces';
import { Typography } from '@generics/content';
import { CustomerMembership } from './memberships';
import { CustomerPackage } from './packages';
import { useAuthenticatedCustomer } from '@features/authentication';
import { useCustomerQuotasQuery } from '@network/api';

export type TCustomerProductsCardProps = Extend<TCardProps, {}>;

export const CustomerProductsCard = (props: TCustomerProductsCardProps) => {
  const { ...restRootProps } = props;

  const authenticatedCustomer = useAuthenticatedCustomer();
  const quotas = useCustomerQuotasQuery();

  const packages = useMemo(
    () => authenticatedCustomer?.products
      ?.filter((product) => product.product_type.toLowerCase() === 'package') ?? [],
    [authenticatedCustomer],
  );

  const packagesWithLimits = useMemo(() => {
    return packages.map((pack) => {
      const quota = quotas.data.find((quota) => quota.bought_membership_id === pack.id);

      return {
        ...pack,
        limit: quota?.limit?.customer_limit,
      };
    });
  }, [packages, quotas.data]);

  return (
    <Card {...restRootProps}>
      <Typography type="body/medium/m">labels.yourProducts</Typography>
      <CustomerMembership customer={authenticatedCustomer} />
      {packagesWithLimits.map((pack) => (
        <CustomerPackage
          key={pack.id}
          name={pack.name}
          expirationDate={pack.expires_at}
          limit={pack.limit}
        />
      ))}
    </Card>
  );
};
