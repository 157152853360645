import React, { useMemo } from 'react';
import { Box } from '@generics/surfaces';
import { Scale, Typography } from '@generics/content';
import { useFormatDatetime } from '@utils/localizing';
import { useTranslate } from '@features/multi-language';
import isNumber from 'lodash/isNumber';
import styles from './CustomerPackage.module.scss';

export type TCustomerPackageProps<RootElementType extends React.ElementType> = {
  as?: RootElementType;
  name?: string;
  expirationDate?: string;
  limit?: {
    total: number;
    used: number;
  };
  buyable?: boolean;
};

export const CustomerPackage = <RootElementType extends React.ElementType = typeof Box>(
  props: PolymorphicComponentPropsWithoutRef<RootElementType, TCustomerPackageProps<RootElementType>>,
) => {
  const { as, name, expirationDate, limit, ...restRootProps } = props;

  const RootComponent = useMemo(() => as ?? Box, [as]);

  const t = useTranslate();
  const formatDatetime = useFormatDatetime();

  const formattedExpirationDate = useMemo(
    () => formatDatetime({ date: expirationDate, format: 'datetime.membership.expiringAt' }),
    [formatDatetime, expirationDate],
  );

  return (
    <RootComponent {...restRootProps}>
      <div className={styles.header}>
        <Typography type="body/medium/m">{name}</Typography>
        <Typography type="body/regular/s" color="secondary">{formattedExpirationDate}</Typography>
        {isNumber(limit?.total) && isNumber(limit?.used)
          ? (
              <Typography type="body/regular/s" color="secondary">
                {t('labels.ridesLeft', { rides: limit.total - limit.used })}
              </Typography>
            )
          : null}
      </div>
      {limit?.total ? (<Scale total={limit.total} active={limit.used ?? 0} />) : null}
    </RootComponent>
  );
};
