export const GET_SIMULATOR = 'GET_SIMULATOR';

export interface Simulator {
  id: string;
  name: string;
}

export interface SimulatorState {
  simulator: Simulator[] | [];
}

interface GetSimulatorAction {
  type: typeof GET_SIMULATOR;
  payload: Simulator[];
}

export type SimulatorActionTypes = GetSimulatorAction;
