import { useMemo } from 'react';
import { useLoungesQuery } from './useLoungesQuery';

export function useLoungesSelection() {
  const { data: lounges } = useLoungesQuery();

  const loungesSelection = useMemo(() => lounges.map((lounge) => ({
    value: lounge.id,
    children: lounge.name,
  })), [lounges]);

  return { loungesSelection };
}
