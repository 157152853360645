import React, { useEffect } from 'react';
import Header from '../../components/Header';
import { Route, Routes } from 'react-router-dom';
import EventBooking from '../EventBooking';
import styles from './Public.module.scss';
import useWithDispatch from '../../hooks/useWithDispatch';
import { getCountries } from '../../store/country/country.actions';
import PartcipantForm from '../SignIntoEvent/SignIntoEvent';
import classNames from 'classnames';
import EventParticipantDrivers from '../EventParticipantDrivers/EventParticipantDrivers';
import ConfirmSubscription from '../ConfirmSubscription/ConfirmSubscription';
import RideBooking from '../RideBooking/RideBooking';

const Public: React.FC = () => {
  const countriesDispatch = useWithDispatch(getCountries);

  useEffect(() => {
    countriesDispatch();
    // eslint-disable-next-line
  }, []);

  // TODO: Fix when have time
  const isSurvey = !window.location.href.includes('booking');

  return (
    <>
      <Header
        hideNavigation
        canBeFixed={false}
        hideTop={isSurvey}
        logoLink={import.meta.env.REACT_APP_WEBSITE_URL}
      />
      <section
        className={classNames(styles.public, {
          [styles.public_shortHeader]: isSurvey,
        })}
      >
        <Routes>
          <Route
            path="/ride-booking"
            element={<RideBooking />}
          />
          <Route
            path="/event-booking"
            element={<EventBooking />}
          />
          <Route
            path="/event-booking/:id"
            element={<EventBooking />}
          />
          <Route
            path="/sign-into-event/:id"
            element={<PartcipantForm />}
          />
          <Route
            path="/event-participant-drivers/:id"
            element={<EventParticipantDrivers />}
          />
          <Route
            path="/confirm-subscription/:token"
            element={<ConfirmSubscription />}
          />
        </Routes>
      </section>
    </>
  );
};

export default Public;
