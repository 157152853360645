import { Country, CountryActionTypes, GET_COUNTRY } from './country.types';
import i18n from '../../i18n/index';
import { Dispatch } from 'react';
import api, { API_ROOT } from '../../config/api.config';
import { AxiosError } from 'axios';

const options = {
  headers: {
    'x-locale': i18n.language,
  },
};

export const getCountries = async (
  dispatch: Dispatch<CountryActionTypes>,
) => {
  try {
    const res = await api.get<{ data?: Country[] }>(`${API_ROOT}/countries`, options);

    const countryData = res?.data?.data ?? [];

    dispatch({
      type: GET_COUNTRY,
      payload: countryData,
    });
  }
  catch (error) {
    const err = error as AxiosError<TRumpApiErrorResponseData>;
    return Promise.reject(typeof err.response !== 'undefined' ? err.response.data : err);
  }
};
