import React from 'react';
import { Box, Card, TCardProps } from '@generics/surfaces';
import { useCustomerStatisticQuery } from '@network/api';
import { Typography } from '@generics/content';
import styles from './CustomerStatisticCard.module.scss';

export type TCustomerStatisticCardProps = Extend<TCardProps, {}>;

export const CustomerStatisticCard = (props: TCustomerStatisticCardProps) => {
  const { ...restRootProps } = props;

  const { data: { experiences, tracks } } = useCustomerStatisticQuery();

  return (
    <Card {...restRootProps}>
      <Typography type="body/medium/m">labels.yourStatistics</Typography>
      <div className={styles.items}>
        <Box className={styles.item} size="small">
          <Typography type="body/medium/l">{experiences}</Typography>
          <Typography type="body/regular/s">labels.experience</Typography>
        </Box>
        <Box className={styles.item} size="small">
          <Typography type="body/medium/l">{tracks}</Typography>
          <Typography type="body/regular/s">labels.track</Typography>
        </Box>
      </div>
    </Card>
  );
};
