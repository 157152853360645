const events = {
  readMore: 'Lee mas',
  events: 'Eventos',
  back: 'Atrás',
  view: 'Ver',
  close: 'Cerca',
  starts: 'Empieza',
  ends: 'Termina',
  lounge: 'Lounge',
};

export default events;
