import React, { useMemo } from 'react';
import styles from './AppProgressCircle.module.scss';
import classNames from 'classnames';

interface IAppProgressCircleProps {
  label: string;
  /**
   * Percentage value
   * @example 75
   */
  progress: number;
  className?: string;
}

const circleRadius = 26;
const circleLength = circleRadius * 2 * Math.PI;

const AppProgressCircle: React.FC<IAppProgressCircleProps> = ({
  label,
  progress,
  className,
}) => {
  const strokeDasharray = circleLength + 'px';

  const strokeDashoffset = useMemo(
    () => circleLength - circleLength * (progress / 100) + 'px',
    [progress],
  );

  return (
    <div className={classNames(styles.wrapper, className)}>
      <svg
        className={styles.progressCircle}
        viewBox="0 0 60 60"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className={styles.progressCircle__track}
          r={circleRadius}
          cx="30"
          cy="30"
          strokeDasharray={strokeDasharray}
          strokeDashoffset="0"
        >
        </circle>
        <circle
          className={styles.progressCircle__progress}
          r={circleRadius}
          cx="30"
          cy="30"
          strokeDashoffset={strokeDashoffset}
          strokeDasharray={strokeDasharray}
        >
        </circle>
      </svg>
      <span className={styles.text}>{label}</span>
    </div>
  );
};

export default AppProgressCircle;
