import { useContext, useEffect } from 'react';
import { GeolocationContext } from '../providers/geolocation.provider';

function useGeolocation() {
  const geolocation = useContext(GeolocationContext);

  useEffect(() => {
    if (geolocation.isRequested) return;
    geolocation.request();
  }, [geolocation]);

  return geolocation;
}

export default useGeolocation;
