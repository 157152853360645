import React from 'react';
import styles from './MapDialog.module.scss';
import { Lounge } from '../../../../store/lounge/lounge.types';
import LoungePicker from '../../../../components/LoungePicker/LoungePicker';
import MobileSlideUpDialog from '../../../../components/MobileSlideUpDialog/MobileSlideUpDialog';

type TMapDialogProps = {
  open: boolean;
  globalSelectionTitle?: string;
  displayPrice?: boolean;
  displayAddress?: boolean;
  onClose: () => void;
  onLoungeSelect: (lounge: Lounge) => void;
};

const MapDialog: React.FC<TMapDialogProps> = ({
  open,
  globalSelectionTitle,
  displayPrice,
  displayAddress,
  onClose,
  onLoungeSelect,
}) => {
  return (
    <MobileSlideUpDialog
      className={styles.dialog}
      classes={{ paper: styles.paper }}
      open={open}
      onClose={onClose}
    >
      <LoungePicker
        displayPrice={displayPrice}
        displayAddress={displayAddress}
        globalSelectionTitle={globalSelectionTitle}
        classNames={{ root: styles.picker }}
        onClose={onClose}
        onPick={onLoungeSelect}
      />
    </MobileSlideUpDialog>
  );
};

export default MapDialog;
