import React, { useMemo } from 'react';
import mergeClassNames from 'classnames';
import { Tab as MuiTab, TabProps as MuiTabProps } from '@mui/material';
import styles from './Tab.module.scss';

export interface ITabProps extends MuiTabProps {}

const Tab: React.FC<ITabProps> = (props) => {
  const { classes } = props;

  const mergedClasses = useMemo(
    () => ({
      root: mergeClassNames(styles.root, classes ? classes.root : null),
      selected: mergeClassNames(
        styles.root_selected,
        classes ? classes.selected : null,
      ),
    }),
    [classes],
  );

  return <MuiTab {...props} classes={mergedClasses} />;
};

export default Tab;
