import React, { useMemo, useCallback } from 'react';
import { Lounge } from '../../store/lounge/lounge.types';
import WorldMapMarker from '../WorldMap/WorldMapMarker';
import styles from './LoungesMapMarker.module.scss';

export interface ILoungesMapMarkerProps {
  lounge: Lounge;
  selected?: boolean;
  onSelect?: (lounge: Lounge) => void;
}

const LoungesMapMarker: React.FC<ILoungesMapMarkerProps> = ({
  lounge,
  selected,
  onSelect,
}) => {
  const latitude = useMemo(() => Number(lounge.lat), [lounge.lat]);

  const longitude = useMemo(() => Number(lounge.lng), [lounge.lng]);

  const popupContent = useMemo(
    () => <div className={styles.popupContent}>{lounge.name}</div>,
    [lounge.name],
  );

  const handleClick = useCallback(() => {
    if (!onSelect) return;
    onSelect(lounge);
  }, [onSelect, lounge]);

  return (
    <WorldMapMarker
      id={lounge.id}
      latitude={latitude}
      longitude={longitude}
      popupContent={popupContent}
      selected={selected}
      onClick={handleClick}
    />
  );
};

export default LoungesMapMarker;
