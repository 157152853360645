import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Public from './views/Public';
import Mobile from './views/Mobile';
import Auth from './views/Auth';
import TermsPrivacy from './views/TermsPrivacy';

import 'react-toastify/dist/ReactToastify.min.css';
import useFacebookTracker from './views/Fb';

import {
  AccountLayout,
} from '@layouts/account';
import {
  LogoutPage,
} from '@routes/authentication';
import {
  DashboardPage,
  ChampionshipsPage,
  EventsPage,
  MembershipPage,
  PackagesPage,
  RankingPage,
  SettingsPage,
  EventPage,
  SettingsProfilePage,
  SettingsChangePasswordPage,
  SettingsPaymentMethodsPage,
  SettingsPaymentHistoryPage,
  SettingsLanguagePage,
} from '@routes/account';
import { SecuredRoutes } from '@features/authentication';
import { LegacyDataProvider } from '@features/legacy/LegacyDataProvider';
import { useBreakpoints } from '@generics/responsiveness';
import { Redirects } from './Redirects';

// TODO: Verify data refetching on account change.

const App = () => {
  useFacebookTracker();

  const { isDesktop } = useBreakpoints();

  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route element={<LegacyDataProvider />}>
            <Route element={<SecuredRoutes />}>
              <Route path="logout" element={<LogoutPage />} />
              <Route path="account" element={<AccountLayout />}>
                <Route index element={<DashboardPage />} />
                <Route path="packages" element={<PackagesPage />} />
                <Route path="membership" element={<MembershipPage />} />
                <Route path="ranking" element={<RankingPage />} />
                <Route path="championships" element={<ChampionshipsPage />} />
                <Route path="events" element={<EventsPage />} />
                <Route path="events/:id" element={<EventPage />} />
                <Route path="settings" element={<SettingsPage />}>
                  <Route index element={isDesktop ? <Navigate to="profile" /> : null} />
                  <Route path="profile" element={<SettingsProfilePage />} />
                  <Route path="change-password" element={<SettingsChangePasswordPage />} />
                  <Route path="language" element={isDesktop ? <Navigate to="../profile" /> : <SettingsLanguagePage />} />
                  <Route path="payment-methods" element={<SettingsPaymentMethodsPage />} />
                  <Route path="payment-history" element={<SettingsPaymentHistoryPage />} />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path="/public/*" element={<Public />} />
          <Route path="/mobile/*" element={<Mobile />} />
          <Route
            path="/terms-of-service/:lang?"
            element={<TermsPrivacy isTerms />}
          />
          <Route
            path="/privacy-policy/:lang?"
            element={<TermsPrivacy />}
          />
          <Route path="/auth/*" element={<Auth />} />
          <Route path="*" element={<Redirects />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
