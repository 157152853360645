import React, { forwardRef, useMemo } from 'react';
import { mergeClassNames } from '@utils/styling';
import styles from './Scroll.module.scss';

export interface IScrollProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  vertical?: boolean;
  horizontal?: boolean;
}

export const Scroll = forwardRef<HTMLDivElement, IScrollProps>(function ScrollWithForwardedRef(props, ref) {
  const { vertical, horizontal, children, className, ...restRootProps } = props;

  const rootClassName = useMemo(() => mergeClassNames({
    [styles.root]: true,
    [styles.root_vertical]: vertical,
    [styles.root_horizontal]: horizontal,
    [className || '']: true,
  }), [vertical, horizontal, className]);

  return <div {...restRootProps} className={rootClassName} ref={ref}>{children}</div>;
});
