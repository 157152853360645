import React, { useCallback, useMemo } from 'react';
import { Typography } from '@generics/content';
import { Form, FormField, useForm } from '@generics/forms';
import { Button } from '@generics/inputs/button';
import { Card } from '@generics/surfaces';
import { CheckIcon, CrossIcon, EditIcon } from '@images/icons';
import { useGendersSelection, useCountriesSelection, useUpdateCustomerMutation } from '@network/api';
import { useAuthenticatedCustomer } from '@features/authentication';
import { useValidator } from '@utils/validation';
import styles from './CustomerPersonalDataSection.module.scss';
import { useConfirmCancelChanges } from '@generics/forms/useConfirmCancelChanges';
import { EInputMode } from '@generics/inputs/types';

interface ICustomerPersonalData {
  nickname: string;
  gender_id: string | null;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string | null;
  company: string | null;
  body_weight: string | null;
  body_height: string | null;
  dob: string | null;
  pob_country_id: string | null;
}

// TODO: Think if `required` prop can be inferred from validation schema.

export const CustomerPersonalDataSection = () => {
  const authenticatedCustomer = useAuthenticatedCustomer();

  const validator = useValidator();

  const validationSchema = useMemo(() => validator.object().shape({
    nickname: validator.string().required(),
    first_name: validator.string().required(),
    last_name: validator.string().required(),
    email: validator.string().required(),
  }), [validator]);

  const form = useForm({
    defaultValues: ({
      nickname: authenticatedCustomer?.nickname ?? '',
      gender_id: authenticatedCustomer?.gender_id || null,
      first_name: authenticatedCustomer?.first_name ?? '',
      last_name: authenticatedCustomer?.last_name ?? '',
      email: authenticatedCustomer?.email ?? '',
      phone_number: authenticatedCustomer?.phone_number ?? null,
      company: authenticatedCustomer?.company ?? null,
      body_weight: authenticatedCustomer?.body_weight ?? null,
      body_height: authenticatedCustomer?.body_height ?? null,
      dob: authenticatedCustomer?.dob ?? null,
      pob_country_id: authenticatedCustomer?.pob_country_id || null,
    } satisfies ICustomerPersonalData),
    mode: EInputMode.Representation,
    validationSchema,
  });

  const { gendersSelection } = useGendersSelection();
  const { countriesSelection } = useCountriesSelection();

  const represent = useCallback(() => {
    setTimeout(() => {
      form.reset();
      form.represent();
    }, 0);
  }, [form]);

  const updateCustomerMutation = useUpdateCustomerMutation({
    onSuccess: () => {
      represent();
      setTimeout(() => {
        updateCustomerMutation.reset();
      }, 0);
    },
    onValidationErrors: form.setApiValidationErrors,
  });

  const { confirm: confirmCancelChanges, dialog } = useConfirmCancelChanges();

  const handleCancelButtonClick = useCallback(() => {
    confirmCancelChanges().then((isConfirm) => {
      if (!isConfirm) return;
      represent();
    });
  }, [confirmCancelChanges, represent]);

  const handleValidSubmit = useCallback(async (data: ICustomerPersonalData) => {
    await updateCustomerMutation.mutateAsync(data);
  }, [updateCustomerMutation]);

  if (!authenticatedCustomer) return null;

  return (
    <Form className={styles.root} form={form} onValidSubmit={handleValidSubmit}>
      {dialog}
      <div className={styles.header}>
        <Typography type="body/medium/m">labels.personalData</Typography>
        {form.mode === EInputMode.Representation
          ? (
              <Button
                type="button"
                design="text"
                label="labels.edit"
                icon={<EditIcon />}
                onClick={form.edit}
              />
            )
          : null}
      </div>
      <Card className={styles.card}>
        <div className={styles.fields}>
          <FormField
            name="nickname"
            type="string"
            required
          />
          <FormField
            name="gender_id"
            type="select"
            items={gendersSelection}
          />
          <FormField
            name="first_name"
            type="string"
            required
          />
          <FormField
            name="last_name"
            type="string"
            required
          />
          <FormField
            name="email"
            type="string"
            required
            readOnly
          />
          <FormField
            name="phone_number"
            type="phone"
          />
          <FormField
            name="company"
            type="string"
          />
          <FormField
            name="body_weight"
            type="number"
            min={0}
            decimalScale={2}
            appendix="kg"
          />
          <FormField
            name="body_height"
            type="number"
            min={0}
            decimalScale={2}
            appendix="cm"
          />
          <FormField
            name="dob"
            type="date"
            futureDisabled
          />
          <FormField
            name="pob_country_id"
            type="select"
            items={countriesSelection}
          />
        </div>
        {form.mode === EInputMode.Input
          ? (
              <div className={styles.actions}>
                <Button
                  type="button"
                  design="outline"
                  label="labels.cancel"
                  icon={<CrossIcon />}
                  onClick={handleCancelButtonClick}
                />
                <Button
                  type="submit"
                  label="labels.save"
                  icon={<CheckIcon />}
                  isLoading={updateCustomerMutation.isPending}
                  disabled={updateCustomerMutation.isSuccess}
                />
              </div>
            )
          : null}
      </Card>
    </Form>
  );
};
