import i18n from '../../../i18n';
import { FormModelField } from '../../../types/form.types';

export interface ISignUpFormValues {
  first_name: string;
  last_name: string;
  nickname: string;
  email: string;
  customer_id: string;
  token: string;
  password: string;
  password_confirmation: string;
  preferred_language: string;
  default_lounge_id: string;
  tos_accepted: boolean;
  age: boolean;
}

export const initialValues: ISignUpFormValues = {
  first_name: '',
  last_name: '',
  nickname: '',
  email: '',
  customer_id: '',
  token: '',
  password: '',
  password_confirmation: '',
  preferred_language: i18n.language,
  default_lounge_id: '',
  tos_accepted: false,
  age: false,
};

export const formModel: FormModelField[] = [
  {
    name: 'nickname',
    i18nKey: 'field.nickname',
    type: 'text',
  },
  {
    name: 'first_name',
    i18nKey: 'field.first_name',
    type: 'text',
  },
  {
    name: 'last_name',
    i18nKey: 'field.last_name',
    type: 'text',
  },
  {
    name: 'email',
    i18nKey: 'field.email',
    type: 'text',
  },
  {
    name: 'customer_id',
    i18nKey: '',
    type: 'text',
  },
  {
    name: 'token',
    i18nKey: '',
    type: 'text',
  },
  {
    name: 'password',
    i18nKey: 'field.password',
    type: 'password',
  },
  {
    name: 'password_confirmation',
    i18nKey: 'field.password_confirmation',
    type: 'password',
  },
  {
    name: 'default_lounge_id',
    i18nKey: 'field.default_lounge_id',
    type: 'select',
    list: [],
  },
];
