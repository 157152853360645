import React from 'react';
import { usePageTitle } from '@features/metadata';
import { accountEventsRoute } from './route';
import Events from '@routes/Events';

export const EventsPage = () => {
  usePageTitle(accountEventsRoute.title);

  return (
    <Events />
  );
};
