import React from 'react';
import AuthLinks from '../../components/AuthLinks';
import ResetPasswordForm from './ResetPasswordForm';
import SignPage from '../../components/SignPage';

const ForgotPassword = () => (
  <>
    <SignPage isSignUp onlyButton>
      <ResetPasswordForm />
    </SignPage>
    <AuthLinks />
  </>
);

export default ForgotPassword;
