const auth = {
  sideInfo: {
    title: 'Als Mitglied kannst Du:',
    p1: 'Deine Leistungen in der Rangliste mit anderen Mitgliedern vergleichen.',
    p2: 'Statistiken zu Deinen Experiences und deiner Fahrleistung sehen.',
    p3: 'Bei unserer monatlichen Championship mitmachen.',
    p4: 'Und vieles mehr!',
    p5: '',
    copyright: 'Racing Unleashed AG. Alle Rechte vorbehalten.',
  },
  links: {
    tos: 'AGB',
    privacyPolicy: 'Datenschutz',
    security: 'Sicherheit',
  },
  authAction: {
    signUp: 'Noch kein Mitglied?',
    signIn: 'Bist du schon Mitglied?',
    btnSignIn: 'Login',
    btnSignUp: 'Registrieren',
  },
  signUp: {
    title: 'Registrieren',
    titleSilentMember: 'Formular ausfüllen zur Aktivierung',
    subtitle:
      'Jetzt Mitglied werden und die Racing Unleashed Experience erleben.',
    confirmMembership: 'Bestätigen',
    confirmText: ' <1> {{customerEmail}} </1> email.',
  },
  signIn: {
    title: 'Login zum Members Portal',
    subtitle: 'Geben Sie Ihre Zugangsdaten ein.',
  },
  forgotPassword: {
    title: 'Passwort vergessen?',
    subtitle: 'Hier schnell und einfach zurücksetzen.',
    linkToPage: 'Passwort vergessen?',
  },
  resetPassword: {
    title: 'Neues Passwort eingeben',
    subtitle: 'Bitte wähle ein Passwort mit mindestens 8 Zeichen.',
  },
  backToSignIn: 'Zurück zum Login',
  unleashNow: 'Unleash now',
  welcomeText: 'Wilkommen zur ultimativen Experience',
  logIn: {
    dialogTitle: 'Anmelden bei \n Racing Unleashed Konto',
    btnText: 'Einloggen',
    successMessage: 'Sie haben sich erfolgreich eingeloggt.',
    caption: 'Haben Sie bereits ein Racing Unleashed-Konto? <button>Einloggen</button>',
  },
  logOut: {
    btnText: 'Abmelden',
    successMessage: 'Sie wurden abgemeldet.',
    confirmationTitle: 'Sind Sie sicher, dass Sie sich abmelden wollen?',
    confirmationSubtitle:
      'Diese Buchung wird nicht in Ihrem Konto gespeichert und Sie müssen alle Rechnungsdaten manuell eingeben.',
  },
  iForgotMyPassword: 'Ich habe mein Passwort vergessen',
  createAnAccount: 'ein Konto erstellen',
};

export default auth;
