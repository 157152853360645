import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Field } from '@generics/surfaces';
import { EStripeCardBrand, StripeCardBrandIcon } from './StripeCardBrandIcon';
import { StripeCardElement, StripeCardElementChangeEvent, TStripeElementProps } from './types';
import { mergeRefs } from '@utils/merge-refs.utils';
import { useTranslate } from '@features/multi-language';
import { useTranslation } from 'react-i18next';
import { loadStripe, Stripe, StripeElementLocale } from '@stripe/stripe-js';
import { stripeKey } from '../../config/api.config';
import { TStripeWithSomeInternals } from './StripeClientProvider';
import { useMergedClassNames } from '@utils/styling';
import styles from './StripeCardField.module.scss';

export interface IStripeCardFieldExposures {
  getElement: () => StripeCardElement | null;
  clear: () => void;
}

export type TStripeCardFieldProps<ElementProps extends TStripeElementProps> =
  Omit<ElementProps, 'onReady' | 'onChange' | 'onBlur'> & {
    StripeElement: React.ComponentType<ElementProps>;
    label?: React.ReactNode;
    placeholder?: string | null;
    brandPlaceholder?: `${EStripeCardBrand}`;
    className?: string;
    onBlur?: () => void;
    onChange?: (event: StripeCardElementChangeEvent) => void;
    onComplete?: (complete: boolean) => void;
    onReady?: () => void;
    elementRef?: MutableRef<StripeCardElement | null>;
    rootRef?: MutableRef<IStripeCardFieldExposures | null>;
  };

export const StripeCardField = <ElementProps extends TStripeElementProps>(
  props: TStripeCardFieldProps<ElementProps>,
) => {
  const {
    StripeElement: StripeElementComponent,
    onBlur,
    onChange,
    onComplete,
    onReady,
    label,
    placeholder,
    brandPlaceholder,
    className,
    elementRef: externalElementRef,
    rootRef,
  } = props;

  const t = useTranslate();
  const internalElementRef = useRef<StripeCardElement | null>(null);
  const [inferredBrand, setInferredBrand] = useState<EStripeCardBrand | undefined>();
  const errorDetailsRef = useRef<{ type: string; code: string } | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isTouched, setIsTouched] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isEmpty, setIsEmpty] = useState(true);

  const isErrorDisplayed = isTouched && isInvalid;

  const brand = inferredBrand ?? (brandPlaceholder as EStripeCardBrand);

  const translatedLabel = useMemo(() => t(label), [t, label]);
  const translatedPlaceholder = useMemo(() => t(placeholder), [t, placeholder]);
  const displayedError = isErrorDisplayed ? error : null;

  const mergeElementRef = useMemo(
    () => mergeRefs([internalElementRef, externalElementRef]),
    [externalElementRef],
  );

  const handleInputChange = useCallback(
    (event: StripeCardElementChangeEvent) => {
      if (event.error) {
        errorDetailsRef.current = {
          type: event.error.type,
          code: event.error.code,
        };
      }
      else {
        errorDetailsRef.current = null;
      }

      setError(event.error?.message ?? null);
      setIsInvalid(event.error ? true : false);
      setIsEmpty(event.empty);

      if ('brand' in event) {
        setInferredBrand(event.brand as EStripeCardBrand);
      }

      onChange?.(event);
      onComplete?.(event.complete);
    },
    [onChange, onComplete],
  );

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsTouched(true);
    onBlur?.();
  }, [onBlur]);

  const handleInputReady = useCallback((element: StripeCardElement) => {
    mergeElementRef(element);
    onReady?.();
  }, [mergeElementRef, onReady]);

  const exposures = useMemo(() => ({
    getElement: () => internalElementRef.current,
    clear: () => {
      internalElementRef.current?.clear();
      setIsTouched(false);
    },
  }), []);

  // TODO: Re-think with forwardRef, but resolve typing issues.
  useEffect(() => {
    if (!rootRef) return;
    if (typeof rootRef === 'function') {
      rootRef(exposures);
    }
    else {
      rootRef.current = exposures;
    }
  }, [rootRef, exposures]);

  const [, { language }] = useTranslation();

  // TODO: Move Stripe supportive Stripe logic into provider.
  useEffect(() => {
    const errorDetails = errorDetailsRef.current;
    if (!errorDetails) return;
    loadStripe(stripeKey, {
      locale: language as StripeElementLocale,
    }).then((stripe: Stripe | null) => {
      const instance = stripe as TStripeWithSomeInternals | null;
      if (!instance) return;
      instance._controller.action.fetchLocale({ locale: language }).then(() => {
        instance._controller.action.localizeError(errorDetails).then((response) => {
          setError(response.error.message);
        });
      });
    });
  }, [language]);

  const inputProps = useMemo<TStripeElementProps>(
    () => ({
      onChange: handleInputChange,
      onBlur: handleInputBlur,
      onFocus: handleInputFocus,
      onReady: handleInputReady,
      options: {
        style: {
          base: {
            fontFamily: '"IBM Plex Sans", sans-serif',
            fontWeight: 400,
            fontSize: '16px',
            color: '#000047',
          },
          invalid: {
            color: '#000047',
          },
        },
        classes: {
          base: styles.input,
        },
        placeholder: isEmpty && !isFocused && translatedPlaceholder
          ? translatedPlaceholder
          : '',
      },
    }),
    [
      handleInputChange,
      handleInputBlur,
      handleInputFocus,
      handleInputReady,
      isEmpty,
      isFocused,
      translatedPlaceholder,
    ],
  );

  const boxClassName = useMergedClassNames({
    [styles.box]: true,
    [styles.box_focused]: isFocused,
    [styles.box_invalid]: isErrorDisplayed,
  });

  return (
    <Field
      className={className}
      label={translatedLabel}
      error={displayedError}
    >
      <Box className={boxClassName} size="input">
        <StripeElementComponent {...(inputProps as ElementProps)} />
        <div className={styles.appendix}>
          <StripeCardBrandIcon brand={brand} />
        </div>
      </Box>
    </Field>
  );
};
