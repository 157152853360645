import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useBackButtonLocation } from '@features/metadata';
import { IStripeCardTokenGeneratorExposures, StripeCardTokenGenerator } from '@generics/stripe/StripeCardTokenGenerator';
import { StripeClientProvider } from '@generics/stripe';
import { usePaymentInfoQuery } from '@network/api/endpoints/payment-info/usePaymentInfoQuery';
import { useFormatDatetime } from '@utils/localizing';
import { EStripeCardBrand } from '@generics/stripe/StripeCardBrandIcon';
import { useUpdatePaymentInfoMutation, useDeletePaymentInfoMutation } from '@network/api';
import { Button } from '@generics/inputs/button';
import { Card } from '@generics/surfaces';
import { Typography } from '@generics/content';
import { PaymentMethod } from '@features/products';
import styles from './SettingsPaymentMethodsPage.module.scss';
import { useConfirmationDialog } from '@generics/ConfirmationDialog/useConfirmationDialog';

export const SettingsPaymentMethodsPage = () => {
  useBackButtonLocation('settings');

  const formatDatetime = useFormatDatetime();
  const tokenGeneratorRef = useRef<IStripeCardTokenGeneratorExposures | null>(null);
  const [stripeToken, setStripeToken] = useState<string | null>(null);
  const { openDialog, dialog } = useConfirmationDialog();
  const { data: paymentInfo, isFetching: isPaymentInfoFetching } = usePaymentInfoQuery();
  const updatePaymentInfoMutation = useUpdatePaymentInfoMutation();
  const deletePaymentInfoMutation = useDeletePaymentInfoMutation();

  const cardExpiresOn = paymentInfo.card_expires_on;
  const isStripeTokenGenerated = Boolean(stripeToken);
  const isAddButtonDisabled = !isStripeTokenGenerated || updatePaymentInfoMutation.isSuccess;
  const isDeleteButtonDisabled = isPaymentInfoFetching || deletePaymentInfoMutation.isSuccess;

  const formattedExpirationDate = useMemo(
    () => cardExpiresOn
      ? formatDatetime({ date: cardExpiresOn, format: 'MM / yy' })
      : 'mm / yy',
    [formatDatetime, cardExpiresOn],
  );

  const handleTokenChange = useCallback((token: string | null) => {
    setStripeToken(token);
  }, []);

  const handleAddPaymentMethodButtonClick = useCallback(async () => {
    if (!stripeToken) return;
    await updatePaymentInfoMutation.mutateAsync({ stripeToken });
    setTimeout(() => {
      tokenGeneratorRef.current?.clear();
      setStripeToken(null);
      updatePaymentInfoMutation.reset();
    }, 0);
  }, [stripeToken, updatePaymentInfoMutation]);

  const handlePaymentMethodDeleteButtonClick = useCallback(() => {
    openDialog({
      type: 'delete',
      title: 'labels.deletePaymentMethod',
      text: (
        <div className={styles.confirmation__content}>
          <Typography type="body/medium/l">
            labels.deletePaymentMethodConfirmation.title
          </Typography>
          <Typography type="body/regular/m">
            labels.deletePaymentMethodConfirmation.message
          </Typography>
        </div>
      ),
      cancelText: 'labels.cancel',
      okText: 'labels.delete',
      okIcon: null,
    }).then(async (isConfirm) => {
      if (!isConfirm) return;
      await deletePaymentInfoMutation.mutateAsync();
      setTimeout(() => {
        deletePaymentInfoMutation.reset();
      }, 0);
    });
  }, [openDialog, deletePaymentInfoMutation]);

  return (
    <div className={styles.root}>
      {dialog}
      <Typography type="body/medium/m">labels.yourPaymentMethod</Typography>
      {paymentInfo.card_number_fragment && paymentInfo.card_number_fragment !== ''
        ? (
            <PaymentMethod
              as={Card}
              brand={paymentInfo.card_brand?.toLocaleLowerCase() as EStripeCardBrand}
              numberFragment={paymentInfo.card_number_fragment}
              expirationDate={paymentInfo.card_expires_on}
              onDeleteButtonClick={handlePaymentMethodDeleteButtonClick}
              isDeleteButtonDisabled={isDeleteButtonDisabled}
              isDeleteButtonLoading={deletePaymentInfoMutation.isPending}
            />
          )
        : (
            <Card className={styles.card}>
              <StripeClientProvider>
                <StripeCardTokenGenerator
                  onChange={handleTokenChange}
                  numberPlaceholder={
                    paymentInfo.card_number_fragment
                      ? `**** **** **** ${paymentInfo.card_number_fragment}`
                      : 'fields.card_number.placeholder'
                  }
                  brandPlaceholder={paymentInfo.card_brand?.toLowerCase() as EStripeCardBrand}
                  expiryPlaceholder={formattedExpirationDate}
                  cvcPlaceholder={paymentInfo.card_expires_on ? '***' : 'xxx'}
                  ref={tokenGeneratorRef}
                />
                <Button
                  label="labels.addPaymentMethod"
                  onClick={handleAddPaymentMethodButtonClick}
                  disabled={isAddButtonDisabled}
                  isLoading={updatePaymentInfoMutation.isPending}
                />
              </StripeClientProvider>
            </Card>
          )}
    </div>
  );
};
