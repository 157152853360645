import React from 'react';
import SignInForm from './SignInForm';
import AuthLinks from '../../components/AuthLinks';
import SignPage from '../../components/SignPage';

const SignIn = () => {
  return (
    <>
      <SignPage>
        <SignInForm />
      </SignPage>
      <AuthLinks />
    </>
  );
};

export default SignIn;
