import React from 'react';
import { Typography } from '@generics/content';
import { useBackButtonLocation } from '@features/metadata';
import { PaymentCard } from '@features/products';
import { usePaymentHistoryQuery } from '@network/api';
import styles from './SettingsPaymentHistoryPage.module.scss';

export const SettingsPaymentHistoryPage = () => {
  useBackButtonLocation('settings');

  // TODO: Add payment history pagination.

  const { data: invoices } = usePaymentHistoryQuery();

  return (
    <div className={styles.root}>
      <Typography type="body/medium/m">labels.paymentHistory</Typography>
      <div className={styles.payments}>
        {invoices.map((invoice) => (
          <PaymentCard
            key={invoice.id}
            purpose={invoice.product_type}
            title={invoice.product_name}
            date={invoice.payment_date}
            currency={invoice.currency}
            price={invoice.total}
            method={invoice.payment_details}
          />
        ))}
      </div>
    </div>
  );
};
