import React, { useMemo, useState } from 'react';
import BaseCard, { IBaseCardProps } from '../BaseCard/BaseCard';
import styles from './DurationStep.module.scss';
import Counter from '../../Counter';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { EventProduct } from '../../../store/eventProducts/eventProducts.types';
import { TimeSlot } from '../../../store/booking/booking.types';
import OptionCard from '../components/OptionCard/OptionCard';
import { Controller } from '../../FormField/Controller';
import { IEventBookForm } from '../../../views/EventBooking/EventBooking';

export interface IDurationStepProps
  extends Pick<
    IBaseCardProps,
    | 'collapsed'
    | 'onForward'
    | 'onBack'
    | 'invalid'
    | 'isLoading'
    | 'revalidateStep'
  > {
  options: EventProduct[];
  timeSlots: TimeSlot[];
  isForwardDisabledBySummary: boolean;
}

export const CUSTOM_DURATION_ID = '97eae8ba-e054-11ee-97b2-6efb7b5f9f50';

const DurationStep: React.FC<IDurationStepProps> = ({
  options,
  timeSlots,
  revalidateStep,
  ...props
}) => {
  const [t] = useTranslation();
  const { control, watch, reset, getValues } = useFormContext<IEventBookForm>();
  const [counter, setCounter] = useState<number | string>(1);

  const selectedDuration = watch('durationId');
  const duration = watch('duration');

  const selectedDurationName = useMemo(() => {
    const selected = options.find(({ id }) => id === selectedDuration);
    const isLast = selected ? options.slice(-1)[0].id === selected.id : false;

    return selected
      ? `${selected.label}${
        isLast
          ? ` - ${duration} ${t('booking.steps.duration.counterSuffix')}`
          : ''
      }`
      : '';
  }, [selectedDuration, duration, options, t]);

  const bestsellerId: string = useMemo(() => {
    if (!timeSlots || !timeSlots.length) return '';

    return timeSlots.reduce(
      (acc, val) =>
        acc.duration_in_minutes > val.duration_in_minutes ? acc : val,
      timeSlots[0],
    ).product_id;
  }, [timeSlots]);

  return (
    <BaseCard
      title="booking.steps.duration.title"
      subtitle="booking.steps.duration.subtitle"
      forwardTitle="booking.steps.duration.forwardButton"
      collapseData={selectedDurationName}
      {...props}
      onForward={selectedDuration ? props.onForward : undefined}
    >
      <Controller
        control={control}
        name="durationId"
        rules={{
          required: 'Required',
        }}
        render={({ value }) => {
          if (options.length <= 1)
            return (
              <span className={styles.emptyState}>
                <Trans i18nKey="booking.steps.duration.emptyState">
                  Your request needs a special format,
                  <a href="mailto:events@racing-unleashed.com">
                    please contact our team
                  </a>
                </Trans>
              </span>
            );
          return (
            <div className={styles.durationStep}>
              {options.map(
                (
                  {
                    id,
                    label,
                    description,
                    price,
                    currency,
                    product_id,
                    event_duration_multiplier,
                  },
                  index,
                ) => {
                  const isSelected = value === id;
                  const isBestseller = bestsellerId === product_id;
                  const timeSlot = timeSlots.find(
                    (timeSlot) => timeSlot.product_id === product_id,
                  );
                  const isLast = index === options.length - 1;
                  const details = !isLast
                    ? description.split('\r\n')
                    : description;

                  return (
                    <OptionCard
                      key={id}
                      className={styles.durationCard}
                      selected={isSelected}
                      onClick={() => {
                        reset({
                          ...getValues(),
                          timeSlotId: timeSlot ? timeSlot.id : '',
                          duration:
                            id === CUSTOM_DURATION_ID
                              ? typeof counter === 'string' ? parseInt(counter) : counter
                              : event_duration_multiplier,
                          durationId: id,
                          time: '',
                        });
                        setTimeout(() => revalidateStep && revalidateStep(), 1);
                      }}
                    >
                      <>
                        {isBestseller
                          ? (
                              <p className={styles.durationCard__bestseller}>
                                {t('booking.steps.duration.bestseller')}
                              </p>
                            )
                          : (
                              ''
                            )}
                        <div className={styles.durationCard__contentWrap}>
                          <h4 className={styles.durationCard__title}>
                            {label}
                          </h4>
                          <div
                            className={classNames(
                              styles.durationCard__contentWrap,
                              styles.durationCard__contentWrap_minHeight,
                            )}
                          >
                            <div className={styles.durationCard__info}>
                              {Array.isArray(details)
                                ? (
                                    <ul>
                                      {details.map((detail, index) => (
                                        <li key={index}>{detail}</li>
                                      ))}
                                    </ul>
                                  )
                                : (
                                    details
                                  )}
                            </div>
                            {isLast
                              ? (
                                  <div className={styles.counter}>
                                    <Controller
                                      control={control}
                                      name="duration"
                                      render={({ value, onChange, onBlur }) => (
                                        <>
                                          <Counter
                                            allowFractional
                                            className={styles.counter__field}
                                            value={isSelected ? value : counter}
                                            step={0.5}
                                            min={1}
                                            max={8}
                                            onChange={(value) => {
                                              setCounter(value);

                                              if (isSelected) {
                                                onChange(typeof value === 'string' ? parseInt(value) : value);
                                              }
                                            }}
                                            onBlur={onBlur}
                                          />
                                          <span className={styles.counter__suffix}>
                                            {t(
                                              'booking.steps.duration.counterSuffix',
                                            )}
                                          </span>
                                        </>
                                      )}
                                    />
                                  </div>
                                )
                              : (
                                  ''
                                )}
                          </div>
                          <p className={styles.durationCard__price}>
                            {t('booking.steps.duration.from')}
                            {' '}
                            {price}
                            {' '}
                            {currency}
                          </p>
                        </div>
                      </>
                    </OptionCard>
                  );
                },
              )}
            </div>
          );
        }}
      />
    </BaseCard>
  );
};

export default DurationStep;
