import React from 'react';
import Select from '../../components/FormField/Select';
import {
  trackSelector,
  monthSelector,
  carSelector,
} from '../../store/track/track.selectors';
import {
  Month,
  Car,
  Track,
} from '../../store/track/track.types';
import useWithSelection from '../../hooks/useWithSelection';
import { SetStateType } from '../../types/utils.types';

const selectType = {
  track: {
    key: 'field.trackSelector',
    selector: trackSelector,
  },
  month: {
    key: 'field.monthSelector',
    selector: monthSelector,
  },
  car: {
    key: 'field.carSelector',
    selector: carSelector,
  },
};

interface RankingSelect {
  selected: string;
  type: keyof typeof selectType;
  setValue: SetStateType<string>;
}

const RankingSelect = React.memo(
  function RankingSelect({ selected, type, setValue }: RankingSelect) {
    const selection = selectType[type];
    const rankingSelects = useWithSelection<Track[] | Month[] | Car[]>(selection.selector);
    const setFieldValue = (_: string, value: string) => {
      setValue(value);
    };

    return (
      <div className="RankingSelect">
        <Select
          i18nKey={selection.key}
          name={`ranking-select-${type}`}
          value={selected}
          list={rankingSelects}
          setFieldValue={setFieldValue}
          disabled={!rankingSelects.length}
        />
      </div>
    );
  },
);

export default RankingSelect;
