import React, { useMemo } from 'react';
import { Box } from '@generics/surfaces';
import { Typography } from '@generics/content';
import { useFormatDatetime } from '@utils/localizing';
import * as Models from '@network/api/models';
import { useTranslate } from '@features/multi-language';

export type TUpcomingRideBoxProps = {
  ride: Models.Ride.Data;
};

export const UpcomingRideBox = (props: TUpcomingRideBoxProps) => {
  const { ride: { start_time, duration_in_minutes, lounge, number_of_simulators } } = props;

  const t = useTranslate();
  const formatDatetime = useFormatDatetime();

  const rideTypeLabel = useMemo(
    () => number_of_simulators === 1 ? t('labels.singleRide') : t('labels.groupRide'),
    [t, number_of_simulators],
  );

  const datetime = useMemo(
    () => formatDatetime({ date: start_time, format: 'dd MMMM yyyy, HH:mm' }),
    [formatDatetime, start_time],
  );

  return (
    <Box>
      <Typography type="body/medium/m">{datetime}</Typography>
      <Typography type="body/medium/l">
        {[
          rideTypeLabel,
          t('labels.durationInMinutes', { duration: duration_in_minutes }),
        ].join(', ')}
      </Typography>
      <Typography type="body/regular/s" color="secondary">
        {[lounge.name, t('labels.lounge')].join(' ')}
      </Typography>
    </Box>
  );
};
