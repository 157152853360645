import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormik, FormikValues, FormikHelpers } from 'formik';
import { resetPassword } from '../../../store/customer/customer.actions';
import FormField from '../../../components/FormField';
import Button from '../../../components/Button';
import validationSchema from './validationSchema';
import { formModel, initialValues } from './formModel';
import AuthFormMain from '../../../components/AuthFormMain';

const FORM_ID = 'resetPasswordForm';

const ForgotPasswordForm = () => {
  const { token } = useParams();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState<string | null>(null);
  const [t] = useTranslation();
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, formMethods: FormikHelpers<FormikValues>) => {
      try {
        setSubmitting(true);
        const response = await resetPassword(values, token);
        if (typeof response?.meta?.message !== 'string') return;
        setSuccessMsg(response.meta.message);
      }
      catch (error) {
        setSubmitting(false);
        const err = error as TRumpApiErrorResponseData;
        if (typeof err?.meta?.message === 'string')
          formMethods.setFieldError('password_confirmation', err.meta.message);
        if (err.meta.errors) {
          err.meta.errors.forEach((e) => {
            return formMethods.setFieldError(e.field, e.messages[0]);
          });
        }
      }
    },
  });
  const isValidForm = validationSchema.isValidSync({ ...formik.values });

  return (
    <AuthFormMain
      title="auth.resetPassword.title"
      subtitle="auth.resetPassword.subtitle"
      footer={(
        <Button
          form={FORM_ID}
          type="submit"
          isLoading={submitting}
          disabled={submitting || !isValidForm}
          label={t('field.resetPassword')}
        />
      )}
    >
      {successMsg
        ? (
            <p>
              {successMsg}
              {' '}
              <Link to="/auth/sign-in">{t('auth.backToSignIn')}</Link>
            </p>
          )
        : (
            <form id={FORM_ID} onSubmit={formik.handleSubmit}>
              {formModel.map((field) => (
                <FormField
                  key={field.name}
                  i18nKey={field.i18nKey}
                  type={field.type}
                  errors={formik.errors}
                  touched={formik.touched}
                  setFieldValue={formik.setFieldValue}
                  {...formik.getFieldProps(field.name)}
                />
              ))}
            </form>
          )}
    </AuthFormMain>
  );
};

export default ForgotPasswordForm;
