import React, { useMemo } from 'react';
import mergeClassNames from 'classnames';
import { Tabs as MuiTabs, TabsProps as MuiTabsProps } from '@mui/material';
import styles from './Tabs.module.scss';

export interface ITabsProps<Value extends number | string>
  extends Omit<
    MuiTabsProps,
    'variant' | 'scrollButtons' | 'onChange' | 'value'
  > {
  value: Value;
  onChange: (event: React.ChangeEvent<{}>, newValue: Value) => void;
}

const Tabs = <Value extends number | string,>(props: ITabsProps<Value>) => {
  const { classes } = props;

  const mergedClasses = useMemo(
    () => ({
      root: mergeClassNames(styles.root, classes ? classes.root : null),
      indicator: mergeClassNames(
        styles.indicator,
        classes ? classes.indicator : null,
      ),
      scrollable: mergeClassNames(
        styles.scrollableX,
        classes ? classes.scrollableX : null,
      ),
      scrollButtons: mergeClassNames(
        styles.scrollButtons,
        classes ? classes.scrollButtons : null,
      ),
    }),
    [classes],
  );

  return (
    <MuiTabs
      {...props}
      classes={mergedClasses}
      variant="scrollable"
      scrollButtons="auto"
    />
  );
};

export default Tabs;
