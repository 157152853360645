import React from 'react';
import { useTranslation } from 'react-i18next';
import { SetStateType } from '../../../types/utils.types';

import './EventsHeader.scss';

interface EventsHeader {
  onSet: SetStateType<boolean>;
  showDetails: boolean;
}

const EventsHeader = ({ onSet, showDetails }: EventsHeader) => {
  const [t] = useTranslation();

  const toggleDetails = () => onSet(!showDetails);

  return (
    <div className="EventsHeader">
      <h2>{t('events.events')}</h2>
      <div className="EventsHeader-switch" onClick={toggleDetails}>
        <span />
        <span />
        <span
          className={`EventsHeader-switch-slider ${
            showDetails ? 'is-detailed' : ''
          }`}
        />
      </div>
    </div>
  );
};

export default EventsHeader;
