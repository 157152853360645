import { useMemo, useState } from 'react';
import { Lounge, LoungeWithDistance } from '../store/lounge/lounge.types';
import useWithSelection from './useWithSelection';
import { loungeSelector } from '../store/lounge/lounge.selectors';
import { isSubstring } from '../utils/is-substring.utils';
import useGeolocation from './useGeolocation';
import { Customer } from '../store/customer/customer.types';
import { customerSelector } from '../store/customer/customer.selectors';

type TUserLoungeSearchResult = {
  lounges: LoungeWithDistance[];
  filteredLounges: LoungeWithDistance[];
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
};

export function useLoungeSearch(): TUserLoungeSearchResult {
  const customer: Customer = useWithSelection(customerSelector);
  const geolocation = useGeolocation();
  const [search, setSearch] = useState<string>('');
  const lounges: Lounge[] = useWithSelection(loungeSelector);

  const loungesWithDistance: LoungeWithDistance[] = useMemo(
    () =>
      lounges.map((lounge) => {
        const distance = geolocation.isAccessAllowed
          ? geolocation.getMetersTo({
            latitude: Number(lounge.lat),
            longitude: Number(lounge.lng),
          })
          : null;
        return {
          ...lounge,
          distance: distance ? Number(distance) : null,
        };
      }),
    [lounges, geolocation],
  );

  const sortedLounges = useMemo(() => {
    const firstSort = geolocation.isAccessAllowed
      ? loungesWithDistance.sort((lounge1, lounge2) =>
        distanceSort({ lounge1, lounge2 }),
      )
      : loungesWithDistance.sort((lounge1, lounge2) =>
        alphabetSort({ lounge1, lounge2 }),
      );

    return customer && customer.default_lounge_id
      ? firstSort.sort((lounge1, lounge2) =>
        defaultFirstSort({
          lounge1,
          lounge2,
          defaultId: customer.default_lounge_id || '',
        }),
      )
      : firstSort;
  }, [loungesWithDistance, geolocation, customer]);

  return useMemo(
    () => ({
      lounges: sortedLounges,
      filteredLounges: search
        ? sortedLounges.filter(({ name }) => isSubstring(name, search))
        : sortedLounges,
      search,
      setSearch,
    }),
    [sortedLounges, search, setSearch],
  );
}

function distanceSort({
  lounge1,
  lounge2,
}: {
  lounge1: LoungeWithDistance;
  lounge2: LoungeWithDistance;
}): number {
  return lounge1.distance
    && lounge2.distance
    && lounge1.distance > lounge2.distance
    ? 1
    : -1;
}

function alphabetSort({
  lounge1,
  lounge2,
}: {
  lounge1: LoungeWithDistance;
  lounge2: LoungeWithDistance;
}): number {
  return lounge1.name > lounge2.name ? 1 : -1;
}

function defaultFirstSort({
  lounge1,
  lounge2,
  defaultId,
}: {
  lounge1: LoungeWithDistance;
  lounge2: LoungeWithDistance;
  defaultId: string;
}): number {
  return lounge1.id === defaultId ? -1 : lounge2.id === defaultId ? 1 : 0;
}
