import React, { useCallback, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { AccountLayoutProvider } from './AccountLayoutProvider';
import { AccountLayoutSideDrawer } from './AccountLayoutSideDrawer';
import { AccountLayoutTopBar } from './AccountLayoutTopBar';
import { useMergedClassNames } from '@utils/styling';
import styles from './AccountLayout.module.scss';

export type TAccountLayoutProps = Extend<Omit<HTMLProps<HTMLDivElement>, 'children'>, {}>;

export const AccountLayout = (props: TAccountLayoutProps) => {
  const { className, ...restRootProps } = props;

  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);

  const openSideDrawer = useCallback(() => {
    setIsSideDrawerOpen(true);
  }, []);

  const closeSideDrawer = useCallback(() => {
    setIsSideDrawerOpen(false);
  }, []);

  const context = {
    isSideDrawerOpen,
    openSideDrawer,
    closeSideDrawer,
  };

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [className || '']: true,
  });

  return (
    <AccountLayoutProvider {...context}>
      <div {...restRootProps} className={rootClassName}>
        <AccountLayoutSideDrawer />
        <div className={styles.workspace}>
          <AccountLayoutTopBar />
          <main className={styles.content}>
            <Outlet />
          </main>
        </div>
      </div>
    </AccountLayoutProvider>
  );
};
