import { TUseApiQueryProps, useApiClient, useApiQuery } from '@network/api/client';
import { WretchError } from 'wretch';
import * as Models from '@network/api/models';

export type PackagesResponse = {
  data: Models.Membership.Data[];
};

export type TUsePackagesQuery = Omit<TUseApiQueryProps<PackagesResponse['data'], WretchError>, 'queryKey' | 'queryFn'> & {};

export function usePackagesQuery(props?: TUsePackagesQuery) {
  const apiClient = useApiClient();

  const { data, ...restQuery } = useApiQuery({
    placeholderData: [],
    ...props,
    queryKey: ['packages', 'all'],
    queryFn: () => apiClient.url(`/packages`)
      .get()
      .json<PackagesResponse>()
      .then(({ data }) => data),
  });

  return {
    data: data ?? [],
    ...restQuery,
  };
}
