import React, { useMemo } from 'react';

export interface ITabPanelProps extends React.HTMLProps<HTMLDivElement> {
  currentValue: number | string;
  value: number | string;
}

const TabPanel: React.FC<ITabPanelProps> = (props) => {
  const { currentValue, value, ...restProps } = props;

  const isHidden = useMemo(() => currentValue !== value, [currentValue, value]);

  return (
    <div {...restProps} style={isHidden ? { display: 'none' } : undefined} />
  );
};

export default TabPanel;
