import { TUseMembershipsQuery, useMembershipsQuery } from './useMembershipsQuery';
import { usePackagesQuery } from './usePackagesQuery';
import { useMemo } from 'react';

export type TUseProductsQuery = TUseMembershipsQuery;

export function useProductsQuery(props?: TUseProductsQuery) {
  const { data: membershipsData } = useMembershipsQuery(props);
  const { data: packagesData } = usePackagesQuery(props);

  const productsData = useMemo(() => [
    ...membershipsData,
    ...packagesData,
  ], [membershipsData, packagesData]);

  return useMemo(() => ({
    data: productsData,
  }), [productsData]);
}
