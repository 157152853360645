import { WretchError } from 'wretch';
import { IUseApiMutationProps, useApiClient, useApiMutation } from '@network/api/client';
import { useQueryClient } from '@network/queries';
import { toastUtil } from '@utils/toast.utils';
import * as Models from '@network/api/models';

export type TUseDeletePaymentInfoMutationResponse = { data: Models.PaymentMethod.Data; meta?: { message?: string } };

export type TUseDeletePaymentInfoMutationProps = Omit<
  IUseApiMutationProps<TUseDeletePaymentInfoMutationResponse, WretchError, void>,
  'mutationKey' | 'mutationFn'
>;

export function useDeletePaymentInfoMutation(props?: TUseDeletePaymentInfoMutationProps) {
  const { onSuccess, ...restHookProps } = props ?? {};

  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useApiMutation({
    ...restHookProps,
    mutationKey: ['paymentInfo', 'delete'],
    mutationFn: () =>
      apiClient.url(`/payment_info`)
        .delete()
        .json<TUseDeletePaymentInfoMutationResponse>()
        .then((response) => response),
    onSuccess: (response, variables, context) => {
      if (response?.meta?.message) toastUtil('info', response.meta.message);
      queryClient.refetchQueries({ queryKey: ['paymentInfo'], exact: false });
      onSuccess?.(response, variables, context);
    },
  });
}
