import React, { useCallback, useState } from 'react';
import { useRef } from 'react';
import ConfirmationDialog, {
  IConfirmationDialogProps,
  IConfirmationDialogRef,
} from './ConfirmationDialog';
import { ReactComponent as OkIcon } from '../../images/check-white.svg?tsx';

type TInputProps = Omit<
  IConfirmationDialogProps,
  'onConfirm' | 'onCancel' | 'onExtraAction'
>;

type TUseConfirmationDialogResult = {
  openDialog: (props?: TInputProps) => Promise<'ok' | 'extra' | boolean | null>;
  closeDialog: () => void;
  dialog: React.ReactNode;
};

const DEFAULT_PROPS: TInputProps = {
  title: 'confirmation.title',
  text: 'confirmation.text',
  okText: 'confirmation.ok',
  extraActionText: '',
  cancelText: 'confirmation.cancel',
  okIcon: <OkIcon />,
  type: 'default',
};

export function useConfirmationDialog(): TUseConfirmationDialogResult {
  const dialogRef = useRef<IConfirmationDialogRef | null>(null);
  const [props, setProps] = useState<IConfirmationDialogProps>({
    ...DEFAULT_PROPS,
    onConfirm: () => closeDialog(),
    onCancel: () => closeDialog(),
    onExtraAction: () => closeDialog(),
  });

  const closeDialog = () => dialogRef.current && dialogRef.current.close();

  const openDialog = useCallback(
    (props?: TInputProps) =>
      new Promise<boolean | 'ok' | 'extra'>((resolve) => {
        setProps(() => ({
          ...DEFAULT_PROPS,
          ...props,
          onConfirm: () => {
            resolve('ok');
            closeDialog();
          },
          onCancel: () => {
            resolve(false);
            closeDialog();
          },
          onExtraAction: () => {
            resolve('extra');
            closeDialog();
          },
        }));

        dialogRef.current?.open();
      }),
    [],
  );

  return {
    openDialog,
    closeDialog,
    dialog: <ConfirmationDialog ref={dialogRef} {...props} />,
  };
}
