import React, { useCallback, useMemo } from 'react';
import { Typography } from '@generics/content';
import { Card } from '@generics/surfaces';
import { usePageTitle } from '@features/metadata';
import { CustomerPackage } from '@features/products/packages';
import { Product, useProductPurchaseModal } from '@features/products';
import { accountPackagesRoute } from './route';
import { useTranslate } from '@features/multi-language';
import { useCustomerQuotasQuery, usePackagesQuery } from '@network/api';
import styles from './PackagesPage.module.scss';
import { useAuthenticatedCustomer } from '@features/authentication';
import Loader from '@generics/Loader';
import { useMergedClassNames } from '@utils/styling';

export const PackagesPage = () => {
  const {
    open: openProductPurchaseModal,
  } = useProductPurchaseModal();

  const t = useTranslate();
  const packages = usePackagesQuery();
  usePageTitle(accountPackagesRoute.title);

  const authenticatedCustomer = useAuthenticatedCustomer();

  const quotas = useCustomerQuotasQuery();

  const customerPackages = useMemo(
    () => authenticatedCustomer?.products
      ?.filter((product) => product.product_type.toLowerCase() === 'package') ?? [],
    [authenticatedCustomer],
  );

  const customerPackagesWithLimits = useMemo(() => {
    return customerPackages.map((pack) => {
      const quota = quotas.data.find((quota) => quota.bought_membership_id === pack.id);

      return {
        ...pack,
        limit: quota?.limit?.customer_limit,
      };
    });
  }, [customerPackages, quotas.data]);

  const handleProductPurchaseButtonClick = useCallback((id: string) => {
    openProductPurchaseModal({
      selectedProductType: 'package',
      selectedProductId: id,
    });
  }, [openProductPurchaseModal]);

  const vat = useMemo(() => {
    return t('labels.pricesAreIncludingVat', packages.data[0]);
  }, [t, packages]);

  const boughtPackagesClassName = useMergedClassNames({
    [styles.boughtPackages]: true,
    [styles.boughtPackages_empty]: !customerPackages.length,
  });

  return (
    <div className={styles.root}>
      <div className={boughtPackagesClassName}>
        {customerPackagesWithLimits.length
          ? (
              <>
                <Typography type="body/medium/m">labels.yourPackages</Typography>
                {customerPackagesWithLimits.map((pack) => (
                  <CustomerPackage
                    key={pack.id}
                    as={Card}
                    name={pack.name}
                    expirationDate={pack.expires_at}
                    limit={pack.limit}
                  />
                ))}
              </>
            )
          : null}
      </div>
      <div className={styles.packages}>
        {packages.isFetching ? <Loader height={80} width="100%" /> : null}
        {!packages.isFetching && packages.data.length
          ? (
              <>
                <Typography type="body/medium/m">labels.buyPackage</Typography>
                {packages.data.map((pack) => (
                  <Product
                    key={pack.id}
                    as={Card}
                    id={pack.id}
                    title={pack.name}
                    currency={pack.currency}
                    price={pack.price}
                    onPurchaseButtonClick={handleProductPurchaseButtonClick}
                  >
                    {pack.description}
                  </Product>
                ))}
                <Typography type="body/regular/s" color="secondary">{vat}</Typography>
              </>
            )
          : null}
      </div>
    </div>
  );
};
