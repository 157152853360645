import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener, Popper } from '@mui/material';
import { Card } from '@generics/surfaces';
import { useAuthentication } from '@features/authentication';
import { UnitedKingdomFlagIcon, SpainFlagIcon, GermanyFlagIcon } from '@images/flags';
import { ChevronDownIcon, CheckIcon } from '@images/icons';
import { useMergedClassNames } from '@utils/styling';
import styles from './LanguagePicker.module.scss';

const languages = [
  {
    id: 'en',
    name: 'English',
    icon: <UnitedKingdomFlagIcon />,
  },
  {
    id: 'es',
    name: 'Spanish',
    icon: <SpainFlagIcon />,
  },
  {
    id: 'de',
    name: 'German',
    icon: <GermanyFlagIcon />,
  },
];

export type TLanguagePickerProps = Omit<HTMLProps<HTMLButtonElement>, 'children'>;

export const LanguagePicker = (props: TLanguagePickerProps) => {
  const { onClick, className, ...restRootProps } = props;

  const { updateAuthenticatedCustomer } = useAuthentication();
  const [, i18next] = useTranslation();
  const buttonElementRef = useRef<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleButtonClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setIsOpen((previousIsOpen) => !previousIsOpen);
      onClick?.(event);
    }, [onClick],
  );

  const handlePopoverClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const getAnchorEl = useCallback(
    () => buttonElementRef.current ?? document.createElement('div'),
    [],
  );

  const getLanguageChangeHandler = useCallback(
    (languageId: string) => () => {
      updateAuthenticatedCustomer({ preferred_language: languageId });
      i18next.changeLanguage(languageId);
      setIsOpen(false);
    },
    [updateAuthenticatedCustomer, i18next],
  );

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [className || '']: true,
  });

  return (
    <>
      <button
        {...restRootProps}
        className={rootClassName}
        onClick={handleButtonClick}
        ref={buttonElementRef}
      >
        {languages.find((language) => language.id === i18next.language)?.icon}
        <ChevronDownIcon />
      </button>
      <Popper
        open={isOpen}
        anchorEl={getAnchorEl}
        placement="bottom-end"
        className={styles.popper}
      >
        <ClickAwayListener onClickAway={handlePopoverClose}>
          <Card className={styles.paper}>
            {languages.map((language) => (
              <div
                className={styles.language}
                key={language.id}
                onClick={getLanguageChangeHandler(language.id)}
              >
                <span className={styles.language__label}>
                  {language.icon}
                  <span>{language.name}</span>
                </span>
                {i18next.language === language.id ? <CheckIcon /> : null}
              </div>
            ))}
          </Card>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
