import * as Models from '@network/api/models';

export const SET_MEMBER = 'SET_MEMBER';
export const SIGN_UP = 'SIGN_UP';
export const SIGN_OUT = 'SIGN_OUT';

export interface BaseCustomer {
  id: string;
  email: string;
  nickname: string;
  first_name: string;
  last_name: string;
  password: string;
  profile_data_incomplete: boolean;
  address1?: string;
  address2?: string;
  city?: string;
  zip_code?: string;
  gender_id?: string;
  country_id?: string;
  pob_country_id?: string;
  company?: string;
  body_weight?: string;
  body_height?: string;
  dob?: string;
  fav_lounge_id?: string;
  fav_simulator_id?: string;
  phone_number?: string;
  registered_at?: string;
  preferred_language?: string;
  active: boolean;
  roles: string[];
  activated_at?: string;
  full_name?: string;
  unconfirmed_email: null | string;
  shareable_package_count: null | number;
  default_lounge_id?: string;
  profile_pic_url?: string;
  social_networks?: SocialNetwork[];
}

export interface Customer extends BaseCustomer {
  membership_plan?: Models.Membership.Data;
  experiences: number;
  tracks: number;
}

export interface AccountActivation {
  customer_id: string;
  token: string;
}

export interface SocialNetwork {
  id: string;
  social_network: string;
  url: string | null;
  username: string | null;
}

export interface CustomerState {
  customer: Customer | null;
}

export interface SignOutAction {
  type: typeof SIGN_OUT;
}

export interface SetCustomerAction {
  type: typeof SET_MEMBER;
  payload: Customer;
}

export type CustomerActionTypes =
  | SignOutAction
  | SetCustomerAction;
