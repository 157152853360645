import React, { forwardRef } from 'react';
import { useMergedClassNames } from '@utils/styling';
import styles from './Card.module.scss';

export type TCardProps = Extend<HTMLProps<HTMLDivElement>, {}>;

export const Card = forwardRef<HTMLDivElement, TCardProps>(
  function CardWithForwardedRef(props, ref) {
    const { children, className, ...restRootProps } = props;

    const rootClassName = useMergedClassNames({
      [styles.root]: true,
      [className || '']: true,
    });

    return (
      <div
        {...restRootProps}
        className={rootClassName}
        ref={ref}
      >
        {children}
      </div>
    );
  },
);
