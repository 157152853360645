import React, { TextareaHTMLAttributes } from 'react';
import styles from './AppTextarea.module.scss';
import { useTranslation } from 'react-i18next';

interface IAppTextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {}

const AppTextarea: React.FC<IAppTextareaProps> = ({
  placeholder,
  ...props
}) => {
  const [t] = useTranslation();

  return (
    <textarea
      {...props}
      className={styles.appTextarea}
      placeholder={placeholder ? t(placeholder) : undefined}
    />
  );
};

export default AppTextarea;
