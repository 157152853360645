import React from 'react';
import type { TMenuLinkProps } from '@generics/navigation';

export interface ICreateRouteDefinitionProps {
  uid: string;
  path: string;
  title: string;
  icon?: React.ReactNode;
}

export function createRouteDefinition(props: ICreateRouteDefinitionProps) {
  function toMenuLinkProps() {
    return {
      uid: props.uid,
      to: props.path,
      children: props.title,
      icon: props.icon,
    } satisfies TMenuLinkProps;
  }

  return {
    ...props,
    toMenuLinkProps,
  };
}
