import React, { useMemo } from 'react';
import BaseCard, { IBaseCardProps } from '../BaseCard/BaseCard';
import styles from './ProgramStep.module.scss';
import { Controller, useFormContext } from 'react-hook-form';
import { EventProduct } from '../../../store/eventProducts/eventProducts.types';
import OptionCard from '../components/OptionCard/OptionCard';
import { IEventBookForm } from '../../../views/EventBooking/EventBooking';

export interface IProgramStepProps
  extends Pick<
    IBaseCardProps,
    | 'collapsed'
    | 'onForward'
    | 'onBack'
    | 'invalid'
    | 'isLoading'
    | 'revalidateStep'
  > {
  options: EventProduct[];
}

const ProgramStep: React.FC<IProgramStepProps> = ({
  options,
  revalidateStep,
  ...props
}) => {
  const { control, watch, setValue } = useFormContext<IEventBookForm>();

  const selectedOption = watch('programId');
  const collapsedValue = useMemo(() => {
    const selected = options.find(({ id }) => id === selectedOption);

    return selected ? selected.label : '';
  }, [selectedOption, options]);

  return (
    <BaseCard
      title="booking.steps.program.title"
      subtitle="booking.steps.program.subtitle"
      forwardTitle="booking.steps.program.forwardButton"
      collapseData={collapsedValue}
      {...props}
      onForward={selectedOption ? props.onForward : undefined}
    >
      <Controller
        control={control}
        name="programId"
        rules={{
          required: 'Required',
        }}
        render={({ value }) => (
          <div className={styles.programStep}>
            {options.map(({ id, label, description }) => {
              const details = description.split('\r\n');

              return (
                <OptionCard
                  className={styles.card}
                  key={id}
                  selected={value === id}
                  onClick={() => {
                    setValue('programId', id);
                    revalidateStep?.();
                  }}
                >
                  <h4 className={styles.card__title}>{label}</h4>
                  <div className={styles.card__details}>
                    {details.length > 1
                      ? (
                          <ul>
                            {details.map((detail, index) => (
                              <li key={index}>{detail}</li>
                            ))}
                          </ul>
                        )
                      : (
                          details[0]
                        )}
                  </div>
                </OptionCard>
              );
            })}
          </div>
        )}
      />
    </BaseCard>
  );
};

export default ProgramStep;
