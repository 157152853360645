import React from 'react';
import { ReactComponent as Logo } from '@images/brand-logo.svg?tsx';
import { useMergedClassNames } from '@utils/styling';
import styles from './BrandLogo.module.scss';

export enum EBrandLogoDesign {
  Default = 'default',
  Primary = 'primary',
}

export type TBrandLogoProps = Extend<SVGProps<SVGSVGElement>, {
  title?: string;
  design?: `${EBrandLogoDesign}`;
  className?: string;
}>;

export const BrandLogo = (props: TBrandLogoProps) => {
  const {
    design = EBrandLogoDesign.Default,
    className,
    ...restRootProps
  } = props;

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [styles.root_primary]: design === EBrandLogoDesign.Primary,
    [className || '']: true,
  });

  return <Logo {...restRootProps} className={rootClassName} />;
};
