import { IUseApiMutationProps, useApiClient, useApiMutation } from '@network/api/client';
import { useDispatch } from 'react-redux';
import { SET_MEMBER } from '@store/customer/customer.types';
import { useQueryClient } from '@network/queries';
import { toastUtil } from '@utils/toast.utils';
import * as Models from '@network/api/models';
import { publish } from '../../../../events';
import { useProductsQuery } from './useProductsQuery';

export type TUseUpgradeProductMutationResponse = { data: Models.Customer.Data; meta?: { message?: string } };

export type TUseUpgradeProductMutationProps = Omit<IUseApiMutationProps<TUseUpgradeProductMutationResponse>, 'mutationKey' | 'mutationFn'>;

// TODO: Merge upgrade & renew mutations.

export function useUpgradeProductMutation(props?: TUseUpgradeProductMutationProps) {
  const { onSuccess, ...restHookProps } = props ?? {};

  const dispatch = useDispatch();
  const apiClient = useApiClient();
  const queryClient = useQueryClient();
  const { data: products } = useProductsQuery();

  return useApiMutation({
    ...restHookProps,
    mutationKey: ['membership', 'update'],
    mutationFn: (data?: { plan?: string; card_token?: string | null; save_card?: boolean }) =>
      apiClient.url(`/membership`)
        .patch(data)
        .json<TUseUpgradeProductMutationResponse>()
        .then((response) => response),
    onSuccess: (response, variables, context) => {
      dispatch({
        type: SET_MEMBER,
        payload: response.data,
      });
      queryClient.refetchQueries({ queryKey: ['memberships', 'all'], exact: false });
      queryClient.refetchQueries({ queryKey: ['packages', 'all'], exact: false });
      queryClient.refetchQueries({ queryKey: ['customer', 'quotas', 'all'], exact: false });
      if (response?.meta?.message) toastUtil('info', response.meta.message);

      const purchasedProduct = products?.find((product) => product.id === variables?.plan);

      if (purchasedProduct) {
        publish('Purchase', {
          content_category: 'Membership Plan',
          content_ids: [purchasedProduct.id],
          contents: [{
            id: purchasedProduct.id,
            quantity: 1,
            name: purchasedProduct.description,
          }],
          num_items: 1,
          currency: purchasedProduct.currency,
          value: purchasedProduct.price,
        });
      };

      onSuccess?.(response, variables, context);
    },
  });
}
