import React from 'react';
import { createPortal } from 'react-dom';

export type TPortalToBodyProps = {
  children?: React.ReactNode;
};

export const PortalToBody = (props: TPortalToBodyProps) => {
  const { children } = props;

  return createPortal(children, document.body);
};
