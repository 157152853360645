import { Customer } from '@store/customer/customer.types';
import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import useWithSelection from '../../hooks/useWithSelection';
import { customerSelector } from '@store/customer/customer.selectors';
import { getCustomer, signOut, updateCustomer } from '@store/customer/customer.actions';
import useWithDispatch from '../../hooks/useWithDispatch';
import { useQueryClient } from '@network/queries';
import { Models } from '@network/api';

export type TAuthenticationContextProps = {
  authenticatedCustomer: Customer | null;
  isAuthenticated: boolean;
  logout: () => void;
  updateAuthenticatedCustomer: (data: Partial<Customer>) => void;
};

export const AuthenticationContext = createContext<TAuthenticationContextProps | undefined>(undefined);

export type TAuthenticationProviderProps = {
  children?: React.ReactNode;
};

export const AuthenticationProvider = (props: TAuthenticationProviderProps) => {
  const { children } = props;

  const queryClient = useQueryClient();

  // TODO: Get rid of store usage.
  const fetchAuthenticatedCustomer = useWithDispatch(getCustomer);
  const authenticatedCustomer = useWithSelection(customerSelector);
  const updateCustomerAction = useWithDispatch(updateCustomer);
  const signOutAction = useWithDispatch(signOut);

  const isAuthenticated = Boolean(authenticatedCustomer);

  const logout = useCallback(() => {
    // TODO: Cancel only queries which requires authentication.
    queryClient.cancelQueries();
    signOutAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateAuthenticatedCustomer = useCallback((data: Partial<Customer>) => {
    if (!authenticatedCustomer) return;
    updateCustomerAction(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchAuthenticatedCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({ authenticatedCustomer, isAuthenticated, logout, updateAuthenticatedCustomer }),
    [authenticatedCustomer, isAuthenticated, logout, updateAuthenticatedCustomer],
  );

  return (
    <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>
  );
};

export function useAuthentication() {
  const context = useContext(AuthenticationContext);

  if (!context) {
    throw new Error('The useAuthentication() must be used within a <AuthenticationProvider />');
  }

  return context;
}

export function useAuthenticatedCustomer() {
  const { authenticatedCustomer } = useAuthentication();
  return authenticatedCustomer as unknown as Models.Customer.Data | null;
}
