import React, { useRef, useState } from 'react';
import Button from '../Button';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UploadIcon } from '../../images/upload.svg?tsx';
import { ReactComponent as CheckIcon } from '../../images/circle-check.svg?tsx';
import { ReactComponent as TrashIcon } from '../../images/trash.svg?tsx';
import styles from './AppUploadFileButton.module.scss';
import classNames from 'classnames';

interface IAppUploadFileButtonProps {
  label: string;
  accept: string;
  acceptLabel?: string;
  error?: string;
  maxSize: number; // example, 3md
  onChange: (file: File | null) => void;
  file?: File | string | null;
}

const AppUploadFileButton: React.FC<IAppUploadFileButtonProps> = ({
  label,
  accept,
  acceptLabel,
  maxSize,
  error,
  file,
  onChange,
}) => {
  const [t] = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [localError, setLocalError] = useState<string>('');

  return (
    <div className={styles.appUploadFileButton}>
      {file
        ? (
            <div className={styles.file}>
              <div className={styles.file__container}>
                <CheckIcon className={styles.file__checkIcon} />
                <p className={styles.file__text}>
                  {t('field.fileUpload.uploaded')}
                </p>
              </div>
              <div className={styles.file__divider} />
              <div
                className={classNames(
                  styles.file__container,
                  styles.file__container_pointer,
                )}
                onClick={() => onChange(null)}
              >
                <p className={classNames(styles.file__text, styles.file__text_red)}>
                  {t('field.fileUpload.delete')}
                </p>
                <TrashIcon className={styles.file__deleteIcon} />
              </div>
            </div>
          )
        : (
            <>
              <input
                hidden
                ref={inputRef}
                type="file"
                accept={accept}
                onChange={({ target: { files } }) => {
                  const file = files && files[0];
                  let error = '';

                  if (file && file.size > maxSize * 2 ** 20) {
                    error = t('field.validations.maxSize', { size: maxSize });
                  }

                  if (file && !accept.includes(file.type)) {
                    error = t('field.validations.invalidFileType');
                  }

                  setLocalError(error);

                  if (!error) {
                    onChange(file);
                  }
                }}
              />
              <Button
                appearance="outline"
                label={t(label)}
                icon={<UploadIcon />}
                onClick={() => inputRef.current && inputRef.current.click()}
              />
              <p className={styles.appUploadFileButton__caption}>
                {t('field.fileUpload.caption', {
                  formats: acceptLabel,
                })}
              </p>
              {error || localError
                ? (
                    <p
                      className={classNames(
                        styles.appUploadFileButton__caption,
                        styles.appUploadFileButton__caption_error,
                      )}
                      data-role="error"
                    >
                      {error || localError}
                    </p>
                  )
                : (
                    ''
                  )}
            </>
          )}
    </div>
  );
};

export default AppUploadFileButton;
