import React, { useMemo, useCallback, forwardRef, useRef } from 'react';
import { NumberFormatValues, NumberFormatBase, useNumericFormat } from 'react-number-format';
import isNumber from 'lodash/isNumber';
import { EInputMode } from '@generics/inputs/types';
import { Box } from '@generics/surfaces';
import { Typography } from '@generics/content';
import { mergeRefs } from '@utils/merge-refs.utils';
import { useMergedClassNames } from '@utils/styling';
import styles from './NumberInput.module.scss';

export enum ENumberInputLimitType {
  Min = 'min',
  Max = 'max',
}

export interface INumberInputProps extends Omit<HTMLProps<HTMLInputElement>, 'type' | 'value' | 'onChange' | 'size'> {
  decimalScale?: number;
  min?: number;
  max?: number;
  value?: number | null;
  onChange?: (value: number | null) => void;
  onLimitExceed?: (type: ENumberInputLimitType) => void;
  inputRef?: React.Ref<HTMLInputElement>;
  appendix?: React.ReactNode | ((props: { mode: `${EInputMode}`; isEmpty: boolean }) => React.ReactNode);
  inputProps?: Omit<React.HTMLProps<HTMLInputElement>, 'value' | 'onChange' | 'type' | 'size' | 'defaultValue'>;
  mode?: `${EInputMode}`;
  invalid?: boolean;
}

export const NumberInput = forwardRef<HTMLDivElement, INumberInputProps>(
  function NumberInput(props, ref) {
    const {
      decimalScale,
      id,
      min,
      max,
      value,
      onChange,
      onLimitExceed,
      inputRef: externalInputRef,
      onClick, readOnly,
      placeholder,
      inputProps,
      appendix,
      mode = EInputMode.Input,
      invalid = false,
      className,
      ...restRootProps
    } = props;

    const internalInputRef = useRef<HTMLInputElement | null>(null);
    const mergeInputRef = useMemo(
      () => mergeRefs([internalInputRef, externalInputRef]),
      [internalInputRef, externalInputRef],
    );

    const minAllowedValue = useMemo(() => min ?? Number.MIN_SAFE_INTEGER, [min]);
    const maxAllowedValue = useMemo(() => max ?? Number.MAX_SAFE_INTEGER, [max]);

    const transformValueToAllowed = useCallback((value: number | null) => {
      if (value === null) return null;
      return isNumber(value)
        ? Math.min(Math.max(value, minAllowedValue), maxAllowedValue)
        : minAllowedValue;
    }, [minAllowedValue, maxAllowedValue]);

    const allowedValue = useMemo(
      () => value ? transformValueToAllowed(Number(value)) : null,
      [value, transformValueToAllowed],
    );

    const isEmpty = useMemo(() => allowedValue === null, [allowedValue]);

    const valueRepresentation = useMemo(
      () => allowedValue?.toString() ?? '-',
      [allowedValue],
    );

    const renderedAppendix = useMemo(() => {
      if (typeof appendix === 'function') {
        return appendix({ mode, isEmpty });
      }

      return mode === EInputMode.Representation && isEmpty ? '' : appendix;
    }, [appendix, mode, isEmpty]);

    const renderedInputAppendix = useMemo(() => {
      if (typeof renderedAppendix === 'string') {
        return (
          <div className={styles.appendix}>
            <Typography type="body/regular/l">
              {renderedAppendix}
            </Typography>
          </div>
        );
      }

      return renderedAppendix;
    }, [renderedAppendix]);

    const handleValueChange = useCallback((values: NumberFormatValues) => {
      const { floatValue } = values;
      const allowedNewValue = transformValueToAllowed(floatValue ?? null);
      onChange?.(allowedNewValue);
    }, [onChange, transformValueToAllowed]);

    const rootClassName = useMergedClassNames({
      [styles.root]: true,
      [styles.root_invalid]: invalid,
      [className || '']: true,
    });

    const inputClassName = useMergedClassNames({
      [styles.input]: true,
      [inputProps?.className || '']: true,
    });

    const { format, ...restNumericInputProps } = useNumericFormat({
      id,
      className: inputClassName,
      placeholder,
      value: allowedValue,
      onValueChange: handleValueChange,
      readOnly,
      decimalScale,
      getInputRef: mergeInputRef,
    });

    const internalFormatFunctionRef = useRef(format);
    internalFormatFunctionRef.current = format;

    const handleValueFormat = useCallback((unformattedValue: string) => {
      if (!unformattedValue) return unformattedValue;
      let valueAsNumber = Number(unformattedValue);
      const preprocessedUnformattedValue = minAllowedValue > -1
        ? unformattedValue.replace('-', '')
        : unformattedValue;
      valueAsNumber = Number(preprocessedUnformattedValue);
      const format = internalFormatFunctionRef.current;
      const formattedValue = format?.(preprocessedUnformattedValue);
      if (isNumber(valueAsNumber) && valueAsNumber > maxAllowedValue) {
        onLimitExceed?.(ENumberInputLimitType.Max);
        return transformValueToAllowed(valueAsNumber)?.toString() ?? '';
      }
      if (isNumber(valueAsNumber) && valueAsNumber < minAllowedValue) {
        onLimitExceed?.(ENumberInputLimitType.Min);
        return transformValueToAllowed(valueAsNumber)?.toString() ?? '';
      }
      return formattedValue ?? '';
    }, [maxAllowedValue, minAllowedValue, transformValueToAllowed, onLimitExceed]);

    if (mode === EInputMode.Representation) {
      return (
        <Typography className={styles.representation} type="body/regular/m">
          <span>{valueRepresentation}</span>
          <span>{renderedAppendix}</span>
        </Typography>
      );
    }

    return (
      <Box
        {...restRootProps}
        className={rootClassName}
        onClick={onClick}
        size="input"
        ref={ref}
      >
        <NumberFormatBase
          {...restNumericInputProps}
          format={handleValueFormat}
        />
        {renderedInputAppendix}
      </Box>
    );
  },
);
