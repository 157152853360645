import React from 'react';
import styles from './BookingSummary.module.scss';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader';

interface IBookingSummaryProps {
  title?: string;
  currency?: string;
  vat?: number | string;
  total?: string | number;
  isLoading?: boolean;
  isExcludedVat?: boolean;
  children?: React.ReactNode;
}

const BookingSummary: React.FC<IBookingSummaryProps> = ({
  title,
  currency,
  vat,
  total,
  isLoading,
  isExcludedVat,
  children,
}) => {
  const [t] = useTranslation();

  return (
    <div className={styles.summary}>
      {title ? <h4 className={styles.summary__title}>{t(title)}</h4> : ''}
      <div className={styles.summary__content}>{children}</div>
      <div className={styles.summary__footer}>
        {currency && vat !== undefined && total !== undefined
          ? (
              <div className={styles.summary__vat}>
                {t(isExcludedVat ? 'booking.excludedVatInfo' : 'booking.vatInfo', { vat, currency })}
              </div>
            )
          : (
              ''
            )}
        <div className={styles.summary__total}>
          <h4>{t('booking.total')}</h4>
          <h4>{total === undefined ? '-' : total}</h4>
        </div>
      </div>
      {isLoading
        ? (
            <Loader fullSize color="dark-blue" height={80} width={80} />
          )
        : (
            ''
          )}
    </div>
  );
};

export default BookingSummary;
