import { format as performFormat, formatISO } from 'date-fns';

export class EnhancedDate extends Date {
  private _iso: string;
  private _isoDate: string;
  private _isoMonth: string;

  constructor(value?: number | string | Date) {
    super(value ?? new Date());
    this._iso = formatISO(this);

    const [isoDate] = this._iso.split('T');
    const [isoYear, isoMonth] = isoDate.split('-');
    this._isoDate = isoDate;
    this._isoMonth = `${isoYear}-${isoMonth}`;
  }

  get iso() {
    return this._iso;
  }

  get isoDate() {
    return this._isoDate;
  }

  get isoMonth() {
    return this._isoMonth;
  }

  toFormat(format: string) {
    return performFormat(this, format);
  }

  toISOString() {
    return this.iso;
  }
}
