import React, { useCallback, useMemo } from 'react';
import { Typography } from '@generics/content';
import { Form, FormField, useForm } from '@generics/forms';
import { Button } from '@generics/inputs/button';
import { Card } from '@generics/surfaces';
import { CheckIcon, CrossIcon, EditIcon } from '@images/icons';
import { useLoungesSelection, useSimulatorsSelection, useUpdateCustomerMutation } from '@network/api';
import { useAuthenticatedCustomer } from '@features/authentication';
import { useValidator } from '@utils/validation';
import styles from './CustomerRacingSection.module.scss';
import { useConfirmCancelChanges } from '@generics/forms/useConfirmCancelChanges';
import { EInputMode } from '@generics/inputs/types';

interface ICustomerRacingData {
  default_lounge_id: string;
  fav_lounge_id: string | null;
  fav_simulator_id: string | null;
}

export const CustomerRacingSection = () => {
  const authenticatedCustomer = useAuthenticatedCustomer();

  const validator = useValidator();

  const validationSchema = useMemo(() => validator.object().shape({
    default_lounge_id: validator.string().required(),
  }), [validator]);

  const form = useForm({
    defaultValues: ({
      default_lounge_id: authenticatedCustomer?.default_lounge_id || '',
      fav_lounge_id: authenticatedCustomer?.fav_lounge_id || null,
      fav_simulator_id: authenticatedCustomer?.fav_simulator_id || null,
    } satisfies ICustomerRacingData),
    mode: EInputMode.Representation,
    validationSchema,
  });

  const loungeId = form.watch('fav_lounge_id');

  const { loungesSelection } = useLoungesSelection();
  const { simulatorsSelection } = useSimulatorsSelection({ loungeId });

  const represent = useCallback(() => {
    setTimeout(() => {
      form.reset();
      form.represent();
    }, 0);
  }, [form]);

  const updateCustomerMutation = useUpdateCustomerMutation({
    onSuccess: () => {
      represent();
      setTimeout(() => {
        updateCustomerMutation.reset();
      }, 0);
    },
    onValidationErrors: form.setApiValidationErrors,
  });

  const { confirm: confirmCancelChanges, dialog } = useConfirmCancelChanges();

  const handleCancelButtonClick = useCallback(() => {
    confirmCancelChanges().then((isConfirm) => {
      if (!isConfirm) return;
      represent();
    });
  }, [confirmCancelChanges, represent]);

  const handleValidSubmit = useCallback(async (data: ICustomerRacingData) => {
    await updateCustomerMutation.mutateAsync(data);
  }, [updateCustomerMutation]);

  if (!authenticatedCustomer) return null;

  return (
    <Form className={styles.root} form={form} onValidSubmit={handleValidSubmit}>
      {dialog}
      <div className={styles.header}>
        <Typography type="body/medium/m">labels.racing</Typography>
        {form.mode === EInputMode.Representation
          ? (
              <Button
                type="button"
                design="text"
                label="labels.edit"
                icon={<EditIcon />}
                onClick={form.edit}
              />
            )
          : null}
      </div>
      <Card className={styles.card}>
        <div className={styles.fields}>
          <FormField
            name="default_lounge_id"
            type="select"
            items={loungesSelection}
            required
            disabled
          />
          <FormField
            name="fav_lounge_id"
            type="select"
            items={loungesSelection}
          />
          <FormField
            name="fav_simulator_id"
            type="select"
            items={simulatorsSelection}
          />
        </div>
        {form.mode === EInputMode.Input
          ? (
              <div className={styles.actions}>
                <Button
                  type="button"
                  design="outline"
                  label="labels.cancel"
                  icon={<CrossIcon />}
                  onClick={handleCancelButtonClick}
                />
                <Button
                  type="submit"
                  label="labels.save"
                  icon={<CheckIcon />}
                  isLoading={updateCustomerMutation.isPending}
                  disabled={updateCustomerMutation.isSuccess}
                />
              </div>
            )
          : null}
      </Card>
    </Form>
  );
};
