export default {
  application: 'Anwendung',
  home: 'Startseite',
  packages: 'Pakete',
  membership: 'Mitgliedschaft',
  ranking: 'Rangliste',
  championships: 'Meisterschaften',
  events: 'Veranstaltungen',
  notifications: 'Benachrichtigungen',
  noNotifications: 'Keine Benachrichtigungen',
  userSettings: 'Benutzereinstellungen',
  logOut: 'Abmelden',
  profileData: 'Profildaten',
  profile: 'Profil',
  changePassword: 'Passwort ändern',
  deleteAccount: 'Konto löschen',
  payments: 'Zahlungen',
  paymentMethods: 'Zahlungsmethoden',
  paymentHistory: 'Zahlungsverlauf',
  newBooking: 'Neue Buchung',
  yourProducts: 'Ihre Produkte',
  yourPackages: 'Ihre Pakete',
  yourMembership: 'Ihre Mitgliedschaft',
  yourStatistics: 'Ihre Statistiken',
  yourPaymentMethod: 'Ihre Zahlungsmethode',
  experience: 'Erfahrung',
  track: 'Strecke',
  markAllAsRead: 'Alle als gelesen markieren',
  yourUpcomingExperiences: 'Ihre bevorstehenden Erfahrungen',
  draftEvents: 'Entwurf Veranstaltungen',
  delete: 'Löschen',
  resume: 'Fortsetzen',
  renew: 'Erneuern',
  cancel: 'Abbrechen',
  showAll: 'Alle anzeigen',
  buyNow: 'Jetzt kaufen',
  payNow: 'Jetzt bezahlen',
  buyNew: 'Neu kaufen',
  bookARide: 'Fahrt buchen',
  buyPackage: 'Paket kaufen',
  buyMembership: 'Mitgliedschaft kaufen',
  renewMembership: 'Mitgliedschaft erneuern',
  payment: 'Zahlung',
  lounge: 'Lounge',
  chosenProduct: 'Ausgewähltes Produkt',
  chooseProduct: 'Produkt wählen',
  choosePaymentMethod: 'Zahlungsmethode wählen',
  addNewPaymentMethod: 'Neue Zahlungsmethode hinzufügen',
  addPaymentMethod: 'Zahlungsmethode hinzufügen',
  uploadNewPicture: 'Neues Bild hochladen',
  otherCreditCard: 'Andere Kreditkarte',
  deleteAvatar: 'Avatar löschen',
  avatar: 'Avatar',
  personalData: 'Persönliche Daten',
  address: 'Adresse',
  racing: 'Rennen',
  edit: 'Bearbeiten',
  save: 'Speichern',
  backToEdit: 'Zurück zum Bearbeiten',
  language: 'Sprache',
  singleRide: 'Einzelne Fahrt',
  groupRide: 'Gruppenfahrt',
  ridesLeft: '{{rides}} fahrten übrig',
  durationInMinutes: '{{duration}} min',
  deletePaymentMethod: 'Zahlungsmethode löschen',
  pricesAreIncludingVat: 'Preise sind inklusive {{vat}}% MwSt.',
  incompleteProfileDataMessage: 'Wir haben kürzlich unser Plattformsystem geändert. In diesem Zusammenhang bitten wir Sie, Ihr Profil mit den fehlenden Details zu aktualisieren.',
  changesCancelConfirmation: {
    title: 'Änderungen werden nicht gespeichert',
    message: 'Sind Sie sicher, dass Sie abbrechen möchten?',
  },
  deleteAccountConfirmation: {
    title: 'Sind Sie sicher, dass Sie Ihr Konto löschen möchten?',
    message: 'Diese Aktion ist unwiderruflich und Sie verlieren alle Ihre Daten und Inhalte, die mit diesem Konto verbunden sind.',
  },
  deletePaymentMethodConfirmation: {
    title: 'Sind Sie sicher, dass Sie Ihre Zahlungsmethode löschen möchten?',
    message: 'Diese Aktion ist unwiderruflich und Sie werden Ihre gespeicherten Zahlungsdetails nicht mehr sehen.',
  },
};
