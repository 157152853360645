import {
  TrackActionTypes,
  GET_TRACKS,
  GET_MONTHS,
  GET_CARS,
  Month,
  Car,
  Track,
} from './track.types';
import { Dispatch } from 'react';
import api from '../../config/api.config';
import { AxiosError } from 'axios';
import RankingItem from '../../views/Ranking/RankingItem';

export const getTracks = async (
  dispatch: Dispatch<TrackActionTypes>,
) => {
  try {
    const res = await api.get<{ data?: Track[] }>(`/rankings/tracks`);
    if (typeof res === 'undefined' || !res) return;
    const tracksData = res?.data?.data ?? [];

    dispatch({
      type: GET_TRACKS,
      payload: tracksData,
    });
  }
  catch (error) {
    const err = error as AxiosError<TRumpApiErrorResponseData>;
    return Promise.reject(typeof err.response !== 'undefined' ? err.response.data : err);
  }
};

export const getMonths = async (
  dispatch: Dispatch<TrackActionTypes>,
  track: string,
) => {
  try {
    const res = await api.get<{ data?: Month[] }>(`/rankings/tracks/${track}/months`);
    const months = res?.data?.data ?? [];

    dispatch({
      type: GET_MONTHS,
      payload: months,
    });

    return months;
  }
  catch (error) {
    const err = error as AxiosError<TRumpApiErrorResponseData>;
    return Promise.reject(typeof err.response !== 'undefined' ? err.response.data : err);
  }
};

export const getCars = async (
  dispatch: Dispatch<TrackActionTypes>,
  track: string,
) => {
  try {
    const res = await api.get<{ data?: Car[] }>(`/rankings/tracks/${track}/cars`);
    const cars = res?.data?.data ?? [];

    dispatch({
      type: GET_CARS,
      payload: cars,
    });
  }
  catch (error) {
    const err = error as AxiosError<TRumpApiErrorResponseData>;
    return Promise.reject(typeof err.response !== 'undefined' ? err.response.data : err);
  }
};

export const getRankings = async (
  track: string | number,
  month: string,
  car: string | number,
) => {
  try {
    const res = await api.get<{ data?: { ladderboard: RankingItem[] } }>(
      `/rankings/${track}${(month || car) && '?'}${
        month ? `date=${month}` : ''
      }${month && car && '&'}${car ? `car_id=${car}` : ''}`,
    );
    return res?.data?.data ?? { ladderboard: [] };
  }
  catch (err) {
    Promise.reject(err);
  }
};

export const getLapTime = (lapTime: number): string => {
  const minutes: number = Math.floor(lapTime / 60000);
  const seconds: string = Math.floor((lapTime % 60000) / 1000).toFixed(0);
  const miliseconds: number = lapTime % 1000;
  return `${minutes}:${seconds.toString().padStart(2, '0')}.${
    miliseconds.toString().padStart(3, '0')
  }`;
};
