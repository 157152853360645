import React from 'react';
import useWithDispatch from '../../hooks/useWithDispatch';
import { getCustomer } from '@store/customer/customer.actions';
import { getTracks } from '@store/track/track.actions';
import { getChampionshipsWithDispatch } from '@store/championships/championships.actions';
import { getEvents } from '@store/events/events.actions';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

export const LegacyDataProvider = () => {
  const customerDispatch = useWithDispatch(getCustomer);
  const tracksDispatch = useWithDispatch(getTracks);
  const championshipsDispatch = useWithDispatch(getChampionshipsWithDispatch);
  const eventsDispatchs = useWithDispatch(getEvents);
  const [, i18n] = useTranslation();

  useEffect(() => {
    eventsDispatchs(i18n.language);
    championshipsDispatch(i18n.language);
    // eslint-disable-next-line
  }, [i18n.language]);

  useEffect(() => {
    customerDispatch();
    tracksDispatch();
    championshipsDispatch();
    // eslint-disable-next-line
  }, []);

  return <Outlet />;
};
