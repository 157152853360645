import React from 'react';
import { useMergedClassNames } from '@utils/styling';
import styles from './ScaleStep.module.scss';

export type TScaleStepProps = Extend<HTMLProps<HTMLDivElement>, {
  active?: boolean;
}>;

export const ScaleStep = (props: TScaleStepProps) => {
  const { active, className, ...restRootProps } = props;

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [styles.root_active]: active,
    [className || '']: true,
  });

  return <div {...restRootProps} className={rootClassName} />;
};
