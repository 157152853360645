import { SimulatorState, SimulatorActionTypes, GET_SIMULATOR } from './simulator.types';

const initialState: SimulatorState = {
  simulator: [],
};

export default (state = initialState, action: SimulatorActionTypes) => {
  switch (action.type) {
    case GET_SIMULATOR:
      return {
        ...state,
        simulator: [...action.payload],
      };

    default:
      return state;
  }
};
