import React from 'react';
import Select, { SelectProps } from 'react-dropdown-select';
import styles from './AppSelect.module.scss';
import classNames from 'classnames';

export interface AppSelectProps<T> extends SelectProps<T> {
  size?: 'normal' | 'big';
}

const AppSelect = <T extends object | string = {}>({
  size,
  className,
  ...props
}: AppSelectProps<T>) => {
  return (
    <Select
      className={classNames(
        styles.select,
        { [styles.select_big]: size === 'big' },
        className,
      )}
      {...props}
    />
  );
};

export default AppSelect;
