import { Dispatch } from 'react';
import { API_ROOT } from '../../config/api.config';
import { Championship, ChampionshipActionType, GET_CHAMPIONSHIPS } from './championships.types';

export const getChampionshipsWithDispatch = async (
  dispatch: Dispatch<ChampionshipActionType>,
  lang?: string,
) => {
  try {
    const response = await fetch(`${API_ROOT}/championships`, {
      headers: {
        'x-locale': lang || 'en',
      },
    });
    const res = await response.json() as TRumpApiResponseData<Championship[]>;
    const championships = res.data;

    dispatch({
      type: GET_CHAMPIONSHIPS,
      payload: championships,
    });

    return championships;
  }
  catch (err) {
    return Promise.reject(err);
  }
};

export const getChampionships = async (lang?: string) => {
  try {
    const response = await fetch(`${API_ROOT}/championships`, {
      headers: {
        'x-locale': lang || 'en',
      },
    });
    const res = await response.json() as TRumpApiResponseData<Championship[]>;
    return res.data;
  }
  catch (error) {
    return Promise.reject(error);
  }
};

export const getChampionship = async (id?: string | number, lang?: string) => {
  try {
    const response = await fetch(`${API_ROOT}/championships/${id}`, {
      headers: {
        'x-locale': lang || 'en',
      },
    });
    const res = await response.json() as TRumpApiResponseData<Championship>;
    return res.data;
  }
  catch (err) {
    return Promise.reject(err);
  }
};
