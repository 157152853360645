import { ReactComponent as ParkingIcon } from '../images/lounge-features/parking-current-color.svg?tsx';
import { ReactComponent as BeveragesIcon } from '../images/lounge-features/beverages-current-color.svg?tsx';
import { ReactComponent as RestZoneIcon } from '../images/lounge-features/lounge-seats-current-color.svg?tsx';
import { ReactComponent as PublicTransportIcon } from '../images/lounge-features/public-transport-current-color.svg?tsx';
import { ReactComponent as SnacksIcon } from '../images/lounge-features/snacks-current-color.svg?tsx';
import { ReactComponent as TerraceIcon } from '../images/lounge-features/terrace-current-color.svg?tsx';

export enum ELoungeFeature {
  parkingPlaces = 'parking-places',
  publicTransport = 'public-transport',
  beverages = 'beverages',
  snacks = 'snacks',
  terrace = 'terrace',
  loungeSeats = 'lounge-seats',
}

export const loungeFeatureIcons: {
  [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
} = {
  [ELoungeFeature.parkingPlaces]: ParkingIcon,
  [ELoungeFeature.publicTransport]: PublicTransportIcon,
  [ELoungeFeature.beverages]: BeveragesIcon,
  [ELoungeFeature.snacks]: SnacksIcon,
  [ELoungeFeature.terrace]: TerraceIcon,
  [ELoungeFeature.loungeSeats]: RestZoneIcon,
};
