import React, { ChangeEvent, FocusEvent } from 'react';
import TextInput from './TextInput';
import Select from './Select';

import './FormField.scss';
import Datepicker from './Datepicker';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';

export interface IListField {
  label?: string;
  name?: string;
  id: string;
  value?: string;
}

export interface BasicField {
  name: string;
  i18nKey: string;
  type: string;
  value: string;
  touched?: FormikTouched<FormikValues>;
  errors?: FormikErrors<FormikValues>;
  errorMessage?: string;
  onChange?:
    | ((evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void)
    | ((name: string, date: Date) => void);
  onBlur?: (evt: FocusEvent<HTMLInputElement> | FocusEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;
  selectedDate?: Date;
  required?: boolean;
  className?: string;
}

interface IFormField extends BasicField {
  list?: IListField[];
  setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => Promise<FormikErrors<FormikValues>> | Promise<void>;
}

const FormField = ({
  name,
  onChange,
  value,
  type,
  i18nKey,
  errors,
  errorMessage,
  list,
  setFieldValue,
  touched,
  disabled,
  required,
  selectedDate,
  className,
}: IFormField) => {
  switch (type) {
    case 'select':
      return (
        <Select
          className={className}
          name={name}
          i18nKey={i18nKey}
          list={list}
          value={value}
          disabled={disabled}
          errors={errors}
          touched={touched}
          errorMessage={errorMessage}
          onChange={onChange as (evt: ChangeEvent<HTMLSelectElement>) => void}
          setFieldValue={setFieldValue}
        />
      );
    case 'datepicker':
      return (
        <Datepicker
          className={className}
          name={name}
          value={value}
          i18nKey={i18nKey}
          errors={errors}
          touched={touched}
          onChange={onChange as ((name: string, date: Date | null) => void)}
          selectedDate={selectedDate}
        />
      );
    default:
      return (
        <TextInput
          className={className}
          name={name}
          type={type}
          value={value}
          i18nKey={i18nKey}
          errors={errors}
          touched={touched}
          onChange={onChange}
          disabled={disabled}
          required={required}
        />
      );
  }
};

export default FormField;
