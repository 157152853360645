import React, { useMemo } from 'react';
import omit from 'lodash/omit';
import { addDays, startOfWeek } from 'date-fns';
import { LocaleUtils } from 'react-day-picker';
import { Typography } from '@generics/content';
import { useFormatDatetime } from '@utils/localizing';
import { useMergedClassNames } from '@utils/styling';
import styles from './DateSelectInputWeekday.module.scss';

export type TDateSelectInputWeekdayProps = Extend<HTMLProps<HTMLDivElement>, {
  weekday: number;
  localeUtils: LocaleUtils;
  locale: string;
  weekdaysShort: string[];
  weekdaysLong: string[];
}>;

export const DateSelectInputWeekday = (props: TDateSelectInputWeekdayProps) => {
  const { weekday, className, ...restRootProps } = props;

  const formatDatetime = useFormatDatetime();

  const restRootPropsWithoutUnusedOnes = useMemo(
    () => omit(restRootProps, [
      'localeUtils',
      'locale',
      'weekdaysShort',
      'weekdaysLong',
    ]),
    [restRootProps],
  );

  const weekStart = useMemo(() => startOfWeek(new Date()), []);

  const weekdayLabel = useMemo(
    () => formatDatetime({
      date: addDays(weekStart, weekday),
      format: 'EEEE',
    })?.slice(0, 1),
    [formatDatetime, weekStart, weekday],
  );

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [className || '']: true,
  });

  return (
    <div {...restRootPropsWithoutUnusedOnes} className={rootClassName}>
      <Typography
        className={styles.label}
        type="button/s"
        color="primary"
      >
        {weekdayLabel}
      </Typography>
    </div>
  );
};
