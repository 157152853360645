import { useMemo } from 'react';
import { useGenderCodesQuery } from './useGenderCodesQuery';

export function useGendersSelection() {
  const { data: genders } = useGenderCodesQuery();

  const gendersSelection = useMemo(() => genders.map((gender) => ({
    value: gender.id,
    children: gender.name,
  })), [genders]);

  return { gendersSelection };
}
