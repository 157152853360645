import React from 'react';
import { MembershipIcon } from '@images/icons';
import { createRouteDefinition } from '@utils/routing';

export const accountMembershipRoute = createRouteDefinition({
  uid: 'membership',
  path: '/account/membership',
  icon: <MembershipIcon />,
  title: 'labels.membership',
});
