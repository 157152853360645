import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useWithSelection from '../../hooks/useWithSelection';
import { customerSelector } from '../../store/customer/customer.selectors';
import useWithDispatch from '../../hooks/useWithDispatch';
import { updateCustomer } from '../../store/customer/customer.actions';
import avatarPlaceholder from '../../images/avatar.svg';
import styles from './AvatarImage.module.scss';
import classNames from 'classnames';

type PropsOfAvatarImage = {
  className?: string;
  size?: 'small' | 'medium' | 'big';
};

const AvatarImage: React.FC<PropsOfAvatarImage> = ({ className, size = 'medium' }) => {
  const [t] = useTranslation();
  const [newAvatar, setNewAvatar] = useState<string>('');
  const customer = useWithSelection(customerSelector);
  const { profile_pic_url, nickname } = customer;
  const updateAvatarAction = useWithDispatch(updateCustomer);
  const fileTypes = ['png', 'jpg', 'jpeg'];

  const changeHandler = (evt: ChangeEvent<HTMLInputElement>) => {
    const { files } = evt.target;

    if (!files?.length) return;

    const fileExtension
      = files[0].name
        .toLowerCase()
        .split('.')
        .pop();

    if (fileExtension && !fileTypes.includes(fileExtension)) {
      console.log('error file type');
      return;
    }
    if (files[0].size / Math.pow(1024, 2) > 5) {
      console.log('error size of img');
      return;
    }

    const reader = new FileReader();
    reader.addEventListener('load', (e) => {
      if (e.target) {
        if (e.target.result instanceof ArrayBuffer) {
          const decoder = new TextDecoder('utf-8');
          const decodedResult = decoder.decode(e.target.result);
          setNewAvatar(decodedResult);
        }

        if (typeof e.target.result === 'string') {
          setNewAvatar(e.target.result);
        }

        updateAvatarAction({ ...customer, profile_pic: e.target.result });
      }
    });
    reader.readAsDataURL(files[0]);
  };
  return (
    <div
      className={classNames(styles.root, { [styles.root_hasOverflow]: !newAvatar && !profile_pic_url, [styles.root_small]: size === 'small', [styles.root_big]: size === 'big' }, className)}
    >
      <label>
        <input type="file" onChange={changeHandler} />
        {(profile_pic_url || newAvatar) && <div>{t('field.change')}</div>}
      </label>
      {/* <img src={newAvatar || profile_pic_url || avatarPlaceholder} alt={nickname} /> */}
      <img src={profile_pic_url || newAvatar || avatarPlaceholder} alt={nickname} />
    </div>
  );
};

export default AvatarImage;
