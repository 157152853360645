import React, { useMemo, useCallback, forwardRef, memo } from 'react';
import { format } from 'date-fns';
import mergeClassNames from 'classnames';
import styles from './CalendarDay.module.scss';
import { EnhancedDate } from '../../utils/enhanced-date.utils';
import { CircularProgress } from '@mui/material';

export interface ICalendarDayProps {
  date: Date;
  barColor?: string;
  discount?: string;
  loading?: boolean;
  disabled?: boolean;
  selected?: boolean;
  onSelect?: (date: Date) => void;
}

const CalendarDay = memo(
  forwardRef<HTMLDivElement, ICalendarDayProps>(function CalendarDay(
    { date, barColor, discount, loading, disabled, selected, onSelect },
    ref,
  ) {
    const enhancedDate = useMemo(() => new EnhancedDate(date), [date]);

    const name = useMemo(() => format(date, 'eee'), [date]);

    const number = useMemo(() => format(date, 'd'), [date]);

    const rootClassName = useMemo(
      () =>
        mergeClassNames({
          [styles.root]: true,
          [styles.root_disabled]: disabled,
          [styles.root_selected]: selected,
          [styles.root_hoverable]: !disabled,
        }),
      [disabled, selected],
    );

    const loaderClasses = useMemo(() => ({ root: styles.loader }), []);

    const handleClick = useCallback(() => {
      if (loading || disabled || !onSelect) return;
      onSelect(date);
    }, [date, loading, disabled, onSelect]);

    return (
      <div
        className={rootClassName}
        onClick={handleClick}
        ref={ref}
        data-iso-date={enhancedDate.isoDate}
      >
        {loading ? <div className={styles.loaderContainer}><CircularProgress classes={loaderClasses} /></div> : null}
        {!loading && discount
          ? (
              <div className={styles.discount}>{discount}</div>
            )
          : null}
        <div>
          <div className={styles.name}>{name}</div>
          <div className={styles.number}>{number}</div>
        </div>
        <div
          className={styles.availabilityBar}
          style={{ backgroundColor: barColor }}
        />
      </div>
    );
  }),
);

export default CalendarDay;
