import React from 'react';
import { Avatar } from '@generics/content';
import { Models } from '@network/api';
import { useMergedClassNames } from '@utils/styling';
import styles from './CustomerSnippet.module.scss';

export type TCustomerSnippetProps = Extend<Omit<HTMLProps<HTMLDivElement>, 'children'>, {
  customer: Models.Customer.Data | null;
}>;

export const CustomerSnippet = (props: TCustomerSnippetProps) => {
  const { customer, className, ...restRootProps } = props;

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [className || '']: true,
  });

  return (
    <div {...restRootProps} className={rootClassName}>
      <Avatar className={styles.avatar} src={customer?.profile_pic_url} />
      <div className={styles.info}>
        <div className={styles.name}>{customer?.full_name}</div>
        <div className={styles.email}>{customer?.email}</div>
      </div>
    </div>
  );
};
