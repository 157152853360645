import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './AppStepper.module.scss';
import { ReactComponent as CheckIcon } from '../../images/check.svg?tsx';
import { ReactComponent as ErrorSvg } from '../../images/exclamation-circle.svg?tsx';
import AppProgressCircle from '../AppProgressCircle/AppProgressCircle';

export interface IAppStep {
  key: string;
  label: string;
}

interface IAppStepperProps {
  /**
   * Steps array must contain translation keys
   */
  steps: IAppStep[];
  errorSteps?: string[];
  currentStep: number;
  className?: string;
}

const AppStepper: React.FC<IAppStepperProps> = ({
  steps,
  errorSteps,
  currentStep,
  className,
}) => {
  const [t] = useTranslation();

  const progress = useMemo(
    () => Math.ceil(((currentStep + 1) / steps.length) * 100),
    [steps, currentStep],
  );

  const progressLabel = useMemo(
    () => `${currentStep + 1} of ${steps.length}`,
    [steps, currentStep],
  );

  const currentStepLabel = useMemo(
    () => steps[currentStep].label,
    [steps, currentStep],
  );

  const nextStepLabel = useMemo(() => {
    const step = steps[currentStep + 1];
    return step ? step.label : '';
  }, [steps, currentStep]);

  return (
    <>
      <div className={classNames(styles.stepper, className)}>
        {steps.map(({ key, label }, index) => {
          const isError = errorSteps && errorSteps.includes(key);
          const isCompleted = currentStep > index;
          return (
            <div
              key={label}
              className={classNames(styles.step, {
                [styles.step_active]: currentStep === index,
                [styles.step_completed]: isCompleted,
                [styles.step_error]: isError,
              })}
            >
              <div className={styles.step__circle}>
                {isError
                  ? (
                      <ErrorSvg
                        className={classNames(
                          styles.step__icon,
                          styles.step__icon_error,
                        )}
                      />
                    )
                  : isCompleted
                    ? (
                        <CheckIcon className={styles.step__icon} />
                      )
                    : (
                        index + 1
                      )}
              </div>
              <div className={styles.step__label}>{t(label)}</div>
            </div>
          );
        })}
      </div>
      <div className={classNames(styles.mobileStepper, className)}>
        <div className={styles.mobileStepper__progress}>
          <AppProgressCircle label={progressLabel} progress={progress} />
        </div>
        <div>
          <div className={styles.mobileStepper__current}>
            {t(currentStepLabel)}
          </div>
          {nextStepLabel
            ? (
                <div className={styles.mobileStepper__next}>
                  Next:
                  {' '}
                  {t(nextStepLabel)}
                </div>
              )
            : (
                ''
              )}
        </div>
      </div>
    </>
  );
};

export default AppStepper;
