import { useCallback, useEffect, useMemo, useState } from 'react';
import { IAppStep } from './AppStepper';

interface useAppStepperResult {
  currentStep: number;
  isFirst: boolean;
  isLast: boolean;
  latestVisitedStep: number;
  nextStep: () => void;
  prevStep: () => void;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  setLatestVisitedStep: React.Dispatch<React.SetStateAction<number>>;
}

export function useAppStepper(
  steps: IAppStep[],
  initialStep?: number,
): useAppStepperResult {
  const [currentStep, setCurrentStep] = useState<number>(initialStep || 0);
  const [latestVisitedStep, setLatestVisitedStep]
    = useState<number>(currentStep);

  useEffect(() => {
    if (currentStep > latestVisitedStep) setLatestVisitedStep(currentStep);
    // eslint-disable-next-line
  }, [currentStep]);

  const nextStep = useCallback(
    () => currentStep < steps.length && setCurrentStep(currentStep + 1),
    [currentStep, steps.length],
  );

  const prevStep = useCallback(
    () => currentStep > 0 && setCurrentStep(currentStep - 1),
    [currentStep],
  );

  const result = useMemo(
    () => ({
      currentStep,
      isFirst: currentStep === 0,
      isLast: currentStep === steps.length - 1,
      latestVisitedStep,
      nextStep,
      prevStep,
      setCurrentStep,
      setLatestVisitedStep,
    }),
    [
      currentStep,
      latestVisitedStep,
      steps.length,
      nextStep,
      prevStep,
      setCurrentStep,
      setLatestVisitedStep,
    ],
  );

  return result;
}
