const policy = {
  data: `

  <h2>Einführung</h2>

  <p >Sammeln und Verwenden personenbezogener Daten Personenbezogene Daten sind Daten,
  die verwendet werden können, um eine Person zu identifizieren oder um eine bestimmte
  Person zu kontaktieren.
  </p>

  <p>
  Wenn Sie mit Racing Unleashed AG verbundenen Unternehmen («Racing Unleashed») in
Kontakt treten, werden Sie möglicherweise gebeten, personenbezogene Daten anzugeben.
Racing Unleashed AG und seine verbundenen Unternehmen können diese
personenbezogenen Daten untereinander austauschen und sie nach Maßgabe dieser
Datenschutzrichtlinie nutzen. Sie können solche Daten auch mit anderen Informationen
verbinden, um unsere Produkte, Dienstleistungen, Inhalte und Werbung anzubieten oder zu
verbessern. Sie sind nicht gezwungen, die angeforderten personenbezogenen Daten
anzugeben. In diesem Fall wird es uns allerdings vielfach nicht möglich sein, Sie über unsere
Produkte oder Leistungen zu informieren oder Ihre Anfragen zu beantworten.</p>

<p class="TermsPolicy-margin">
Hier sind einige Beispiele für verschiedene Arten personenbezogener Daten, die Racing
Unleashed AG möglicherweise sammelt, und wie diese verwendet werden:
</p>

<h2>Welche personenbezogenen Daten wir erheben</h2>

  <p>
  Wenn Sie, Kontakt mit uns aufnehmen (zum Beispiel über soziale Netzwerke), unsere Lounges
besuchen oder an einer Online-Umfrage teilnehmen, können wir eine Vielzahl von Daten
erheben, einschließlich Name, Adresse, Telefonnummer, E-Mail-Adresse, Informationen zur
bevorzugten Kontaktaufnahme, Gerätekennungen, IP-Adressen, Standortinformationen,
Kreditkarteninformationen und Profilinformationen, wenn der Kontakt über ein soziales
Netzwerk erfolgt.
  </p>
  
  <p>
  Mit Ihrer Zustimmung können wir Ihre personenbezogenen Daten, inklusive Foto- und
Filmmaterial, für die in dieser Datenschutzrichtlinie beschriebenen Zwecke verarbeiten sowie
zur Einhaltung gesetzlicher Verpflichtungen, denen Racing Unleashed unterliegt, für die
Erfüllung eines Vertrages, an dem Sie beteiligt sind, um Ihre wesentlichen Interessen zu
schützen, oder wenn wir zur Erkenntnis gelangen, dass das Offenlegen von Informationen für
die Verfolgung der legitimen Interessen von Racing Unleashed oder Dritten notwendig ist, was
ggf. die Weitergabe von Daten an solche Dritte einschließt. Wenn Sie Fragen zur rechtlichen
Grundlage der Datenverarbeitung haben können Sie den Datenschutzbeauftragten
kontaktieren.
  </p>

  <p>
  Die personenbezogenen Daten, die wir erheben, erlauben uns, Sie über die beispielsweise
neuesten Produktankündigungen von Racing Unleashed zu informieren.
  </p>

  <p>
  Personenbezogene Daten unterstützen uns auch bei Erstellung, Entwicklung, Bereitstellung,
Erbringung und Verbesserung unserer Produkte, Dienstleistungen, Inhalte und Werbung.
  </p>

  <p>
  Gelegentlich können wir personenbezogene Daten dazu nutzen, um wichtige Mitteilungen zu
versenden, beispielsweise über Änderungen unserer Geschäftsbedingungen und Richtlinien.
Weil diese Informationen für die Beziehung zu Racing Unleashed wichtig sind, kann man den
Erhalt dieser Informationen möglicherweise nicht ablehnen.
  </p>

  <p>
  Wenn Sie an Gewinnspielen, Preisausschreiben oder ähnlichen Aktionen teilnehmen, können
wir die zur Verfügung gestellten Informationen zur Verwaltung dieser Programme nutzen.
  </p>

  <p class="TermsPolicy-margin">
  Wenn Sie sich auf eine Stelle bei Racing Unleashed bewerben oder wir Ihre Informationen in
Verbindung mit einer potenziellen Position bei uns erhalten, können wir Ihre Informationen
verwenden, um Ihre Bewerbung zu beurteilen und Kontakt mit Ihnen aufzunehmen. Falls Sie
ein aussichtsreicher Kandidat sind, erhalten Sie weitere Informationen darüber, wie Racing
Unleashed die personenbezogenen Daten von Kandidaten bei Bewerbungen handhabt.</p>

<h2>
Erheben und Nutzen von nicht personenbezogenen Daten
</h2>

<p >Wir erheben auch Daten in einer Form, die für sich allein genommen keinen direkten Bezug zu
einer bestimmten Person erlauben. Wir können nicht personenbezogene Daten für jeden
Zweck erheben, nutzen, weitergeben und offenlegen. Nachfolgend sind einige Beispiele für
nicht personenbezogene Daten aufgeführt, welche wir erheben, und wie wir sie nutzen können:
</p>

<p class="TermsPolicy-margin">
Wir erheben Daten wie namentlich Beruf, Sprache, Postleitzahl, Vorwahl, individuelle
Geräteidentifizierungsmerkmale, Weiterleitungs-URL sowie Ort und Zeitzone, damit wir das
Verhalten unserer Kunden besser verstehen und unsere Produkte, Dienste und Werbung
verbessern können</p>


<h2>
Cookies und andere Technologien</h2>

<p >
Websites, Onlinedienste, interaktive Programme, E-Mails und Werbematerialien von Racing
Unleashed können „Cookies“ und andere Technologien verwenden, beispielsweise Zählpixel
(Pixel Tags) und Webbeacons. Diese Technologien erlauben uns, das Nutzerverhalten besser
zu verstehen, informieren uns, welche Teile unserer Websites besucht wurden, und
vereinfachen und messen die Effizienz von Werbung und der Websuchen. Wir behandeln
Daten, die von Cookies und anderen Technologien gesammelt wurden, als nicht
personenbezogene Daten. Soweit Internet-Protokolladressen (IP-Adressen) oder ähnliche
Identifizierungsmerkmale durch das anwendbare Recht jedoch als personenbezogene Daten
eingestuft werden, behandeln wir diese Identifizierungsmerkmale ebenfalls als
personenbezogene Daten. Entsprechend werden wir, soweit nicht personenbezogene Daten
mit personenbezogenen Daten verknüpft werden, die verknüpften Daten für die Zwecke dieser
Datenschutzrichtlinie als personenbezogene Daten behandeln.
</p>

<p class="TermsPolicy-margin">
Racing Unleashed verwendet ebenfalls Cookies und andere Technologien, um persönliche
Daten zu vermerken, wenn Sie unsere Website, Onlinedienste und Anwendungen benutzen.
Unser Ziel in diesen Fällen ist, Ihre Erfahrung mit Racing Unleashed komfortabler und
persönlicher zu gestalten.
</p>

<h2>
Schutz personenbezogener Daten</h2>

<p class="TermsPolicy-margin">
Racing Unleashed legt großen Wert auf die Sicherheit Ihrer personenbezogenen Daten. Wenn
Sie bestimmte Produkte, Dienste oder Anwendungen von Racing Unleashed nutzen oder
personenbezogene Daten in einem Forum, Chatroom oder sozialen Netzwerk einstellen, sind
diese personenbezogenen Daten und Inhalte für andere Nutzer sichtbar und können von
diesen gelesen, erfasst oder genutzt werden. Die Verantwortung für die personenbezogenen
Daten, die Sie dabei jeweils teilen oder angeben, liegt ausschließlich bei Ihnen. Wenn Sie
beispielsweise Ihren Namen und Ihre E-Mail-Adresse in einem Forum bekannt geben, sind
diese Informationen öffentlich. Nutzen Sie diese Funktionen mit Vorsicht.
</p>


<h2>
Schutz und Aufbewahrung von Kreditkarteninformationen</h2>

<p class="TermsPolicy-margin">
Kreditkarteninformationen werden für die Buchung von Veranstaltungen, Fahrten,
Abonnements, Mehrwertdiensten (z.B. Renncoaching) und Rennen verwendet. Ihre
Kreditkartendaten werden in unseren Systemen nur mit den letzten vier Ziffern der Nummer
und dem Verfallsdatum gespeichert. Zusätzlich wird die Art der Bankkarte gespeichert. Wir
werden Ihre Kreditkartendaten nur mit Ihrer ausdrücklichen Zustimmung für jede durchgeführte
Zahlungsaktion verwenden.
</p>

<h2>
Integrität und Aufbewahrung von personenbezogenen Daten</h2>

<p class="TermsPolicy-margin">
Wir bewahren personenbezogene Daten so lange wie notwendig auf, um die in dieser
Datenschutzrichtlinie und unseren dienstspezifischen Übersichten zum Datenschutz
beschriebenen Zwecke zu erfüllen. Beim Bemessen der hierfür erforderlichen Zeiträume
erwägen wir sorgfältig, inwieweit eine Erhebung personenbezogener Daten überhaupt
erforderlich ist. Stellen wir die Notwendigkeit zum Erheben solcher Daten fest, so bewahren
wir sie nur so lange auf, wie zum gegebenen Zwecke unbedingt erforderlich ist, es sei denn,
längere Aufbewahrungsfristen sind gesetzlich ausdrücklich vorgeschrieben.
</p>

<h2>
Fragen zum Datenschutz</h2>

<p>
Wenn Sie Fragen oder Bedenken bezüglich der Racing Unleashed Datenschutzrichtlinie oder
der Datenverarbeitung haben, Datenschutzbeauftragten erreichen möchten oder wenn Sie
einen möglichen Verstoß gegen örtliche Datenschutzgesetze anzeigen möchten, können Sie
Kontakt mit uns aufnehmen. 
</p>

<p>
Dieser sichtet die Kontaktanfrage und nimmt sich Ihrem Problem an. Wo die Problematik
substanziellerer Natur ist, holen wir ggf. weitere Informationen von Ihnen ein. Alle solchen
substanziellen Kontaktanfragen erhalten, soweit möglich, innerhalb von sieben (7) Tagen eine
Rückmeldung. Dabei wird auf das vorliegende Problem eingegangen, es werden ggf. weitere
Informationen angefordert oder es wird darauf hingewiesen, dass für eine Antwort noch etwas
Zeit benötigt wird. Wenn Sie in Ihrer Beschwerde darauf hinweisen, dass der Umgang mit Ihren
Problemen mit dem Datenschutz verbessert werden könne, werden wir entsprechende Schritte
unternehmen, um dies bei der nächstmöglichen Gelegenheit umzusetzen. Falls sich ein
Problem mit dem Datenschutz negativ auf Sie oder eine andere Person ausgewirkt hat, werden
wir entsprechende Schritte unternehmen, um das Problem für Sie oder die andere Person zu
lösen.
</p>

<p>
Racing Unleashed ist berechtigt, die Racing Unleashed Datenschutzrichtlinie zu ändern,
soweit dies erforderlich ist. Werden an der Datenschutzrichtlinie wesentliche Änderungen
vorgenommen, wird eine entsprechende Mitteilung zusammen mit der aktuellen Version der
Datenschutzrichtlinie auf unserer Website veröffentlicht. Wir werden Sie über die für Sie
hinterlegten Kontaktdaten kontaktieren, z. B. per E-Mail, Benachrichtigung oder über eine
andere vergleichbare Methode.
</p>

<p>
6330 Cham, den 12.09.2019
</p>

<p>
Racing Unleashed AG
</p>
  `,
};

export default policy;
