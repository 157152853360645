import { useCallback } from 'react';
import { FieldValues, Resolver } from 'react-hook-form';
import { ObjectSchema, ValidationError } from 'yup';

export interface IUseFormResolverProps<ValidationSchema extends ObjectSchema<FieldValues>> {
  validationSchema?: ValidationSchema;
}

export function useFormResolver<Values extends FieldValues, ValidationSchema extends ObjectSchema<FieldValues>>({
  validationSchema,
}: IUseFormResolverProps<ValidationSchema>) {
  return useCallback(async (values: Values) => {
    if (!validationSchema) return { values, errors: {} };

    try {
      const validatedValues = await validationSchema.validate(values, {
        abortEarly: false,
      });

      return {
        values: validatedValues,
        errors: {},
      };
    }
    catch (error) {
      const validationError = error as ValidationError;
      return {
        values: {},
        errors: validationError.inner.reduce(
          (allErrors, currentError) => ({
            ...allErrors,
            [currentError.path!]: {
              type: currentError.type ?? 'unknown',
              message: currentError.message,
            },
          }),
          {},
        ),
      };
    }
  }, [validationSchema]) as Resolver<Values>;
}
