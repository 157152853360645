import React from 'react';
import { RankingIcon } from '@images/icons';
import { createRouteDefinition } from '@utils/routing';

export const accountRankingRoute = createRouteDefinition({
  uid: 'ranking',
  path: 'ranking',
  icon: <RankingIcon />,
  title: 'labels.ranking',
});
