import React, { useMemo } from 'react';
import { AspectRatio, TAspectRatioProps } from './AspectRatio';
import { ImageIcon } from '@images/icons';
import { useMergedClassNames } from '@utils/styling';
import styles from './ImagePlaceholder.module.scss';

export enum EImagePlaceholderType {
  Default = 'default',
  Broken = 'broken',
}

export type TImagePlaceholderProps = Extend<TAspectRatioProps, {
  type?: EImagePlaceholderType;
  icon?: React.ReactNode;
}>;

export const ImagePlaceholder = (props: TImagePlaceholderProps) => {
  const { icon, className, ...restRootProps } = props;

  const computedIcon = useMemo(
    () => typeof icon === 'undefined' ? <ImageIcon className={styles.icon} /> : icon,
    [icon],
  );

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [className || '']: true,
  });

  return (
    <AspectRatio {...restRootProps} className={rootClassName}>
      {computedIcon}
    </AspectRatio>
  );
};
