const header = {
  about: 'About us',
  connect: 'Connect',
  signin: 'Sign in',
  experience: 'Experience',
  join: 'Join',
  compete: 'Compete',
  followUs: 'Follow us',
  doBusiness: 'Do Business',
  becomeAMember: 'Become a member',
  myProfile: 'My Profile',
  myAccount: 'My Account',
  changePassword: 'Change Password',
  billing: 'Payment Methods',
  membership: 'Membership',
  notifications: 'Notifications',
  dashboard: 'Dashboard',
  rides: 'Book',
  ranking: 'Ranking',
  championships: 'Championships',
  events: 'Events',
  signOut: 'Sign Out',
};

export default header;
