export default {
  unknown: {
    label: 'The field',
  },
  profile_pic: {
    label: 'Avatar',
  },
  nickname: {
    label: 'Nickname',
    placeholder: 'Enter nickname',
  },
  gender_id: {
    label: 'Gender',
    placeholder: 'Select gender',
  },
  first_name: {
    label: 'First name',
    placeholder: 'Enter first name',
  },
  last_name: {
    label: 'Last name',
    placeholder: 'Enter last name',
  },
  email: {
    label: 'Email',
    placeholder: 'Enter email',
  },
  phone_number: {
    label: 'Phone number',
    placeholder: 'Enter phone number',
  },
  company: {
    label: 'Company',
    placeholder: 'Enter company name',
  },
  body_weight: {
    label: 'Body weight',
    placeholder: 'Enter body weight',
  },
  body_height: {
    label: 'Body height',
    placeholder: 'Enter body height',
  },
  dob: {
    label: 'Date of birth',
    placeholder: 'Select date of birth',
  },
  pob_country_id: {
    label: 'Country of birth',
    placeholder: 'Select country of birth',
  },
  default_lounge_id: {
    label: 'Default lounge',
    placeholder: 'Select default lounge',
  },
  fav_lounge_id: {
    label: 'Favorite lounge',
    placeholder: 'Select favorite lounge',
  },
  fav_simulator_id: {
    label: 'Favorite simulator',
    placeholder: 'Select favorite simulator',
  },
  address1: {
    label: 'Address',
    placeholder: 'Enter first address line',
  },
  address2: {
    label: 'Address line 2',
    placeholder: 'Enter second address line',
  },
  country_id: {
    label: 'Country',
    placeholder: 'Select country',
  },
  city: {
    label: 'City',
    placeholder: 'Enter city name',
  },
  zip_code: {
    label: 'Zip code',
    placeholder: 'Enter zip code',
  },
  password: {
    label: 'New Password',
    placeholder: 'Enter new password',
  },
  password_confirmation: {
    label: 'Confirm Password',
    placeholder: 'Confirm new password',
  },
  card_number: {
    label: 'Card Number',
    placeholder: 'Enter card number',
  },
  card_expiry: {
    label: 'Expiration Date',
  },
  card_cvc: {
    label: 'CVC',
  },
};
