import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthentication } from './AuthenticationProvider';
import { logoutRoute } from '@routes/authentication';

const signInPath = '/auth/sign-in';

export const SecuredRoutes = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuthentication();

  if (!isAuthenticated) {
    const destination = [logoutRoute.path].includes(location.pathname)
      ? signInPath
      : `${signInPath}?redirectTo=${location.pathname}`;

    return <Navigate to={destination} />;
  }

  return <Outlet />;
};
