import { QueryClient } from '@tanstack/react-query';

export function createQueryClient() {
  return new QueryClient({
    defaultOptions: {
      mutations: {
        retry: false,
        networkMode: 'online',
        throwOnError: false,
        /**
         * The GC stands for Garbage Collection.
         *
         * https://tanstack.com/query/latest/docs/framework/react/guides/migrating-to-v5#rename-cachetime-to-gctime
         */
        gcTime: 0,
      },
      queries: {
        enabled: true,
        staleTime: 0,
        retry: false,
        retryOnMount: false,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
        refetchInterval: false,
        refetchIntervalInBackground: false,
        placeholderData: null,
        networkMode: 'online',
        throwOnError: false,
        structuralSharing: true,
        /**
         * The GC stands for Garbage Collection.
         *
         * https://tanstack.com/query/latest/docs/framework/react/guides/migrating-to-v5#rename-cachetime-to-gctime
         */
        gcTime: 0,
      },
    },
  });
}
