import { Code, CodeActionTypes, GET_EVENT_TYPES } from './code.types';
import i18n from '../../i18n/index';
import { Dispatch } from 'react';
import api, { API_ROOT } from '../../config/api.config';
import { AxiosError } from 'axios';

const getOptions = () => ({
  headers: {
    'x-locale': i18n.language,
  },
});

export const getEventTypeCodes = async (
  dispatch: Dispatch<CodeActionTypes>,
) => {
  try {
    const res = await api.get<{ data?: Code[] }>(`${API_ROOT}/codes/event_types`, getOptions());

    const codeData = res?.data?.data ?? [];

    dispatch({
      type: GET_EVENT_TYPES,
      payload: codeData,
    });
  }
  catch (err) {
    const error = err as AxiosError<TRumpApiRequestError>;
    return Promise.reject(
      'response' in error ? error.response?.data : error,
    );
  }
};
