import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format as dateFnsFormat, formatRelative as dateFnsFormatRelative, parseISO } from 'date-fns';
import en from 'date-fns/locale/en-GB';
import de from 'date-fns/locale/de';
import es from 'date-fns/locale/es';
import { useTranslate } from '@features/multi-language';

const locales: Record<string, Locale> = {
  en,
  de,
  es,
};

function isDate<Value>(value: Value) {
  return value instanceof Date;
}

export type TFormatDatetimeProps = {
  date?: Date | string | null;
  format?: string;
  relativeToDate?: Date | string | null;
};

export type TFormatDatetimeContextProps = (props: TFormatDatetimeProps) => string | null;

const FormatDatetimeContext = createContext<TFormatDatetimeContextProps | undefined>(undefined);

export type TFormatDatetimeProviderProps = {
  children?: React.ReactNode;
};

export const FormatDatetimeProvider = (props: TFormatDatetimeProviderProps) => {
  const { children } = props;

  const [, { language }] = useTranslation();
  const t = useTranslate();

  const translateAndEscape = useCallback(<Params extends Parameters<typeof t>>(...params: Params) => {
    const [key, ...restParams] = params;
    if (typeof key !== 'string') return '';
    const translation = t(key, ...restParams);
    return typeof translation === 'string' && translation === key
      ? `'${translation}'`
      : translation;
  }, [t]);

  const relativeTranslations = useMemo(() => ({
    lastWeek: translateAndEscape('datetime.relative.lastWeek'),
    yesterday: translateAndEscape('datetime.relative.yesterday'),
    today: translateAndEscape('datetime.relative.today'),
    tomorrow: translateAndEscape('datetime.relative.tomorrow'),
    nextWeek: translateAndEscape('datetime.relative.nextWeek'),
    other: translateAndEscape('datetime.relative.other'),
  }), [translateAndEscape]);

  const locale = useMemo(() => ({
    ...locales[language],
    formatRelative: (token: keyof typeof relativeTranslations) => relativeTranslations[token],
  }), [language, relativeTranslations]);

  const formatDatetime = useCallback((formatProps: TFormatDatetimeProps) => {
    const { date, format, relativeToDate } = formatProps;
    // TODO: Verify parsing process is error-proof.
    const parsedDate = typeof date === 'string' ? parseISO(date) : date;
    const parsedRelativeToDate = typeof relativeToDate === 'string' ? parseISO(relativeToDate) : relativeToDate;
    if (!isDate(parsedDate)) return null;
    // TODO: Purify.
    try {
      const result = isDate(parsedRelativeToDate)
        ? dateFnsFormatRelative(parsedDate, parsedRelativeToDate, { locale })
        : dateFnsFormat(parsedDate, format ?? '', { locale });
      return result;
    }
    catch {
      const translatedFormat = translateAndEscape(format);
      return isDate(parsedRelativeToDate)
        ? dateFnsFormatRelative(parsedDate, parsedRelativeToDate, { locale })
        : dateFnsFormat(parsedDate, translatedFormat, { locale });
    }
  }, [translateAndEscape, locale]);

  return (
    <FormatDatetimeContext.Provider value={formatDatetime}>
      {children}
    </FormatDatetimeContext.Provider>
  );
};

export function useFormatDatetime() {
  const formatDatetime = useContext(FormatDatetimeContext);

  if (!formatDatetime) {
    throw new Error('The useFormatDatetime() must be used within a <FormatDatetimeProvider />');
  }

  return formatDatetime;
};
