import React from 'react';
import { Typography } from '@generics/content';
import { useMergedClassNames } from '@utils/styling';
import styles from './Field.module.scss';

export type TFieldProps = Extend<HTMLProps<HTMLDivElement>, {
  inputId?: string;
  label?: React.ReactNode;
  error?: React.ReactNode;
  required?: boolean;
  hideLabel?: boolean;
}>;

export const Field = (props: TFieldProps) => {
  const {
    inputId,
    label,
    error,
    required = false,
    hideLabel = false,
    children,
    className,
    ...restRootProps
  } = props;

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [className || '']: true,
  });

  return (
    <div {...restRootProps} className={rootClassName}>
      {label && !hideLabel
        ? (
            <div className={styles.label}>
              <Typography type="body/regular/m" color="trinary" as="label" htmlFor={inputId}>{label}</Typography>
              {required ? <Typography type="body/regular/s" color="danger">*</Typography> : null}
            </div>
          )
        : null}
      {children}
      {error ? <Typography type="body/regular/s" color="danger">{error}</Typography> : null}
    </div>
  );
};
