const terms = {
  data: `
  <h2>Einführung<br/>
  Allgemeine Geschäftsbedingungen zur Nutzung der Racing Unleashed-Lounges</h2>

  <h2 class="TermsPolicy-margin">Geltungsbereich</h2>

  <p>Diese Geschäftsbedingungen gelten für Verträge zur kurzfristigen Miete von Räumlichkeiten
  und weitere Infrastruktur der Racing Unleashed-Lounges und der Benützung der in den
  Lounges zur Verfügung gestellten Rennsimulatoren, zur Durchführung von Firmenanlässen
  sowie für alle damit zusammenhängenden weiteren Leistungen und Lieferungen.</p>

  <p>
  Die Unter- oder Weitervermietung der überlassenen Räume, Flächen oder Infrastruktur ist
  grundsätzlich nicht vorgesehen und kann nur mit schriftlicher Einwilligung von Racing
  Unleashed AG erfolgen.
  </p>

  <p class="TermsPolicy-margin">
  Abweichende Bestimmungen finden nur Anwendung, wenn sie vorher ausdrücklich schriftlich
vereinbart worden sind.
  </p>

  <h2>
  Vertragsabschluss
  </h2>

  <p >
  Der Nutzung- und Dienstleistungsvertrag (nachfolgend „Vertrag“ genannt) kommt durch
  schriftliche Annahme des von Racing Unleashed AG abgegebenen Angebots durch den
  Besteller zustande.
  </p>

  <p>
  Ist der Nutzniesser nicht der Besteller selbst, so haftet der Besteller zusammen mit dem
Nutzniesser solidarisch für alle Verpflichtungen aus dem Vertrag. Der Besteller hat Racing
Unleashed AG rechtzeitig vor dem Vertragsabschluss darauf hinzuweisen, wer Besteller und
wer Nutzniesser ist, und Racing Unleashed Namen und Anschrift des tatsächlichen
Nutzniessers mitzuteilen. In diesem Fall hat der Besteller eine entsprechende Erklärung der
Nutzniessers Racing Unleashed AG vorzulegen.
  </p>

  <p class="TermsPolicy-margin">
  Wird ein Vertrag mit mehr als einem Vertragspartner abgeschlossen, gelten diese Allgemeinen
Geschäftsbedingungen für sämtliche Vertragspartner, insbesondere haften alle
Vertragspartner von Racing Unleashed AG solidarisch für die vertraglichen Verpflichtungen.
  </p>



  <h2>
  Leistungen
  </h2>

  <p >
  Racing Unleashed AG verpflichtet sich, die vom Vertragspartner (Besteller) bestellten und von
  Racing Unleashed AG zugesagten Leistungen zu erbringen.
  </p>

  <p>
  Der Vertragspartner verpflichtet sich, die für diese und weitere in Anspruch genommene
  Leistungen vereinbarten Preise zu zahlen. 
  </p>

  <p>
  Die Leistungen umfassen:
  </p>

  <ol class="TermsPolicy-margin">
    <li>
    Benützung der Lounge gemäss Vereinbarung.
    </li>
    <li>
    Instruktion und Koordination mit verschiedenen Drittparteien (Simulatorbetreuer, Catering).
    </li>
    <li>
    Eine Person des Event-Managements als verantwortliche Ansprechperson während der
Veranstaltung.
    </li>
    <li>
    Endreinigung der Lounge nach durchgeführtem Anlass
    </li>
  </ol>

  <h2>
  Dauer der Leistungen
  </h2>

  <p class="TermsPolicy-margin">
  Die Räumlichkeiten von Racing Unleashed AG können stundenweise, für Halbtagesanlässe
  (Vormittag oder Nachmittag) und für Ganztagesanlässe beansprucht werden. Wird die
  vereinbarte Dauer des Anlasses überschritten, kann Racing Unleashed AG die ihr daraus
  entstehenden internen und externen Kosten zusätzlich verrechnen.
  </p>

  <h2>
  Preisvorbehalt
  </h2>

  <p class="TermsPolicy-margin">
  Die Preisangaben im Angebot bzw. in der Bestätigung basieren auf dem
  Wissensstand/Projektstand zum Zeitpunkt der Ausfertigung des Angebotes bzw. der
  Bestätigung. Es gelten die Preise zum Zeitpunkt der Angebotserstellung. Ändert sich das
  Umfang nach Vertragsabschluss, so werden diese Änderungen zu den zum Zeitpunkt des
  Vertragsabschlusses gültigen Ansätzen fakturiert.
  </p>

  <h2>
  Rechnungstellung
  </h2>

  <p>
  Vom Vertragspartner gewünschte Zusatzleistungen oder Wünsche an Ort, die in der
  Basisofferte nicht enthalten sind, wie zum Beispiel erweitertes Catering, sowie vom
  Vertragspartner in Auftrag gegebene andere Arbeiten, werden von Racing Unleashed AG nach
  Möglichkeit ausgeführt. Die Verrechnung erfolgt nach Aufwand. 
  </p>

  <p>
  Die vereinbarten Leistungen im Zusammenhang mit der Nutzung werden von Racing
Unleashed AG nach dem jeweiligen Anlass in Rechnung gestellt und sind innerhalb von 30
Tagen nach Rechnungsstellung ohne Abzug zur Zahlung fällig. Bei Zahlungsverzug ist Racing
Unleashed AG berechtigt, Verzugszinsen von 5 Prozent in Rechnung zu stellen. 
  </p>

  <p>
  Die Verrechnung mit Forderungen des Vertragspartners ist nur möglich, wenn dies im Voraus
schriftlich festgelegt wurde.
  </p>

  <p>
  Wenn die MWST nicht in der Offerte erwähnt wird, verstehen sich die vereinbarten Preise
exklusive MWST. 
  </p>

  <p class="TermsPolicy-margin">
  Racing Unleashed AG ist berechtigt, bei Vertragsabschluss eine angemessene Anzahlung zu
verlangen. Die Höhe der Anzahlung und die Zahlungstermine werden schriftlich vereinbart.
  </p>

  <h2>
  Rücktritt des Vertragspartners
  </h2>

  <p>
  Tritt der Vertragspartner vor dem Anlass vom Vertrag zurück, sind die folgenden
  Entschädigungen geschuldet:
  </p>

  <ol>
    <li>
    30 Tage vor Event: 80% des Packagepreises (Gesamtpreis)
    </li>
    <li>
    15 Tage vor Event: 100% des Packagepreises (Gesamtpreis)
    </li>
  </ol>
 

  <p class="TermsPolicy-margin">
  Die Geltendmachung weiteren Schadenersatzes bleibt vorbehalten
  </p>

  <h2>
  Rücktritt von Racing Unleashed AG
  </h2>

  <p>
  Hält der Vertragspartner die vertraglich vereinbarten Zahlungsfristen und -termine nicht ein, ist
  Racing Unleashed AG berechtigt, sofort vom Vertrag zurückzutreten.
  </p>

  <p>
  Racing Unleashed AG ist zudem berechtigt, aus wichtigem Grund gemäss den gesetzlichen
Bestimmungen oder bei einer Verletzung einer oder mehrerer Bestimmungen dieser AGB
und/oder des Vertrages zwischen Racing Unleashed AG und dem Vertragspartner durch den
Vertragspartner zurückzutreten, insbesondere falls<br/>

a) höhere Gewalt oder andere von Racing Unleashed AG nicht zu vertretende Umstände die
Erfüllung des Vertrags unmöglich machen; <br/>

b) Veranstaltungen unter irreführender oder falscher Angabe wesentlicher Tatsachen, z.B. des
tatsächlichen Veranstalters oder Zwecks, gebucht werden; <br/>

c) Racing Unleashed AG begründeten Anlass zu der Annahme hat, dass die Veranstaltung
den reibungslosen Geschäftsbetrieb, die Sicherheit oder den Ruf des Unternehmens zu
gefährden droht. 

  </p>

  <p class="TermsPolicy-margin">
  Bei berechtigtem Rücktritt durch Racing Unleashed AG entsteht kein Anspruch des
Vertragspartners auf Schadenersatz.
  </p>

  <h2>
  Änderung der Teilnehmerzahl, Räumlichkeiten oder Nutzungszeiten
  </h2>

  <p>
  Eine Änderung der Teilnehmerzahl muss spätestens 5 Werktage vor Veranstaltungsbeginn
Racing Unleashed AG schriftlich mitgeteilt werden. Im Falle einer Änderung gilt folgendes:
  </p>

  <p>
  a) Eine erhöhte Teilnehmerzahl bedarf der vorherigen schriftlichen Zustimmung seitens
Racing Unleashed AG. Allfällige dadurch resultierende Zusatzkosten sind vom
Vertragspartner zu tragen.
  </p>

  <p>
  b) Im Falle der Reduzierung der Teilnehmerzahl ist Racing Unleashed AG berechtigt, die vom
Vertragspartner in der Annahme des von Racing Unleashed AG abgegebenen Angebots
bestätigten Kosten in Rechnung zu stellen.
  </p>

  <p class="TermsPolicy-margin">
  c) Änderungswünsche des Vertragspartners/Veranstalters bezüglich Räume, deren Nutzung
und/oder Einrichtung inkl. technische Infrastruktur, die kurzfristig vor oder während des
Anlasses in Auftrag gegeben werden, sind kostenpflichtig.
  </p>


  <h2>
  Catering/Mitbringen von Speisen und Getränken
  </h2>

  <p>
  Das Catering wird von Racing Unleashed AG in Auftrag gegeben.
  </p>

  <p class="TermsPolicy-margin">
  Der Vertragspartner darf Speisen und Getränke zu Veranstaltungen grundsätzlich nicht
mitbringen. Ausnahmen bedürfen einer schriftlichen Vereinbarung mit der Racing Unleashed
AG. In diesen Fällen werden die Kosten für die Zurverfügungstellung der Infrastruktur gemäss
Vereinbarung in Rechnung gestellt.
  </p>

  <h2>
  Zusätzliche technische Unterstützung
  </h2>

  <p class="TermsPolicy-margin">
  Sollte die vorhandene Technik nicht ausreichen, werden alle weiteren technischen
  Installationen ausschliesslich durch Racing Unleashed AG bzw. deren jeweiligen Partner
  vorgenommen. Soweit Racing Unleashed AG für den Vertragspartner zusätzliche technische
  Einrichtungen oder sonstige Leistungen beschaffen muss, handelt diese im Auftrag und auf
  Rechnung des Vertragspartners. Der Vertragspartner verpflichtet sich, Racing Unleashed AG
  sämtliche Auslagen und Aufwendungen, welche letztere in ordnungsgemässer Ausführung
  des Auftrags getätigt hat, zu ersetzen und Racing Unleashed AG von den entstandenen
  Verbindlichkeiten zu befreien.
  </p>

  <h2>
  Haftung
  </h2>

  <p>
  Der Vertragspartner haftet gegenüber Racing Unleashed AG für sämtliche Verluste und alle
  Schäden an Gebäude, den Simulatoren oder Inventar, die durch Veranstaltungsteilnehmer
  bzw. Veranstaltungsbesucher, Mitarbeiter, sonstige Dritte aus seinem Bereich oder ihn selbst
  oder seine gesetzlichen Vertreter verursacht werden.
  </p>

  <p>
  Racing Unleashed AG darf vom Vertragspartner zur Absicherung von eventuellen Schäden
die Stellung angemessener Sicherheiten (z.B. Versicherungen, Kautionen, Bürgschaften)
verlangen.
  </p>

  <p>
  Racing Unleashed AG lehnt jegliche Haftung für Verlust, Diebstahl oder Beschädigung von
Kleidern sowie mitgebrachter Gegenstände insbesondere des Vertragspartners, seiner
Mitarbeiter, Helfer und von Gästen ab.
  </p>

  <p>
  Die von Racing Unleashed AG zur Verfügung gestellten Räumlichkeiten unterliegen
sicherheitstechnischen, feuerpolizeilichen und betrieblichen Auflagen, die auch vom
Vertragspartner jederzeit erfüllt werden müssen.
  </p>

  <p>
  Mitgebrachte Ausstellungs- oder sonstige, einschliesslich persönliche, Gegenstände befinden
sich unter der Verantwortung des Vertragspartners in den Räumlichkeiten. Racing Unleashed
AG übernimmt für Verlust oder Beschädigung keine Haftung, ausser bei grober Fahrlässigkeit
oder Vorsatz. Alle mitgebrachten Materialien, wie z.B. Flipchart- und Handnotizen,
Werbeartikel, Unterlagen aller Art usw. sind nach Ende der Veranstaltung unverzüglich und
fachgerecht zu entfernen.
  </p>

  <p>
  Allfällige vom Vertragspartner verursachte Schäden oder ausserordentliche Verschmutzungen
werden zulasten des Vertragspartners durch Racing Unleashed AG nach Abschluss der
Veranstaltung behoben. Zurückgelassene Gegenstände darf Racing Unleashed AG auf
Kosten des Vertragspartners entfernen und einlagern lassen.
  </p>

  <p class="TermsPolicy-margin">
  Ist die Entfernung mit unverhältnismässig hohem Aufwand verbunden, kann Racing
Unleashed AG die Gegenstände aufbewahren und für die Dauer des Verbleibs Raummiete
berechnen. Racing Unleashed AG bleibt der Nachweis eines höheren Schadens vorbehalten.
Zurückgelassene Unterlagen werden während maximal 2 Tagen aufbewahrt und
anschliessend zulasten des Vertragspartners entsorgt.
  </p>

  <h2>
  Versicherung
  </h2>

  <p class="TermsPolicy-margin">
  Der Vertragspartner verpflichtet sich, eine adäquate Haftpflicht- und Sachversicherung gegen
  Personen- und Sachschäden abzuschliessen. Racing Unleashed AG ist berechtigt, die
  entsprechende Police einzusehen. Sollte ihres Erachtens der Schutz nicht ausreichend sein,
  kann eine entsprechende Ergänzung verlangt werden. Es gelten die
  Datenschutzbestimmungen der Racing Unleashed AG siehe Anhang
  </p>

  <h2>
  Schlussbestimmungen
  </h2>

  <p class="TermsPolicy-margin">
  Änderungen oder Ergänzungen des Vertrags zwischen Racing Unleashed AG und dem
  Vertragspartner, in der Annahme der von Racing Unleashed AG gemachten Offerte oder
  dieser Geschäftsbedingungen sind nur in schriftlicher Form gültig und bedürfen der
  Zustimmung aller Vertragsparteien. 
  </p>

  <p>
  Erfüllungs- und Zahlungsort ist der Sitz von Racing Unleashed AG in CH-6330 Cham 
  </p>

  <p>
  Gerichtsstand ist CH-6300 Zug.
  </p>

  <p>
  Es gilt Schweizer Recht. 
  </p>

  <p>
  Sollten einzelne Bestimmungen dieser Allgemeinen Geschäftsbedingungen für
Veranstaltungen unwirksam oder nichtig sein, so wird dadurch die Wirksamkeit der übrigen
Bestimmungen nicht berührt. Im Übrigen gelten die gesetzlichen Vorschriften.
  </p>

  <p>
  6330 Cham, den 12.09.2019
  </p>
  <p>
  Racing Unleashed AG
  </p>
  `,
};

export default terms;
