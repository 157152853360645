import React, { useRef } from 'react';
import { ReactComponent as SearchIcon } from '../../images/magnifying-glass.svg?tsx';
import styles from './SearchBox.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

interface ISearchBoxProps {
  value: string;
  className?: string;
  onChange: (value: string) => void;
}

const SearchBox: React.FC<ISearchBoxProps> = ({
  value,
  className,
  onChange,
}) => {
  const [t] = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <div
      className={classNames(styles.searchBox, className)}
      onClick={(event) => {
        event.stopPropagation();
        return inputRef.current && inputRef.current.focus();
      }}
    >
      <input
        placeholder={t('field.search.placeholder')}
        className={styles.searchBox__input}
        value={value}
        onChange={({ target: { value } }) => onChange(value)}
      />
      <SearchIcon className={styles.searchBox__icon} />
    </div>
  );
};

export default SearchBox;
