import React, { ComponentType, ReactNode, memo } from 'react';
import flowRight from 'lodash/flowRight';

type ComponentAndPropsPair<P> = [ComponentType<P>, P?];

export function composeComponents(pairs: ComponentAndPropsPair<Record<string, unknown>>[]) {
  const compositionItems = pairs.map(([Component, props]) => function CompositionItem(composableChildren?: ReactNode) {
    return <Component {...props}>{composableChildren}</Component>;
  });

  const compose = flowRight(compositionItems) as (children: ReactNode) => React.JSX.Element;

  return memo((({ children }: { children?: ReactNode }) => compose(children)) satisfies ComponentType<{ children: ReactNode }>);
}
