import { AxiosError } from 'axios';
import api from '../../config/api.config';
import {
  IParticipant,
  IOrganiserEvent,
  IParticipantEvent,
} from './participant-drivers.types';

export const getOrganiserBookingData = async (
  reservation_id: string,
  controller?: AbortController,
) => {
  try {
    const response = await api.post<TRumpApiResponseData<IOrganiserEvent>>(
      '/participant_drivers/event_info_organiser',
      {
        reservation_id,
      },
      {
        signal: controller && controller.signal,
      },
    );

    return response.data.data;
  }
  catch (err) {
    const error = err as AxiosError<TRumpApiRequestError>;
    return Promise.reject(
      'response' in error ? error.response?.data : error,
    );
  }
};

export const getParticipantBookingData = async (
  reservation_id: string,
  controller?: AbortController,
) => {
  try {
    const response = await api.get<TRumpApiResponseData<IParticipantEvent>>(
      `/participant_drivers/event_info_participant/${reservation_id}`,
      {
        signal: controller && controller.signal,
      },
    );

    return response.data.data;
  }
  catch (err) {
    const error = err as AxiosError<TRumpApiRequestError>;
    return Promise.reject(
      'response' in error ? error.response?.data : error,
    );
  }
};

export const addOrganiserParticipant = async (
  reservation_id: string,
  participant: Omit<IParticipant, 'participant_driver_id'>,
) => {
  try {
    const response = await api.post<TRumpApiResponseData<IParticipant>>('participant_drivers/add_by_organiser', {
      reservation_id,
      participant_driver: {
        customer: participant,
      },
    });

    return response.data.data;
  }
  catch (err) {
    const error = err as AxiosError<TRumpApiRequestError>;
    return Promise.reject(
      'response' in error ? error.response?.data : error,
    );
  }
};

export const addCustomerParticipant = async (
  reservation_id: string,
  participant: Omit<IParticipant, 'participant_driver_id'>,
): Promise<IParticipant> => {
  try {
    const response = await api.post<TRumpApiResponseData<IParticipant>>('/participant_drivers/add_by_customer', {
      reservation_id,
      ...participant,
    });

    return response.data.data;
  }
  catch (err) {
    const error = err as AxiosError<TRumpApiRequestError>;
    return Promise.reject(
      'response' in error ? error.response?.data : error,
    );
  }
};

export const updateOrganiserParticipant = async (
  reservation_id: string,
  participant: IParticipant,
): Promise<IParticipant> => {
  const { participant_driver_id, ...rest } = participant;

  try {
    const response = await api.post<TRumpApiResponseData<IParticipant>>('participant_drivers/update_by_organiser', {
      reservation_id,
      participant_driver: {
        customer: rest,
      },
      id: participant_driver_id,
    });

    return response.data.data;
  }
  catch (err) {
    const error = err as AxiosError<TRumpApiRequestError>;
    return Promise.reject(
      'response' in error ? error.response?.data : error,
    );
  }
};

export const deleteOrganiserParticipant = async (
  reservation_id: string,
  participant_id: string,
): Promise<IParticipant> => {
  try {
    const response = await api.post<TRumpApiResponseData<IParticipant>>('participant_drivers/delete_by_organiser', {
      reservation_id,
      id: participant_id,
    });

    return response.data.data;
  }
  catch (err) {
    const error = err as AxiosError<TRumpApiRequestError>;
    return Promise.reject(
      'response' in error ? error.response?.data : error,
    );
  }
};
