import React, { useState, useEffect, useRef } from 'react';
import { toastUtil } from '../../utils/toast.utils';
import {
  getMonths,
  getCars,
  getRankings,
} from '../../store/track/track.actions';
import { trackSelector } from '../../store/track/track.selectors';
import { championshipsSelector } from '../../store/championships/championships.selector';
import { Championship } from '../../store/championships/championships.types';
import { Track, Month } from '../../store/track/track.types';
import useWithSelection from '../../hooks/useWithSelection';
import useWithDispatch from '../../hooks/useWithDispatch';
import RankingSelect from './RankingSelect';
import RankingItem from './RankingItem';
import { useTranslation } from 'react-i18next';
import './Ranking.scss';
import { AxiosError } from 'axios';

const Ranking = () => {
  const [t] = useTranslation();
  const tracks = useWithSelection(trackSelector);
  const championships = useWithSelection(championshipsSelector);
  const monthsDispatch = useWithDispatch(getMonths);
  const carsDispatch = useWithDispatch(getCars);
  const _isMounted = useRef(false);
  const [currentTrack, setCurrentTrack] = useState<string>('');
  const [currentMonth, setCurrentMonth] = useState<string>('');
  const [currentCar, setCurrentCar] = useState<string>('');
  const [rankings, setRankings] = useState<{ ladderboard: RankingItem[] }>();

  const getList = async () => {
    if (!currentTrack) return;
    try {
      const res = await getRankings(currentTrack, currentMonth, currentCar);
      setRankings(res);
    }
    catch (error) {
      const err = error as AxiosError<TRumpApiRequestError>;
      if (err?.response?.data && 'meta' in err.response.data && typeof err.response.data.meta.message === 'string')
        toastUtil('error', err.response.data.meta.message);
    }
  };

  useEffect(() => {
    const activeChampionship = championships.find(
      (championship: Championship) => championship.active,
    );
    if (activeChampionship) {
      setCurrentCar(activeChampionship.car_id);
    }
    else {
      setCurrentCar('40fd03ec-b915-11ed-82b0-0242ac1f0008');
    }
  }, [championships]);

  useEffect(() => {
    if (tracks && tracks.length)
      (async () => {
        const activeChampionshipTrack = tracks.find(
          (track: Track) => track.id === 'e8d0c490-ec0f-11e9-8000-300000000000',
        );
        if (!activeChampionshipTrack) return;

        const months = await monthsDispatch(activeChampionshipTrack.id);
        const activeChampionshipMonth = months.find(
          (month: Month) => month.active,
        );
        if (activeChampionshipMonth?.value) setCurrentMonth(activeChampionshipMonth.value);
        setCurrentTrack(activeChampionshipTrack.id);
      })();
    // eslint-disable-next-line
  }, [tracks]);

  useEffect(() => {
    if (!currentTrack) return;
    monthsDispatch(currentTrack);
    carsDispatch(currentTrack);
    if (_isMounted.current) {
      setCurrentMonth('');
      setCurrentCar('');
    }
    if (!_isMounted.current) _isMounted.current = true;
    // eslint-disable-next-line
  }, [currentTrack]);

  useEffect(() => {
    getList();
    // eslint-disable-next-line
  }, [currentTrack, currentMonth, currentCar]);

  return (
    <div className="row justify-content-center">
      <div className="col-md-8">
        <h2>{t('ranking.ranking')}</h2>
        <div className="Ranking-selections row">
          <div className="col-md-4">
            <RankingSelect
              selected={currentTrack}
              type="track"
              setValue={setCurrentTrack}
            />
          </div>
          <div className="col-md-4">
            <RankingSelect
              selected={currentMonth}
              type="month"
              setValue={setCurrentMonth}
            />
          </div>
          <div className="col-md-4">
            <RankingSelect
              selected={currentCar}
              type="car"
              setValue={setCurrentCar}
            />
          </div>
        </div>
        <div className="Ranking-cells Ranking-title">
          <span>{t('ranking.rank').toUpperCase()}</span>
          <span>{t('ranking.racer').toUpperCase()}</span>
          {/* <span>CAR TYPE</span> */}
          <span>{t('ranking.lapTime').toUpperCase()}</span>
        </div>
        <ul>
          {rankings
            ? (
                rankings.ladderboard.length
                  ? (
                      rankings.ladderboard.map((racer, index) => (
                        <RankingItem key={racer.customer_fullname} {...racer} index={index} />
                      ))
                    )
                  : (
                      <li className="Ranking-cells-empty">{t('ranking.noItems')}</li>
                    )
              )
            : (
                <li className="Ranking-cells-empty">{t('ranking.selectTrack')}</li>
              )}
        </ul>
      </div>
    </div>
  );
};

export default Ranking;
