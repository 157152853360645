import React, { useEffect, useState } from 'react';
import styles from './ConfirmSubscription.module.scss';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { ISubscriptionConfirmation } from '../../store/subscription-confirmation/subscription-confirmation.types';
import { updateSubscriptionConfirmation } from '../../store/subscription-confirmation/subscription-confirmation.actions';
import { toastUtil } from '../../utils/toast.utils';
import Loader from '../../components/Loader';

const ConfirmSubscription: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const [confirmationResult, setConfirmationResult] = useState<ISubscriptionConfirmation | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!token) return;

    setIsLoading(true);
    const controller = new AbortController();

    updateSubscriptionConfirmation(token, controller)
      .then(({ message }) => {
        if (typeof message !== 'string') return;
        setConfirmationResult({ message });
      })
      .catch((err: TRumpApiRequestError) => {
        if ('message' in err && err.message === 'canceled') return;

        const msg = 'meta' in err ? err.meta.message : err.message;
        if (typeof msg === 'string') toastUtil('error', msg);
      })
      .finally(() => setIsLoading(false));

    return () => controller.abort();
  }, [token]);

  return (
    <div className={styles.confirmSubscription}>
      {confirmationResult
        ? (
            <div className={classNames(styles.confirmSubscription__card, styles.confirmSubscription__content)}>
              <div className={styles.welcome}>
                <h1 className={styles.welcome__title}>{confirmationResult.message}</h1>
              </div>
            </div>
          )
        : (
            isLoading && <Loader fullSize color="black" width={80} height={80} />
          )}
    </div>
  );
};

export default ConfirmSubscription;
