import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { resetPasswordRequest } from '../../../store/customer/customer.actions';
import Button from '../../../components/Button';
import FormField from '../../../components/FormField';
import validationSchema from './validationSchema';
import AuthFormMain from '../../../components/AuthFormMain';

const FORM_ID = 'forgotPasswordForm';

const ForgotPasswordForm = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState<string | null>(null);
  const [t] = useTranslation();
  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema,
    onSubmit: async (values, formMethods) => {
      try {
        setSubmitting(true);
        const response = await resetPasswordRequest(values.email);
        if (typeof response?.meta?.message !== 'string') return;
        setSuccessMsg(response.meta.message);
      }
      catch (error) {
        setSubmitting(false);
        const err = error as TRumpApiErrorResponseData;
        if (typeof err?.meta?.message === 'string')
          formMethods.setFieldError('email', err.meta.message);
        if (err?.meta?.errors) {
          err.meta.errors.forEach((e) => {
            return formMethods.setFieldError(e.field, e.messages[0]);
          });
        }
      }
    },
  });
  const isValidForm = validationSchema.isValidSync({ ...formik.values });

  return (
    <AuthFormMain
      title="auth.forgotPassword.title"
      subtitle="auth.forgotPassword.subtitle"
      footer={(
        <Button
          form={FORM_ID}
          type="submit"
          isLoading={submitting}
          disabled={submitting || !isValidForm}
          label={t('field.forgotPassword')}
        />
      )}
    >
      {successMsg
        ? (
            <p>{successMsg}</p>
          )
        : (
            <form id={FORM_ID} onSubmit={formik.handleSubmit}>
              <FormField
                key="email"
                i18nKey="field.email"
                type="text"
                errors={formik.errors}
                touched={formik.touched}
                setFieldValue={formik.setFieldValue}
                {...formik.getFieldProps('email')}
              />
            </form>
          )}
    </AuthFormMain>
  );
};

export default ForgotPasswordForm;
