export default {
  unknown: {
    label: 'El campo',
  },
  profile_pic: {
    label: 'Avatar',
  },
  nickname: {
    label: 'Apodo',
    placeholder: 'Ingrese apodo',
  },
  gender_id: {
    label: 'Género',
    placeholder: 'Seleccione género',
  },
  first_name: {
    label: 'Nombre',
    placeholder: 'Ingrese nombre',
  },
  last_name: {
    label: 'Apellido',
    placeholder: 'Ingrese apellido',
  },
  email: {
    label: 'Correo electrónico',
    placeholder: 'Ingrese correo electrónico',
  },
  phone_number: {
    label: 'Número de teléfono',
    placeholder: 'Ingrese número de teléfono',
  },
  company: {
    label: 'Empresa',
    placeholder: 'Ingrese nombre de la empresa',
  },
  body_weight: {
    label: 'Peso corporal',
    placeholder: 'Ingrese peso corporal',
  },
  body_height: {
    label: 'Altura corporal',
    placeholder: 'Ingrese altura corporal',
  },
  dob: {
    label: 'Fecha de nacimiento',
    placeholder: 'Seleccione fecha de nacimiento',
  },
  pob_country_id: {
    label: 'País de nacimiento',
    placeholder: 'Seleccione país de nacimiento',
  },
  default_lounge_id: {
    label: 'Salón predeterminado',
    placeholder: 'Seleccione salón predeterminado',
  },
  fav_lounge_id: {
    label: 'Salón favorito',
    placeholder: 'Seleccione salón favorito',
  },
  fav_simulator_id: {
    label: 'Simulador favorito',
    placeholder: 'Seleccione simulador favorito',
  },
  address1: {
    label: 'Dirección',
    placeholder: 'Ingrese primera línea de dirección',
  },
  address2: {
    label: 'Línea de dirección 2',
    placeholder: 'Ingrese segunda línea de dirección',
  },
  country_id: {
    label: 'País',
    placeholder: 'Seleccione país',
  },
  city: {
    label: 'Ciudad',
    placeholder: 'Ingrese nombre de la ciudad',
  },
  zip_code: {
    label: 'Código postal',
    placeholder: 'Ingrese código postal',
  },
  password: {
    label: 'Nueva contraseña',
    placeholder: 'Ingrese nueva contraseña',
  },
  password_confirmation: {
    label: 'Confirmar contraseña',
    placeholder: 'Confirme nueva contraseña',
  },
  card_number: {
    label: 'Número de tarjeta',
    placeholder: 'Ingrese número de tarjeta',
  },
  card_expiry: {
    label: 'Fecha de expiración',
  },
  card_cvc: {
    label: 'CVC',
  },
};
