const dashboard = {
  title: 'Dashboard',
  memberSince: 'Miembro desde',
  upcomingRides: 'Próximas Experiencias',
  dateTime: 'Fecha / Hora',
  experience: 'Experiencia',
  experiences: 'Experiencias',
  lounge: 'Lounge',
  viewAll: 'Ver todo',
  laps: 'Vueltas',
  tracks: 'Pistas',
};

export default dashboard;
