import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import CustomerMenu from '../CustomerMenu';
import Lang from './Lang';
import links from './links';
import styles from './Header.module.scss';
import classNames from 'classnames';
import { setUserFromLocaStorage } from '../../store/customer/customer.actions';
import useWithDispatch from '../../hooks/useWithDispatch';
import { customerSelector } from '../../store/customer/customer.selectors';
import useWithSelection from '../../hooks/useWithSelection';

interface NavigationLink {
  text: string;
  href: string;
}

export interface HeaderNavigationLangData {
  headerMainNavigation: NavigationLink[];
  headerServiceNavigation: NavigationLink[];
}

export interface HeaderNavigationData {
  en?: HeaderNavigationLangData;
  de?: HeaderNavigationLangData;
  es?: HeaderNavigationLangData;
}

interface Props {
  /**
   * @default false
   */
  hideNavigation?: boolean;
  /**
   * @default false
   */
  hideTop?: boolean;
  /**
   * For redirection to an external page
   */
  logoLink?: string;
  /**
   * @default true
   */
  canBeFixed?: boolean;
}

const Header = ({
  logoLink,
  hideNavigation,
  hideTop,
  canBeFixed = true,
}: Props) => {
  const [t] = useTranslation();
  const [activeNav, setActiveNav] = useState<boolean>(false);
  const setUser = useWithDispatch(setUserFromLocaStorage);
  const customer = useWithSelection(customerSelector);

  const closeMobileNav = (): void => {
    setActiveNav(false);
    document.querySelector('body')!.classList.remove('js-active');
  };

  const handleResize = (): void => {
    if (window.innerWidth > 767) {
      closeMobileNav();
    }
  };

  const handleMobileNavClick = (): void => {
    setActiveNav(!activeNav);
    document.querySelector('body')!.classList.toggle('js-active');
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    const channel = window.BroadcastChannel
      ? new BroadcastChannel('onAuth')
      : null;

    channel?.addEventListener('message', () => setUser());

    return () => {
      window.removeEventListener('resize', handleResize);
      channel?.close();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <header
      className={classNames(styles.root, { [styles.root_withoutNavbar]: hideNavigation, [styles.root_sticky]: canBeFixed })}
    >
      {hideTop
        ? (
            ''
          )
        : (
            <MediaQuery query="(min-width: 768px)">
              <div className={styles.topPanel}>
                <div className={classNames('container', styles.topPanel__container)}>
                  <CustomerMenu onNavClick={closeMobileNav} />
                  <Lang isAuthorized={!!customer} />
                </div>
              </div>
            </MediaQuery>
          )}
      <div className={styles.bottomPanel}>
        <div className={classNames('container', styles.bottomPanel__container)}>
          {logoLink
            ? (
                <a
                  className={styles.brand}
                  href={logoLink}
                  target="_self"
                  rel="noopener noreferrer"
                >
                  Racing Unleashed
                </a>
              )
            : (
                <Link className={styles.brand} to="/">
                  Racing Unleashed
                </Link>
              )}
          {hideTop
            ? (
                ''
              )
            : customer
              ? (
                  <div
                    className={classNames(styles.root__hamburger, { [styles.root__hamburger_isActive]: activeNav })}
                    onClick={handleMobileNavClick}
                  />
                )
              : (
                  <MediaQuery query="(max-width: 767px)">
                    <Lang alwaysDesktop isAuthorized={!!customer} />
                  </MediaQuery>
                )}
        </div>
        {hideNavigation
          ? (
              ''
            )
          : (
              <div className={styles.appNavContainer}>
                <div className="container">
                  <nav className={styles.appNav}>
                    {links.bottom.map((link) => (
                      <NavLink
                        key={link.i18nKey}
                        to={link.to}
                        onClick={closeMobileNav}
                        className={({ isActive }) => classNames(styles.appNav__item, { [styles.appNav__item_active]: isActive })}
                      >
                        {t(link.i18nKey)}
                      </NavLink>
                    ))}
                  </nav>
                </div>
              </div>
            )}
        <MediaQuery query="(max-width: 767px)">
          <div className={classNames(styles.nav, { [styles.nav_isVisible]: activeNav })}>
            <CustomerMenu onNavClick={closeMobileNav} />
            <Lang isAuthorized={!!customer} />
          </div>
        </MediaQuery>
      </div>
    </header>
  );
};

export default Header;
