const membership = {
  title: 'My Membership ',
  subtitle:
    'Current membership information. Upgrade, renew and cancel memberships.',
  current: {
    label: 'Current Membership',
  },
  expiry: {
    label: 'Expiry Date',
  },
  upgrade: 'Upgrade',
  selectPlan: 'Select',
  label: 'Choose Subscription',
  details: 'Membership Details',
  status: 'Current Membership Status',
  ridesAvailable: 'Single Rides Available',
  customerLimitLabel: 'Monthly Single Rides Used',
  pricingLabel: 'Single Rides',
  renew: 'Renew',
  formTitle: 'Renew membership',
  membershipExpiresAt: 'Your Membership expires on ',
  renewC2a: 'To renew please click on the Renew Membership Button bellow',
  membershipExpiredAt: 'Your Membership has expired:',
};

export default membership;
