import React from 'react';
import { TImageProps, Image } from './Image';
import { PersonIcon } from '@images/icons';
import { useMergedClassNames } from '@utils/styling';
import styles from './Avatar.module.scss';

export type TAvatarProps = Extend<Omit<TImageProps, 'aspectRatio'>, {}>;

export const Avatar = (props: TAvatarProps) => {
  const { className, ...restRootProps } = props;

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [className || '']: true,
  });

  return (
    <Image
      {...restRootProps}
      className={rootClassName}
      aspectRatio={1 / 1}
      placeholderIcon={<PersonIcon className={styles.icon} />}
    />
  );
};
