import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener } from '@mui/material';
import Button from '../Button';
import AvatarImage from '../AvatarImage';
import useWithSelection from '../../hooks/useWithSelection';
import useWithDispatch from '../../hooks/useWithDispatch';
import { customerSelector } from '../../store/customer/customer.selectors';
import { signOut } from '../../store/customer/customer.actions';
import arrowIcon from '../../images/Arrow.svg';
import navLinks from './navLinks';
import { ReactComponent as LogOutIcon } from '../../images/log-out.svg?tsx';
import './CustomerMenu.scss';

interface CustomerMenu {
  onNavClick: () => void;
}

const CustomerMenu = ({ onNavClick }: CustomerMenu) => {
  const [t] = useTranslation();
  const customer = useWithSelection(customerSelector);
  const [activeDropdown, setActiveDropdown] = useState<boolean>(false);
  const signOutAction = useWithDispatch(signOut);
  const toggleDropdown = () => setActiveDropdown(!activeDropdown);
  const closeDropdown = () => {
    if (activeDropdown) setActiveDropdown(false);
  };
  const clickHandler = () => {
    onNavClick();
    closeDropdown();
  };

  if (!customer) return <></>;

  const { first_name, last_name, email } = customer;

  return (
    <ClickAwayListener onClickAway={closeDropdown}>
      <div className="CustomerMenu">
        <div className="CustomerMenu-top" onClick={toggleDropdown}>
          <span>{t('header.myAccount')}</span>
          <img
            src={arrowIcon}
            alt=""
            className={activeDropdown ? 'is-active' : ''}
          />
        </div>
        <div
          className={`CustomerMenu-dropdown ${
            activeDropdown ? 'is-active' : ''
          }`}
        >
          <div className="CustomerMenu-profile">
            <AvatarImage />
            <div>
              {first_name}
              {' '}
              {last_name}
              <span>{email}</span>
            </div>
          </div>
          <div className="CustomerMenu-divider" />
          <div className="CustomerMenu-nav">
            {navLinks.map((link) => (
              <NavLink
                key={link.i18nKey}
                to={link.to}
                className={({ isActive }) =>
                  isActive ? 'is-active' : undefined}
                onClick={clickHandler}
              >
                {t(link.i18nKey)}
              </NavLink>
            ))}
          </div>
          <Button
            label={t('header.signOut')}
            onClick={() => {
              onNavClick();
              signOutAction();
            }}
            color="secondary"
            appearance="outline"
            icon={<LogOutIcon style={{ transform: 'rotate(180deg)' }} />}
          />
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default CustomerMenu;
