import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  createContext,
  useContext,
  useCallback,
} from 'react';
import {
  map as createMap,
  tileLayer as createMapTileLayer,
  featureGroup as createMarkersGroup,
  Map as LeafletMap,
  Marker as LeafletMapMarker,
} from 'leaflet';
import styles from './WorldMap.module.scss';
import mergeClassNames from 'classnames';
import WorldMapZoomControls, {
  IWorldMapZoomControlsClassNames,
} from './WorldMapZoomControls';
import Loader from '../Loader';
import { useMediaQuery } from '@mui/material';

export interface IWorldMapContextValueProps {
  instance: LeafletMap | null;
  addMarker: (marker: { id: string; instance: LeafletMapMarker }) => void;
  removeMarker: (marker: { id: string; instance: LeafletMapMarker }) => void;
}

export const WorldMapContext = createContext<IWorldMapContextValueProps>({
  instance: null,
  addMarker: () => {},
  removeMarker: () => {},
});

export function useWorldMap() {
  return useContext(WorldMapContext);
}

export interface IWorldMapClassNames {
  root?: string;
  zoomControls?: IWorldMapZoomControlsClassNames;
}

export interface IWorldMapProps {
  classNames?: IWorldMapClassNames;
  loading?: boolean;
  fit?: boolean;
  tileTemplateUrl?: string;
  maxZoom?: number;
  initialCenter?: [number, number];
  initialZoom?: number;
  onInstantiated?: (mapInstance: LeafletMap) => void;
  children?: React.ReactNode;
}

const WorldMap: React.FC<IWorldMapProps> = ({
  classNames,
  loading,
  fit,
  tileTemplateUrl = 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}@2x.png',
  maxZoom = 17,
  initialCenter,
  initialZoom = 2,
  onInstantiated,
  children,
}) => {
  const mapContentElementRef = useRef<HTMLDivElement | null>(null);
  const [mapInstance, setMapInstance] = useState<LeafletMap | null>(null);
  const [markers, setMarkers] = useState<{ id: string; instance: LeafletMapMarker }[]>([]);
  const isMobile = useMediaQuery('only screen and (max-width: 768px)');

  const markersGroup = useMemo(() => markers.length > 0 ? createMarkersGroup(markers.map((marker) => marker.instance)) : null, [markers]);

  const addMarker = useCallback((marker: { id: string; instance: LeafletMapMarker }) => {
    if (!mapInstance) return;
    mapInstance.addLayer(marker.instance);
    setMarkers((previousMarkers) => {
      const index = previousMarkers.findIndex((m) => m.id === marker.id);
      if (index > -1) return previousMarkers;
      return [...previousMarkers, marker];
    });
  }, [mapInstance]);

  const removeMarker = useCallback((marker: { id: string; instance: LeafletMapMarker }) => {
    if (!mapInstance) return;
    mapInstance.removeLayer(marker.instance);
    setMarkers((previousMarkers) => {
      const index = previousMarkers.findIndex((m) => m.id === marker.id);
      return previousMarkers.splice(index, 1);
    });
  }, [mapInstance]);

  useEffect(() => {
    if (!mapInstance || !markersGroup || initialCenter) return;
    const bounds = markersGroup.getBounds();
    const northEast = bounds.getNorthEast();
    const southWest = bounds.getSouthWest();

    if (northEast.equals(southWest)) {
      mapInstance.setView([northEast.lat, northEast.lng], initialZoom);
      return;
    }

    mapInstance.fitBounds(bounds, {
      paddingTopLeft: [25, isMobile ? 125 : 50],
      paddingBottomRight: [25, isMobile ? 175 : 25],
    });
  }, [mapInstance, markersGroup, initialCenter, initialZoom, isMobile]);

  useEffect(() => {
    if (!mapContentElementRef.current || loading) return;
    const newMapInstance = createMap(mapContentElementRef.current, {
      zoomControl: false,
      zoomSnap: 0.25,
      zoomDelta: 0.25,
    }).setView([0, 0], 2);

    if (initialCenter) {
      newMapInstance.setView(initialCenter, initialZoom);
    }

    const copyrights = {
      'http://www.openstreetmap.org/copyright': 'OpenStreetMap',
      'https://carto.com/attributions': 'CARTO',
    };

    const attribution = Object.entries(copyrights)
      .map(([href, title]) => `&copy; <a href="${href}">${title}</a>`)
      .join(' | ');

    newMapInstance.addLayer(
      createMapTileLayer(tileTemplateUrl, {
        maxZoom,
        attribution,
        subdomains: 'abcd',
      }),
    );

    setMapInstance(newMapInstance);

    return () => {
      newMapInstance.remove();
    };
  }, [initialCenter, initialZoom, tileTemplateUrl, maxZoom, loading]);

  useEffect(() => {
    if (!onInstantiated || !mapInstance) return;
    onInstantiated(mapInstance);
  }, [onInstantiated, mapInstance]);

  const contextValue = useMemo(
    () => ({ instance: mapInstance, addMarker, removeMarker }),
    [mapInstance, addMarker, removeMarker],
  );

  const rootClassName = useMemo(
    () =>
      mergeClassNames(styles.root, classNames ? classNames.root : null, {
        [styles.root_fit]: fit,
      }),
    [fit, classNames],
  );

  const zoomControlsClassNames = useMemo(
    () => (classNames ? classNames.zoomControls : undefined),
    [classNames],
  );

  if (loading) return <Loader fullSize width={80} height={80} />;

  return (
    <WorldMapContext.Provider value={contextValue}>
      <div className={rootClassName}>
        <div className={styles.content} ref={mapContentElementRef} />
        {children}
        <WorldMapZoomControls classNames={zoomControlsClassNames} />
      </div>
    </WorldMapContext.Provider>
  );
};

export default WorldMap;
