import React, { useMemo } from 'react';
import { ETypographyColor, Typography } from '@generics/content';
import { useFormatDatetime } from '@utils/localizing';
import { useMergedClassNames } from '@utils/styling';
import styles from './DateSelectInputDay.module.scss';

export enum EDateSelectInputDayModifier {
  Today = 'today',
  Selected = 'selected',
  Outside = 'outside',
  Disabled = 'disabled',
}

export type TDateSelectInputDayModifiers = Record<`${EDateSelectInputDayModifier}`, boolean>;

export interface IDateSelectInputDayProps extends HTMLProps<HTMLDivElement> {
  date: Date;
  modifiers: TDateSelectInputDayModifiers;
}

export const DateSelectInputDay = (props: IDateSelectInputDayProps) => {
  const { date, modifiers, className, ...restRootProps } = props;

  const formatDatetime = useFormatDatetime();

  const color = useMemo(
    () => modifiers.today || modifiers.selected
      ? ETypographyColor.Primary
      : ETypographyColor.Trinary,
    [modifiers],
  );

  const dayLabel = useMemo(
    () => formatDatetime({ date, format: 'd' }),
    [formatDatetime, date],
  );

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [styles.root_today]: modifiers.today,
    [styles.root_selected]: modifiers.selected,
    [styles.root_disabled]: modifiers.disabled,
    [className || '']: true,
  });

  return (
    <div {...restRootProps} className={rootClassName}>
      <Typography
        className={styles.label}
        type="button"
        color={color}
      >
        {dayLabel}
      </Typography>
    </div>
  );
};
