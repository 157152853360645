import React from 'react';
import { useMemo } from 'react';
import styles from './AppAlert.module.scss';
import classNames from 'classnames';
import { ReactComponent as InfoIcon } from '../../images/exclamation-circle.svg?tsx';
import { useTranslation } from 'react-i18next';

interface IAppAlertProps {
  text: React.ReactNode | string;
  type: 'info';
  bordered?: boolean;
}

const AppAlert: React.FC<IAppAlertProps> = ({ text, type, bordered }) => {
  const [t] = useTranslation();

  const icon = useMemo(() => {
    switch (type) {
      case 'info':
        return <InfoIcon />;
      default:
        return '';
    }
  }, [type]);

  return (
    <div
      className={classNames(styles.appAlert, {
        [styles.appAlert_info]: type === 'info',
        [styles.appAlert_bordered]: bordered,
      })}
    >
      {icon}
      <p className={styles.appAlert__text}>
        {typeof text === 'string' ? t(text) : text}
      </p>
    </div>
  );
};

export default AppAlert;
