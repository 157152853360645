import {
  FieldName,
  FieldValues,
  UseFormMethods,
  UseFormOptions,
  useForm as useReactHookForm,
} from 'react-hook-form';
import { IUseFormResolverProps, useFormResolver } from './useFormResolver';
import { ObjectSchema } from 'yup';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ApiValidationErrors } from '@network/api';
import { useTranslate } from '@features/multi-language';
import { useTranslation } from 'react-i18next';
import { EInputMode } from '@generics/inputs/types';

// TODO: Improve typings.

export type TUseFormProps<
  Values extends FieldValues,
  ValidationSchema extends ObjectSchema<FieldValues>,
  Context extends object = object,
> =
  Extend<UseFormOptions<Values, Context>, {
    mode?: `${EInputMode}`;
    validationSchema?: IUseFormResolverProps<ValidationSchema>['validationSchema'];
  }>;

export function useForm<
  Values extends FieldValues = FieldValues,
  ValidationSchema extends ObjectSchema<FieldValues> = ObjectSchema<FieldValues>,
  Context extends object = object,
>(props: TUseFormProps<Values, ValidationSchema, Context>) {
  const {
    defaultValues,
    mode = EInputMode.Input,
    validationSchema,
    ...restHookProps
  } = props;

  const defaultValuesRef = useRef(defaultValues);
  defaultValuesRef.current = defaultValues;

  const resolver = useFormResolver<Values, ValidationSchema>({
    validationSchema,
  });

  const [internalMode, setInternalMode] = useState<`${EInputMode}`>(mode);
  const [, { language }] = useTranslation();
  const t = useTranslate();
  const { setError, trigger, reset: resetHookForm, ...form } = useReactHookForm<Values>({
    ...restHookProps,
    defaultValues,
    resolver,
  }) satisfies UseFormMethods<Values>;

  useEffect(() => {
    trigger();
  }, [trigger, language]);

  const reset = useCallback<typeof resetHookForm>((values, omitResetState) => {
    resetHookForm(values ?? defaultValuesRef.current, omitResetState);
  }, [resetHookForm]);

  const edit = useCallback(() => {
    setInternalMode(`${EInputMode.Input}`);
  }, []);

  const represent = useCallback(() => {
    setInternalMode(`${EInputMode.Representation}`);
  }, []);

  const setApiValidationErrors = useCallback((errors: ApiValidationErrors) => {
    errors.forEach((error) => {
      const [fieldNameFirstLetter, ...fieldNameRestLetters]
        = error.field.split('_').join(' ');

      const fieldName = t(`fields.${error.field}.label`, {
        fallback: [
          fieldNameFirstLetter.toUpperCase(),
          ...fieldNameRestLetters,
        ].join(''),
      });

      const [errorMessageFirstLetter, ...errorMessageRestLetters]
        = error.messages[0]?.split('_')?.join(' ') ?? '';
      const errorMessage = [
        errorMessageFirstLetter.toLowerCase(),
        ...errorMessageRestLetters,
      ].join('');

      setError(error.field as FieldName<Values>, {
        type: 'api',
        message: `${fieldName} ${errorMessage}`,
      });
    });
  }, [t, setError]);

  return useMemo(() => ({
    ...form,
    reset,
    trigger,
    setError,
    setApiValidationErrors,
    mode: internalMode,
    edit,
    represent,
  }), [
    form,
    reset,
    trigger,
    setError,
    setApiValidationErrors,
    internalMode,
    edit,
    represent,
  ]);
}
