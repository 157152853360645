import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  accountChampionshipsRoute,
  accountDashboardRoute,
  accountEventsRoute,
  accountMembershipRoute,
  accountPackagesRoute,
  accountRankingRoute,
  accountSettingsRoute,
} from '@routes/account';
import {
  logoutRoute,
} from '@routes/authentication';
import { Link, Menu } from '@generics/navigation';
import { Button } from '@generics/inputs/button';
import { BrandLogo } from '@generics/branding';
import { CustomerSnippet, PortalToBody } from '@generics/content';
import { Breakpoint, useBreakpoints } from '@generics/responsiveness';
import { useAuthenticatedCustomer } from '@features/authentication';
import { useAccountLayout } from './AccountLayoutProvider';
import { CrossIcon } from '@images/icons';
import { useMergedClassNames } from '@utils/styling';
import styles from './AccountLayoutSideDrawer.module.scss';

export type TAccountLayoutSideDrawerProps =
  Extend<Omit<HTMLProps<HTMLElement>, 'children'>, {}>;

export const AccountLayoutSideDrawer = (props: TAccountLayoutSideDrawerProps) => {
  const { className, ...restRootProps } = props;

  const [t] = useTranslation();
  const { isMobile } = useBreakpoints();
  const { isSideDrawerOpen, closeSideDrawer } = useAccountLayout();
  const authenticatedCustomer = useAuthenticatedCustomer();

  const mainMenuLinks = useMemo(
    () => [
      accountDashboardRoute,
      accountPackagesRoute,
      accountMembershipRoute,
      accountRankingRoute,
      accountChampionshipsRoute,
      accountEventsRoute,
    ].map((route) => route.toMenuLinkProps())
      .map((link) => ({
        ...link,
        children: t(link.children),
      })),
    [t],
  );

  const serviceMenuLinks = useMemo(
    () => [
      accountSettingsRoute,
      logoutRoute,
    ].map((route) => route.toMenuLinkProps())
      .map((link) => ({
        ...link,
        children: t(link.children),
      })),
    [t],
  );

  useEffect(() => {
    if (!isMobile || !isSideDrawerOpen) return;
    document.body.style.setProperty('overflow', 'hidden');
    return () => {
      document.body.style.removeProperty('overflow');
    };
  }, [isMobile, isSideDrawerOpen]);

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [styles.root_overlay]: isMobile,
    [styles.root_fullWidth]: isMobile,
    [styles.root_collapsed]: isMobile && !isSideDrawerOpen,
    [className || '']: true,
  });

  const spacerClassName = useMergedClassNames({
    [styles.spacer]: true,
    [styles.spacer_collapsed]: isMobile,
  });

  const brandingSectionClassName = useMergedClassNames({
    [styles.section]: true,
    [styles.brandingSection]: true,
  });

  const mainNavigationSectionClassName = useMergedClassNames({
    [styles.section]: true,
    [styles.mainNavigationSection]: true,
  });

  return (
    <>
      <div className={spacerClassName} />
      <PortalToBody>
        <aside {...restRootProps} className={rootClassName}>
          <div className={brandingSectionClassName}>
            <Link
              className={styles.brandingSection__dashboardLink}
              to={accountDashboardRoute.path}
              onClick={closeSideDrawer}
            >
              <BrandLogo design="primary" />
            </Link>
            <Breakpoint is="mobile">
              <Button
                className={styles.closeButton}
                design="outline"
                icon={<CrossIcon />}
                onClick={closeSideDrawer}
              />
            </Breakpoint>
          </div>
          <div className={styles.section}>
            <CustomerSnippet customer={authenticatedCustomer} />
          </div>
          <div className={mainNavigationSectionClassName}>
            <Menu
              links={mainMenuLinks}
              onMenuLinkClick={closeSideDrawer}
            />
          </div>
          <div className={styles.section}>
            <Menu
              links={serviceMenuLinks}
              onMenuLinkClick={closeSideDrawer}
            />
          </div>
        </aside>
      </PortalToBody>
    </>
  );
};
