import { EInputMode } from '@generics/inputs/types';
import React, { createContext, useMemo } from 'react';

export type TFormModeContextProps = {
  current?: `${EInputMode}`;
  edit?: () => void;
  represent?: () => void;
};

export const FormModeContext = createContext<TFormModeContextProps | undefined>(undefined);

export type TFormModeProviderProps = Extend<TFormModeContextProps, {
  children?: React.ReactNode;
}>;

export const FormModeProvider = (props: TFormModeProviderProps) => {
  const { current, edit, represent, children } = props;

  const context = useMemo(() => ({
    current,
    edit,
    represent,
  }), [current, edit, represent]);

  return (
    <FormModeContext.Provider value={context}>
      {children}
    </FormModeContext.Provider>
  );
};

export function useFormMode() {
  const context = React.useContext(FormModeContext);

  if (context === undefined) {
    throw new Error('The useFormMode() must be used within a <FormModeProvider /> component.');
  }

  return context;
}
