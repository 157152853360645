import React, { useMemo } from 'react';
import {
  QueryClientProvider as TanstackQueryClientProvider,
  useQueryClient as useTanstackQueryClient,
} from '@tanstack/react-query';
import { createQueryClient } from './createQueryClient';

export type DataManagementProviderProps = {
  children?: React.ReactNode;
};

export const useQueryClient = useTanstackQueryClient;

export function QueryClientProvider({ children }: DataManagementProviderProps) {
  const client = useMemo(() => createQueryClient(), []);

  return <TanstackQueryClientProvider client={client}>{children}</TanstackQueryClientProvider>;
}
