import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@generics/surfaces';
import { Typography } from '@generics/content';
import { Button } from '@generics/inputs/button';
import { Link } from '@generics/navigation';
import { useBreakpoints } from '@generics/responsiveness';
import { useConfirmationDialog } from '@generics/ConfirmationDialog/useConfirmationDialog';
import { ArrowRightIcon, CrossIcon, TrashIcon } from '@images/icons';
import { Models, useReservationCancelMutation } from '@network/api';
import { useFormatDatetime } from '@utils/localizing';
import styles from './DraftEventBox.module.scss';

export type TDraftEventBoxProps = {
  event: Models.Booking.Event.Data;
};

export const DraftEventBox = (props: TDraftEventBoxProps) => {
  const { event: { id, start_time, time_slot, lounge, event_information } } = props;

  const [t] = useTranslation();
  const formatDatetime = useFormatDatetime();
  const { isMobile } = useBreakpoints();
  const { openDialog, dialog } = useConfirmationDialog();
  const reservationCancelMutation = useReservationCancelMutation();

  const eventStartDatetime = useMemo(
    () => formatDatetime({ date: start_time, format: 'do MMMM' }),
    [formatDatetime, start_time],
  );

  const handleDeleteButtonClick = useCallback(() => {
    openDialog({
      title: 'booking.deleteDraftEvent',
      text: 'booking.deleteDraftEventInfo',
      okText: 'booking.deleteDraftEventOk',
      okIcon: <CrossIcon />,
      type: 'delete',
    }).then((isConfirm) => {
      if (isConfirm) reservationCancelMutation.mutate(id);
    });
  }, [id, openDialog, reservationCancelMutation]);

  return (
    <Box size="large">
      <div className={styles.content}>
        <Typography type="body/regular/m" color="primary">
          {[
            eventStartDatetime,
            lounge?.name && `${lounge.name} Lounge Event`,
          ]
            .filter(Boolean)
            .join(', ')}
        </Typography>
        <Typography type="body/regular/s" color="primary">
          {[
            event_information?.ParticipantsCount ?? 0,
            `${t('booking.participants').toLowerCase()},`,
            `${time_slot.duration_in_minutes / 60}h -`,
            time_slot.name.toLowerCase(),
          ].join(' ')}
        </Typography>
      </div>
      <div className={styles.actions}>
        <Button
          severity="danger"
          design="outline"
          label={isMobile ? undefined : 'labels.delete'}
          icon={<TrashIcon />}
          onClick={handleDeleteButtonClick}
          isLoading={reservationCancelMutation.isPending}
        />
        <Button
          className={styles.resumeButton}
          as={Link}
          to={`/public/event-booking/${id}`}
          design="outline"
          label="labels.resume"
          icon={<ArrowRightIcon />}
          disabled={reservationCancelMutation.isPending}
        />
      </div>
      {dialog}
    </Box>
  );
};
