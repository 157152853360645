export enum ProductType {
  Membership = 'membership',
  Package = 'package',
  SingleRide = 'time_slot',
  GroupRide = 'group_ride',
}

export type Data = {
  id: string;
  currency: string;
  total: string;
  status: string;
  created_at: string;
  completed: boolean;
  product_type: `${ProductType}`;
  product_name: string;
  payment_date: string;
  payment_place_type: string;
  payment_details: string;
  transaction_failed: boolean;
  failed: boolean;
  transaction_type: string;
};
