import React, { useMemo } from 'react';
import BookingDetailsList, {
  BookingDetail,
} from '../../../../../components/BookingDetailsList/BookingDetailsList';
import styles from './ThankYou.module.scss';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { EBookingPayment, IRideBookingForm } from '../../../RideBooking';
import Button from '../../../../../components/Button';
import { ReactComponent as ArrowRightIcon } from '../../../../../images/arrow-current-color.svg?tsx';
import { Lounge } from '../../../../../store/lounge/lounge.types';

type TThankYouProps = {
  selectedLounge: Lounge | null;
  summaryDetails: BookingDetail[];
};

const ThankYou: React.FC<TThankYouProps> = ({
  summaryDetails,
  selectedLounge,
}) => {
  const { watch } = useFormContext<IRideBookingForm>();
  const [t] = useTranslation();

  const { firstName, lastName, email, payment } = watch([
    'firstName',
    'lastName',
    'email',
    'payment',
  ]);

  const billingDetails: BookingDetail[] = useMemo(
    () => [
      {
        label: t('field.firstName.label'),
        value: firstName,
      },
      {
        label: t('field.lastName.label'),
        value: lastName,
      },
      {
        label: t('field.email.labelWithoutStar'),
        value: email,
      },
      {
        label: t('booking.steps.rideThankYou.paymentMethod'),
        value:
          payment === EBookingPayment.lounge
            ? t('booking.steps.summary.payInLounge')
            : t('booking.steps.summary.payByCard'),
      },
    ],
    [firstName, lastName, email, payment, t],
  );

  return (
    <div className={styles.thankYou}>
      <div>
        <h2 className={styles.thankYou__title2}>
          {t('booking.steps.rideThankYou.title', { name: firstName })}
        </h2>
        <div className={styles.thankYou__info}>
          {t('booking.steps.rideThankYou.text')}
        </div>
        <div className={styles.faqCard}>
          <h4 className={styles.faqCard__title}>
            {t('booking.steps.rideThankYou.faqTitle')}
          </h4>
          <span className={styles.faqCard__text}>
            {t('booking.steps.rideThankYou.faqText')}
          </span>
          <Button
            className={styles.faqCard__button}
            label={t('booking.steps.rideThankYou.checkFaqButton')}
            icon={<ArrowRightIcon />}
            onClick={() =>
              selectedLounge
              && window.open(selectedLounge.thank_you_page_link, '_blank')}
          />
        </div>
      </div>
      <div className={styles.thankYou__detailsCol}>
        <div>
          <h2 className={styles.thankYou__title3}>
            {t('booking.steps.summary.bookingSummary')}
          </h2>
          <BookingDetailsList details={summaryDetails} />
        </div>
        <div>
          <h2 className={styles.thankYou__title3}>
            {t('booking.steps.summary.billingDetails')}
          </h2>
          <BookingDetailsList details={billingDetails} />
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
