const events = {
  readMore: 'Weiterlesen',
  events: 'Events',
  back: 'Zurück',
  view: 'Anzeigen',
  close: 'Schliessen',
  starts: 'Von',
  ends: 'Bis',
  lounge: 'Lounge',
};

export default events;
