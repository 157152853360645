import { FieldErrors, FieldValues } from 'react-hook-form';

export function getFormError<T extends FieldValues>(
  fieldName: string,
  errors: FieldErrors<T>,
) {
  const fieldError = errors[fieldName as keyof T];
  return (
    (fieldError
    && !Array.isArray(fieldError)
    && (fieldError.message as string))
    || ''
  );
}
