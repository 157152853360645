import React, { useEffect, useRef } from 'react';
import { useMergedClassNames } from '@utils/styling';
import styles from './AspectRatio.module.scss';

const defaults = {
  aspectRatio: 4 / 3,
};

export type TAspectRatioProps = Extend<HTMLProps<HTMLDivElement>, {
  aspectRatio?: number;
}>;

export const AspectRatio = (props: TAspectRatioProps) => {
  const { aspectRatio = defaults.aspectRatio, children, className, ...restRootProps } = props;

  const rootElementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    rootElementRef.current?.style
      .setProperty('--AspectRatio__root--aspectRatio', aspectRatio?.toString());
  }, [aspectRatio]);

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [className || '']: true,
  });

  return <div {...restRootProps} className={rootClassName} ref={rootElementRef}>{children}</div>;
};
