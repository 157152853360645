import {
  ChampionshipActionType,
  GET_CHAMPIONSHIPS,
  ChampionshipState,
} from './championships.types';

const initialState: ChampionshipState = {
  championships: [],
};

export default (state = initialState, action: ChampionshipActionType) => {
  switch (action.type) {
    case GET_CHAMPIONSHIPS:
      return {
        championships: action.payload,
      };
    default:
      return state;
  }
};
