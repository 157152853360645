import { LoungeActionTypes, GET_LOUNGE, Supervisor, Lounge } from './lounge.types';
import i18n from '../../i18n/index';
import { Dispatch } from 'react';
import api, { API_ROOT } from '../../config/api.config';
import { AxiosError } from 'axios';

const getOptions = () => ({
  headers: {
    'x-locale': i18n.language,
  },
});

export const getLounge = async (
  dispatch: Dispatch<LoungeActionTypes>,
  events_only: boolean = false,
) => {
  try {
    const res = await api.get<{ data?: Lounge[] }>(`${API_ROOT}/lounges`, {
      ...getOptions(),
      params: { events_only },
    });
    const loungeData = res?.data?.data ?? [];

    dispatch({
      type: GET_LOUNGE,
      payload: loungeData,
    });
  }
  catch (err) {
    const error = err as AxiosError<TRumpApiRequestError>;
    return Promise.reject(
      'response' in error ? error.response?.data : error,
    );
  }
};

export const getSupervisorByLoungeId = async (
  id: string,
  controller?: AbortController,
) => {
  try {
    const res = await api.get<{ data?: Supervisor | null }>(`${API_ROOT}/lounges/${id}/supervisor_info`, {
      ...getOptions(),
      signal: controller && controller.signal,
    });
    const supervisor = res?.data?.data ?? null;

    return supervisor;
  }
  catch (err) {
    const error = err as AxiosError<TRumpApiRequestError>;
    return Promise.reject(
      'response' in error ? error.response?.data : error,
    );
  }
};
