import { composeComponents } from '@utils/composeComponents';
import GeolocationProvider from './providers/geolocation.provider';
import { AuthenticationProvider } from '@features/authentication';
import { QueryClientProvider } from '@network/queries';
import { MetadataProvider } from '@features/metadata';
import { NotificationsProvider } from '@features/notifications';
import { ApiClientProvider } from '@network/api';
import { ProductPurchaseModalProvider } from '@features/products';
import { ValidatorProvider } from '@utils/validation';
import { FormatDatetimeProvider } from '@utils/localizing';

export const Providers = composeComponents([
  [FormatDatetimeProvider],
  [ValidatorProvider],
  [GeolocationProvider],
  [QueryClientProvider],
  [AuthenticationProvider],
  [ApiClientProvider],
  [MetadataProvider],
  [ProductPurchaseModalProvider],
  [NotificationsProvider],
]);
