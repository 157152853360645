const ranking = {
  ranking: 'Ranking',
  rank: 'Rank',
  racer: 'Racer',
  lapTime: 'Lap Time',
  noItems: 'No items',
  selectTrack: 'Select track',
  viewAll: 'View all',
};

export default ranking;
