import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useAuthentication } from '@features/authentication';
import {
  Models,
  useNotificationsQuery,
  useMarkNotificationAsSeenMutation,
} from '@network/api';

export type TNotificationsContextProps = {
  items: Models.Notification.Data[];
  unseenCount: number;
  isDialogOpen: boolean;
  hasUnseen: boolean;
  isMarkingAllAsRead: boolean;
  markAllAsRead: () => void;
  openDialog: () => void;
  closeDialog: () => void;
};

export const NotificationsContext = createContext<TNotificationsContextProps | undefined>(undefined);

export type TNotificationsProviderProps = {
  children?: React.ReactNode;
};

export const NotificationsProvider = (props: TNotificationsProviderProps) => {
  const { children } = props;

  const { isAuthenticated } = useAuthentication();

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isMarkingAllAsRead, setIsMarkingAllAsRead] = useState(false);

  const { data: { notifications, unseen_count } } = useNotificationsQuery({ enabled: isAuthenticated });
  const markNotificationAsSeenMutation = useMarkNotificationAsSeenMutation();

  const hasUnseen = useMemo(() => unseen_count > 0, [unseen_count]);

  const markAllAsRead = useCallback(async () => {
    setIsMarkingAllAsRead(true);
    await Promise.all(notifications.filter(({ seen }) => !seen).map(({ id }) => markNotificationAsSeenMutation.mutateAsync(id)));
    setIsMarkingAllAsRead(false);
  }, [notifications, markNotificationAsSeenMutation]);

  const openDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const context = useMemo(() => ({
    items: notifications,
    unseenCount: unseen_count,
    isDialogOpen,
    hasUnseen,
    isMarkingAllAsRead,
    markAllAsRead,
    openDialog,
    closeDialog,
  }), [notifications, isDialogOpen, unseen_count, hasUnseen, isMarkingAllAsRead, markAllAsRead, openDialog, closeDialog]);

  return (
    <NotificationsContext.Provider value={context}>
      {children}
    </NotificationsContext.Provider>
  );
};

export function useNotifications() {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error('The useNotifications() must be used within a <NotificationsProvider />');
  }

  return context;
}
