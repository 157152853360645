import React, { useCallback, useMemo } from 'react';
import { LinkProps, NavLink, NavLinkRenderProps, To } from 'react-router-dom';
import { mergeClassNames, useMergedClassNames } from '@utils/styling';
import styles from './MenuLink.module.scss';

export type TMenuLinkProps = Extend<LinkProps, {
  to?: To;
  uid: string | number;
  icon?: React.ReactNode;
  selected?: boolean;
}>;

export const MenuLink = (props: TMenuLinkProps) => {
  const {
    to,
    children,
    icon,
    onClick,
    selected = false,
    className,
    ...restRootProps
  } = props;

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [className || '']: true,
  });

  // TODO: Add functions support into useMergedClassNames?
  const computeRootClassName = useCallback(
    (navLinkProps: NavLinkRenderProps) => mergeClassNames({
      [rootClassName]: true,
      [styles.root_selected]: navLinkProps.isActive || selected,
    }),
    [selected, rootClassName],
  );

  const content = useMemo(() => (
    <>
      {icon ? <span className={styles.icon}>{icon}</span> : null}
      {children ? <span className={styles.label}>{children}</span> : null}
    </>
  ), [icon, children]);

  if (to) {
    return (
      <NavLink
        {...restRootProps}
        className={computeRootClassName}
        to={to}
        onClick={onClick}
        end
      >
        {content}
      </NavLink>
    );
  }

  return (
    <a {...restRootProps} className={rootClassName} onClick={onClick}>
      {content}
    </a>
  );
};
