import React, { useEffect } from 'react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import StripeField from '../../../../components/CardForm/StripeField';
import styles from './CardForm.module.scss';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { stripeClient } from '../../../../config/api.config';

export interface ICardFormProps {
  onStripeChange?: (stripe: Stripe | null) => void;
  onStripeElementsChange?: (stripe: StripeElements | null) => void;
}

const CardForm = ({ onStripeChange, onStripeElementsChange }: ICardFormProps) => {
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    onStripeChange?.(stripe);
  }, [onStripeChange, stripe]);

  useEffect(() => {
    onStripeElementsChange?.(elements);
  }, [onStripeElementsChange, elements]);

  return (
    <div className={styles.cardForm}>
      <StripeField
        outlined
        StripeField={CardNumberElement}
        i18nKey="billing.card_number"
        placeholder="0000 0000 0000 0000"
      />
      <StripeField
        outlined
        StripeField={CardExpiryElement}
        i18nKey="billing.card_expiration"
        placeholder="MM/YY"
      />
      <StripeField
        outlined
        StripeField={CardCvcElement}
        i18nKey="billing.card_cvc"
        placeholder="000"
      />
    </div>
  );
};

const WrappedForm = (props: ICardFormProps) => (
  <Elements stripe={stripeClient.instance ?? stripeClient.promise}>
    <CardForm {...props} />
  </Elements>
);

export default WrappedForm;
