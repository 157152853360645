const policy = {
  data: `

  <h2>Privacy</h2>

  <p class="TermsPolicy-margin">This privacy statement informs you about the type of personal data that is collected
  and received through our Racing Unleashed.com website (“website”) and through
  our member portal and app ("Racing Unleashed app). This document details how we
  use, share, retain and protect such personal data.</p>

  <h2>Who we are</h2>

  <p class="TermsPolicy-margin">We are the Racing Unleashed AG (“we”, or “us”), a Swiss company registered at
  Lorzenparkstrasse 4, 6330 Cham, Switzerland. We are the data controller
  responsible for the processing of personal data through the website.</p>

  <h2>How we obtain personal data</h2>

  <p>We collect and receive personal data through the website or our member portal (web
    and app). Personal data means any information relating to an identified or
    identifiable natural person.</p>

  <p>We collect personal data that you choose to voluntarily provide to us for your use of
  the website and the Racing Unleashed services such as booking, real time ranking,
  connection to other Racing Unleashed competition members. </p>

  <p>The personal data we obtain, following your expressed confirmation, is categorized
  in the following list:</p>

  <ol>
    <li>Name and contact details (such as name, address, email, username, phone
      numbers);</li>
    <li>Racing Unleashed account details (such as username and password);</li>
    <li>Information on your use of the website and of the Racing Unleashed member
    portal (inclusive of website and apps);</li>
    <li>Credit card information for bookings, subscriptions, value added services and
    participation in championships. Please note, we do not store credit card
    information in any of our systems other than the last four digits of the credit
    card number and the expiry date for future reference. We use a trusted and
    industry standard third-party supplier ("www.stripe.com") for credit card
    transactions and we communicate with this supplier only through anonymized
    tokens</li>
    <li>Personal data included in comments posted by you on the website, our social
    media and public platforms and on the member platform;</li>
    <li>Personal data of individuals you wish to share your uploaded content with
    (such as name and email address);</li>
    <li>Traffic data (such as your Internet usage and your country of access).</li>
  </ol>

  <p class="TermsPolicy-margin">
  We may collect other types of personal data if required under applicable law or if
  necessary, for the purposes listed below. We will then inform you and ensure that
  there is a valid legal basis for doing so. In all cases, when requesting and using
  personal data we request your approval and will notify you of any changes to our
  privacy and data protection policies.
  </p>

  <h2>
  How we may use personal data
  </h2>

  <p>
  We may use the personal data we collect and receive for the following purposes:
  </p>

  <h2>
  Registration
  </h2>

  <ol class="TermsPolicy-margin">
      <li>To create an account for your use of the website; to operate our website and
      deliver the services you have requested, including, without limitation, our
      interactive services which you use;</li>
      <li>To manage our relationship with you;</li>
      <li>To reserve subscriptions, rides, participation in racing championships and provide
      you access to value added services</li>
      <li>To analyze how the website is used, such as which pages are visited, how
      long pages were visited, and the paths taken by visitors to our website as they
      move from page to page;</li>
      <li>To ensure the security of our business, preventing or detecting fraud or abuses
      of our website.</li>
  </ol>

  <h2>
  Newsletter/Email communication
  </h2>

  <ol class="TermsPolicy-margin">
    <li>To contact you, such as by sending personalized emails or newsletters, about
    our products and service;</li>
    <li>To contact you, to invite you for regular competition or events;</li>
    <li>To respond to your queries.</li>
  </ol>

  <h2>
  Usage of competition data
  </h2>

  <ol class="TermsPolicy-margin">
    <li>Your username/nickname will be published on our ranking system and
    external media;</li>
    <li>We will be using email to invite you und compete;</li>
    <li>We will be recording your performance data for performance analysis.</li>
  </ol>

  <h2>
  Usage of credit card information
  </h2>

  <ol class="TermsPolicy-margin">
      <li>Credit card information is used to book events, rides, subscriptions, value
      added services (eg racing coaching) and competitions</li>
      <li>Your credit card information is not stored in our systems other than the last
      four digits of the number and the expiry date</li>
      <li>We will only use your credit card information with your express consent for
      each payment action performed</li>
  </ol>

  <h2>
  How we may share personal data
  </h2>

  <p>
  We may share the personal data we collect and receive on a need to know basis
with the following third parties:
  </p>

  <ol>
      <li>Other affiliates of the Racing Unleashed group or its agents;</li>
      <li>Third party providers that perform services for us;</li>
      <li>Other users of the platform if you choose to share your messages; </li>
      <li>Competent public authorities or other third parties, if required by law or
      reasonably necessary to protect the rights, property and safety of ourselves or
      others.</li>
  </ol>

  <p class="TermsPolicy-margin">
  We do not sell, rent or trade your personal data.
  </p>

  <h2>
  Data transfers outside the EEA
  </h2>

  <p>
  We may transfer the personal data we obtain to third parties in countries outside the
European Economic Area (EEA). The laws in those countries may not offer an
adequate level of data protection. Countries to which personal data may be
transferred include Singapore, Philippines and the United States.
  </p>

  <p class="TermsPolicy-margin">
  When we transfer your personal data outside the EEA, we will protect your personal
data as described in this privacy statement and in accordance with applicable laws,
such as by entering into the European Commission’s Standard Contractual Clauses
for the transfer of personal data to a processor located outside of the EEA.
  </p>

  <h2>
  Links
  </h2>

  <p class="TermsPolicy-margin">
  This website may contain links to other sites. Please be aware that we are not
  responsible for the content or privacy practices of such other sites. We encourage
  our users to be aware when they leave our site and to read the privacy statements of
  any other site that collects personally identifiable information. Your data protection
  and privacy rights under these third-party platforms will be governed by their
  respective privacy policies
  </p>

  <h2>
  How we protect personal data
  </h2>

  <p class="TermsPolicy-margin">
  We maintain appropriate technical and organizational security safeguards designed
  to protect your personal data against accidental, unlawful or unauthorized
  destruction, loss, alteration, access, disclosure or use. However, due the inherent
  open nature of the Internet, we cannot guarantee that communications between you
  and us or the personal information stored is absolutely secure. We will notify you of
  any data breach that is likely to have unfavorable consequences for your privacy in
  accordance with applicable law.
  </p>

  <h2>
  How long we retain personal data for
  </h2>

  <p class="TermsPolicy-margin">
  We retain personal data for as long as necessary to fulfil the purposes for which we
collect or receive the personal data, except if required otherwise by applicable law.
Typically, we will retain most of the personal data for the duration of your use of the
website, until you have deleted your account, unless a longer applicable statutory
retention period applies.
  </p>

  <h2>
  Your rights
  </h2>

  <p>
  You have the following rights in relation to your personal data:
  </p>

  <ol class="TermsPolicy-margin">
      <li>The right to obtain, at reasonable intervals and free of charge, information on
      whether your personal data is being processed and to receive the personal
      data that is being processed in an intelligible form;</li>
      <li>The right to have your personal data rectified, blocked or deleted if your
      personal data is incorrect, incomplete, inaccurate, irrelevant, outdated or
      processed unlawfully.</li>
      <li>The right to object on legitimate grounds to the processing of your personal
      data.</li>
      <li>To exercise these rights, please contact us using our contact details set out
      below. We may request that you provide a copy of your ID card or otherwise
      prove your identity. We will respond to your request within the applicable
      statutory term.</li>
  </ol>

  <h2>
  Updates to this privacy statement
  </h2>

  <p class="TermsPolicy-margin">
  We may occasionally update this privacy statement. We encourage you to
  periodically review this privacy statement in order to stay informed of how we use
  your information. 
  </p>

  <h2>
  How to contact us
  </h2>

  <p class="TermsPolicy-margin">
  If you have any comments or inquiries about the information in this privacy
statement, if you would like us to update your personal data, or if you want to
exercise your rights, please contact our Data Protection Officer by sending an email
to dataprotection@Racing-Unleashed.com.
  </p>

  <h2 class="fv-terms-policy__title">
  Use of cookies
  </h2>

  <h2>
  How we use cookies
  </h2>

  <p >
  In addition, we collect certain personal data by using cookies when you visit the
website, including similar technologies such as local storage.
  </p>

  <p>
  Cookies are bits of text that are placed on your computer’s hard drive or mobile
device when you visit the website. Cookies hold information that may be accessible
by the party that places the cookie, which is either the website itself (first part cookie)
or a third party (third party cookies). You have control over cookies and can refuse
the use of cookies by selecting the appropriate setting on your browser. Most
browsers will tell you how to stop accepting new cookies, how to be notified when
you receive a new cookie, and how to disable or delete existing cookies. Please
note, however, that by not accepting or deleting the use of cookies, you may not be
able to use the full functionality of the website.
  </p>

  <p>
  On the website, we use cookies for the following purposes:
  </p>

  <ol>
      <li>To gather website statistical data to analyze how our users use the website,
      such as which pages are visited, how long pages were visited, and the paths
      taken by visitors to our website as they move from page to page;</li>
      <li>To provide authentication (i.e. to keep you logged in between sessions). The
      information collected using local storage is stored on your browser and
      persists after your browser is closed.</li>
  </ol>

  <p>
  For the purpose listed under 1), we use Google Analytics. Google Analytics is a web
  analytics service provided by Google, Inc. (“Google”), which uses cookies to help
  analyze the website usage. The information generated by the cookie about your use
  of our website (such as your IP address, the URL visited, the date and time the page
  was viewed) will be transmitted and stored by Google on servers in the United States
  or any other country in which Google maintains facilities. Your IP address will be
  masked by setting the last octet of your IP address to zero before it is stored by
  Google. Google will use this information to monitor your use of our website, and to
  compile reports on website activity for us.
  </p>
  `,
};

export default policy;
