import React from 'react';
import { useTranslation } from 'react-i18next';
import DayPicker from 'react-day-picker';
import { startOfDay } from 'date-fns';
import {
  Championship,
  ChallegePhase,
} from '../../store/championships/championships.types';
import de from '../../i18n/de/dates';
import en from '../../i18n/en/dates';
import es from '../../i18n/sp/dates';
import Navbar from './Navbar';

import 'react-day-picker/lib/style.css';
import './DateRange.scss';

const langs = {
  de,
  en,
  es,
};

interface DateRange {
  championship: Championship;
}

const Calendar = ({
  championship: { championship_phases, date_from, date_to },
}: DateRange) => {
  const [t, i18n] = useTranslation();
  const today = new Date(date_from);

  const startDays = championship_phases.map((phase) =>
    startOfDay(new Date(phase.date_from)).getTime(),
  );
  const endDays = championship_phases.map((phase) =>
    startOfDay(new Date(phase.date_to)).getTime(),
  );

  const getSelectionColor = (date: Date) => {
    const currentDay = startOfDay(date).getTime();
    const selectionPhase = championship_phases.filter(
      (phase: ChallegePhase) => {
        const start = startOfDay(new Date(phase.date_from)).getTime();
        const end = startOfDay(new Date(phase.date_to)).getTime();
        return currentDay >= start && currentDay <= end;
      },
    )[0];

    return selectionPhase
      ? {
          phase: selectionPhase,
          isStart: startDays.some((day) => day === currentDay),
          isEnd: endDays.some((day) => day === currentDay),
        }
      : null;
  };

  const renderDay = (day: Date) => {
    const phase = getSelectionColor(day);

    return (
      <>
        {phase && (
          <div
            className={`DataRange-field ${phase.isStart ? 'is-start' : ''} ${
              phase.isEnd ? 'is-end' : ''
            }`}
            style={{ background: phase.phase.color }}
          />
        )}
        <span className="Calendar-day">{day.getDate()}</span>
      </>
    );
  };

  return (
    <div className="DateRange is-range">
      <DayPicker
        fromMonth={today}
        toMonth={new Date(date_to)}
        initialMonth={today}
        showOutsideDays
        locale={i18n.language}
        weekdaysShort={langs[i18n.language as keyof typeof langs].weekdaysShort}
        months={langs[i18n.language as keyof typeof langs].months}
        navbarElement={<Navbar fromMonth={today} toMonth={new Date(date_to)} />}
        renderDay={renderDay}
        disabledDays={{ before: new Date() }}
        selectedDays={[]}
        firstDayOfWeek={1}
      />
      <div className="Calendar-legend">
        <div>
          <span />
          {t('booking.raceDay')}
        </div>
        <div>
          <span />
          {t('booking.currentDay')}
        </div>
        <div>
          <span />
          {t('booking.fullyBooked')}
        </div>
        <div>
          <span />
          {t('booking.nonBookable')}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
