import { WretchError } from 'wretch';
import { TUseApiQueryProps, useApiClient, useApiQuery } from '@network/api/client';
import * as Models from '@network/api/models';

export type NotificationsResponse = {
  data: {
    notifications: Models.Notification.Data[];
    unseen_count: number;
  };
};

export type TUseNotificationsQuery = Omit<TUseApiQueryProps<NotificationsResponse['data'], WretchError>, 'queryKey' | 'queryFn'>;

export function useNotificationsQuery(props: TUseNotificationsQuery) {
  const apiClient = useApiClient();

  const { data, ...restQuery } = useApiQuery({
    refetchInterval: 30000,
    placeholderData: { notifications: [], unseen_count: 0 },
    ...props,
    queryKey: ['notifications', 'all'],
    queryFn: () => apiClient.url('/notifications').get().json<NotificationsResponse>().then(({ data }) => data),
  });

  return {
    data: data ?? { notifications: [], unseen_count: 0 },
    ...restQuery,
  };
}
