import React, { useState, useCallback } from 'react';
import { Map as LeafletMap } from 'leaflet';
import WorldMapComponent, {
  IWorldMapProps,
} from '../components/WorldMap';

export interface IMapMarkerProps {
  latitude: number;
  longitude: number;
  popupContent?: React.ReactNode;
  onClick?: () => void;
}

export interface IUseMapProps {
  markers: IMapMarkerProps[];
}

function useInstantiatedWorldMap() {
  const [worldMapInstance, setWorldMapInstance] = useState<LeafletMap | null>(
    null,
  );

  const WorldMap = useCallback(
    (props: Omit<IWorldMapProps, 'onInitialized'>) => (
      <WorldMapComponent {...props} onInstantiated={setWorldMapInstance} />
    ),
    [],
  );

  return { WorldMap, worldMapInstance };
}

export default useInstantiatedWorldMap;
