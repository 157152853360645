import React from 'react';
import { SettingsIcon } from '@images/icons';
import { createRouteDefinition } from '@utils/routing';

export const accountSettingsRoute = createRouteDefinition({
  uid: 'settings',
  path: 'settings',
  icon: <SettingsIcon />,
  title: 'labels.userSettings',
});
