import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import ParticipantForm, {
  IEventParticipantDriverFormValues,
} from '../../components/ParticipantForm/ParticipantForm';
import SurveyWelcomeStep from '../../components/SurveyWelcomeStep/SurveyWelcomeStep';
import styles from './EventParticipantDrivers.module.scss';
import Button from '../../components/Button';
import { ReactComponent as ArrowIcon } from '../../images/arrow-current-color.svg?tsx';
import { ReactComponent as EditSvg } from '../../images/edit.svg?tsx';
import { ReactComponent as CloseSvg } from '../../images/cancel-current-color.svg?tsx';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useEditParticipantDialog } from './components/EditParticipantDialog/useEditParticipantDialog';
import {
  IParticipant,
  IOrganiserEvent,
} from '../../store/participant-drivers/participant-drivers.types';
import {
  addOrganiserParticipant,
  deleteOrganiserParticipant,
  getOrganiserBookingData,
} from '../../store/participant-drivers/participant-drivers.actions';
import { toastUtil } from '../../utils/toast.utils';
import Loader from '../../components/Loader';
import { useConfirmationDialog } from '../../components/ConfirmationDialog/useConfirmationDialog';
import { format } from 'date-fns';
import { dateLocales } from '../../constants/dateLocales';
import i18n from '../../i18n';

const EventParticipantDrivers: React.FC = () => {
  const [t] = useTranslation();
  const { openDialog, dialog } = useEditParticipantDialog();
  const { openDialog: openConfirmation, dialog: confirmationDialog }
    = useConfirmationDialog();
  const { id } = useParams<{ id: string }>();
  const form = useForm<IEventParticipantDriverFormValues>({
    mode: 'all',
    shouldUnregister: false,
    defaultValues: {
      id: '',
      first_name: '',
      last_name: '',
      email: '',
      newsletter: false,
    },
  });
  const [isBegun, setIsBegun] = useState<boolean>(false);
  const [eventData, setEventData] = useState<IOrganiserEvent | null>(null);
  const [reloadBooking, setReloadBooking] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<{
    eventData: boolean;
    sending: boolean;
  }>({ eventData: false, sending: false });

  const reloadEventData = () => setReloadBooking((count) => ++count);

  const {
    formState: { isValid },
    trigger,
    getValues,
    reset,
  } = form;

  useEffect(() => {
    if (!id) return;

    setTimeout(() =>
      setIsLoading((loading) => ({ ...loading, eventData: true })),
    );

    const controller = new AbortController();

    getOrganiserBookingData(id, controller)
      .then(setEventData)
      .catch((err: TRumpApiRequestError) => {
        if ('message' in err && err.message === 'canceled') return;

        const msg = 'meta' in err ? err.meta.message : err && err.message;
        if (typeof msg === 'string') toastUtil('error', msg);
      })
      .finally(() =>
        setIsLoading((loading) => ({ ...loading, eventData: false })),
      );

    return () => controller.abort();
  }, [id, reloadBooking]);

  const onSend = async () => {
    if (!trigger || !id) return;

    setIsLoading((loading) => ({ ...loading, sending: true }));

    try {
      await addOrganiserParticipant(id, getValues());
      reloadEventData();
      reset();
      toastUtil('success', t('eventParticipantDriver.participantAdded'));
    }
    catch (e) {
      const err = e as TRumpApiRequestError;
      if (!err) return;

      let msg: string = '';

      if ('meta' in err && typeof err.meta.message === 'object') {
        const error = Object.entries(
          err.meta.message as { [key: string]: string },
        )[0];
        const [key, value] = error;
        msg = key && value ? `${key} ${value}` : '';
      }
      else {
        msg
          = 'meta' in err && typeof err.meta.message === 'string'
            ? err.meta.message
            : (err as Error).message;
      }

      if (msg) {
        toastUtil('error', msg);
      }
    }

    setIsLoading((loading) => ({ ...loading, sending: false }));
  };

  const onDelete = async (participant: IParticipant) => {
    if (!id) return;
    if (
      !(await openConfirmation({
        title: 'eventParticipantDriver.deleteConfirmation.title',
        text: (
          <Trans
            i18nKey="eventParticipantDriver.deleteConfirmation.text"
            values={{
              name: `${participant.first_name} ${participant.last_name}`,
            }}
            components={{ 1: <b /> }}
          />
        ),
        okText: 'eventParticipantDriver.deleteConfirmation.okText',
        type: 'delete',
      }))
    )
      return;

    try {
      await deleteOrganiserParticipant(id, participant.participant_driver_id);
      reloadEventData();
      toastUtil('success', t('eventParticipantDriver.participantDeleted'));
    }
    catch (e) {
      const err = e as TRumpApiRequestError;
      const msg = 'meta' in err ? err.meta.message : err.message;
      if (typeof msg === 'string') toastUtil('error', msg);
    }
  };

  const onEdit = async (participant: IParticipant) => {
    if (!id) return;
    if (await openDialog(id, participant)) {
      reloadEventData();
    }
  };

  const isAllParticipantAdded
    = eventData
    && eventData.participant_drivers.length === eventData.participants_count;
  const isLastPasrticipantForm
    = eventData
    && eventData.participant_drivers.length + 1 === eventData.participants_count;
  const buttonLabel = !isBegun
    ? t('eventParticipantDriver.startSurvey')
    : isLastPasrticipantForm
      ? t('eventParticipantDriver.save')
      : t('eventParticipantDriver.organiserSend');

  const loader = <Loader fullSize color="black" width={80} height={80} />;

  return (
    <div className={styles.eventParticipantDrivers}>
      {eventData
        ? (
            <>
              <div
                className={classNames(
                  styles.eventParticipantDrivers__card,
                  styles.eventParticipantDrivers__content,
                )}
              >
                {!isBegun
                  ? (
                      <SurveyWelcomeStep
                        title={(
                          <>
                            {t('eventParticipantDriver.welcome.title')}
                            {' '}
                            <span role="img" aria-label="hello">
                              👋
                            </span>
                          </>
                        )}
                        description={[
                          <Trans
                            key="eventParticipantDriver.welcome.organiserDetails.firstLine"
                            i18nKey="eventParticipantDriver.welcome.organiserDetails.firstLine"
                            values={{
                              dateTime: format(
                                new Date(eventData.start_time),
                                'MMMM dd, yyyy',
                                {
                                  locale: dateLocales[i18n.language],
                                },
                              ),
                              location: eventData.lounge.name,
                            }}
                            components={{ 1: <b /> }}
                          />,
                          t(
                            'eventParticipantDriver.welcome.organiserDetails.secondLine',
                          ),
                        ]}
                      />
                    )
                  : (
                      ''
                    )}
                <FormProvider {...form}>
                  {!isBegun
                    ? (
                        ''
                      )
                    : isAllParticipantAdded
                      ? (
                          <div className={styles.eventParticipantDrivers__completed}>
                            {t('eventParticipantDriver.allParticipantsAdded')}
                          </div>
                        )
                      : (
                          <div>
                            <h1 className={styles.eventParticipantDrivers__title}>
                              {t('eventParticipantDriver.form.title')}
                            </h1>
                            <p className={styles.eventParticipantDrivers__subtitle}>
                              {t('eventParticipantDriver.form.subtitle')}
                            </p>
                            <ParticipantForm organiserView />
                          </div>
                        )}
                </FormProvider>
                {isBegun && isAllParticipantAdded
                  ? (
                      ''
                    )
                  : (
                      <Button
                        label={buttonLabel}
                        disabled={!isValid}
                        icon={isBegun ? <ArrowIcon /> : undefined}
                        onClick={isBegun ? onSend : () => setIsBegun(true)}
                      />
                    )}
                {isLoading.sending ? loader : ''}
              </div>
              {isBegun
                ? (
                    <div
                      className={classNames(
                        styles.eventParticipantDrivers__card,
                        styles.participantsList,
                      )}
                    >
                      <h2 className={styles.participantsList__title}>
                        {t('booking.participants')}
                        {' '}
                        <span>
                          {eventData ? eventData.participant_drivers.length : 0}
                          /
                          {eventData ? eventData.participants_count : 0}
                        </span>
                      </h2>

                      {!eventData || !eventData.participant_drivers.length
                        ? (
                            <p className={styles.participantsList__emptyList}>
                              {t('eventParticipantDriver.emptyParticipantsList')}
                            </p>
                          )
                        : (
                            <div className={styles.participantsList__list}>
                              {eventData.participant_drivers.map((participant) => (
                                <div key={participant.id} className={styles.participant}>
                                  <p className={styles.participant__name}>
                                    {participant.first_name}
                                    {' '}
                                    {participant.last_name}
                                  </p>
                                  <p className={styles.participant__email}>
                                    {participant.email}
                                  </p>
                                  <button
                                    className={styles.participant__edit}
                                    onClick={() => onEdit(participant)}
                                  >
                                    <EditSvg />
                                  </button>
                                  <button
                                    className={styles.participant__delete}
                                    onClick={() => onDelete(participant)}
                                  >
                                    <CloseSvg />
                                  </button>
                                </div>
                              ))}
                            </div>
                          )}
                    </div>
                  )
                : (
                    ''
                  )}
            </>
          )
        : (
            ''
          )}
      {isLoading.eventData ? loader : ''}
      {dialog}
      {confirmationDialog}
    </div>
  );
};

export default EventParticipantDrivers;
