import { IUseApiMutationProps, useApiClient, useApiMutation } from '@network/api/client';
import { useDispatch } from 'react-redux';
import { SET_MEMBER } from '@store/customer/customer.types';
import * as Models from '@network/api/models';
import { toastUtil } from '@utils/toast.utils';

export type UseUpdateCustomerMutationResponse = { data: Models.Customer.Data; meta?: { message?: string } };

export type TUseUpdateCustomerMutationProps = Omit<IUseApiMutationProps<UseUpdateCustomerMutationResponse>, 'mutationKey' | 'mutationFn'>;

export function useUpdateCustomerMutation(props?: TUseUpdateCustomerMutationProps) {
  const { onSuccess, ...restHookProps } = props ?? {};

  const dispatch = useDispatch();
  const apiClient = useApiClient();

  return useApiMutation({
    ...restHookProps,
    mutationKey: ['customers', 'update'],
    mutationFn: (data: Partial<Omit<Models.Customer.Data, 'password'> & { password: string | null; password_confirmation: string | null }>) =>
      apiClient.url(`/customers`)
        .patch(data)
        .json<UseUpdateCustomerMutationResponse>()
        .then((response) => response),
    onSuccess: (response, variables, context) => {
      dispatch({
        type: SET_MEMBER,
        payload: response.data,
      });
      if (response?.meta?.message) toastUtil('info', response.meta.message);
      onSuccess?.(response, variables, context);
    },
  });
}
