export default {
  relative: {
    lastWeek: '\'La semana pasada\' @{eeee}',
    yesterday: '\'Ayer\', HH:mm',
    today: '\'Hoy\', HH:mm',
    tomorrow: '\'Mañana\', HH:mm',
    nextWeek: '\'La próxima semana\' eeee',
    other: 'dd.MM.yyyy, HH:mm',
  },
  membership: {
    expiringAt: '\'vence el\' dd MMMM yyyy',
    expiredAt: '\'venció el\' dd MMMM yyyy',
  },
  bankCard: {
    expirationDate: '\'Fecha de exp.\' MM / yy',
  },
};
