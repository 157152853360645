import { FormModelField } from '../../../types/form.types';

export interface ISignInFormValues {
  identifier: string;
  password: string;
  remember_me: boolean;
}

export const initialValues: ISignInFormValues = {
  identifier: '',
  password: '',
  remember_me: false,
};

export const formModel: FormModelField[] = [
  {
    name: 'identifier',
    i18nKey: 'field.identifier',
    type: 'text',
  },
  {
    name: 'password',
    i18nKey: 'field.password',
    type: 'password',
  },
];
