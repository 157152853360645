import React, { useMemo } from 'react';
import mergeClassNames from 'classnames';
import { Dialog, DialogProps, Slide, SlideProps } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions/transition';
import styles from './MobileSlideUpDialog.module.scss';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...(props as SlideProps)} />;
});

export interface ISlideUpDialogProps extends Omit<DialogProps, 'fullScreen'> {}

const SlideUpDialog: React.FC<ISlideUpDialogProps> = (props) => {
  const { children, classes, BackdropProps } = props;

  const containerClassName = useMemo(
    () => mergeClassNames(styles.container, classes ? classes.container : null),
    [classes],
  );

  const paperClassName = useMemo(
    () => mergeClassNames(styles.paper, classes ? classes.paper : null),
    [classes],
  );

  const mergedClasses = useMemo(
    () => ({
      ...classes,
      container: containerClassName,
      paper: paperClassName,
    }),
    [classes, containerClassName, paperClassName],
  );

  const backdropProps = useMemo(
    () => ({
      ...BackdropProps,
      classes: {
        ...(BackdropProps ? BackdropProps.classes : {}),
        root: mergeClassNames(
          styles.backdrop__root,
          BackdropProps && BackdropProps.classes
            ? BackdropProps.classes.root
            : null,
        ),
      },
    }),
    [BackdropProps],
  );

  return (
    <Dialog
      {...props}
      classes={mergedClasses}
      BackdropProps={backdropProps}
      TransitionComponent={
        Transition as React.ComponentType<
          TransitionProps & { children?: React.ReactElement<unknown, string> }
        >
      }
    >
      {children}
    </Dialog>
  );
};

export default SlideUpDialog;
