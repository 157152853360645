import React, { useCallback, useRef } from 'react';
import { Typography } from '@generics/content';
import { Form, FormField, useForm } from '@generics/forms';
import { useUpdateCustomerMutation } from '@network/api';
import { useAuthenticatedCustomer } from '@features/authentication';
import styles from './CustomerAvatarSection.module.scss';

interface ICustomerAvatarData {
  profile_pic: string | null;
}

export const CustomerAvatarSection = () => {
  const authenticatedCustomer = useAuthenticatedCustomer();

  const getDefaultValues = useCallback(() => ({
    profile_pic: authenticatedCustomer?.profile_pic_url || null,
  } satisfies ICustomerAvatarData), [authenticatedCustomer]);

  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const form = useForm({
    defaultValues: getDefaultValues(),
  });

  const updateCustomerMutation = useUpdateCustomerMutation({
    onValidationErrors: form.setApiValidationErrors,
  });

  const handleValidSubmit = useCallback(async (data: ICustomerAvatarData) => {
    await updateCustomerMutation.mutateAsync(data);
  }, [updateCustomerMutation]);

  const handleAvatarChange = useCallback(() => {
    submitButtonRef.current?.click();
  }, []);

  if (!authenticatedCustomer) return null;

  return (
    <Form className={styles.root} form={form} onValidSubmit={handleValidSubmit}>
      <Typography type="body/medium/m">labels.avatar</Typography>
      <FormField
        name="profile_pic"
        type="avatar"
        onChange={handleAvatarChange}
        hideLabel
      />
      <button type="submit" hidden ref={submitButtonRef} />
    </Form>
  );
};
