import React, { useLayoutEffect } from 'react';
import { useAuthentication } from '@features/authentication';

export interface ILogoutPageProps extends Omit<React.HTMLProps<HTMLDivElement>, 'children'> {}

export const LogoutPage = (props: ILogoutPageProps) => {
  const { ...restRootProps } = props;

  const { logout } = useAuthentication();

  useLayoutEffect(() => {
    logout();
  }, [logout]);

  return (
    <div {...restRootProps}>Logout</div>
  );
};
