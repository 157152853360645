import React, { useEffect, useMemo } from 'react';
import {
  marker as createMapMarker,
  popup as createPopup,
  icon as createMapMarkerIcon,
  Icon,
  IconOptions,
} from 'leaflet';
import RUPin from '../../images/ru-pin.svg';
import RUPinSelected from '../../images/ru-pin-selected.svg';
import { createPortal } from 'react-dom';
import { useWorldMap } from './WorldMap';

export interface IWorldMapMarkerProps {
  id: string;
  latitude: number;
  longitude: number;
  markerIcon?: Icon<IconOptions>;
  popupContent?: React.ReactNode;
  selected?: boolean;
  onClick?: () => void;
}

const defaultMarkerIcon = createMapMarkerIcon({
  iconUrl: RUPin,
  iconSize: [32, 38],
  iconAnchor: [16, 38],
  popupAnchor: [0, -36],
});

const selectedMarkerIcon = createMapMarkerIcon({
  iconUrl: RUPinSelected,
  iconSize: [34, 40],
  iconAnchor: [17, 40],
  popupAnchor: [0, -38],
});

const WorldMapMarker: React.FC<IWorldMapMarkerProps> = ({
  id,
  latitude,
  longitude,
  markerIcon: customMarkerIcon,
  popupContent,
  selected = false,
  onClick,
}) => {
  const map = useWorldMap();

  const markerIcon = useMemo(() => customMarkerIcon ?? defaultMarkerIcon, [customMarkerIcon]);

  const marker = useMemo(
    () =>
      createMapMarker([latitude, longitude], {
        icon: selected ? selectedMarkerIcon : markerIcon,
      }),
    [latitude, longitude, markerIcon, selected],
  );

  const popupContainerElement = useMemo(
    () => document.createElement('div'),
    [],
  );

  const popup = useMemo(
    () => createPortal(popupContent, popupContainerElement),
    [popupContent, popupContainerElement],
  );

  useEffect(() => {
    marker.bindPopup(
      createPopup({
        content: popupContainerElement,
      }),
    );

    return () => {
      marker.unbindPopup();
    };
  }, [marker, popupContainerElement]);

  useEffect(() => {
    if (!onClick) return;
    const savedClickHandler = onClick;
    marker.on('click', savedClickHandler);
    return () => {
      marker.off('click', savedClickHandler);
    };
  }, [marker, onClick]);

  useEffect(() => {
    if (!map) return;
    const savedMapReference = map;
    const savedMarkerReference = { id, instance: marker };
    savedMapReference.addMarker(savedMarkerReference);
    return () => {
      savedMapReference.removeMarker(savedMarkerReference);
    };
  }, [map, id, marker]);

  return <>{popup}</>;
};

export default WorldMapMarker;
