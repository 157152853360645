import { useMediaQuery } from '@mui/material';

export function useBreakpoints() {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const isDesktop = !isMobile;

  return {
    isMobile,
    isDesktop,
  };
}
