import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import links from './links';
import styles from './AuthLinks.module.scss';

const AuthLinks = () => {
  const [t] = useTranslation();

  return (
    <div className={styles.root}>
      {links.map((link) => (
        <Link className={styles.root__link} key={link.i18nKey} to={link.url} target="_blank">
          {t(link.i18nKey)}
        </Link>
      ))}
    </div>
  );
};

export default AuthLinks;
