import { NotificationsResponse } from './useNotificationsQuery';
import { IUseApiMutationProps, useApiClient, useApiMutation } from '@network/api/client';
import { useQueryClient } from '@network/queries';
import { WretchResponse } from 'wretch';

export type TUseMarkNotificationAsSeenMutationResponse = WretchResponse;

export type TUseMarkNotificationAsSeenMutationProps = Omit<IUseApiMutationProps<WretchResponse>, 'mutationKey' | 'mutationFn'>;

export function useMarkNotificationAsSeenMutation() {
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useApiMutation({
    mutationKey: ['notifications', 'markAsSeen'],
    mutationFn: (id: string) => apiClient.url(`/notifications/${id}`).patch().res(),
    onMutate: (id: string) => {
      queryClient.setQueriesData(
        { queryKey: ['notifications', 'all'], exact: false },
        (previousData?: NotificationsResponse['data']) => {
          if (!previousData) return previousData;

          return {
            ...previousData,
            notifications: previousData?.notifications?.map(
              (notification) => notification.id === id ? { ...notification, seen: true } : notification,
            ),
            unseen_count: Math.max(0, previousData.unseen_count - 1),
          };
        },
      );
    },
  });
}
