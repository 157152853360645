import { AppState } from '..';
export const GET_TRACKS = 'GET_TRACKS';
export const GET_MONTHS = 'GET_MONTHS';
export const GET_CARS = 'GET_CARS';

export interface Track {
  id: string;
  name: string;
  active: boolean;
}

export interface Month {
  id: string;
  label: string;
  value: string;
  active: boolean;
}

export interface Car {
  id: string;
  value: string;
}

export interface Racer {
  customer_id: string;
  customer_fullname: string;
  best_lap: number;
  current_customer: boolean;
}

export type TrackSelector = (state: AppState) => Track[];
export type MonthSelector = (state: AppState) => Month[];
export type CarSelector = (state: AppState) => Car[];

export interface TracksState {
  tracks: Track[];
  months: Month[];
  cars: Car[];
}

interface GetTracksAction {
  type: typeof GET_TRACKS;
  payload: Track[];
}

interface GetMonthsAction {
  type: typeof GET_MONTHS;
  payload: Month[];
}

interface GetCarsAction {
  type: typeof GET_CARS;
  payload: Car[];
}

export type TrackActionTypes =
  | GetTracksAction
  | GetMonthsAction
  | GetCarsAction;
