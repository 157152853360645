import { RegisterOptions } from 'react-hook-form';
import { containsEmojis } from '../utils/contains-emoji.utils';

type TErrors = { required?: string } & { emojis?: string };

export const requiredTextValidator: (error: TErrors) => RegisterOptions = ({
  required,
  emojis,
}: TErrors) => ({
  validate: (value: string | undefined) => {
    if (required && (!value || (value && !value.trim()))) {
      return required;
    }

    if (emojis && value && containsEmojis(value)) {
      return emojis;
    }
  },
});
