import { EInputMode } from '@generics/inputs/types';
import React, { useMemo } from 'react';
import {
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  UseFormMethods,
} from 'react-hook-form';
import { FormModeProvider } from './FormModeProvider';

export type TFormProps<Data extends FieldValues> = {
  form: UseFormMethods<Data> & {
    mode?: `${EInputMode}`;
    edit?: () => void;
    represent?: () => void;
  };
  children?: React.ReactNode;
  onValidSubmit?: SubmitHandler<Data>;
  onInvalidSubmit?: SubmitErrorHandler<Data>;
  className?: string;
};

export const Form = <Data extends FieldValues>(props: TFormProps<Data>) => {
  const { form, children, onValidSubmit, onInvalidSubmit, className } = props;

  const { mode, edit, represent, ...restFormProps } = form;

  const { handleSubmit: handleHookFormSubmit } = restFormProps;

  const handleSubmit = useMemo(
    () => onValidSubmit ? handleHookFormSubmit(onValidSubmit, onInvalidSubmit) : undefined,
    [handleHookFormSubmit, onValidSubmit, onInvalidSubmit],
  );

  return (
    <FormProvider {...restFormProps}>
      <FormModeProvider
        current={mode}
        edit={edit}
        represent={represent}
      >
        <form className={className} onSubmit={handleSubmit}>{children}</form>
      </FormModeProvider>
    </FormProvider>
  );
};
