import React, { useState } from 'react';
import styles from './LoginDialog.module.scss';
import AppFormField from '../AppFormField/AppFormField';
import AppInput from '../AppInput';
import CheckboxRadio from '../FormField/CheckboxRadio';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useWithDispatch from '../../hooks/useWithDispatch';
import { signIn } from '../../store/customer/customer.actions';
import { toastUtil } from '../../utils/toast.utils';
import Button from '../Button';
import { ReactComponent as CloseIcon } from '../../images/cancel-current-color.svg?tsx';
import { useForm } from 'react-hook-form';
import { getFormError } from '../../utils/get-form-error.utils';
import MobileSlideUpDialog from '../MobileSlideUpDialog/MobileSlideUpDialog';
import { Controller } from '../FormField/Controller';

type TLoginDialogProps = {
  open: boolean;
  onClose: () => void;
};

const LoginDialog: React.FC<TLoginDialogProps> = ({ open, onClose }) => {
  const [t] = useTranslation();
  const signInAction = useWithDispatch(signIn);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const {
    control,
    formState: { errors },
    getValues,
    trigger,
  } = useForm<{
    identifier: string;
    password: string;
    remember_me: boolean;
  }>({
    mode: 'onChange',
    defaultValues: { identifier: '', password: '', remember_me: false },
  });

  const onSubmit = async () => {
    if (!(await trigger())) return;

    setSubmitting(true);

    try {
      await signInAction(getValues());
      toastUtil('success', t('auth.logIn.successMessage'));
      onClose();
    }
    catch (err) {
      const error = err as TRumpApiErrorResponseData;

      if (typeof error?.meta?.message === 'string')
        toastUtil('error', error.meta.message);
    }

    setSubmitting(false);
  };

  return (
    <MobileSlideUpDialog
      className={styles.container}
      classes={{ paper: styles.dialog }}
      open={open}
      onClose={onClose}
    >
      <div className={styles.dialog__content}>
        <h3 className={styles.dialog__title}>{t('auth.logIn.dialogTitle')}</h3>
        <form
          className={styles.form}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
              event.stopPropagation();
              onSubmit();
            }
          }}
        >
          <Controller
            control={control}
            name="identifier"
            rules={{
              required: t('field.email.required'),
            }}
            render={({ value, onChange }) => (
              <AppFormField
                required
                label="field.email.labelWithoutStar"
                error={getFormError('identifier', errors)}
              >
                <AppInput
                  type="email"
                  placeholder="field.email.placeholder"
                  value={value}
                  onChange={({ target: { value } }) => onChange(value)}
                />
              </AppFormField>
            )}
          />

          <Controller
            control={control}
            name="password"
            rules={{
              required: t('field.password.required'),
            }}
            render={({ value, onChange }) => (
              <AppFormField
                required
                label="field.password.labelWithoutStar"
                error={getFormError('password', errors)}
              >
                <AppInput
                  type="password"
                  placeholder="field.password.placeholder"
                  value={value}
                  onChange={({ target: { value } }) => onChange(value)}
                />
              </AppFormField>
            )}
          />

          <div className={styles.form__row}>
            <Controller
              control={control}
              name="remember_me"
              render={({ value, onChange }) => (
                <CheckboxRadio
                  primary
                  checked={value}
                  name="rememberMe"
                  value="rememberMe"
                  i18nKey="field.remember_me.label"
                  onChange={({ target: { checked } }) => onChange(checked)}
                />
              )}
            />

            <Link
              className={styles.form__link}
              to="/auth/forgot-password"
              target="_blank"
            >
              {t('auth.iForgotMyPassword')}
            </Link>
          </div>

          <Button
            disabled={submitting}
            label={t('auth.logIn.btnText')}
            onClick={onSubmit}
          />
        </form>
        <button className={styles.dialog__close} onClick={onClose}>
          <CloseIcon />
        </button>
      </div>
    </MobileSlideUpDialog>
  );
};

export default LoginDialog;
