import React from 'react';
import styles from './AuthFormMain.module.scss';
import { useTranslation } from 'react-i18next';

interface IAuthFormMainProps {
  title: string;
  subtitle: string;
  footer: React.ReactNode;
  children: React.ReactNode;
}

const AuthFormMain: React.FC<IAuthFormMainProps> = ({ title, subtitle, footer, children }) => {
  const [t] = useTranslation();

  return (
    <div className={styles.root}>
      <h3>
        {t(title)}
      </h3>
      <h4>{t(subtitle)}</h4>
      {children}
      <div className={styles.root__footer}>
        {footer}
      </div>
    </div>
  );
};

export default AuthFormMain;
