import { useTranslate } from '@features/multi-language';
import React, { createContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { en, de, es } from 'yup-locales';

const yupLocales = {
  en,
  de,
  es,
};

export type TValidatorContextProps = typeof Yup;

const ValidatorContext = createContext<TValidatorContextProps | undefined>(undefined);

export type TValidatorProviderProps = {
  children?: React.ReactNode;
};

export const ValidatorProvider = (props: TValidatorProviderProps) => {
  const { children } = props;

  const [, { language }] = useTranslation();
  const t = useTranslate();

  const validator = useMemo(() => {
    Yup.setLocale(yupLocales[language as keyof typeof yupLocales] ?? Yup.defaultLocale);

    Yup.addMethod(Yup.string, 'same', function (fieldName: string) {
      const message = 'validations.same';
      return this.test('same', message, function (value) {
        const { path, createError } = this;

        const ctx = this.parent as Record<string, unknown>;

        const fieldValue = ctx[fieldName];

        return value === fieldValue || createError({ path, message, params: { ref: fieldName } });
      });
    });

    // TODO: Re-think as a bit messy solution.
    Yup.ValidationError.formatError = function (message, params) {
      const path = typeof params.path === 'string' ? params.path : 'unknown';
      const label = params.label || `fields.${path}.label`;
      const translatedLabel = t(label, params);
      params.label = translatedLabel;

      if (typeof message === 'string') {
        const translatedMessage = t(message, params);

        const readyMessage = translatedMessage.replace(/\${(.*?)}/g, (_, key: keyof typeof params) => {
          if (key === 'path') {
            return String(params.label || params.path);
          }

          return String(params[key]);
        });

        return readyMessage;
      }

      if (typeof message === 'function') {
        const buildMessage = message as (_: typeof params) => string;
        return buildMessage(params);
      };

      return message;
    };

    return { ...Yup };
  }, [t, language]);

  return (
    <ValidatorContext.Provider value={validator}>
      {children}
    </ValidatorContext.Provider>
  );
};

export function useValidator() {
  const context = React.useContext(ValidatorContext);

  if (!context) {
    throw new Error('The useValidator() must be used within a <ValidatorProvider />');
  }

  return context;
}
