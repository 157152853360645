import React, { useCallback, memo, useEffect } from 'react';
import { useMultipleIntersectionObserver } from './MultipleIntersectionObserver';
import IntersectionObserver from '@researchgate/react-intersection-observer';
import {
  ChangeHandler,
  Options as IntersectionObserverProps,
} from '@researchgate/react-intersection-observer/typings/types';

export interface IMultipleIntersectionObserverItemProps<Value>
  extends Omit<IntersectionObserverProps, 'onChange'> {
  children?: React.ReactNode;
  identifier: string;
  value: Value;
}

const MultipleIntersectionObserverItem = memo(
  function MultipleIntersectionObserverItem<Value>(
    props: IMultipleIntersectionObserverItemProps<Value>,
  ) {
    const { children, identifier, value } = props;

    const { updateIntersectionStatus } = useMultipleIntersectionObserver();

    const handleIntersectionChange = useCallback<ChangeHandler>(
      (entry) => {
        if (!updateIntersectionStatus) return;
        updateIntersectionStatus({
          isIntersecting: entry.isIntersecting,
          identifier,
          value,
        });
      },
      [updateIntersectionStatus, identifier, value],
    );

    useEffect(() => {
      return () => {
        if (!updateIntersectionStatus) return;
        updateIntersectionStatus({
          isIntersecting: false,
          identifier,
          value,
        });
      };
    }, [identifier, updateIntersectionStatus, value]);

    return (
      <IntersectionObserver onChange={handleIntersectionChange}>
        {children as React.ReactElement}
      </IntersectionObserver>
    );
  },
);

export default MultipleIntersectionObserverItem;
