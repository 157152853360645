import React from 'react';
import { DashboardIcon } from '@images/icons';
import { createRouteDefinition } from '@utils/routing';

export const accountDashboardRoute = createRouteDefinition({
  uid: 'dashboard',
  path: '/account',
  icon: <DashboardIcon />,
  title: 'labels.home',
});
