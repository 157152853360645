import React, { useCallback } from 'react';
import { usePageTitle } from '@features/metadata';
import { UpcomingRidesCard } from '@features/booking';
import { DraftEventsCard } from '@features/events';
import { CustomerProductsCard, useProductPurchaseModal } from '@features/products';
import { CustomerStatisticCard } from '@features/statistic';
import { accountDashboardRoute } from './route';
import styles from './DashboardPage.module.scss';
import { ImageBanner } from '@generics/surfaces/image-banner';
import { Typography } from '@generics/content';
import bookARideBannerSrc from '@images/banners/book-a-ride.png';
import { useNavigate } from 'react-router-dom';
import { useProductsQuery } from '@network/api';

// TODO: Make banners scrollable on desktop via arrows/drag.

export const DashboardPage = () => {
  usePageTitle(accountDashboardRoute.title);

  const {
    open: openProductPurchaseModal,
  } = useProductPurchaseModal();

  const { data: products } = useProductsQuery();

  const navigate = useNavigate();

  const handleBookARideBannerClick = useCallback(() => {
    navigate('/public/ride-booking');
  }, [navigate]);

  const initProductPurchase = useCallback((props: { type: string; id: string }) => {
    openProductPurchaseModal({
      selectedProductType: props.type,
      selectedProductId: props.id,
    });
  }, [openProductPurchaseModal]);

  return (
    <div className={styles.root}>
      <div className={styles.banners}>
        <ImageBanner className={styles.banner} src={bookARideBannerSrc} onClick={handleBookARideBannerClick}>
          <Typography type="heading/4" color="current">labels.bookARide</Typography>
        </ImageBanner>
        {products.map((product) => (
          <ImageBanner
            key={product.id}
            className={styles.banner}
            src={bookARideBannerSrc}
            onClick={() => initProductPurchase({ type: product.product_type, id: product.id })}
          >
            <Typography type="heading/4" color="current">{product.name}</Typography>
            <Typography type="body/regular/s" color="current">{product.description}</Typography>
          </ImageBanner>
        ))}
      </div>
      <div className={styles.main}>
        <UpcomingRidesCard />
        <DraftEventsCard />
        <div className={styles.productsAndStatistic}>
          <CustomerProductsCard className={styles.products} />
          <CustomerStatisticCard className={styles.statistic} />
        </div>
      </div>
    </div>
  );
};
