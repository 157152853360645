import { IUseApiMutationProps, useApiClient, useApiMutation } from '@network/api/client';
import { useDispatch } from 'react-redux';
import { SET_MEMBER } from '@store/customer/customer.types';
import { useQueryClient } from '@network/queries';
import { toastUtil } from '@utils/toast.utils';
import * as Models from '@network/api/models';

export type TUseRenewProductMutationResponse = { data: Models.Customer.Data; meta?: { message?: string } };

export type TUseRenewProductMutationProps = Omit<IUseApiMutationProps<TUseRenewProductMutationResponse>, 'mutationKey' | 'mutationFn'>;

export function useRenewProductMutation(props?: TUseRenewProductMutationProps) {
  const { onSuccess, ...restHookProps } = props ?? {};

  const dispatch = useDispatch();
  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useApiMutation({
    ...restHookProps,
    mutationKey: ['membership', 'renew'],
    mutationFn: (data?: { card_token?: string | null; save_card?: boolean }) =>
      apiClient.url(`/membership/renew`)
        .patch(data)
        .json<{ data: Models.Customer.Data; meta?: { message?: string } }>()
        .then((response) => response),
    onSuccess: (response, variables, context) => {
      dispatch({
        type: SET_MEMBER,
        payload: response.data,
      });
      queryClient.refetchQueries({ queryKey: ['memberships', 'all'], exact: false });
      queryClient.refetchQueries({ queryKey: ['packages', 'all'], exact: false });
      queryClient.refetchQueries({ queryKey: ['customer', 'quotas', 'all'], exact: false });
      if (response?.meta?.message) toastUtil('info', response.meta.message);
      onSuccess?.(response, variables, context);
    },
  });
}
