import React from 'react';
import { championshipsSelector } from '../../store/championships/championships.selector';
import { Championship } from '../../store/championships/championships.types';
import useWithSelection from '../../hooks/useWithSelection';
import ChampionshipItem from './ChampionshipItem';

const Championships = () => {
  const championships = useWithSelection(championshipsSelector);

  return (
    <div className="Championships">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          {championships.map((c: Championship) => (
            <ChampionshipItem championship={c} key={c.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Championships;
