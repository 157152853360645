import React from 'react';
import { AmexBrandIcon, MastercardBrandIcon, VisaBrandIcon } from '@images/card-brands';

export enum EStripeCardBrand {
  Amex = 'amex',
  Mastercard = 'mastercard',
  Visa = 'visa',
}

const cardsBrandsIcons = {
  [EStripeCardBrand.Amex]: <AmexBrandIcon />,
  [EStripeCardBrand.Mastercard]: <MastercardBrandIcon />,
  [EStripeCardBrand.Visa]: <VisaBrandIcon />,
};

export interface IStripeCardFieldProps {
  brand?: `${EStripeCardBrand}`;
}

export const StripeCardBrandIcon = (props: IStripeCardFieldProps) => {
  const { brand } = props;
  return brand ? cardsBrandsIcons[brand] : null;
};
