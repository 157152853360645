import React from 'react';
import styles from './AppFormField.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

interface IAppFormFieldProps {
  label?: string;
  error?: string;
  required?: boolean;
  maxLength?: number;
  currentLength?: number;
  className?: string;
  children?: React.ReactNode;
}

const AppFormField: React.FC<IAppFormFieldProps> = ({
  label,
  error,
  currentLength,
  maxLength,
  className,
  required,
  children,
}) => {
  const [t] = useTranslation();

  return (
    <div className={classNames(styles.appFormField, className)}>
      {label || currentLength || maxLength
        ? (
            <div
              className={classNames(styles.appFormField__label, {
                [styles.appFormField__label_required]: required,
                [styles.appFormField__label_error]: error,
              })}
            >
              <h6>{label ? t(label) : ''}</h6>
              {currentLength || maxLength
                ? (
                    <span>
                      {currentLength}
                      /
                      {maxLength}
                    </span>
                  )
                : (
                    ''
                  )}
            </div>
          )
        : (
            ''
          )}
      {children}
      {error
        ? (
            <div className={styles.appFormField__error} data-role="error">
              {error}
            </div>
          )
        : (
            ''
          )}
    </div>
  );
};

export default AppFormField;
