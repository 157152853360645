import React, { useMemo } from 'react';
import BaseCard, { IBaseCardProps } from '../BaseCard/BaseCard';
import styles from './ExtraStep.module.scss';
import CheckboxRadio from '../../FormField/CheckboxRadio';
import classNames from 'classnames';
import AppInput from '../../AppInput/AppInput';
import AppFormField from '../../AppFormField/AppFormField';
import AppAlert from '../../AppAlert';
import AppUploadFileButton from '../../AppUploadFileButton';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { requiredTextValidator } from '../../../validators/requiredText.validator';
import { getFormError } from '../../../utils/get-form-error.utils';
import { EventProduct } from '../../../store/eventProducts/eventProducts.types';
import { IEventBookForm } from '../../../views/EventBooking/EventBooking';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import Loader from '../../Loader';
import { Controller } from '../../FormField/Controller';

const ACCEPT_FORMATS
  = 'application/pdf,image/svg+xml,application/postscript,image/jpeg,image/png';
const ACCEPT_FORMATS_LABEL = '.pdf, .svg, .eps, .jpeg, .png';

export interface IExtraStepProps
  extends Pick<
    IBaseCardProps,
    | 'collapsed'
    | 'onForward'
    | 'onBack'
    | 'invalid'
    | 'revalidateStep'
    | 'isLoading'
  > {
  trophiesOption: EventProduct | null;
  carBrandingOption: EventProduct | null;
  trackBrandingOption: EventProduct | null;
}

const ExtraStep: React.FC<IExtraStepProps> = ({
  trophiesOption,
  carBrandingOption,
  trackBrandingOption,
  revalidateStep,
  ...props
}) => {
  const [t] = useTranslation();
  const { control, errors, watch, setValue, clearErrors }
    = useFormContext<IEventBookForm>();

  const selectedExtras = watch('selectedExtras');
  const firstLine = watch('firstLine');
  const secondLine = watch('secondLine');
  const carBranding = watch('carBranding');
  const trackBranding = watch('trackBranding');
  const isTrophiesSelected
    = trophiesOption && selectedExtras.includes(trophiesOption.id);
  const isCarBrandingSelected
    = carBrandingOption && selectedExtras.includes(carBrandingOption.id);
  const isTrackBrandingSelected
    = trackBrandingOption && selectedExtras.includes(trackBrandingOption.id);

  const collapseData = useMemo(() => {
    const options: string[] = [];

    if (isTrophiesSelected) {
      options.push('Trophies');
    }

    if (isCarBrandingSelected) {
      options.push('Car Branding');
    }

    if (isTrackBrandingSelected) {
      options.push('Track Branding');
    }

    return options.join(', ');
  }, [isTrophiesSelected, isCarBrandingSelected, isTrackBrandingSelected]);

  const isStepValid = useMemo(() => {
    const isTropiesValid
      = !isTrophiesSelected || (isTrophiesSelected && firstLine && secondLine);
    const isCarBrandingValid
      = !isCarBrandingSelected || (isCarBrandingSelected && carBranding);
    const isTrackBrandingValid
      = !isTrackBrandingSelected || (isTrackBrandingSelected && trackBranding);

    return isTropiesValid && isCarBrandingValid && isTrackBrandingValid;
  }, [
    isTrophiesSelected,
    isCarBrandingSelected,
    isTrackBrandingSelected,
    firstLine,
    secondLine,
    carBranding,
    trackBranding,
  ]);

  const onSelectionChange = (id: string, index: number) => {
    const newSelectedExtras = selectedExtras.slice();

    newSelectedExtras[index] = newSelectedExtras[index] === id ? '' : id;

    setValue('selectedExtras', newSelectedExtras);
  };

  return (
    <BaseCard
      title="booking.steps.extra.title"
      subtitle="booking.steps.extra.subtitle"
      forwardTitle="booking.steps.extra.forwardButton"
      collapseData={collapseData}
      {...props}
      onForward={isStepValid ? props.onForward : undefined}
    >
      <div className={styles.extraStep}>
        {[trophiesOption, carBrandingOption, trackBrandingOption]
          .filter(Boolean)
          .map((option, index) => {
            const {
              id,
              label,
              description,
              price,
              currency,
              product_image_url,
            } = option!;
            const isSelected = selectedExtras.includes(id);
            const isTrophies = trophiesOption
              ? trophiesOption.id === id
              : false;
            const fileFieldName
              = carBrandingOption && carBrandingOption.id === id
                ? 'carBranding'
                : 'trackBranding';

            return (
              <div
                className={classNames(styles.card, {
                  [styles.card_selected]: isSelected,
                })}
                key={id}
                onClick={
                  isSelected ? undefined : () => onSelectionChange(id, index)
                }
              >
                <CheckboxRadio
                  primary
                  name={id}
                  value={id}
                  checked={isSelected}
                  onChange={({ target: { checked } }) => {
                    onSelectionChange(id, index);

                    if (checked) return;

                    switch (id) {
                      case trophiesOption && trophiesOption.id:
                        setValue('firstLine', '');
                        setValue('secondLine', '');
                        clearErrors('firstLine');
                        clearErrors('secondLine');
                        break;
                      case carBrandingOption && carBrandingOption.id:
                        setValue('carBranding', null);
                        clearErrors('carBranding');
                        break;
                      case trackBrandingOption && trackBrandingOption.id:
                        setValue('trackBranding', null);
                        clearErrors('trackBranding');
                        break;
                    }

                    revalidateStep?.();
                  }}
                />
                <div className={styles.card__content}>
                  <h4 className={styles.card__title}>
                    {label}
                    <span>
                      {price}
                      {' '}
                      {currency}
                    </span>
                  </h4>
                  <div>
                    <p className={styles.card__subtitle}>
                      {description}
                      {' '}
                      <PhotoProvider
                        className={styles.imageViewer}
                        maskOpacity={0.2}
                        loadingElement={<Loader width={80} height={80} />}
                        easing={() => '1'}
                      >
                        <PhotoView src={product_image_url || ''}>
                          <span
                            style={{ textDecoration: 'underline' }}
                            onClick={(event) => {
                              event.stopPropagation();
                              event.preventDefault();
                            }}
                          >
                            {t('booking.steps.extra.viewExamplePhoto')}
                          </span>
                        </PhotoView>
                      </PhotoProvider>
                    </p>
                    {!isTrophies
                      ? (
                          <p className={styles.card__caption}>
                            (delivery time - 5 days)
                          </p>
                        )
                      : (
                          ''
                        )}
                  </div>
                  {isTrophies
                    ? (
                        <div
                          className={classNames(styles.card__collapsableWrapper, {
                            [styles.card__collapsableWrapper_collapsed]: !isSelected,
                          })}
                        >
                          <AppAlert
                            type="info"
                            text="booking.steps.extra.informationPanel"
                          />
                          <div className={styles.card__fields}>
                            <Controller
                              control={control}
                              name="firstLine"
                              rules={
                                isSelected
                                  ? {
                                      maxLength: {
                                        value: 20,
                                        message: t('field.validations.maxLength', {
                                          length: 20,
                                        }),
                                      },
                                      ...requiredTextValidator({
                                        required: t('field.firstLine.required'),
                                        emojis: t(
                                          'field.validations.emojisNotAllowed',
                                        ),
                                      }),
                                    }
                                  : undefined
                              }
                              render={({ value, onChange, onBlur }) => (
                                <AppFormField
                                  required
                                  label="field.firstLine.label"
                                  currentLength={value.length}
                                  maxLength={20}
                                  error={getFormError('firstLine', errors)}
                                >
                                  <AppInput
                                    placeholder="field.firstLine.placeholder"
                                    maxLength={20}
                                    value={value}
                                    onChange={(value) => {
                                      onChange(value);
                                      revalidateStep?.();
                                    }}
                                    onBlur={onBlur}
                                  />
                                </AppFormField>
                              )}
                            />
                            <Controller
                              control={control}
                              name="secondLine"
                              rules={
                                isSelected
                                  ? {
                                      maxLength: {
                                        value: 20,
                                        message: t('field.validations.maxLength', {
                                          length: 20,
                                        }),
                                      },
                                      ...requiredTextValidator({
                                        required: t('field.secondLine.required'),
                                        emojis: t(
                                          'field.validations.emojisNotAllowed',
                                        ),
                                      }),
                                    }
                                  : undefined
                              }
                              render={({ value, onChange, onBlur }) => (
                                <AppFormField
                                  required
                                  label="field.secondLine.label"
                                  currentLength={value.length}
                                  maxLength={20}
                                  error={getFormError('secondLine', errors)}
                                >
                                  <AppInput
                                    placeholder="field.firstLine.placeholder"
                                    maxLength={20}
                                    value={value}
                                    onChange={(value) => {
                                      onChange(value);
                                      revalidateStep?.();
                                    }}
                                    onBlur={onBlur}
                                  />
                                </AppFormField>
                              )}
                            />
                          </div>
                        </div>
                      )
                    : (
                        ''
                      )}
                  {!isTrophies
                    ? (
                        <div
                          className={classNames(styles.card__collapsableWrapper, {
                            [styles.card__collapsableWrapper_collapsed]: !isSelected,
                          })}
                        >
                          <Controller
                            control={control}
                            name={fileFieldName}
                            rules={
                              isSelected
                                ? {
                                    required: t(
                                      'field.fileUpload.required',
                                    ),
                                  }
                                : undefined
                            }
                            render={({ value, onChange }) => (
                              <AppUploadFileButton
                                label="booking.steps.extra.uploadLabel"
                                error={getFormError(fileFieldName, errors)}
                                accept={ACCEPT_FORMATS}
                                acceptLabel={ACCEPT_FORMATS_LABEL}
                                maxSize={2}
                                file={value}
                                onChange={(value) => {
                                  onChange(value);
                                  revalidateStep?.();
                                }}
                              />
                            )}
                          />
                        </div>
                      )
                    : (
                        ''
                      )}
                </div>
              </div>
            );
          })}
      </div>
    </BaseCard>
  );
};

export default ExtraStep;
