import React, { useCallback, useRef } from 'react';
import { RadioGroupInputItem, TRadioGroupInputItemExposures, TRadioGroupInputItemProps } from './RadioGroupInputItem';
import { useMergedClassNames } from '@utils/styling';
import styles from './RadioGroupInput.module.scss';

export type TRadioGroupInputProps = Extend<HTMLProps<HTMLDivElement>, {
  items?: Omit<TRadioGroupInputItemProps, 'onChange'>[];
  value?: string | null;
  onChange?: (value: string | null) => void;
  inputRef?: React.Ref<HTMLInputElement>;
}>;

// TODO: Adjust to match other inputs.
export const RadioGroupInput = (props: TRadioGroupInputProps) => {
  const {
    id,
    items,
    value,
    onChange,
    inputRef,
    className,
    ...restRootProps
  } = props;

  const itemsRef = useRef<Record<string, TRadioGroupInputItemExposures | null>>({});

  const handleInputFocus = useCallback(() => {
    itemsRef.current[0]?.focus();
  }, []);

  const handleItemSelect = useCallback((value: string | null) => {
    onChange?.(value);
  }, [onChange]);

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [className || '']: true,
  });

  itemsRef.current = {};

  return (
    <div {...restRootProps} className={rootClassName}>
      <input
        className={styles.input}
        type="text"
        id={id}
        onFocus={handleInputFocus}
        ref={inputRef}
        tabIndex={-1}
      />
      {items?.map((item, index) => (
        <RadioGroupInputItem
          key={item.value}
          {...item}
          onChange={handleItemSelect}
          selected={value === item.value}
          ref={(element) => {
            itemsRef.current[index] = element;
          }}
        />
      ))}
    </div>
  );
};
