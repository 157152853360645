import { useMemo } from 'react';
import { mergeClassNames } from './mergeClassNames';

export function useMergedClassNames<ClassNames extends Record<string, unknown>>(
  classNames: ClassNames,
) {
  const keys = Object.keys(classNames).join('|');
  const conditions = Object.keys(classNames).map((key) => Boolean(classNames[key]));

  return useMemo(
    () => mergeClassNames(classNames),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...conditions, keys],
  );
}
