import React, { useState, useMemo, useCallback } from 'react';
import mergeClassNames from 'classnames';
import SwipableEdgeBottomDrawer, {
  ISwipableEdgeBottomDrawerProps,
  ESwipeableEdgeBottomDrawerState,
} from '../SwipableEdgeBottomDrawer/SwipableEdgeBottomDrawer';
import PriceTotal from '../Price/PriceTotal';
import Tabs from '../Tabs/Tabs';
import TabPanel from '../Tabs/TabPanel';
import Tab from '../Tabs/Tab';
import { useTranslation } from 'react-i18next';
import BookingDetailsList, {
  BookingDetail,
} from '../BookingDetailsList/BookingDetailsList';
import BookingPriceDetails, {
  BookingPriceDetail,
} from '../BookingPriceDetails/BookingPriceDetails';
import styles from './BookingDetailsDrawer.module.scss';
import Loader from '../Loader';

export enum EBookingDetailsDrawerTab {
  Price = 'price',
  Summary = 'summary',
}

export interface IBookingDetailsDrawerProps
  extends Omit<ISwipableEdgeBottomDrawerProps, 'edgeChildren' | 'offset'> {
  priceDetails?: BookingPriceDetail[];
  summaryDetails?: BookingDetail[];
  total?: string;
  priceOnly?: boolean;
  emptyPriceState?: string;
  currency?: string;
  vat?: string;
  loading?: boolean;
  isExcludedVat?: boolean;
}

const BookingDetailsDrawer: React.FC<IBookingDetailsDrawerProps> = (props) => {
  const {
    classes,
    open,
    onOpened,
    onOpening,
    onClosing,
    onClosed,
    priceDetails,
    summaryDetails,
    total,
    priceOnly,
    emptyPriceState,
    currency,
    vat,
    loading,
    isExcludedVat,
    ...restProps
  } = props;

  const [t] = useTranslation();
  const [drawerState, setDrawerState] = useState(
    open
      ? ESwipeableEdgeBottomDrawerState.Opened
      : ESwipeableEdgeBottomDrawerState.Closed,
  );
  const [currentTab, setCurrentTab] = useState(EBookingDetailsDrawerTab.Price);

  const handleOpened = useCallback(() => {
    setDrawerState(ESwipeableEdgeBottomDrawerState.Opened);
    onOpened?.();
  }, [onOpened]);

  const handleOpening = useCallback(() => {
    setDrawerState(ESwipeableEdgeBottomDrawerState.Opening);
    onOpening?.();
  }, [onOpening]);

  const handleClosing = useCallback(() => {
    setDrawerState(ESwipeableEdgeBottomDrawerState.Closing);
    onClosing?.();
  }, [onClosing]);

  const handleClosed = useCallback(() => {
    setCurrentTab(EBookingDetailsDrawerTab.Price);
    setDrawerState(ESwipeableEdgeBottomDrawerState.Closed);
    onClosed?.();
  }, [onClosed]);

  const isPriceTotalCollapsed = useMemo(
    () =>
      [
        ESwipeableEdgeBottomDrawerState.Closing,
        ESwipeableEdgeBottomDrawerState.Closed,
      ].includes(drawerState),
    [drawerState],
  );

  const isPriceTotalHidden = useMemo(
    () => currentTab !== EBookingDetailsDrawerTab.Price,
    [currentTab],
  );

  const handleTabChange = useCallback(
    (_: React.ChangeEvent<{}>, newCurrentTab: EBookingDetailsDrawerTab) => {
      setCurrentTab(newCurrentTab);
    },
    [],
  );

  const mergedClasses = useMemo(
    () => ({
      ...classes,
      root: mergeClassNames(styles.drawer, classes ? classes.root : null),
      swipeArea: mergeClassNames(
        styles.drawer__swipeArea,
        classes ? classes.swipeArea : null,
      ),
      edgeContent: mergeClassNames(
        styles.drawer__edge__content,
        classes ? classes.edgeContent : null,
      ),
    }),
    [classes],
  );

  const priceData = useMemo(() => {
    return (
      <>
        <div className={styles.priceDetails}>
          {priceDetails
            ? (
                <BookingPriceDetails
                  details={priceDetails}
                  emptyStateKey={emptyPriceState || '-'}
                  isExcludedVat={isExcludedVat}
                />
              )
            : null}
        </div>
        <div className={styles.vatInfo}>
          {t(isExcludedVat ? 'booking.excludedVatInfo' : 'booking.vatInfo', {
            vat: vat ? vat : '-',
            currency: currency ? currency : 'CHF',
          })}
        </div>
      </>
    );
  }, [priceDetails, vat, currency, emptyPriceState, isExcludedVat, t]);

  return (
    <SwipableEdgeBottomDrawer
      {...restProps}
      classes={mergedClasses}
      open={open}
      onOpened={handleOpened}
      onOpening={handleOpening}
      onClosing={handleClosing}
      onClosed={handleClosed}
      edgeChildren={(
        <PriceTotal
          total={total}
          collapsed={isPriceTotalCollapsed}
          hidden={isPriceTotalHidden}
          loading={loading}
        />
      )}
      edgePinned
      offset={56}
    >
      {loading ? <Loader fullSize width={80} height={80} /> : null}
      <div className={styles.drawerContent}>
        {priceOnly
          ? (
              <>
                <h3 className={styles.title}>{t('booking.price')}</h3>
                <div
                  className={mergeClassNames(
                    styles.tabPanel,
                    styles.priceDetailsTabPanel,
                  )}
                >
                  {priceData}
                </div>
              </>
            )
          : (
              <>
                <Tabs
                  classes={{ root: styles.tabs }}
                  value={currentTab}
                  onChange={handleTabChange}
                >
                  <Tab
                    value={EBookingDetailsDrawerTab.Price}
                    label={t('booking.price')}
                  />
                  <Tab
                    value={EBookingDetailsDrawerTab.Summary}
                    label={t('booking.summary')}
                  />
                </Tabs>
                <TabPanel
                  className={mergeClassNames(
                    styles.tabPanel,
                    styles.priceDetailsTabPanel,
                  )}
                  currentValue={currentTab}
                  value={EBookingDetailsDrawerTab.Price}
                >
                  {priceData}
                </TabPanel>
                <TabPanel
                  className={styles.tabPanel}
                  currentValue={currentTab}
                  value={EBookingDetailsDrawerTab.Summary}
                >
                  {summaryDetails
                    ? (
                        <BookingDetailsList details={summaryDetails} />
                      )
                    : null}
                </TabPanel>
              </>
            )}
      </div>
    </SwipableEdgeBottomDrawer>
  );
};

export default BookingDetailsDrawer;
