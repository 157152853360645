import React, { useCallback } from 'react';
import { Typography } from '@generics/content';
import { Form, FormField, useForm } from '@generics/forms';
import { Button } from '@generics/inputs/button';
import { Card } from '@generics/surfaces';
import { CheckIcon, CrossIcon, EditIcon } from '@images/icons';
import { useCountriesSelection, useUpdateCustomerMutation } from '@network/api';
import { useAuthenticatedCustomer } from '@features/authentication';
import styles from './CustomerAddressSection.module.scss';
import { useConfirmCancelChanges } from '@generics/forms/useConfirmCancelChanges';
import { EInputMode } from '@generics/inputs/types';

interface ICustomerAddressData {
  address1: string | null;
  address2: string | null;
  country_id: string | null;
  city: string | null;
  zip_code: string | null;
}

export const CustomerAddressSection = () => {
  const authenticatedCustomer = useAuthenticatedCustomer();

  const form = useForm({
    defaultValues: ({
      address1: authenticatedCustomer?.address1 ?? null,
      address2: authenticatedCustomer?.address2 ?? null,
      country_id: authenticatedCustomer?.country_id || null,
      city: authenticatedCustomer?.city ?? null,
      zip_code: authenticatedCustomer?.zip_code ?? null,
    } satisfies ICustomerAddressData),
    mode: EInputMode.Representation,
  });

  const { countriesSelection } = useCountriesSelection();

  const represent = useCallback(() => {
    setTimeout(() => {
      form.reset();
      form.represent();
    }, 0);
  }, [form]);

  const updateCustomerMutation = useUpdateCustomerMutation({
    onSuccess: () => {
      represent();
      setTimeout(() => {
        updateCustomerMutation.reset();
      }, 0);
    },
    onValidationErrors: form.setApiValidationErrors,
  });

  const { confirm: confirmCancelChanges, dialog } = useConfirmCancelChanges();

  const handleCancelButtonClick = useCallback(() => {
    confirmCancelChanges().then((isConfirm) => {
      if (!isConfirm) return;
      represent();
    });
  }, [confirmCancelChanges, represent]);

  const handleValidSubmit = useCallback(async (data: ICustomerAddressData) => {
    await updateCustomerMutation.mutateAsync(data);
  }, [updateCustomerMutation]);

  if (!authenticatedCustomer) return null;

  return (
    <Form className={styles.root} form={form} onValidSubmit={handleValidSubmit}>
      {dialog}
      <div className={styles.header}>
        <Typography type="body/medium/m">labels.address</Typography>
        {form.mode === EInputMode.Representation
          ? (
              <Button
                type="button"
                design="text"
                label="labels.edit"
                icon={<EditIcon />}
                onClick={form.edit}
              />
            )
          : null}
      </div>
      <Card className={styles.card}>
        <div className={styles.fields}>
          <FormField
            name="address1"
            type="string"
          />
          <FormField
            name="address2"
            type="string"
          />
          <FormField
            name="country_id"
            type="select"
            items={countriesSelection}
          />
          <FormField
            name="city"
            type="string"
          />
          <FormField
            name="zip_code"
            type="string"
          />
        </div>
        {form.mode === EInputMode.Input
          ? (
              <div className={styles.actions}>
                <Button
                  type="button"
                  design="outline"
                  label="labels.cancel"
                  icon={<CrossIcon />}
                  onClick={handleCancelButtonClick}
                />
                <Button
                  type="submit"
                  label="labels.save"
                  icon={<CheckIcon />}
                  isLoading={updateCustomerMutation.isPending}
                  disabled={updateCustomerMutation.isSuccess}
                />
              </div>
            )
          : null}
      </Card>
    </Form>
  );
};
