import React, { useState } from 'react';
import AppFormField from '../AppFormField/AppFormField';
import styles from './BookingCouponField.module.scss';
import AppInput from '../AppInput';
import { removeEmojis } from '../../utils/remove-emoji.utils';
import Button from '../Button';
import { useTranslation } from 'react-i18next';

type TBookingCouponFieldProps = {
  disabled?: boolean;
  onSend: (value: string) => void;
};

const BookingCouponField: React.FC<TBookingCouponFieldProps> = ({ disabled, onSend }) => {
  const [t] = useTranslation();
  const [coupon, setCoupon] = useState<string>('');

  return (
    <AppFormField label="field.coupon.label">
      <div className={styles.coupon}>
        <AppInput
          withClear
          disabled={disabled}
          placeholder="field.coupon.placeholder"
          value={coupon}
          maxLength={128}
          onClear={() => {
            onSend('');
            setCoupon('');
          }}
          onChange={({ target: { value } }) =>
            setCoupon(removeEmojis(value || ''))}
        />
        <Button
          appearance="outline"
          disabled={disabled}
          label={t('booking.couponApply')}
          onClick={() => onSend(coupon)}
        />
      </div>
    </AppFormField>
  );
};

export default BookingCouponField;
