import { EnhancedDate } from '@utils/enhanced-date.utils';
import { addDays, eachDayOfInterval, endOfYear, startOfYear } from 'date-fns';
import React, { createContext, useCallback, useEffect, useMemo } from 'react';

export type CalendarYearsContextValue = {
  dates: EnhancedDate[];
  generateNextYear: () => void;
};

export const CalendarYearsContext = createContext<CalendarYearsContextValue>({
  dates: [],
  generateNextYear: () => {},
});

export type CalendarYearsProviderProps = {
  children?: React.ReactNode;
};

export const CalendarYearsProvider = ({ children }: CalendarYearsProviderProps) => {
  const [dates, setDates] = React.useState<EnhancedDate[]>([]);

  const generateYearForDate = useCallback((date: Date) => {
    return eachDayOfInterval({ start: startOfYear(date), end: endOfYear(date) }).map((date) => new EnhancedDate(date));
  }, []);

  useEffect(() => {
    const today = new Date();
    setDates(generateYearForDate(today));
  }, [generateYearForDate]);

  const generateNextYear = useCallback(() => {
    setDates((prevDates) => {
      const lastDate = prevDates[prevDates.length - 1];
      const nextYearDate = addDays(lastDate, 1);
      return [...prevDates, ...generateYearForDate(nextYearDate)];
    });
  }, [generateYearForDate]);

  const value = useMemo(() => ({ dates, generateNextYear }), [dates, generateNextYear]);

  return <CalendarYearsContext.Provider value={value}>{children}</CalendarYearsContext.Provider>;
};

export function useCalendarYears() {
  const calendarYears = React.useContext(CalendarYearsContext);

  if (!calendarYears) {
    throw new Error('The useCalendarYears() must be used within a <CalendarYearsProvider />');
  }

  return calendarYears;
}

export function withCalendarYears<T extends Record<string, unknown>>(Component: React.ComponentType<T>) {
  return function WithCalendarYears(props: T) {
    return (
      <CalendarYearsProvider>
        <Component {...props} />
      </CalendarYearsProvider>
    );
  };
}
