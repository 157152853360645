import api from '../../config/api.config';

import { AxiosError } from 'axios';

export const postMembershipConvert = async (data: object) => {
  try {
    const response = await api.post('/memberships/convert', { cookies: document.cookie, data: data });
    if (typeof response === 'undefined' || !response) return;
  }
  catch (error) {
    const err = error as AxiosError<TRumpApiErrorResponseData>;
    return Promise.reject(typeof err.response !== 'undefined' ? err.response.data : err);
  }
};
