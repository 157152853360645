import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export function useTranslate() {
  const [t] = useTranslation();

  return useCallback(
    <Value, Options extends Parameters<typeof t>[2]>(
      value: Value,
      options?: Options,
    ) => typeof value === 'string' ? t(value, options) : value,
    [t],
  );
}
