const links = [
  {
    i18nKey: 'auth.links.tos',
    url: '/terms-of-service',
  },
  {
    i18nKey: 'auth.links.privacyPolicy',
    url: '/privacy-policy',
  },
  // {
  //   i18nKey: 'auth.links.security',
  //   url: '/security'
  // }
];

export default links;
