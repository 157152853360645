import React from 'react';
import { PackagesIcon } from '@images/icons';
import { createRouteDefinition } from '@utils/routing';

export const accountPackagesRoute = createRouteDefinition({
  uid: 'packages',
  path: '/account/packages',
  icon: <PackagesIcon />,
  title: 'labels.packages',
});
