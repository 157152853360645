import { Booking } from '../booking/booking.types';

export const GET_DRAFT_EVENTS = 'GET_DRAFT_EVENTS';
export const GET_DRAFT_BY_ID = 'GET_DRAFT_BY_ID';

export interface DashboardState {
  draftEvents: Booking[];
}

export interface GetDraftEventsAction {
  type: typeof GET_DRAFT_EVENTS;
  payload: Booking[];
}

export interface GetDraftByIdAction {
  type: typeof GET_DRAFT_BY_ID;
  payload: Booking;
}

export type DashboardActionTypes = GetDraftEventsAction | GetDraftByIdAction;
