import React, { useState, useMemo, createContext, useContext, useCallback, useEffect } from 'react';
import { useMembershipsQuery } from '@network/api';
import { publish } from '../../events';
import SlideUpDialog from '@generics/MobileSlideUpDialog/MobileSlideUpDialog';
import { Button } from '@generics/inputs/button';
import { CrossIcon } from '@images/icons';
import styles from './ProductPurchaseModalProvider.module.scss';
import { Typography } from '@generics/content';
import { ProductPurchaseForm } from './ProductPurchaseForm';
import { useAuthentication } from '@features/authentication';

export enum EProductPurchaseStep {
  ProductSelection = 'product-selection',
  Payment = 'payment',
}

export type TProductPurchaseModalOpenProps = {
  selectedProductType?: string;
  selectedProductId?: string;
  renew?: boolean;
};

export type TProductPurchaseModalContextProps = {
  open: (props?: TProductPurchaseModalOpenProps) => void;
  close: () => void;
  step: EProductPurchaseStep;
  setStep: (step: EProductPurchaseStep) => void;
  selectedProductType: string | null;
  selectedProductId: string | null;
  isRenew: boolean;
};

export const ProductPurchaseModalContext = createContext<TProductPurchaseModalContextProps | undefined>(undefined);

export type TProductPurchaseModalProviderProps = {
  children?: React.ReactNode;
};

export const ProductPurchaseModalProvider = (props: TProductPurchaseModalProviderProps) => {
  const { children } = props;

  const { isAuthenticated } = useAuthentication();

  const { data: memberships } = useMembershipsQuery({
    enabled: isAuthenticated,
  });
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProductType, setSelectedProductType] = useState<string | null>(null);
  const [selectedProductId, setSelectedProductId] = useState<string | null>(null);
  const [step, setStep] = useState(EProductPurchaseStep.ProductSelection);
  const [isRenew, setIsRenew] = useState<boolean>(false);

  const selectedProduct = useMemo(
    () => memberships.find((membership) => membership.id === selectedProductId),
    [memberships, selectedProductId],
  );

  const open = useCallback((props?: TProductPurchaseModalOpenProps) => {
    const { selectedProductType, selectedProductId, renew } = props ?? {};
    if (selectedProductType) setSelectedProductType(selectedProductType);
    if (selectedProductId) setSelectedProductId(selectedProductId);
    if (renew) setIsRenew(renew);
    setStep(EProductPurchaseStep.ProductSelection);
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
    setSelectedProductType(null);
    setSelectedProductId(null);
    setIsRenew(false);
  }, []);

  const productSpecificTitle = useMemo(() => {
    if (selectedProductType === 'membership') {
      return 'labels.buyMembership';
    }

    if (selectedProductType === 'package') {
      return 'labels.buyPackage';
    }

    return 'labels.buyProduct';
  }, [selectedProductType]);

  const title = useMemo(() => {
    if (step === EProductPurchaseStep.ProductSelection) {
      return productSpecificTitle;
    }

    if (step === EProductPurchaseStep.Payment) {
      return 'labels.payment';
    }

    return null;
  }, [step, productSpecificTitle]);

  useEffect(() => {
    if (!selectedProduct) return;
    publish('InitiateCheckout', {
      content_category: 'Membership Plan',
      content_ids: [selectedProduct.id],
      contents: [{
        id: selectedProduct.id,
        quantity: 1,
        name: selectedProduct.name,
      }],
      num_items: 1,
      currency: selectedProduct.currency,
      value: selectedProduct.price,
    });
  }, [selectedProduct]);

  const context = useMemo(
    () => ({
      open,
      close,
      step,
      setStep,
      selectedProductType,
      selectedProductId,
      isRenew,
    }),
    [close, open, step, setStep, selectedProductType, selectedProductId, isRenew],
  );

  return (
    <ProductPurchaseModalContext.Provider value={context}>
      {children}
      <SlideUpDialog
        open={isOpen}
        onClose={close}
        classes={{ paper: styles.paper }}
      >
        <div className={styles.content}>
          <div className={styles.header}>
            <Typography type="heading/title">{title}</Typography>
            <Button design="text" size="small" icon={<CrossIcon />} onClick={close} />
          </div>
          <ProductPurchaseForm />
        </div>
      </SlideUpDialog>
    </ProductPurchaseModalContext.Provider>
  );
};

export function useProductPurchaseModal() {
  const context = useContext(ProductPurchaseModalContext);

  if (!context) {
    throw new Error('The useProductPurchaseModal() must be used within an <ProductPurchaseModalProvider />');
  }

  return context;
}
