import React, { useMemo, useState, useCallback, useLayoutEffect } from 'react';
import { distanceTo } from 'geolocation-utils';

export interface IShortCoordinates
  extends Pick<GeolocationCoordinates, 'latitude' | 'longitude'> {}

export interface IGeolocationContextValue {
  position: GeolocationPosition | null;
  isSupported: boolean;
  isRequested: boolean;
  isAccessAllowed: boolean;
  isAvailable: boolean;
  isResolved: boolean;
  coordsAsTuple?: [number, number];
  getMetersTo: (coords: IShortCoordinates) => number | null;
  getHumanizedDistanceTo: (coords: IShortCoordinates) => string | null;
  request: () => void;
}

export const GeolocationContext = React.createContext<IGeolocationContextValue>(
  {
    position: null,
    isSupported: false,
    isRequested: false,
    isAccessAllowed: false,
    isAvailable: false,
    isResolved: false,
    coordsAsTuple: undefined,
    getMetersTo: () => null,
    getHumanizedDistanceTo: () => null,
    request: () => undefined,
  },
);

export interface IGeolocationProviderProps {
  children?: React.ReactNode;
}

const GeolocationProvider = ({ children }: IGeolocationProviderProps) => {
  const [position, setPosition] = useState<GeolocationPosition | null>(null);
  const [isRequested, setIsRequested] = useState(false);
  const [isAccessAllowed, setIsAccessAllowed] = useState(false);
  const [isSupported, setIsSupported] = useState(false);
  const [isResolved, setIsResolved] = useState(false);

  const isAvailable = useMemo(
    () => isSupported && isAccessAllowed,
    [isSupported, isAccessAllowed],
  );

  const coordsAsTuple = useMemo(
    () =>
      (position && position.coords
        ? [position.coords.latitude, position.coords.longitude]
        : undefined) as [number, number] | undefined,
    [position],
  );

  useLayoutEffect(() => {
    setIsSupported(!!navigator.geolocation);
  }, []);

  useLayoutEffect(() => {
    if (!isSupported || !isRequested) return;

    navigator.geolocation.getCurrentPosition(
      (position) => {
        setPosition(position);
        setIsAccessAllowed(true);
        setIsResolved(true);
      },
      () => {
        setIsAccessAllowed(false);
        setIsResolved(true);
      },
    );
  }, [isSupported, isRequested]);

  const getMetersTo = useCallback(
    ({ latitude, longitude }: IShortCoordinates) => {
      return Array.isArray(coordsAsTuple)
        ? distanceTo(coordsAsTuple, [latitude, longitude])
        : null;
    },
    [coordsAsTuple],
  );

  const getHumanizedDistanceTo = useCallback(
    (coords: IShortCoordinates) => {
      let distance = getMetersTo(coords);

      if (!distance) {
        return null;
      }

      let measureUnit = 'm';

      if (distance >= 1000) {
        distance = distance / 1000;
        measureUnit = 'km';
      }

      return `${Math.round(distance)} ${measureUnit}`;
    },
    [getMetersTo],
  );

  const request = useCallback(() => {
    setIsRequested(true);
  }, []);

  const value = useMemo(
    () => ({
      position,
      isSupported,
      isRequested,
      isAccessAllowed,
      isAvailable,
      isResolved,
      coordsAsTuple,
      getMetersTo,
      getHumanizedDistanceTo,
      request,
    }),
    [
      position,
      isSupported,
      isRequested,
      isAccessAllowed,
      isAvailable,
      isResolved,
      coordsAsTuple,
      getMetersTo,
      getHumanizedDistanceTo,
      request,
    ],
  );

  return (
    <GeolocationContext.Provider value={value}>
      {children}
    </GeolocationContext.Provider>
  );
};

export default GeolocationProvider;
