export function createAnimation(newFrameCallback: (timestamp: number) => void) {
  let latestRequestId: number | null = null;
  let isEnded = false;
  let isPlaying = false;

  function end() {
    isEnded = true;
    if (!latestRequestId) return;
    window.cancelAnimationFrame(latestRequestId);
    latestRequestId = null;
  }

  function handleNextFrame(timestamp: number) {
    newFrameCallback(timestamp);

    if (isEnded) {
      isPlaying = false;
      isEnded = false;
      return;
    }

    latestRequestId = window.requestAnimationFrame(handleNextFrame);
  }

  function start() {
    isPlaying = true;
    latestRequestId = window.requestAnimationFrame(handleNextFrame);
  }

  return { start, end, isEnded, isPlaying };
}
