import { TUseApiQueryProps, useApiClient, useApiQuery } from '@network/api/client';
import { WretchError } from 'wretch';
import * as Models from '@network/api/models';

export type MembershipsResponse = {
  data: Models.Membership.Data[];
};

export type TUseMembershipsQuery = Omit<TUseApiQueryProps<MembershipsResponse['data'], WretchError>, 'queryKey' | 'queryFn'> & {};

export function useMembershipsQuery(props?: TUseMembershipsQuery) {
  const apiClient = useApiClient();

  const { data, ...restQuery } = useApiQuery({
    placeholderData: [],
    ...props,
    queryKey: ['memberships', 'all'],
    queryFn: () => apiClient.url(`/memberships`)
      .get()
      .json<MembershipsResponse>()
      .then(({ data }) => data?.filter((product) => product.product_type === 'membership')),
  });

  return {
    data: data ?? [],
    ...restQuery,
  };
}
