const eventParticipantDriver = {
  welcome: {
    title: '¡Hola!',
    details: {
      firstLine:
        'Vas a participar en un evento que tendrá lugar el <1>{{dateTime}} en {{location}} Racing Unleashed Lounge</1>.',
      secondLine:
        'Pero antes necesitaremos algunos datos sobre ti. Rellena el formulario para que tu experiencia en la sala VIP sea perfecta. No te llevará más de <1>1 minuto.</1>',
    },
    organiserDetails: {
      firstLine:
        'Esta es una encuesta para el evento que tendrá lugar el <1>{{dateTime}} en {{location}} Racing Unleashed Lounge</1>.',
      secondLine:
        'Necesitamos información sobre los participantes. Rellena el formulario para que tu experiencia en la sala sea perfecta.',
    },
  },
  form: {
    title: 'Participantes en su evento',
    subtitle:
      'Por favor, añada el nombre y la dirección postal de cada participante',
  },
  deleteConfirmation: {
    title: 'Borrar participante',
    text: '¿Estás seguro de que quieres eliminar a <1>{{name}}</1> de la lista de participantes?\nEsta acción no se puede deshacer.',
    okText: 'Borrar',
  },
  fields: {
    firstName: {
      label: '¿Cuál es tu nombre de pila?',
      organiserLabel: 'Nombre de pila',
      placeholder: 'Introduce tu nombre...',
      organiserPlaceholder: 'Introduzca su nombre...',
    },
    lastName: {
      label: '¿Cuál es tu apellido?',
      organiserLabel: 'Apellido',
      placeholder: 'Introduzca su apellido...',
      organiserPlaceholder: 'Introduzca su apellido...',
    },
    email: {
      label: '¿Cuál es su dirección de correo electrónico?',
      organiserLabel: 'Dirección de correo electrónico',
      placeholder: 'Introduzca su dirección de correo electrónico...',
      organiserPlaceholder: 'Introduzca su dirección de correo electrónico...',
    },
    receiveEmail: {
      label: 'Acuerdos',
      placeholder:
        'Deseo recibir un boletín informativo una vez al año. (opcional)',
    },
    surveyLanguage: {
      label: 'Lengua de la Encuesta',
    },
  },
  startSurvey: '¡Empecemos!',
  send: 'Enviar',
  organiserSend: 'Guardar y añadir siguiente',
  save: 'Guardar',
  thankYou: {
    title: '¡Gracias!',
    details: {
      firstLine: '¡Ya está listo!',
      secondLine: '¡Nos vemos en la sala!',
      thirdLine: 'Gracias,\nEquipo Racing Unleashed',
    },
  },
  participantAdded: 'El participante se ha añadido correctamente',
  participantUpdated: 'El participante se ha actualizado correctamente',
  participantDeleted: 'El participante se ha eliminado correctamente',
  allParticipantsAdded:
    'Todos los participantes añadidos.\nAún puede modificar la lista borrando o editando los datos de los participantes.',
};

export default eventParticipantDriver;
