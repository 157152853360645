import {
  BookingPayload,
  EBookingStatus,
  EventInformation,
} from '../../../store/booking/booking.types';
import { Code } from '../../../store/code/code.types';
import { IEventBookForm } from '../EventBooking';
import { EEventType } from '../enums/event-type';
import { ECustomerType } from './generate-event-form';

export function generateEventData({
  values,
  eventTypes,
  draft,
  summary,
}: {
  values: IEventBookForm;
  eventTypes: Code[];
  draft?: boolean;
  summary?: boolean;
}): Promise<Partial<BookingPayload>> {
  const {
    id,
    participantsAmount,
    loungeId,
    duration,
    time,
    timeSlotId,
    foodId,
    programId,
    selectedExtras,
    firstLine,
    secondLine,
    carBranding,
    trackBranding,
    organiserFirstName,
    organiserLastName,
    organiserEmail,
    organizerPhoneNumber,
    companyName,
    companyAddress1,
    companyAddress2,
    companyCity,
    companyRegion,
    companyCountryId,
    companyTax,
    companyZipCode,
    getUpdates,
    specialRequest,
    coupon,
    additionalRequest,
    customerType,
  } = values;

  const eventType = eventTypes.find(
    ({ slug }) => slug === EEventType.customerEvent as string,
  );

  const eventInformation: EventInformation | null = {
    CateringProductID: foodId || null,
    ParticipantsCount: participantsAmount,
    CateringSpecialRequest: specialRequest || null,
    FormatProductID: summary ? null : programId || null,
    TrophiesProductID: selectedExtras[0] || null,
    TrophiesFirstLine: firstLine || null,
    TrophiesSecondLine: secondLine || null,
    CarBrandingProductID: selectedExtras[1] || null,
    TrackBrandingProductID: selectedExtras[2] || null,
    EventTypeID: eventType ? eventType.id : null,
    AdditionalRequest: additionalRequest || '',
    Organiser: {
      FirstName: organiserFirstName,
      LastName: organiserLastName,
      Email: organiserEmail,
      PhoneNumber: organizerPhoneNumber,
      Subscribe: getUpdates,
    },
    BillingData: {
      CompanyName: companyName,
      AddressLine1: companyAddress1,
      AddressLine2: companyAddress2,
      City: companyCity,
      Region: companyRegion,
      CountryID: companyCountryId,
      TaxNumber: companyTax,
      ZipCode: companyZipCode,
      IsCompany: customerType === ECustomerType.company,
    },
  };

  return Promise.resolve({
    ...(id && { existing_reservation_id: id }),
    start_time: time,
    time_slot_id: timeSlotId,
    participants_count: participantsAmount,
    simulators: duration,
    lounge_id: loungeId,
    rides_sharing: false,
    internal: true,
    status: draft ? EBookingStatus.draft : EBookingStatus.pending,
    car_branding_logo: !carBranding
      ? 'destroy'
      : carBranding instanceof File
        ? carBranding
        : null,
    track_branding_logo: !trackBranding
      ? 'destroy'
      : trackBranding instanceof File
        ? trackBranding
        : null,
    event_information: eventInformation,
    ...(coupon && {
      coupon_code: coupon,
    }),
  });
}
