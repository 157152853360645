const billing = {
  enterDetailsTitle: 'Añadir tarjeta de crédito',
  updateDetailsTitle: 'Cambiar tarjeta de crédito',
  detailsTitle: 'Tarjeta de crédito',
  updateDetailsSubtitle: 'Por favor, actualiza tu tarjeta de crédito',
  enterDetailsSubtitle: 'Por favor, añade una tarjeta de crédito.',
  cardHolderName: {
    label: 'Nombre en la tarjeta de crédito',
    required: 'Esta casilla es obligatoria.',
  },
  card_number: {
    label: 'Número de tarjeta de crédito',
    incomplete_number: 'Tu número de tarjeta de crédito es incompleto',
    invalid_number: 'Tu número de tarjeta de crédito es inválido',
  },
  card_expiration: {
    label: 'Fecha de caducidad',
    incomplete_expiry: 'La fecha de caducidad es incompleta',
    invalid_expiry_year_past: 'La fecha de caducidad es inválida',
  },
  card_cvc: {
    label: 'CVC',
    incomplete_cvc: 'Tu CVC es incompleto.',
  },
  card_cvv: {
    label: 'CVV',
    incomplete_cvc: 'Tu CVV es incompleto.',
  },
  saveCardForLater: 'Guarda la tarjeta de crédito para futuros pedido',
  updateSubmit: 'Añadir tarjeta de crédito',
  enterSubmit: 'Continuar el proceso de pedido',
  removeCard: 'Eliminar',
  expiresOn: 'Expira el',
};

export default billing;
