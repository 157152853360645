import { WretchError } from 'wretch';
import { useApiClient, TUseApiQueryProps, useApiQuery } from '@network/api/client';
import * as Models from '@network/api/models';

export type CountriesResponse = {
  data: Models.Country.Data[];
};

export type TUseCountriesQuery = Omit<TUseApiQueryProps<CountriesResponse['data'], WretchError>, 'queryKey' | 'queryFn'>;

export function useCountriesQuery(props?: TUseCountriesQuery) {
  const apiClient = useApiClient();

  const { data, ...restQuery } = useApiQuery({
    placeholderData: [],
    ...props,
    queryKey: ['countries', 'all'],
    queryFn: () => apiClient.url('/countries')
      .get()
      .json<CountriesResponse>()
      .then(({ data }) => data),
  });

  return {
    data: data ?? [],
    ...restQuery,
  };
}
