import { useConfirmationDialog } from '@generics/ConfirmationDialog/useConfirmationDialog';
import { Typography } from '@generics/content';
import { ArrowLeftIcon, CrossIcon } from '@images/icons';
import React, { useCallback, useMemo } from 'react';
import styles from './useConfirmCancelChanges.module.scss';

export function useConfirmCancelChanges() {
  const { openDialog, dialog } = useConfirmationDialog();

  const confirm = useCallback(() => openDialog({
    title: 'labels.cancel',
    text: (
      <div className={styles.content}>
        <Typography type="body/medium/l">
          labels.changesCancelConfirmation.title
        </Typography>
        <Typography type="body/regular/m">
          labels.changesCancelConfirmation.message
        </Typography>
      </div>
    ),
    cancelText: 'labels.backToEdit',
    cancelIcon: <ArrowLeftIcon />,
    cancelIconBefore: true,
    okText: 'labels.cancel',
    okIcon: <CrossIcon />,
  }), [openDialog]);

  return useMemo(
    () => ({ confirm, dialog }),
    [confirm, dialog],
  );
}
