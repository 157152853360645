import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener } from '@mui/material';
import useWithDispatch from '../../hooks/useWithDispatch';
import { updateCustomer } from '../../store/customer/customer.actions';
import arrowIcon from '../../images/Arrow.svg';
import englandFlag from '../../images/flags/england.png';
import germanyFlag from '../../images/flags/germany.png';
import spainFlag from '../../images/flags/spain.png';
import styles from './Lang.module.scss';
import classNames from 'classnames';

export interface ILanguage {
  text: string;
  key: string;
  flag: string;
}

export const langs: ILanguage[] = [
  {
    text: 'English',
    key: 'en',
    flag: englandFlag,
  },
  {
    text: 'Deutsch',
    key: 'de',
    flag: germanyFlag,
  },
  {
    text: 'Español',
    key: 'es',
    flag: spainFlag,
  },
];

const Lang = ({
  isAuthorized,
  alwaysDesktop = false,
}: {
  isAuthorized: boolean;
  alwaysDesktop?: boolean;
}) => {
  const [, i18next] = useTranslation();
  const updateCustomerAction = useWithDispatch(updateCustomer);
  const [activeDropdown, setActiveDropdown] = useState<boolean>(false);

  const toggleDropdown = () => setActiveDropdown(!activeDropdown);
  const closeDropdown = () => {
    if (activeDropdown) setActiveDropdown(false);
  };

  const changeLang = (lang: string): void => {
    if (isAuthorized) {
      updateCustomerAction({ preferred_language: lang });
    }
    i18next.changeLanguage(lang);
    closeDropdown();
  };

  return (
    <ClickAwayListener onClickAway={closeDropdown}>
      <div
        className={classNames(styles.lang, { [styles.lang__isActive]: activeDropdown })}
        data-desktop-only={alwaysDesktop}
      >
        <span onClick={toggleDropdown}>
          {i18next.language}
          {' '}
          <img src={arrowIcon} alt="" />
        </span>
        <div>
          {langs.map((lang) => (
            <span
              key={lang.key}
              className={i18next.language === lang.key ? styles.selected : ''}
              onClick={() => changeLang(lang.key)}
            >
              <span>
                {lang.text}
                {' '}
                (
              </span>
              <span>{lang.key}</span>
              <span>)</span>
            </span>
          ))}
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default Lang;
