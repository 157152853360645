const billing = {
  enterDetailsTitle: 'Enter Payment Details',
  updateDetailsTitle: 'Update Payment Details',
  detailsTitle: 'Payment Details',
  updateDetailsSubtitle: 'Please update your payment details',
  enterDetailsSubtitle: 'Please enter your payment details',
  cardHolderName: {
    label: 'Card Holder Name',
    required: 'This field is required',
  },
  card_number: {
    label: 'Card Number',
    incomplete_number: 'Your card number is incomplete',
    invalid_number: 'Your card number is invalid',
  },
  card_expiration: {
    label: 'Expiration date',
    incomplete_expiry: 'Your card\'s expiration date is incomplete',
    invalid_expiry_year_past: 'Your card\'s expiration year is in the past',
  },
  card_cvc: {
    label: 'CVC',
    incomplete_cvc: 'Your card\'s security code is incomplete',
  },
  card_cvv: {
    label: 'CVV',
    incomplete_cvc: 'Your card\'s security code is incomplete',
  },
  expiresOn: 'Expires on',
  saveCardForLater: 'Save your card information for later',
  updateSubmit: 'Update Details',
  removeCard: 'Remove',
  enterSubmit: 'Continue with payment',
};

export default billing;
