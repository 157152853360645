import { Form, FormField, useForm } from '@generics/forms';
import { Button } from '@generics/inputs/button';
import {
  Models,
  useLoungesQuery,
  usePaymentInfoQuery,
  useProductsQuery,
  useRenewProductMutation,
  useUpgradeProductMutation,
} from '@network/api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PaymentMethod } from './PaymentMethod';
import { EStripeCardBrand } from '@generics/stripe/StripeCardBrandIcon';
import { Box, EBoxSize } from '@generics/surfaces';
import { Typography } from '@generics/content';
import { StripeCardTokenGenerator, StripeClientProvider } from '@generics/stripe';
import { TRadioGroupInputItemProps } from '@generics/inputs/radio-group/RadioGroupInputItem';
import { Product } from './Product';
import styles from './ProductPurchaseForm.module.scss';
import { ArrowRightIcon } from '@images/icons';
import { EProductPurchaseStep, useProductPurchaseModal } from './ProductPurchaseModalProvider';
import { useAuthenticatedCustomer } from '@features/authentication';
import { useTranslate } from '@features/multi-language';
import { Unless, When } from 'react-if';

export const ProductPurchaseForm = () => {
  const {
    step,
    setStep,
    selectedProductType,
    selectedProductId,
    isRenew,
    close: closeProductPurchaseModal,
  } = useProductPurchaseModal();
  const t = useTranslate();
  const { data: products } = useProductsQuery({
    select: (data) => data.filter((product) => product.product_type === selectedProductType),
  });
  const { data: paymentInfo } = usePaymentInfoQuery();
  const { data: lounges } = useLoungesQuery();
  const authenticatedCustomer = useAuthenticatedCustomer();
  const upgradeProductMutation = useUpgradeProductMutation({
    onSuccess: closeProductPurchaseModal,
    onSettled: () => {
      setTimeout(() => {
        upgradeProductMutation.reset();
      }, 0);
    },
  });
  const renewProductMutation = useRenewProductMutation({
    onSuccess: closeProductPurchaseModal,
    onSettled: () => {
      setTimeout(() => {
        renewProductMutation.reset();
      }, 0);
    },
  });
  const [productsFilter, setProductsFilter] = useState(
    () => (product: Models.Membership.Data) => !!product,
  );
  // TODO: Create form input for card token.
  const [cardToken, setCardToken] = useState<string | null>(null);

  const authenticatedCustomerDefaultLounge = useMemo(
    () => lounges.find((lounge) => lounge.id === authenticatedCustomer?.default_lounge_id),
    [lounges, authenticatedCustomer],
  );

  const handlePaymentButtonClick = useCallback(() => {
    if (authenticatedCustomerDefaultLounge?.accepts_cash_only) {
      if (isRenew) {
        renewProductMutation.mutate(undefined);
      }
      else {
        upgradeProductMutation.mutate({
          card_token: null,
          save_card: false,
        });
      }
      return;
    }

    setStep(EProductPurchaseStep.Payment);
  }, [authenticatedCustomerDefaultLounge, isRenew, renewProductMutation, upgradeProductMutation, setStep]);

  const productsAsSelectionItems = useMemo(
    () => products.filter(productsFilter).map((product) => ({
      value: product.id,
      children: (
        <Product
          id={product.id}
          title={product.name}
          currency={product.currency}
          price={product.price}
        >
          {product.description}
        </Product>
      ),
    })),
    [productsFilter, products],
  );

  const paymentMethods = useMemo(() => {
    const methods = [];

    if (paymentInfo.card_number_fragment && paymentInfo.card_number_fragment !== '') {
      methods.push({
        value: 'existing-card',
        size: EBoxSize.Input,
        children: (
          <PaymentMethod
            brand={paymentInfo.card_brand?.toLowerCase() as EStripeCardBrand}
            numberFragment={paymentInfo.card_number_fragment}
            expirationDate={paymentInfo.card_expires_on}
          />
        ),
      } satisfies TRadioGroupInputItemProps);
    }

    methods.push({
      value: 'other-card',
      render: ({ selected, ...restItemProps }) => (
        <Box {...restItemProps}>
          <When condition={selected}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
              <Typography type="body/medium/m">labels.otherCreditCard</Typography>
              <StripeClientProvider>
                <StripeCardTokenGenerator onChange={setCardToken} />
              </StripeClientProvider>
            </div>
          </When>
          <Unless condition={selected}>
            <Typography type="body/medium/m">labels.otherCreditCard</Typography>
          </Unless>
        </Box>
      ),
    } satisfies TRadioGroupInputItemProps);

    return methods;
  }, [paymentInfo]);

  const form = useForm({
    defaultValues: {
      chosen_product_id: selectedProductId,
      // TODO: Select existing card as default payment method.
      payment_method: paymentMethods[0].value,
    },
  });

  // TODO: Do check if product selected via validation?
  const chosenProductId = form.watch('chosen_product_id');
  const chosenPaymentMethod = form.watch('payment_method');

  const chosenProduct = useMemo(
    () => products.find((product) => product.id === chosenProductId),
    [products, chosenProductId],
  );

  const finalPrice = useMemo(
    () => isRenew ? chosenProduct?.renewal_price : chosenProduct?.price,
    [isRenew, chosenProduct],
  );

  const purchaseTotal = useMemo(
    () => finalPrice && chosenProduct?.currency
      ? `(${finalPrice} ${chosenProduct.currency})`
      : null,
    [finalPrice, chosenProduct],
  );

  const isPurchaseButtonLoading = upgradeProductMutation.isPending
    || renewProductMutation.isPending;

  const isPurchaseButtonDisabled = !chosenPaymentMethod
    || (chosenPaymentMethod === 'other-card' && !cardToken)
    || upgradeProductMutation.isSuccess
    || renewProductMutation.isSuccess;

  // TODO: Aggregate logic within mutation.
  const handleValidFormSubmit = useCallback((data: { chosen_product_id: string | null; payment_method: string }) => {
    if (data.payment_method === 'other-card' && data.chosen_product_id) {
      upgradeProductMutation.mutate({
        plan: data.chosen_product_id,
        card_token: cardToken,
      });
    }

    if (data.payment_method === 'existing-card' && data.chosen_product_id) {
      upgradeProductMutation.mutate({
        plan: data.chosen_product_id,
      });
    }

    if (isRenew && data.payment_method === 'other-card') {
      renewProductMutation.mutate({
        card_token: cardToken,
      });
    }

    if (isRenew && data.payment_method === 'existing-card') {
      renewProductMutation.mutate(undefined);
    }
  }, [cardToken, isRenew, renewProductMutation, upgradeProductMutation]);

  const handlePaymentMethodChange = useCallback(() => {
    setCardToken(null);
  }, []);

  const vat = useMemo(() => {
    return t('labels.pricesAreIncludingVat', products?.[0]);
  }, [t, products]);

  useEffect(() => {
    if (!chosenProductId) return;
    setProductsFilter(() =>
      (product: Models.Membership.Data) =>
        [EProductPurchaseStep.Payment].includes(step)
          ? product.id === chosenProductId
          : true,
    );
  }, [chosenProductId, step]);

  return (
    <div>
      <Form className={styles.root} form={form} onValidSubmit={handleValidFormSubmit}>
        <div className={styles.productSelection}>
          <FormField
            name="chosen_product_id"
            label={(
              <Typography type="body/medium/m">
                {step === EProductPurchaseStep.ProductSelection
                  ? 'labels.chooseProduct'
                  : 'labels.chosenProduct'}
              </Typography>
            )}
            type="radio-group"
            items={productsAsSelectionItems}
          />
          <Typography type="body/regular/s" color="trinary">{vat}</Typography>
        </div>
        {step === EProductPurchaseStep.Payment
          ? (
              <FormField
                name="payment_method"
                label={<Typography type="body/medium/m">labels.choosePaymentMethod</Typography>}
                type="radio-group"
                items={paymentMethods}
                onChange={handlePaymentMethodChange}
              />
            )
          : null}
        {step === EProductPurchaseStep.ProductSelection
          ? (
              <Button
                label="labels.payment"
                icon={<ArrowRightIcon />}
                fullWidth
                onClick={handlePaymentButtonClick}
                disabled={!chosenProductId}
              />
            )
          : null}
        {step === EProductPurchaseStep.Payment
          ? (
              <Button
                type="submit"
                label={[t('labels.payNow'), purchaseTotal].join(' ')}
                fullWidth
                isLoading={isPurchaseButtonLoading}
                disabled={isPurchaseButtonDisabled}
              />
            )
          : null}
      </Form>
    </div>
  );
};
