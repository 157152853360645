const membership = {
  title: 'Meine Mitgliedschaft',
  subtitle: 'Erneuer, kündige oder wechsle dein Abo hier.',
  current: {
    label: 'Dein Abo',
  },
  expiry: { label: 'Gültig bis' },
  upgrade: 'Upgrade',
  label: 'Mitgliedschaft auswählen',
  details: 'Enthalten in deinem Abo',
  status: 'Aktueller Mitgliedschaftsstatus',
  ridesAvailable: 'Einzelne Fahrten verfügbar',
  customerLimitLabel: 'Monatlich genutzte Einzelfahrten',
  pricingLabel: 'Einzelfahrten',
  renew: 'Verlängern',
  formTitle: 'Mitgliedschaft verlängern',
  membershipExpiresAt: 'Deine Mitgliedschaft läuft ab am ',
  renewC2a: 'Um zu verlängern, klick bitte unten auf den Button zur Mitgliedschaftsverlängerung',
  membershipExpiredAt: 'Deine Mitgliedschaft ist abgelaufen:',
};

export default membership;
