import React, { useCallback, useMemo, useState } from 'react';
import styles from './LoungeCard.module.scss';
import Button from '../Button';
import StyledTooltip from '../../components/StyledTooltip/StyledTooltip';
import { Lounge, LoungeFeature } from '../../store/lounge/lounge.types';
import useGeolocation from '../../hooks/useGeolocation';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { loungeFeatureIcons } from '../../constants/loungeFeatures';
import { getLoungeAddress } from '../../utils/get-lounge-address.utils';

export interface ILoungeCardProps {
  id?: string;
  lounge: Lounge;
  pricePrefix?: React.ReactNode;
  priceValue?: React.ReactNode;
  description?: React.ReactNode;
  features?: LoungeFeature[];
  proceedButtonIcon?: React.ReactNode;
  proceedButtonLabel?: string;
  selected?: boolean;
  onSelect?: (lounge: Lounge) => void;
  onProceed?: (lounge: Lounge) => void;
}

const LoungeCard: React.FC<ILoungeCardProps> = ({
  id,
  lounge,
  priceValue,
  description,
  features,
  proceedButtonIcon,
  proceedButtonLabel = 'Select',
  selected,
  onSelect,
  onProceed,
}) => {
  const [t] = useTranslation();
  const [buttonClicked, setButtonClicked] = useState<boolean>(false);
  const geolocation = useGeolocation();

  const humanizedDistance = useMemo(
    () =>
      geolocation.getHumanizedDistanceTo({
        latitude: Number(lounge.lat),
        longitude: Number(lounge.lng),
      }),
    [geolocation, lounge.lat, lounge.lng],
  );

  const handleSelect = useCallback(() => {
    if (!onSelect) return;
    onSelect(lounge);
  }, [lounge, onSelect]);

  const handleProceed = useCallback(() => {
    if (!onProceed) return;

    setButtonClicked(true);

    setTimeout(() => {
      setButtonClicked(false);
      onProceed(lounge);
    }, 500);
  }, [lounge, onProceed, setButtonClicked]);

  const address = getLoungeAddress(lounge);

  const rootClassName = useMemo(
    () =>
      classNames(styles.root, {
        [styles.root_selectionModeCard]: !!onSelect,
        [styles.root_selected]: selected,
        [styles.root_buttonClicked]: buttonClicked,
      }),
    [onSelect, selected, buttonClicked],
  );

  return (
    <div
      data-selection-mode={!onProceed}
      id={id}
      className={rootClassName}
      onClick={onSelect ? handleSelect : undefined}
    >
      <div className={styles.header}>
        <div className={styles.header__title}>
          <h3 className={styles.header__name}>{lounge.name}</h3>
          {geolocation.isAvailable
            ? (
                <div className={styles.header__distance}>
                  (
                  {humanizedDistance}
                  )
                </div>
              )
            : null}
        </div>
        {priceValue
          ? (
              <div className={styles.header__price}>
                <strong className={styles.header__priceValue}>{priceValue}</strong>
              </div>
            )
          : null}
      </div>
      {address ? <div className={styles.address}>{address}</div> : null}
      {description || (features && features.length > 0)
        ? (
            <div className={styles.content}>
              <div className={styles.content__description}>{description}</div>
              {features && features.length > 0
                ? (
                    <div className={styles.content__features}>
                      {features.map((feature) => {
                        const Icon = loungeFeatureIcons[feature.slug];

                        if (!Icon) return null;

                        return (
                          <StyledTooltip
                            arrow
                            enterTouchDelay={0}
                            placement="top"
                            key={feature.slug}
                            title={feature.name}
                          >
                            <div>
                              <Icon />
                            </div>
                          </StyledTooltip>
                        );
                      })}
                    </div>
                  )
                : null}
            </div>
          )
        : null}
      {onProceed
        ? (
            <div className={styles.footer}>
              <Button
                className={styles.footer__button}
                label={t(proceedButtonLabel)}
                icon={proceedButtonIcon}
                onClick={handleProceed}
              />
            </div>
          )
        : null}
    </div>
  );
};

export default LoungeCard;
