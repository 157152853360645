const eventParticipantDriver = {
  welcome: {
    title: 'Guten Tag!',
    details: {
      firstLine:
        'Sie nehmen an einem Event teil, das am <1>{{dateTime}} in der {{location}} Racing Unleashed Lounge</1> stattfindet.',
      secondLine:
        'Aber zuerst benötigen wir einige Informationen über Sie. Füllen Sie das Formular aus, damit Ihr Aufenthalt in der Lounge reibungslos verläuft. Es wird Sie nicht länger als <1>1 Minute</1> in Anspruch nehmen.',
    },
    organiserDetails: {
      firstLine:
        'Dies ist eine Umfrage für die Veranstaltung, die am <1>{{dateTime}} in der {{location}} Racing Unleashed Lounge</1> stattfindet.',
      secondLine:
        'Wir benötigen einige Informationen über die Teilnehmer. Füllen Sie das Formular aus, damit Ihr Lounge-Erlebnis reibungslos verläuft.',
    },
  },
  form: {
    title: 'Teilnehmer an Ihrer Veranstaltung',
    subtitle:
      'Bitte fügen Sie den Namen und die Postadresse jedes Teilnehmers hinzu',
  },
  deleteConfirmation: {
    title: 'Teilnehmer löschen',
    text: 'Sind Sie sicher, dass Sie <1>{{name}}</1> aus der Teilnehmerliste löschen möchten?\nDiese Aktion kann nicht rückgängig gemacht werden.',
    okText: 'löschen',
  },
  fields: {
    firstName: {
      label: 'Wie lautet Ihr Vorname?',
      organiserLabel: 'Vorname',
      placeholder: 'Geben Sie Ihren Vornamen ein...',
      organiserPlaceholder: 'Vornamen eingeben...',
    },
    lastName: {
      label: 'Wie lautet Ihr Nachname?',
      organiserLabel: 'Nachname',
      placeholder: 'Geben Sie Ihren Nachnamen ein...',
      organiserPlaceholder: 'Nachnamen eingeben...',
    },
    email: {
      label: 'Wie lautet Ihre E-Mail Adresse?',
      organiserLabel: 'E-Mail Adresse',
      placeholder: 'Geben Sie Ihre E-Mail-Adresse ein...',
      organiserPlaceholder: 'E-Mail-Adresse eingeben...',
    },
    receiveEmail: {
      label: 'Vereinbarungen',
      placeholder:
        'Ich möchte einmal im Jahr einen Newsletter erhalten. (fakultativ)',
    },
    surveyLanguage: {
      label: 'Sprache der Umfrage',
    },
  },
  startSurvey: 'Los geht\'s!',
  send: 'Senden Sie',
  organiserSend: 'Speichern und als nächstes hinzufügen',
  save: 'Speichern',
  thankYou: {
    title: 'Dankeschön!',
    details: {
      firstLine: 'Sie sind startklar!',
      secondLine: 'Wir sehen uns in der Lounge!',
      thirdLine: 'Vielen Dank,\nRacing Unleashed Team',
    },
  },
  participantAdded: 'Der Teilnehmer wurde erfolgreich hinzugefügt',
  participantUpdated: 'Der Teilnehmer wurde erfolgreich aktualisiert',
  participantDeleted: 'Der Teilnehmer wurde erfolgreich gelöscht',
  allParticipantsAdded:
    'Alle Teilnehmer hinzugefügt.\nSie können die Liste noch ändern, indem Sie die Daten der Teilnehmer löschen oder bearbeiten.',
};

export default eventParticipantDriver;
