export default {
  application: 'Application',
  home: 'Home',
  packages: 'Packages',
  membership: 'Membership',
  ranking: 'Ranking',
  championships: 'Championships',
  events: 'Events',
  notifications: 'Notifications',
  noNotifications: 'No notifications',
  userSettings: 'User Settings',
  logOut: 'Log Out',
  profileData: 'Profile Data',
  profile: 'Profile',
  changePassword: 'Change Password',
  deleteAccount: 'Delete Account',
  payments: 'Payments',
  paymentMethods: 'Payment Methods',
  paymentHistory: 'Payment History',
  newBooking: 'New Booking',
  bookARide: 'Book a Ride',
  yourProducts: 'Your Products',
  yourPackages: 'Your Packages',
  yourMembership: 'Your Membership',
  yourStatistics: 'Your Statistics',
  yourPaymentMethod: 'Your Payment Method',
  experience: 'Experience',
  track: 'Track',
  markAllAsRead: 'Mark All As Read',
  yourUpcomingExperiences: 'Your Upcoming Experiences',
  draftEvents: 'Draft Events',
  delete: 'Delete',
  resume: 'Resume',
  renew: 'Renew',
  cancel: 'Cancel',
  showAll: 'Show All',
  buyNow: 'Buy Now',
  payNow: 'Pay Now',
  buyNew: 'Buy New',
  buyPackage: 'Buy Package',
  buyMembership: 'Buy Membership',
  renewMembership: 'Renew Membership',
  payment: 'Payment',
  lounge: 'Lounge',
  chosenProduct: 'Chosen Product',
  chooseProduct: 'Choose Product',
  choosePaymentMethod: 'Choose Payment Method',
  addNewPaymentMethod: 'Add New Payment Method',
  addPaymentMethod: 'Add Payment Method',
  uploadNewPicture: 'Upload New Picture',
  otherCreditCard: 'Other Credit Card',
  deleteAvatar: 'Delete Avatar',
  avatar: 'Avatar',
  personalData: 'Personal Data',
  address: 'Address',
  racing: 'Racing',
  edit: 'Edit',
  save: 'Save',
  backToEdit: 'Back to Edit',
  language: 'Language',
  singleRide: 'Single Ride',
  groupRide: 'Group Ride',
  ridesLeft: '{{rides}} rides left',
  durationInMinutes: '{{duration}} min',
  deletePaymentMethod: 'Delete Payment Method',
  pricesAreIncludingVat: 'Prices are including {{vat}}% VAT.',
  incompleteProfileDataMessage: 'We recently changed our platform system, in this regards we kindly ask you to please update your profile with the missing details.',
  changesCancelConfirmation: {
    title: 'Changes won\'t be saved',
    message: 'Are you sure you want to cancel?',
  },
  deleteAccountConfirmation: {
    title: 'Are you sure you want to delete your account?',
    message: 'This action is irreversible and you will lose all your data and content related to this account.',
  },
  deletePaymentMethodConfirmation: {
    title: 'Are you sure you want to delete your payment method?',
    message: 'This action is irreversible and you will no longer see your saved payment method details.',
  },
};
