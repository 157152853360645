import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { getChampionship } from '../../store/championships/championships.actions';
import './MobileChampionshipDetails.scss';
import { toastUtil } from '../../utils/toast.utils';

const MobileChampionshipDetails = () => {
  const { id, lang } = useParams();
  const [championship, setChampionship] = useState({
    title: '',
    featured_image_medium: '',
    description: '',
  });
  const _isMounted = useRef(true);

  const fetchChallanges = async () => {
    try {
      const response = await getChampionship(id, lang);
      if (_isMounted.current) setChampionship(response);
    }
    catch (err) {
      const error = err as TRumpApiRequestError;
      if ('message' in error && error.message === 'canceled') return;

      const msg = 'meta' in error ? error.meta.message : error.message;
      if (typeof msg === 'string') toastUtil('error', msg);
    }
  };

  useEffect(() => {
    fetchChallanges();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="ru-championships ru-championship-mobile">
      {championship && (
        <div className="ru-championship">
          <h3>{championship.title}</h3>
          <img src={championship.featured_image_medium} alt="" />
          <div className="ru-championship-content">
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {championship.description}
            </ReactMarkdown>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileChampionshipDetails;
