import React from 'react';
import mergeClassNames from 'classnames';
import styles from './PriceTotal.module.scss';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader';

export interface IPriceTotalProps extends React.HTMLProps<HTMLDivElement> {
  total?: string;
  collapsed?: boolean;
  loading?: boolean;
}

const PriceTotal: React.FC<IPriceTotalProps> = (props) => {
  const [t] = useTranslation();
  const { total, collapsed, hidden, loading, ...restProps } = props;

  return (
    <>
      <div
        {...restProps}
        className={mergeClassNames(styles.root, {
          [styles.root_collapsed]: collapsed,
          [styles.root_hidden]: hidden,
        })}
      >
        {loading ? <Loader fullSize width={40} height={40} /> : null}
        <div className={styles.title}>
          <span>{t('booking.total')}</span>
          <span className={styles.title__semicolon}>:</span>
        </div>
        <div className={styles.value}>
          {total !== null && total !== undefined ? total : '-'}
        </div>
      </div>
    </>
  );
};

export default PriceTotal;
