import React, { forwardRef, useCallback, useMemo } from 'react';
import { Box, EBoxSize, TBoxExposures, TBoxProps } from '@generics/surfaces';
import { Typography } from '@generics/content';
import { useMergedClassNames } from '@utils/styling';
import styles from './RadioGroupInputItem.module.scss';

export type TRadioGroupInputItemExposures = TBoxExposures;

export type TRadioGroupInputItemProps = Extend<TBoxProps, {
  value?: string | null;
  onChange?: (value: string | null) => void;
  selected?: boolean;
  render?: (props: TRadioGroupInputItemRenderProps) => React.ReactNode;
}>;

export type TRadioGroupInputItemRenderProps = Extend<Omit<TRadioGroupInputItemProps, 'render'>, {
  onChange?: () => void;
}>;

export const RadioGroupInputItem = forwardRef<
  TRadioGroupInputItemExposures,
  TRadioGroupInputItemProps
>(
  function RadioGroupInputItemWithForwardedRef(props, ref) {
    const {
      value,
      onChange,
      selected = false,
      render,
      children,
      size = EBoxSize.Small,
      className,
      ...restRootProps
    } = props;

    const handleClick = useCallback(() => {
      onChange?.(typeof value === 'string' ? value : null);
    }, [onChange, value]);

    const rootClassName = useMergedClassNames({
      [styles.root]: true,
      [styles.root_selected]: selected,
      [className || '']: true,
    });

    const rootProps = useMemo(() => ({
      ...restRootProps,
      selected,
      className: rootClassName,
      size,
      onClick: handleClick,
      tabIndex: 0,
      ref,
    }), [handleClick, ref, restRootProps, rootClassName, selected, size]);

    const renderProps = useMemo(
      () => (rootProps) satisfies TRadioGroupInputItemRenderProps,
      [rootProps],
    );

    const renderedChildren = useMemo(
      () => render?.(renderProps) ?? (
        <Box {...rootProps}>
          {children}
        </Box>
      ),
      [render, renderProps, rootProps, children],
    );

    const wrappedRenderedChildren = useMemo(
      () => typeof renderedChildren === 'string'
        ? <Typography type="body/medium/m">{renderedChildren}</Typography>
        : renderedChildren,
      [renderedChildren],
    );

    return wrappedRenderedChildren;
  },
);
