import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { CardCvcElementProps, CardExpiryElementProps, CardNumberElementProps } from '@stripe/react-stripe-js';
import type {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import visaImg from '../../images/visa-card.svg';
import mcImage from '../../images/mastercard.svg';
import amexImage from '../../images/amex.svg';

const cardMap = new Map([
  ['visa', visaImg],
  ['amex', amexImage],
  ['mastercard', mcImage],
]);

const style = {
  base: {
    fontSize: '17px',
    lineHeight: '40px',
    fontWeight: '400',
    color: '#14006b',
    '::placeholder': {
      color: '#70707d',
    },
  },
  invalid: {
    color: '#14006b',
  },
};

type TStripeElementsProps = CardNumberElementProps | CardExpiryElementProps | CardCvcElementProps;

interface IStripeFieldProps<TStripeFieldComponentProps extends TStripeElementsProps> {
  StripeField: React.ComponentType<TStripeFieldComponentProps>;
  i18nKey: string;
  placeholder?: string;
  brand?: string;
  outlined?: boolean;
}

const StripeField = <TStripeFieldComponentProps extends TStripeElementsProps>({
  StripeField: StripeFieldComponent,
  i18nKey,
  placeholder,
  brand,
  outlined,
}: IStripeFieldProps<TStripeFieldComponentProps>) => {
  const [t] = useTranslation();
  const [fieldBrand, setFieldBrand] = useState<string>('');
  const [focus, setFocus] = useState(false);
  const [errorCode, setError] = useState<string | null>(null);
  const [isEmpty, setEmpty] = useState(true);

  const handleChange = useCallback(
    (
      event: StripeCardNumberElementChangeEvent | StripeCardExpiryElementChangeEvent | StripeCardCvcElementChangeEvent,
    ) => {
      setError(event.error?.code ?? null);

      setEmpty(event.empty);

      if (event.elementType === 'cardNumber' && event.brand) {
        setFieldBrand(event.brand.toLowerCase());
      }
    },
    [],
  );

  const handleFocus = useCallback(() => {
    setFocus(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocus(false);
  }, []);

  const stripeFieldProps = useMemo<TStripeElementsProps>(
    () => ({
      onChange: handleChange,
      onBlur: handleBlur,
      onFocus: handleFocus,
      options: {
        style,
        placeholder: isEmpty && !focus && placeholder ? placeholder : '',
      },
    }),
    [handleChange, handleBlur, handleFocus, isEmpty, focus, placeholder],
  );

  return (
    <div
      className={`FormField ${!isEmpty || placeholder ? 'has-value' : ''} ${
        outlined ? 'outlined' : ''
      }`}
    >
      {i18nKey && <label>{t(`${i18nKey}.label`)}</label>}
      <div
        className={`FormField-input stripe-field ${
          brand ? 'is-card-field' : ''
        } ${focus ? 'is-focused' : ''} ${outlined ? 'outlined' : ''}`}
      >
        <StripeFieldComponent {...(stripeFieldProps as TStripeFieldComponentProps)} />
        {isEmpty && brand && !focus && (
          <img src={cardMap.get(brand.toLowerCase())} alt="" />
        )}
        {!isEmpty && fieldBrand && cardMap.has(fieldBrand.toLowerCase()) && (
          <img src={cardMap.get(fieldBrand.toLowerCase())} alt="" />
        )}
        <span className="FormField-focusIndicator" />
      </div>
      {errorCode && (
        <p className="FormField-error">{t(`${i18nKey}.${errorCode}`)}</p>
      )}
    </div>
  );
};

export default StripeField;
