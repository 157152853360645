import { useMemo } from 'react';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

export type TUseApiQueryProps<
  QueryFnData = unknown,
  Err = Error,
  Data = QueryFnData,
  QueryKey extends ReadonlyArray<unknown> = ReadonlyArray<unknown>,
> = UseQueryOptions<
  QueryFnData,
  Err,
  Data,
  QueryKey
>;

export function useApiQuery<
  QueryFnData = unknown,
  Err = Error,
  Data = QueryFnData,
  QueryKey extends ReadonlyArray<unknown> = ReadonlyArray<unknown>,
>(
  props?: TUseApiQueryProps<QueryFnData, Err, Data, QueryKey>,
) {
  const { queryKey, ...restHookProps } = props ?? {};

  const [, { language }] = useTranslation();

  const extendedQueryKey = useMemo(() => {
    const key = Array.isArray(queryKey) ? queryKey : [queryKey];
    return [...key, { language }] as unknown as QueryKey;
  }, [queryKey, language]);

  return useQuery({
    ...restHookProps,
    queryKey: extendedQueryKey,
  });
}
