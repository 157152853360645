import axios, { AxiosError, AxiosInstance } from 'axios';
import store from '../store';
import { signOut } from '../store/customer/customer.actions';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { SignOutAction } from '../store/customer/customer.types';
import { Dispatch } from 'redux';
const stripeKey = import.meta.env.REACT_APP_STRIPE_KEY;

export const API_ROOT = import.meta.env.REACT_APP_API;
export { stripeKey };

export const stripeClient: { instance: Stripe | null; promise: Promise<Stripe | null> | null } = {
  instance: null,
  promise: null,
};

stripeClient.promise = loadStripe(stripeKey).then((instance) => {
  stripeClient.instance = instance;
  return instance;
});

const api: AxiosInstance = axios.create({
  baseURL: API_ROOT,
});

api.interceptors.request.use(
  (config) => {
    // set access_token
    // add access_token to the request headers
    const token = localStorage.getItem('ruToken');
    if (token && config.headers) config.headers['x-auth-token'] = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      signOut(store.dispatch as Dispatch<SignOutAction>);
      return false;
    }
    else {
      return Promise.reject(error);
    }
  },
);

export function fixedEncodeURIComponent(str: string | null) {
  if (str === null) {
    return '';
  }
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return '%' + c.charCodeAt(0).toString(16).toUpperCase();
  });
};

export default api;
