import React from 'react';
import { TMenuLinkProps, MenuLink } from './MenuLink';
import { useMergedClassNames } from '@utils/styling';
import styles from './Menu.module.scss';

export enum EMenuSize {
  Small = 'small',
  Medium = 'medium',
}

export type TMenuProps = Extend<Omit<HTMLProps<HTMLDivElement>, 'children'>, {
  size?: `${EMenuSize}`;
  links: TMenuLinkProps[];
  onMenuLinkClick?: () => void;
}>;

export const Menu = (props: TMenuProps) => {
  const {
    size = EMenuSize.Medium,
    links,
    onMenuLinkClick,
    className,
    ...restRootProps
  } = props;

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [styles.root_small]: size === EMenuSize.Small,
    [className || '']: true,
  });

  return (
    <nav {...restRootProps} className={rootClassName}>
      {links.map(({ uid, onClick, ...restLinkProps }) => (
        <MenuLink
          {...restLinkProps}
          key={uid}
          uid={uid}
          onClick={(event) => {
            onClick?.(event);
            onMenuLinkClick?.();
          }}
        />
      ))}
    </nav>
  );
};
