import React from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import { accountDashboardRoute } from '@routes/account';
import { Breakpoint, useBreakpoints } from '@generics/responsiveness';
import { Divider } from '@generics/content';
import { Button } from '@generics/inputs/button';
import { BrandLogo } from '@generics/branding';
import { useMetadata } from '@features/metadata';
import { NotificationsButton } from '@features/notifications';
import { LanguagePicker } from '@features/multi-language';
import { ArrowLeftIcon, BarsIcon, PlusIcon } from '@images/icons';
import { useAccountLayout } from './AccountLayoutProvider';
import { useMergedClassNames } from '@utils/styling';
import styles from './AccountLayoutTopBar.module.scss';

export type TAccountLayoutTopBarProps = Extend<Omit<HTMLProps<HTMLElement>, 'children'>, {}>;

export const AccountLayoutTopBar = (props: TAccountLayoutTopBarProps) => {
  const { className, ...restRootProps } = props;

  const isDashboard = !!useMatch({
    path: accountDashboardRoute.path,
    end: true,
  });

  const { isMobile, isDesktop } = useBreakpoints();
  const { pageTitle, backButtonLocation } = useMetadata();
  const { openSideDrawer } = useAccountLayout();

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [styles.root_transparent]: isMobile,
    [styles.root_fixed]: isDesktop,
    [className || '']: true,
  });

  return (
    <>
      {isDesktop ? <div className={styles.spacer} /> : null}
      <aside {...restRootProps} className={rootClassName}>
        {isMobile && !isDashboard
          ? (
              <Button
                as={NavLink}
                to={backButtonLocation ? backButtonLocation : accountDashboardRoute.path}
                design="outline"
                icon={<ArrowLeftIcon />}
              />
            )
          : null}
        {isMobile && isDashboard ? <BrandLogo /> : <h1 className={styles.pageTitle}>{pageTitle}</h1>}
        <div className={styles.actions}>
          <Breakpoint is="desktop">
            <Button
              as={NavLink}
              to="/public/ride-booking"
              label="labels.newBooking"
              icon={<PlusIcon className={styles.buttonIcon} />}
            />
            <Divider />
          </Breakpoint>
          <NotificationsButton hidden={isDesktop ? false : !isDashboard} />
          <Breakpoint is="mobile">
            <Button
              design="outline"
              icon={<BarsIcon />}
              onClick={openSideDrawer}
            />
          </Breakpoint>
          <Breakpoint is="desktop">
            <LanguagePicker />
          </Breakpoint>
        </div>
      </aside>
    </>
  );
};
