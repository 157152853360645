import React, { useCallback, useRef } from 'react';
import { Button, TButtonProps } from '@generics/inputs/button';
import { BellIcon } from '@images/icons';
import { useNotifications } from './NotificationsProvider';
import { useMergedClassNames } from '@utils/styling';
import styles from './NotificationsButton.module.scss';
import { NotificationsDialog } from './NotificationsDialog';

// TODO: Simplify types.
export type TNotificationsButtonProps = Extend<Omit<TButtonProps<'button'>, 'icon'> & Omit<React.ComponentPropsWithRef<'button'>, keyof TButtonProps<'button'>>, {
  hidden?: boolean;
}>;

export const NotificationsButton = (props: TNotificationsButtonProps) => {
  const { hidden, className, ...restRootProps } = props;

  const buttonElementRef = useRef<HTMLButtonElement | null>(null);

  const notifications = useNotifications();

  const getAnchorEl = useCallback(() => buttonElementRef.current ?? document.createElement('div'), []);

  const rootClassName = useMergedClassNames({
    [styles.root_hidden]: hidden,
    [className || '']: true,
  });

  const indicatorClassName = useMergedClassNames({
    [styles.indicator]: true,
    [styles.indicator_visible]: notifications.hasUnseen,
  });

  return (
    <>
      <NotificationsDialog open={notifications.isDialogOpen} anchorEl={getAnchorEl} />
      <Button
        design="outline"
        icon={(
          <span className={styles.iconBox}>
            <span className={indicatorClassName} />
            <BellIcon />
          </span>
        )}
        {...restRootProps}
        className={rootClassName}
        onClick={notifications.openDialog}
        ref={buttonElementRef}
      />
    </>
  );
};
