import React, { useEffect, useMemo, useRef, useState } from 'react';
import BaseCard, { IBaseCardProps } from '../BaseCard/BaseCard';
import Calendar from '../../Calendar';
import { AvailableDate } from '../../../store/booking/booking.types';
import styles from './DateStep.module.scss';
import { format, add, parse, startOfMonth, endOfMonth } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EnvelopIcon } from '../../../images/envelope.svg?tsx';
import { useFormContext } from 'react-hook-form';
import {
  getAvailableDates,
  getAvailableTimes,
} from '../../../store/booking/booking.actions';
import { IEventBookForm } from '../../../views/EventBooking/EventBooking';
import { timeFromIsoDate } from '../../../utils/time-from-iso-date.utils';
import Loader from '../../Loader';
import { toastUtil } from '../../../utils/toast.utils';
import { requiredTextValidator } from '../../../validators/requiredText.validator';
import { dateLocales } from '../../../constants/dateLocales';
import i18n from '../../../i18n';
import OptionCard from '../components/OptionCard/OptionCard';
import { Controller } from '../../FormField/Controller';

function compareObjects(
  obj1: Partial<IEventBookForm>,
  obj2: Partial<IEventBookForm>,
): (keyof IEventBookForm)[] {
  const differentFields: (keyof IEventBookForm)[] = [];

  for (const key in obj1) {
    const prop = key as keyof IEventBookForm;
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    if ((obj1[prop] || '').toString() !== (obj2[prop] || '').toString())
      differentFields.push(prop);
  }

  return differentFields;
}

export interface IDateStepProps
  extends Pick<
    IBaseCardProps,
    'collapsed' | 'onForward' | 'onBack' | 'invalid'
  > {
  isForwardDisabledBySummary: boolean;
  revalidateStep?: () => void;
}

interface ITimeSlot {
  key: string;
  label: string;
}

const DateStep: React.FC<IDateStepProps> = ({
  isForwardDisabledBySummary,
  onForward,
  revalidateStep,
  ...props
}) => {
  const [t] = useTranslation();
  const { control, watch, getValues, setValue }
    = useFormContext<IEventBookForm>();
  const prevFields = useRef<Partial<IEventBookForm> | null>(null);
  const [selectedMonth, setSelectedMonth] = useState<Date | undefined>(
    new Date(),
  );
  const [availableTimes, setAvailableTimes] = useState<string[]>([]);
  const [availableDates, setAvailableDates] = useState<AvailableDate[] | null>(
    null,
  );
  const [isTimeLoading, setIsTimeLoading] = useState<boolean>(false);
  const [isDateLoading, setIsDateLoading] = useState<boolean>(false);

  const watchFields = watch([
    'duration',
    'date',
    'time',
    'loungeId',
    'simulatorsAmount',
    'timeSlotId',
  ]);
  const { date, time, loungeId, timeSlotId, simulatorsAmount, duration }
    = watchFields;

  useEffect(() => {
    const thereIsDateAndMonthNotSelected = date && !selectedMonth;
    const thereIsEverythingAndMonthsDifferent
      = date
      && selectedMonth
      && format(date, 'MM') !== format(selectedMonth, 'MM');

    if (thereIsDateAndMonthNotSelected || thereIsEverythingAndMonthsDifferent) {
      setSelectedMonth(date || undefined);
    }
    // eslint-disable-next-line
  }, [date]);

  useEffect(() => {
    if (!loungeId || !date || !timeSlotId || !simulatorsAmount || !duration)
      return;

    setTimeout(() => setIsTimeLoading(true), 1);

    const controller = new AbortController();

    getAvailableTimes(
      loungeId,
      timeSlotId,
      date.toISOString(),
      duration,
      simulatorsAmount,
      controller,
    )
      .then((availableTimes) => {
        setAvailableTimes(availableTimes);

        const time = getValues('time');

        if (!time) return;

        const timeValue = timeFromIsoDate(time);
        const sameTimeSlot = timeValue
          ? availableTimes.find((availableTime) =>
            availableTime.includes(timeValue[0]),
          )
          : null;
        setValue('time', sameTimeSlot || '');
      })
      .catch((error) => {
        const err = error as TRumpApiRequestError;
        if ('message' in err && err.message === 'canceled') return;

        const msg = 'meta' in err ? err.meta.message : err.message;
        if (typeof msg === 'string') toastUtil('error', msg);
      })
      .finally(() => setIsTimeLoading(false));

    return () => controller.abort();
    // eslint-disable-next-line
  }, [date, loungeId, timeSlotId, simulatorsAmount, duration]);

  useEffect(() => {
    const differentFields = prevFields.current
      ? compareObjects(watchFields, prevFields.current)
      : null;

    if (
      !selectedMonth
      || (differentFields
      && differentFields.includes('time')
      && differentFields.includes('date')
      && differentFields.length === 2)
    ) {
      prevFields.current = watchFields;
      return;
    }

    setTimeout(() => setIsDateLoading(true), 1);

    prevFields.current = watchFields;
    const controller = new AbortController();
    const dateFrom = format(startOfMonth(selectedMonth), 'yyyy-MM-dd');
    const dateTo = format(endOfMonth(selectedMonth), 'yyyy-MM-dd');

    getAvailableDates(
      +simulatorsAmount,
      timeSlotId,
      loungeId,
      dateFrom,
      dateTo,
      time,
      controller,
    )
      .then(setAvailableDates)
      .catch((error) => {
        const err = error as TRumpApiRequestError;
        if ('message' in err && err.message === 'canceled') return;

        const msg = 'meta' in err ? err.meta.message : err.message;
        if (typeof msg === 'string') toastUtil('error', msg);
      })
      .finally(() => setIsDateLoading(false));

    return () => controller.abort();
    // eslint-disable-next-line
  }, [time, simulatorsAmount, selectedMonth, loungeId, timeSlotId]);

  const timeSlots: ITimeSlot[] = useMemo(() => {
    if (!availableTimes.length) return [];

    return availableTimes
      .map((timeSlot) => {
        const startTime = timeFromIsoDate(timeSlot);

        const dateEnd = startTime
          ? add(parse(startTime[0], 'HH:mm', new Date(timeSlot)), {
            minutes: duration * 60,
          })
          : '';

        return {
          key: timeSlot,
          label: `${startTime?.[0]} - ${timeFromIsoDate(dateEnd.toString())?.[0]}`,
        };
      })
      .filter(Boolean) as ITimeSlot[];
  }, [availableTimes, duration]);

  const isForwardActive = useMemo(() => {
    return (
      !isForwardDisabledBySummary
      && !isDateLoading
      && !isTimeLoading
      && !!availableDates
      && !!date
      && !!time
    );
  }, [
    date,
    time,
    availableDates,
    isDateLoading,
    isTimeLoading,
    isForwardDisabledBySummary,
  ]);

  const value = useMemo(() => {
    const timeSlot = time ? timeSlots.find(({ key }) => key === time) : null;

    return date && time && timeSlot
      ? `${format(date, 'dd MMMM yyy', {
        locale: dateLocales[i18n.language],
      })}, ${timeSlot.label}`
      : '';
  }, [date, time, timeSlots]);

  return (
    <BaseCard
      title="booking.steps.date.title"
      subtitle="booking.steps.date.subtitle"
      forwardTitle="booking.steps.date.forwardButton"
      collapseData={value}
      {...props}
      onForward={isForwardActive ? onForward : undefined}
      isLoading={props.collapsed && (isTimeLoading || isDateLoading)}
    >
      <div className={styles.dateStep}>
        <div className={styles.calendar}>
          {isDateLoading
            ? (
                <Loader fullSize color="black" width={80} height={80} />
              )
            : (
                ''
              )}
          <Controller
            control={control}
            name="date"
            rules={{
              required: true,
            }}
            render={({ value, onChange }) => (
              <Calendar
                selectedMonth={selectedMonth}
                selectedDay={value ?? undefined}
                availableDates={availableDates}
                selectDay={(date) => {
                  if (!date) return;

                  onChange(date);
                  revalidateStep?.();
                }}
                onMonthChange={setSelectedMonth}
              />
            )}
          />
        </div>
        <div className={styles.sidebar}>
          {date
            ? (
                <>
                  <div className={styles.sidebar__title}>
                    {date
                      ? format(date, 'eeee, do MMMM yyyy:', {
                        locale: dateLocales[i18n.language],
                      })
                      : ''}
                  </div>

                  {isTimeLoading
                    ? (
                        <Loader fullSize color="black" width={80} height={80} />
                      )
                    : (
                        ''
                      )}

                  {!isTimeLoading && !timeSlots.length
                    ? (
                        <p className={styles.sidebar__emptyInfo}>
                          <i>
                            <EnvelopIcon />
                          </i>
                          {t('booking.steps.date.emptySlots')}
                          <a href="mailto:events@racing-unleashed.com">
                            events@racing-unleashed.com
                          </a>
                        </p>
                      )
                    : (
                        ''
                      )}

                  <div className={styles.timeSlots}>
                    <Controller
                      control={control}
                      name="time"
                      rules={{
                        ...requiredTextValidator({ required: 'required' }),
                      }}
                      render={({ value, onChange }) => (
                        <>
                          {timeSlots.map(({ key, label }) => (
                            <OptionCard
                              key={key}
                              className={styles.timeSlots__slot}
                              selected={key === value}
                              onClick={() => {
                                onChange(key);
                                revalidateStep?.();
                              }}
                            >
                              {label}
                            </OptionCard>
                          ))}
                        </>
                      )}
                    />
                  </div>
                </>
              )
            : (
                ''
              )}
        </div>
      </div>
    </BaseCard>
  );
};

export default DateStep;
