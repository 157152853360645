import { WretchResponse } from 'wretch';
import { IUseApiMutationProps, useApiClient, useApiMutation } from '@network/api/client';
import { useQueryClient } from '@network/queries';

export type TUseReservationCancelMutationResponse = WretchResponse;

export type TUseReservationCancelMutationProps = Omit<IUseApiMutationProps<TUseReservationCancelMutationResponse>, 'mutationKey' | 'mutationFn'>;

export function useReservationCancelMutation(props?: TUseReservationCancelMutationProps) {
  const { onSuccess, ...restHookProps } = props ?? {};

  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useApiMutation({
    ...restHookProps,
    mutationKey: ['reservations', 'cancel'],
    mutationFn: (id: string) =>
      apiClient.url(`/reservations/cancel`)
        .body(JSON.stringify({ existing_reservation_id: id }))
        .delete(`?existing_reservation_id=${id}`)
        .res(),
    onSuccess: (response, variables, context) => {
      queryClient.refetchQueries({ queryKey: ['customers', 'me', 'draftEvents'], exact: true });
      onSuccess?.(response, variables, context);
    },
  });
}
