import React, { useEffect, useState } from 'react';
import styles from './SignIntoEvent.module.scss';
import SurveyWelcomeStep from '../../components/SurveyWelcomeStep/SurveyWelcomeStep';
import Button from '../../components/Button';
import { Trans, useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { ReactComponent as ArrowIcon } from '../../images/arrow-current-color.svg?tsx';
import ParticipantForm, {
  IEventParticipantDriverFormValues,
} from '../../components/ParticipantForm/ParticipantForm';
import ThankYouStep from './ThankYouStep/ThankYouStep';
import {
  addCustomerParticipant,
  getParticipantBookingData,
} from '../../store/participant-drivers/participant-drivers.actions';
import { useParams } from 'react-router-dom';
import { IParticipantEvent } from '../../store/participant-drivers/participant-drivers.types';
import { toastUtil } from '../../utils/toast.utils';
import { format } from 'date-fns';
import Loader from '../../components/Loader';
import { dateLocales } from '../../constants/dateLocales';
import i18n from '../../i18n';

const PartcipantSurvey: React.FC = () => {
  const [t] = useTranslation();
  const { id } = useParams<{ id: string }>();
  const form = useForm<IEventParticipantDriverFormValues>({
    mode: 'all',
    shouldUnregister: false,
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      newsletter: false,
    },
  });
  const [status, setStatus] = useState<'started' | 'completed' | null>(null);
  const [isLoading, setIsLoading] = useState<{
    eventData: boolean;
    sending: boolean;
  }>({ eventData: false, sending: false });
  const [eventData, setEventData] = useState<IParticipantEvent | null>(null);
  const {
    formState: { isValid },
    trigger,
    getValues,
  } = form;

  useEffect(() => {
    if (!id) return;

    setTimeout(() =>
      setIsLoading((loading) => ({ ...loading, eventData: true })),
    );

    const controller = new AbortController();

    getParticipantBookingData(id, controller)
      .then(setEventData)
      .catch((err: TRumpApiRequestError) => {
        if ('message' in err && err.message === 'canceled') return;

        const msg = 'meta' in err ? err.meta.message : err && err.message;
        if (typeof msg === 'string') toastUtil('error', msg);
      })
      .finally(() =>
        setIsLoading((loading) => ({ ...loading, eventData: false })),
      );

    return () => controller.abort();
  }, [id]);

  const onSend = async () => {
    if (!(await trigger())) return;

    setIsLoading((loading) => ({ ...loading, sending: true }));

    try {
      if (!id) {
        throw new Error('The participant id is not specified.');
      }
      await addCustomerParticipant(id, getValues());
      setStatus('completed');
    }
    catch (e) {
      const err = e as TRumpApiRequestError;
      if (!err) return;

      let msg: string = '';

      if ('meta' in err && typeof err.meta.message === 'object') {
        const error = Object.entries(
          err.meta.message as { [key: string]: string },
        )[0];
        const [key, value] = error;
        msg = key && value ? `${key} ${value}` : '';
      }
      else {
        msg
          = 'meta' in err && typeof err.meta.message === 'string'
            ? err.meta.message
            : (err as Error).message;
      }

      if (msg) {
        toastUtil('error', msg);
      }
    }

    setIsLoading((loading) => ({ ...loading, sending: false }));
  };

  const loader = <Loader fullSize color="black" width={80} height={80} />;

  return (
    <div className={styles.eventParticipantDriver}>
      {eventData
        ? (
            <div className={styles.eventParticipantDriver__content}>
              <FormProvider {...form}>
                {!status
                  ? (
                      <SurveyWelcomeStep
                        title={t('eventParticipantDriver.welcome.title')}
                        description={[
                          <Trans
                            key="eventParticipantDriver.welcome.details.firstLine"
                            i18nKey="eventParticipantDriver.welcome.details.firstLine"
                            values={{
                              dateTime: format(
                                new Date(eventData.start_time),
                                'MMMM dd, yyyy',
                                {
                                  locale: dateLocales[i18n.language],
                                },
                              ),
                              location: eventData.lounge.name,
                            }}
                            components={{ 1: <b /> }}
                          />,
                          <Trans
                            key="eventParticipantDriver.welcome.details.secondLine"
                            i18nKey="eventParticipantDriver.welcome.details.secondLine"
                            components={{ 1: <b /> }}
                          />,
                        ]}
                      />
                    )
                  : (
                      ''
                    )}
                {status === 'started' ? <ParticipantForm /> : ''}
                {status === 'completed' ? <ThankYouStep /> : ''}
              </FormProvider>
              {!status
                ? (
                    <Button
                      label={t('eventParticipantDriver.startSurvey')}
                      onClick={() => {
                        setStatus('started');
                      }}
                    />
                  )
                : (
                    ''
                  )}
              {status === 'started'
                ? (
                    <Button
                      label={t('eventParticipantDriver.send')}
                      disabled={!isValid}
                      icon={<ArrowIcon />}
                      onClick={onSend}
                    />
                  )
                : (
                    ''
                  )}
              {isLoading.sending ? loader : ''}
            </div>
          )
        : (
            ''
          )}
      {isLoading.eventData ? loader : ''}
    </div>
  );
};

export default PartcipantSurvey;
