import React, { useCallback, useMemo } from 'react';
import Loader from '@generics/Loader';
import { Typography } from '@generics/content';
import { Card } from '@generics/surfaces';
import { usePageTitle } from '@features/metadata';
import { CustomerMembership } from '@features/products/memberships';
import { Product, useProductPurchaseModal } from '@features/products';
import { useAuthenticatedCustomer } from '@features/authentication';
import { accountMembershipRoute } from './route';
import { useTranslate } from '@features/multi-language';
import { useMembershipsQuery } from '@network/api';
import styles from './MembershipPage.module.scss';

export const MembershipPage = () => {
  const {
    open: openProductPurchaseModal,
  } = useProductPurchaseModal();

  const t = useTranslate();
  const authenticatedCustomer = useAuthenticatedCustomer();
  const memberships = useMembershipsQuery();
  usePageTitle(accountMembershipRoute.title);

  const handleProductPurchaseButtonClick = useCallback((id: string) => {
    openProductPurchaseModal({
      selectedProductType: 'membership',
      selectedProductId: id,
    });
  }, [openProductPurchaseModal]);

  const vat = useMemo(() => {
    return t('labels.pricesAreIncludingVat', memberships.data[0]);
  }, [t, memberships]);

  return (
    <div className={styles.root}>
      <div className={styles.boughtMembership}>
        <Typography type="body/medium/m">labels.yourMembership</Typography>
        <CustomerMembership as={Card} customer={authenticatedCustomer} />
      </div>
      <div className={styles.memberships}>
        {memberships.isFetching ? <Loader height={80} width="100%" /> : null}
        {!memberships.isFetching && memberships.data.length
          ? (
              <>
                <Typography type="body/medium/m">labels.buyMembership</Typography>
                {memberships.data.map((membership) => (
                  <Product
                    key={membership.id}
                    as={Card}
                    id={membership.id}
                    title={membership.name}
                    currency={membership.currency}
                    price={membership.price}
                    onPurchaseButtonClick={handleProductPurchaseButtonClick}
                  >
                    {membership.description}
                  </Product>
                ))}
                <Typography type="body/regular/s" color="secondary">{vat}</Typography>
              </>
            )
          : null}
      </div>
    </div>
  );
};
