import React, { useMemo } from 'react';
import { Box } from '@generics/surfaces';
import * as Models from '@network/api/models';
import { useFormatDatetime } from '@utils/localizing';
import { useMergedClassNames } from '@utils/styling';
import styles from './Notification.module.scss';

export type TNotificationProps = Models.Notification.Data;

export const Notification = (props: TNotificationProps) => {
  const { title, content, seen = false, created_at } = props;

  const formatDatetime = useFormatDatetime();

  const humanizedCreatedAt = useMemo(
    () => formatDatetime({ date: created_at, relativeToDate: new Date() }),
    [formatDatetime, created_at],
  );

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [styles.root_unseen]: !seen,
  });

  return (
    <Box className={rootClassName}>
      <div className={styles.datetime}>{humanizedCreatedAt}</div>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        {seen ? null : <div className={styles.dot} />}
      </div>
      <div className={styles.content}>{content}</div>
    </Box>
  );
};
