import { Tooltip, type TooltipProps } from '@mui/material';
import { withStyles } from '@mui/styles';
import React, { forwardRef } from 'react';

export interface IStyledTooltipProps extends TooltipProps {
  disabled?: boolean;
}

const TooltipWithContent = forwardRef<HTMLDivElement, IStyledTooltipProps>(
  function TooltipWithContent({ disabled, title, children, ...restProps }, ref) {
    return (
      <Tooltip {...restProps} title={disabled ? null : title}>
        <div ref={ref}>{children}</div>
      </Tooltip>
    );
  },
);

const StyledTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#000047 !important',
    color: '#fff',
    boxShadow: 'unset',
    fontSize: '12px !important',
    maxWidth: '500px !important',
    lineHeight: '2.4rem',
  },
  arrow: {
    color: '#000047 !important',
  },
}))(TooltipWithContent);

export default StyledTooltip;
