import React, { HTMLProps } from 'react';
import { useMergedClassNames } from '@utils/styling';
import styles from './Divider.module.scss';

export type TDividerProps = Extend<HTMLProps<HTMLDivElement>, {}>;

export const Divider = (props: TDividerProps) => {
  const { className, ...restRootProps } = props;

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [styles.root_vertical]: true,
    [className || '']: true,
  });

  return <div {...restRootProps} className={rootClassName} />;
};
