const eventParticipantDriver = {
  emptyParticipantsList: 'No participants added.',
  welcome: {
    title: 'Hello!',
    details: {
      firstLine:
        'You\'re going to take part in the event happening on <1>{{dateTime}} in the {{location}} Racing Unleashed Lounge</1>.',
      secondLine:
        'But first we\'ll need some information about you. Fill the form to make your lounge experience seamless. It won\'t take you longer than <1>1 minute</1>.',
    },
    organiserDetails: {
      firstLine:
        'This is a survey for the event happening on <1>{{dateTime}} in the {{location}} Racing Unleashed Lounge</1>.',
      secondLine:
        'We\'ll need some information about participants. Fill the form to make your lounge experience seamless.',
    },
  },
  form: {
    title: 'Participants in your Event',
    subtitle: 'Please add the name and mail address of each participant',
  },
  deleteConfirmation: {
    title: 'Delete participant',
    text: 'Are you sure you want to delete <1>{{name}}</1> from the participants list?\nThis action can\'t be undone.',
    okText: 'Delete',
  },
  fields: {
    firstName: {
      label: 'What\'s your first name?',
      organiserLabel: 'First name',
      placeholder: 'Enter your first name...',
      organiserPlaceholder: 'Enter first name...',
    },
    lastName: {
      label: 'What\'s your last name?',
      organiserLabel: 'Last name',
      placeholder: 'Enter your last name...',
      organiserPlaceholder: 'Enter last name...',
    },
    email: {
      label: 'What\'s your e-mail address?',
      organiserLabel: 'E-mail address',
      placeholder: 'Enter your e-mail address...',
      organiserPlaceholder: 'Enter e-mail address...',
    },
    receiveEmail: {
      label: 'Agreements',
      placeholder: 'I want to receive a newsletter once a year. (optional)',
    },
    surveyLanguage: {
      label: 'Survey Language',
    },
  },
  startSurvey: 'Let\'s start!',
  send: 'Send',
  organiserSend: 'Save and add next',
  save: 'Save',
  thankYou: {
    title: 'Thank you!',
    details: {
      firstLine: 'You\'re set to go!',
      secondLine: 'See you in the lounge!',
      thirdLine: 'Thank you,\nRacing Unleashed Team',
    },
  },
  participantAdded: 'The participant was successfully added',
  participantUpdated: 'The participant was successfully updated',
  participantDeleted: 'The participant was successfully deleted',
  allParticipantsAdded:
    'All participants added.\nYou can still modify the list by deleting or editing participants\' data.',
};

export default eventParticipantDriver;
