const policy = {
  data: `
  <h2 class="TermsPolicy-margin">Política de privacidad</h2>
  <h2>Introducción</h2>
  <p>Recopilación y uso de datos personales. Se consideran datos personales aquellos datos que
  pueden utilizarse para identificar a una persona o ponerse en contacto con un individuo
  particular. Si usted se pone en contacto con compañías asociadas con Racing Unleashed
  AG (en adelante denominada "Racing Unleashed"), quizá se le solicite la provisión de datos
  personales.</p>
  <p>Racing Unleashed AG y sus compañías asociadas podrían intercambiar estos datos
  personales entre sí y utilizarlos de acuerdo con la presente política de privacidad. Asimismo,
  podrán combinar dichos datos con otra información para ofrecer o mejorar nuestros
  productos, servicios, contenido y publicidad.</p>
  <p>Usted no está obligado a proporcionar los datos personales solicitados. No obstante, de no
  hacerlo, en numerosos casos no podremos informarle sobre nuestros productos o servicios
  ni responder a sus preguntas.</p>
  <p class="TermsPolicy-margin">A continuación se proporcionan algunos ejemplos de los diferentes tipos de datos
  personales que Racing Unleashed AG podría recopilar y el modo en que se utilizan:</p>
  <h2>Qué datos personales recopilamos</h2>
  <p>Si usted se pone en contacto con nosotros (por ejemplo a través de redes sociales), visita
  nuestras salas o participa en una encuesta en línea, podríamos recopilar diferentes detalles,
  entre ellos su nombre, dirección, número de teléfono, dirección de correo electrónico, modo
  de contacto preferido, ID de su dispositivo, direcciones IP, ubicación, tarjeta de crédito e
  información sobre su perfil, en caso de que dicho contacto se haya realizado a través de
  redes sociales.</p>
  <p>Con su consentimiento, podríamos procesar su información personal, incluyendo material
  fotográfico y videográfico, para los fines descritos en la presente política de privacidad, para
  cumplir con las obligaciones legales a las que Racing Unleashed esté vinculado, respetar un
  contrato del que usted forme parte, proteger sus intereses esenciales o en caso de que
  determinemos que la revelación de información resulta necesaria para perseguir los
  intereses legítimos de Racing Unleashed o terceros, incluyendo, si corresponde, la
  revelación de información a dichos terceros. Si tiene alguna pregunta sobre las bases
  legales de nuestro procesamiento de datos, puede ponerse en contacto con el encargado de
  protección de datos.</p>
  <p>Los datos personales que recopilamos nos permiten informarle, entre otras cosas, sobre los
  productos más recientes de Racing Unleashed.</p>
  <p>La información personal nos ayuda además a crear, desarrollar, ofrecer, proporcionar,
  facilitar y mejorar nuestros productos, servicios, contenido y publicidad.</p>
  <p>De manera ocasional, podríamos utilizar información personal para enviar mensajes
  importantes, por ejemplo sobre cambios en nuestras políticas, términos y condiciones. Dado
  que esta información es importante para su relación con Racing Unleashed, no podrá
  renunciar a la recepción de la misma.</p>
  <p>Si participa en concursos, sorteos o promociones similares, podríamos utilizar la información
  que haya facilitado para administrar estos programas.</p>
  <p class="TermsPolicy-margin">Si presenta su candidatura para un puesto de trabajo en Racing Unleashed o recibimos
  información sobre usted en relación con un puesto potencial en nuestra empresa, podríamos
  utilizar su información para evaluar su candidatura o ponernos en contacto con usted. Si
  resulta ser un candidato seleccionado, recibirá más información sobre el modo en que
  Racing Unleashed trata los datos personales de las personas que presentan su candidatura
  para un puesto de trabajo.</p>
  <h2>Recopilación y uso de datos no personales</h2>
  <p>También recopilamos datos de un modo que no permite en sí mismo realizar una conexión
  directa con una persona específica. Dicha información no personal podría ser recopilada,
  utilizada, compartida y revelada por nosotros para cualquier fin. A continuación se facilitan
  algunos ejemplos de información no personal que recopilamos y el modo en que podríamos
  utilizarla:</p>
  <p class="TermsPolicy-margin">Recopilamos información como la profesión, idioma preferido, código postal, prefijo
  telefónico, ID de dispositivos individuales, URL de redirección, así como su ubicación y zona
  horaria, para comprender mejor el comportamiento de nuestros clientes y mejorar nuestros
  productos, servicios y publicidad.</p>
  <h2>Cookies y otras tecnologías</h2>
  <p>Los sitios web, servicios en línea, programas interactivos, mensajes de correo electrónico y
  materiales promocionales de Racing Unleashed pueden utilizar las denominadas "cookies",
  así como otras tecnologías como píxeles de seguimiento y balizas web. Estas tecnologías
  nos permiten comprender mejor el comportamiento del usuario, nos informan sobre qué
  partes de nuestros sitios web se visitan y simplifican y miden la eficacia de la publicidad y
  búsquedas web.</p>
  <p>Los datos recopilados por cookies y otras tecnologías se tratan como información no
  personal. No obstante, si la legislación aplicable definiese las direcciones de protocolo de
  Internet (IP) o identificadores similares como datos personales, nosotros también trataremos
  dichos identificadores como datos personales. En consecuencia, si se asocian datos no
  personales con datos personales, trataremos los datos asociados como datos personales a
  los efectos de la presente política de privacidad.</p>
  <p class="TermsPolicy-margin">Racing Unleashed también utiliza cookies y otras tecnologías para registrar datos personales
  cuando usted utiliza nuestro sitio web, servicios en línea o aplicaciones. En este caso,
  nuestro objetivo es buscar que su experiencia con Racing Unleashed sea más sencilla y
  personal.</p>
  <h2>Protección de datos personales</h2>
  <p class="TermsPolicy-margin">Racing Unleashed otorga gran importancia a la seguridad de sus datos personales. Si utiliza
  ciertos productos, servicios o aplicaciones de Racing Unleashed o introduce información
  personal en un foro, sala de chat o en una red social, esta información personal y contenido serán visibles para otros usuarios y estos podrán leer, recopilar o emplear los mismos. Usted
  será el único responsable de los datos personales que comparta o divulgue de este modo.
  Por ejemplo, si publica su nombre y dirección de correo electrónico en un foro, dicha
  información será pública. Por lo tanto, esta función debe utilizarse con cautela.</p>
  <h2>Protección y conservación de información de tarjetas de crédito</h2>
  <p class="TermsPolicy-margin">La información de tarjetas de crédito se utiliza para reservar eventos, experiencias de
  conducción, suscripciones, servicios de valor añadido (por ejemplo formación para pilotaje) y
  carreras. En nuestros sistemas solo se almacenarán los cuatro últimos dígitos y la fecha de
  caducidad de su tarjeta de crédito. Asimismo se guardará el tipo de tarjeta. Únicamente
  utilizaremos los detalles de su tarjeta de crédito con su consentimiento explícito para llevar a
  cabo transacciones de pago.</p>
  <h2>Integridad y conservación de datos personales</h2>
  <p class="TermsPolicy-margin">Conservamos su información personal durante el tiempo necesario para alcanzar los fines
  descritos en esta política de privacidad y las descripciones generales de privacidad
  específicas de nuestros servicios. A la hora de valorar el tiempo requerido para ello,
  consideramos de manera minuciosa en qué medida es necesaria la recopilación de datos
  personales. Si determinamos que dicha recopilación de datos resulta necesaria,
  conservaremos los mismos durante el tiempo absolutamente necesario para el propósito
  para el que se han recogido, a menos que la legislación exija periodos de conservación
  superiores.</p>
  <h2>Preguntas sobre protección de datos</h2>
  <p>En caso de tener alguna pregunta o duda sobre la política de privacidad o procesamiento de
  datos de Racing Unleashed, si desea ponerse en contacto con nuestro encargado de
  protección de datos o si quiere informar sobre una posible violación de la legislación de
  protección de datos local, no dude en ponerse en contacto con nosotros. </p>
  <p>Nuestro encargado de protección de datos revisará su consulta y atenderá su problema. Si
  el problema presenta una naturaleza más trascendental, podríamos solicitarle más
  información. Siempre que resulte posible, todas las consultas trascendentales se
  responderán en un periodo de siete (7) días. Para ello, abordaremos el problema en
  cuestión, solicitaremos más información en caso de necesidad o le informaremos de que
  necesitamos más tiempo para ofrecerle una respuesta. Si en su queja indicase que la
  atención de su problema podría mejorarse a través de la protección de datos, seguiremos
  los pasos necesarios para implementar la misma con la mayor brevedad posible. Si una
  cuestión sobre privacidad tuviese un impacto negativo sobre usted u otra persona,
  seguiremos los pasos apropiados para resolverle el problema a usted o a la otra persona.
  Racing Unleashed tiene derecho a modificar la política de privacidad de Racing Unleashed
  cuando sea necesario. Si se realizasen cambios materiales en la presente política de
  privacidad, se publicará la correspondiente notificación en nuestro sitio web junto a la nueva
  versión de la política de privacidad. Nos pondremos en contacto con usted utilizando la
  información de contacto a nuestra disposición, por ejemplo por correo electrónico, mensaje o
  por medio de otro método similar. </p>
  `,
};

export default policy;
