import React, { useMemo } from 'react';
import { Box } from '@generics/surfaces';
import { Typography } from '@generics/content';
import { useFormatDatetime } from '@utils/localizing';
import styles from './CustomerMembership.module.scss';
import { Models } from '@network/api';

export type TCustomerMembershipProps<RootElementType extends React.ElementType> = {
  as?: RootElementType;
  customer?: Models.Customer.Data | null;
};

export const CustomerMembership = <RootElementType extends React.ElementType = typeof Box>(
  props: PolymorphicComponentPropsWithoutRef<RootElementType, TCustomerMembershipProps<RootElementType>>,
) => {
  const { as, customer, ...restRootProps } = props;

  const formatDatetime = useFormatDatetime();

  const mainMembership = useMemo(
    () => customer?.products
      ?.filter((product) => product.product_type.toLowerCase() === 'membership')
      ?.reduce((prioritizedProduct, productToCompare) => {
        const prioritizedProductLevel = prioritizedProduct
          ? Number(prioritizedProduct?.level)
          : Number.NEGATIVE_INFINITY;
        const productToCompareLevel = Number(productToCompare?.level);
        return productToCompareLevel > prioritizedProductLevel
          ? productToCompare as Models.Membership.Data
          : prioritizedProduct as Models.Membership.Data;
      }, null as Models.Membership.Data | null),
    [customer],
  );

  const formattedExpirationDate = useMemo(
    () => formatDatetime({
      date: mainMembership?.expires_at,
      format: 'datetime.membership.expiringAt',
    }),
    [formatDatetime, mainMembership],
  );

  const RootComponent = useMemo(() => as ?? Box, [as]);

  return (
    <RootComponent {...restRootProps}>
      <div className={styles.header}>
        <Typography type="body/medium/m">{mainMembership?.name}</Typography>
        <Typography type="body/regular/s" color="secondary">
          {formattedExpirationDate}
        </Typography>
      </div>
    </RootComponent>
  );
};
