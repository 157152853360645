import React, { useRef, useCallback, useMemo } from 'react';
import styles from './HorizontalScrollContainer.module.scss';
import { ReactComponent as ArrowIcon } from '../../images/arrow-current-color.svg?tsx';
import { createAnimation } from '../../utils';
import { Tween, Easing } from '@tweenjs/tween.js';
import { mergeRefs } from '../../utils/merge-refs.utils';

export enum EHorizontalScrollDirection {
  Left = 'left',
  Right = 'right',
}

export interface IHorizontalScrollContainerProps {
  children?: React.ReactNode;
  contentElementRef?: React.RefObject<HTMLDivElement>;
}

const HorizontalScrollContainer: React.FC<IHorizontalScrollContainerProps> = (
  props,
) => {
  const { children, contentElementRef } = props;

  const internalContentElementRef = useRef<HTMLDivElement | null>(null);

  const mergedContentElementRef = useMemo(
    () => mergeRefs([internalContentElementRef, contentElementRef]),
    [internalContentElementRef, contentElementRef],
  );

  const scrollAnimationRef = useRef<Tween<{}> | null>(null);

  const scrollTo = useCallback((direction: EHorizontalScrollDirection) => {
    if (!internalContentElementRef.current) return;

    /**
     * Temporary solution to prevent chunky & dragging scrolling.
     *
     * TODO: Re-think toward to additive animation approach.
     */
    if (scrollAnimationRef.current && scrollAnimationRef.current.isPlaying())
      return;

    const animation = createAnimation((timestamp) => {
      if (!scrollAnimationRef.current) return;
      scrollAnimationRef.current.update(timestamp);
    });

    scrollAnimationRef.current = new Tween(internalContentElementRef.current)
      .to({
        scrollLeft:
          direction === EHorizontalScrollDirection.Left
            ? internalContentElementRef.current.scrollLeft
            - internalContentElementRef.current.clientWidth
            : internalContentElementRef.current.scrollLeft
            + internalContentElementRef.current.clientWidth,
      })
      .duration(1000)
      .easing(Easing.Quadratic.InOut)
      .onComplete(animation.end)
      .start();

    animation.start();
  }, []);

  const handleBackClick = useCallback(() => {
    scrollTo(EHorizontalScrollDirection.Left);
  }, [scrollTo]);

  const handleForwardClick = useCallback(() => {
    scrollTo(EHorizontalScrollDirection.Right);
  }, [scrollTo]);

  return (
    <div className={styles.root}>
      <button className={styles.scrollLeftButton} onClick={handleBackClick}>
        <ArrowIcon className={styles.scrollLeftButton__arrowIcon} />
      </button>
      <div className={styles.content} ref={mergedContentElementRef}>
        {children}
      </div>
      <button className={styles.scrollRightButton} onClick={handleForwardClick}>
        <ArrowIcon className={styles.scrollRightButton__arrowIcon} />
      </button>
    </div>
  );
};

export default HorizontalScrollContainer;
