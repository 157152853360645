import { useRef, useEffect, useCallback, useMemo } from 'react';

export function useDisableableEffect(
  effect: (disable: () => void) => void | (() => void),
  deps: React.DependencyList,
) {
  const disabledRef = useRef(false);

  const disable = useCallback(() => {
    disabledRef.current = true;
  }, []);

  const enable = useCallback(() => {
    disabledRef.current = false;
  }, []);

  useEffect(() => {
    if (disabledRef.current) return;
    return effect(disable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disable, ...deps]);

  return useMemo(() => ({ disable, enable }), [disable, enable]);
}
