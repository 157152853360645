import React, { useMemo } from 'react';
import { ClickAwayListener, PopoverProps, Popper } from '@mui/material';
import { merge } from 'lodash';
import SlideUpDialog, { ISlideUpDialogProps } from '@generics/MobileSlideUpDialog/MobileSlideUpDialog';
import { Button } from '@generics/inputs/button';
import { Breakpoint, useBreakpoints } from '@generics/responsiveness';
import { useNotifications } from './NotificationsProvider';
import { Notification } from './Notification';
import { CheckCircleIcon, CrossIcon } from '@images/icons';
import { Card, Scroll } from '@generics/surfaces';
import { Typography } from '@generics/content';
import { mergeClassNames } from '@utils/styling';
import styles from './NotificationsDialog.module.scss';

export type TNotificationsDialogProps = Extend<ISlideUpDialogProps, {
  anchorEl?: PopoverProps['anchorEl'];
}>;

export const NotificationsDialog = (props: TNotificationsDialogProps) => {
  const { anchorEl, classes, ...restRootProps } = props;

  const { isMobile, isDesktop } = useBreakpoints();
  const notifications = useNotifications();

  const mergedClasses = useMemo(() => merge({}, classes, {
    paper: mergeClassNames({
      [styles.paper]: true,
      [styles.paper_desktopMode]: isDesktop,
      [classes?.paper || '']: true,
    }),
  }), [classes, isDesktop]);

  const markAllAsReadButton = useMemo(() => (
    <Button
      design="text"
      label="labels.markAllAsRead"
      icon={<CheckCircleIcon />}
      fullWidth={isMobile}
      isLoading={notifications.isMarkingAllAsRead}
      disabled={!notifications.hasUnseen}
      onClick={notifications.markAllAsRead}
    />
  ), [notifications, isMobile]);

  const content = useMemo(() => (
    <>
      <div className={styles.header}>
        <div className={styles.title}>
          <Typography type="heading/title">labels.notifications</Typography>
          {notifications.hasUnseen ? <div className={styles.title__unseenCounter}>{notifications.unseenCount}</div> : null}
        </div>
        <Breakpoint is="desktop">
          {markAllAsReadButton}
        </Breakpoint>
        <Breakpoint is="mobile">
          <Button
            design="text"
            size="small"
            icon={<CrossIcon />}
            onClick={notifications.closeDialog}
          />
        </Breakpoint>
      </div>
      <Scroll vertical>
        <div className={styles.notifications}>
          {notifications.items.length
            ? (
                notifications.items.map((notification) => (
                  <Notification {...notification} key={notification.id} />
                ))
              )
            : (
                <Typography type="body/regular/m" color="secondary">
                  labels.noNotifications
                </Typography>
              )}
        </div>
      </Scroll>
      <Breakpoint is="mobile">
        <div className={styles.footer}>
          {markAllAsReadButton}
        </div>
      </Breakpoint>
    </>
  ), [markAllAsReadButton, notifications]);

  if (isDesktop) {
    return (
      <Popper
        open={notifications.isDialogOpen}
        anchorEl={anchorEl}
        placement="bottom-end"
        className={styles.popper}
      >
        <ClickAwayListener onClickAway={notifications.closeDialog}>
          <Card className={mergedClasses.paper}>
            {content}
          </Card>
        </ClickAwayListener>
      </Popper>
    );
  }

  return (
    <SlideUpDialog {...restRootProps} classes={mergedClasses}>
      {content}
    </SlideUpDialog>
  );
};
