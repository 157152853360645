import { EnhancedDate } from '@utils/enhanced-date.utils';
import IntersectionObserver from '@researchgate/react-intersection-observer';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { ICalendarDayProps } from './CalendarDay';
import styles from './LinearCalendarChunk.module.scss';
import LinearCalendarDay from './LinearCalendarDay';
import isEmpty from 'lodash/isEmpty';

export type ILinearCalendarChunkProps = {
  dates: EnhancedDate[];
  datesData: Record<
    string,
    Omit<ICalendarDayProps, 'date' | 'selected' | 'onSelect'>
  >;
  mergedDatesData: Record<
    string,
    Omit<ICalendarDayProps, 'date' | 'selected' | 'onSelect'>
  >;
  isDataRequestAllowedWithoutIntersection?: boolean;
  isDataRequestsAllowed?: boolean;
  onDatesDataRequest: (props: { from: EnhancedDate; to: EnhancedDate }) => void;
  onDateSelect: (date: Date) => void;
};

export const LinearCalendarChunk = ({
  dates,
  datesData,
  mergedDatesData,
  isDataRequestAllowedWithoutIntersection,
  isDataRequestsAllowed,
  onDatesDataRequest,
  onDateSelect,
}: ILinearCalendarChunkProps) => {
  const datesBoundaries = useMemo(
    () => dates.length
      ? ({
          startDate: dates[0],
          endDate: dates[dates.length - 1],
        })
      : null,
    [dates],
  );

  const isDatesBoundariesDataFetched = useMemo(() => {
    if (!datesBoundaries || isEmpty(datesData)) return undefined;
    const { startDate, endDate } = datesBoundaries;
    return startDate.isoDate in datesData && endDate.isoDate in datesData;
  }, [datesData, datesBoundaries]);

  const isDataRequestAllowedOnIntersectionRef = useRef(!isDatesBoundariesDataFetched);
  const isIntersectingRef = useRef(false);

  const requestDatesDataRef = useRef(() => {});

  requestDatesDataRef.current = () => {
    if (!isDataRequestsAllowed) return;

    const isStaticDataRequestAllowed
      = datesBoundaries
      && !isDatesBoundariesDataFetched
      && !isIntersectingRef.current
      && isDataRequestAllowedWithoutIntersection;

    const isIntersectionDataRequestAllowed
      = datesBoundaries
      && !isDatesBoundariesDataFetched
      && isIntersectingRef.current
      && isDataRequestAllowedOnIntersectionRef.current;

    if (!isStaticDataRequestAllowed && !isIntersectionDataRequestAllowed) return;
    isDataRequestAllowedOnIntersectionRef.current = false;
    onDatesDataRequest({ from: datesBoundaries.startDate, to: datesBoundaries.endDate });
  };

  const handleDatesDataRequestTrigger = useCallback((entry: IntersectionObserverEntry) => {
    if (!isDataRequestsAllowed) return;
    isIntersectingRef.current = entry.isIntersecting;
    if (!isIntersectingRef.current) return;
    requestDatesDataRef.current();
  }, [isDataRequestsAllowed]);

  useEffect(() => {
    if (!isDataRequestsAllowed || isDatesBoundariesDataFetched) return;
    isDataRequestAllowedOnIntersectionRef.current = true;
    requestDatesDataRef.current();
  }, [isDataRequestsAllowed, isDatesBoundariesDataFetched]);

  return (
    <IntersectionObserver
      threshold={0.005}
      onChange={handleDatesDataRequestTrigger}
    >
      <div className={styles.root}>
        {dates.map((date) => (
          <LinearCalendarDay
            key={date.isoDate}
            {...mergedDatesData[date.isoDate]}
            date={date}
            onSelect={onDateSelect}
          />
        ))}
      </div>
    </IntersectionObserver>
  );
};
