import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../../images/racing-unleashed-logo.svg';
import './ActivationMessage.scss';

interface ActivationMessage {
  msg: string;
  onClose: () => void;
}

const ActivationMessage = ({ msg, onClose }: ActivationMessage) => {
  const [t] = useTranslation();

  return (
    <div className="ActivationMessage">
      <div>
        <img src={logo} alt="Racing Unleashed" />
      </div>
      <div>
        <h3>{t('auth.welcomeText')}</h3>
        <p>{msg}</p>
        <button className="btn secondary s" onClick={onClose}>
          {t('auth.unleashNow')}
        </button>
      </div>
    </div>
  );
};

export default ActivationMessage;
