import React, { createContext, useContext, useMemo } from 'react';

export interface IAccountLayoutContextProps {
  isSideDrawerOpen: boolean;
  openSideDrawer: () => void;
  closeSideDrawer: () => void;
}

export const AccountLayoutContext = createContext<IAccountLayoutContextProps | undefined>(undefined);

export interface IAccountLayoutProviderProps extends IAccountLayoutContextProps {
  children?: React.ReactNode;
};

export const AccountLayoutProvider = (props: IAccountLayoutProviderProps) => {
  const { isSideDrawerOpen, openSideDrawer, closeSideDrawer, children } = props;

  const context = useMemo(() => ({
    isSideDrawerOpen,
    openSideDrawer,
    closeSideDrawer,
  }), [
    isSideDrawerOpen,
    openSideDrawer,
    closeSideDrawer,
  ]);

  return (
    <AccountLayoutContext.Provider value={context}>
      {children}
    </AccountLayoutContext.Provider>
  );
};

export function useAccountLayout() {
  const context = useContext(AccountLayoutContext);

  if (!context) {
    throw new Error('The useAccountLayout() must be used within an <AccountLayoutProvider />');
  }

  return context;
}
