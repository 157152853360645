import { WretchError } from 'wretch';
import { TUseApiQueryProps, useApiClient, useApiQuery } from '@network/api/client';

export type CustomerQuotasResponse = {
  data: {
    bought_membership_id: string;
    limit: {
      customer_limit: {
        show: boolean;
        total: number;
        used: number;
      };
    };
  }[];
};

export type TUseCustomerQuotasQuery = Omit<TUseApiQueryProps<CustomerQuotasResponse['data'], WretchError>, 'queryKey' | 'queryFn'> & {};

export function useCustomerQuotasQuery(props?: TUseCustomerQuotasQuery) {
  const apiClient = useApiClient();

  const { data, ...restQuery } = useApiQuery({
    placeholderData: [],
    ...props,
    queryKey: ['customer', 'quotas', 'all'],
    queryFn: () => apiClient.url(`/customers/quotas?plan_type=membership`)
      .get()
      .json<CustomerQuotasResponse>()
      .then(({ data }) => data),
  });

  return {
    data: data ?? [],
    ...restQuery,
  };
}
