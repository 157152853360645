import React, { ButtonHTMLAttributes, MouseEvent } from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';
import Loader from '../Loader';

interface Button extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  icon?: React.ReactNode;
  iconBefore?: boolean;
  appearance?: 'default' | 'outline';
  color?: 'primary' | 'secondary' | 'error';
  size?: 'small' | 'large';
  fullWidth?: boolean;
  isLoading?: boolean;
  className?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

const Button = ({
  label,
  icon,
  iconBefore = false,
  disabled = false,
  isLoading = false,
  size = 'small',
  appearance = 'default',
  color = 'primary',
  className,
  fullWidth = false,
  onClick = () => {},
  ...props
}: Button) => {
  return (
    <button
      {...props}
      className={classNames(
        styles.button,
        {
          [styles.button__large]: size === 'large',
          [styles.button__primary]: color === 'primary',
          [styles.button__secondary]: color === 'secondary',
          [styles.button__error]: color === 'error',
          [styles.button__outline]: appearance === 'outline',
          [styles.button_fullWidth]: fullWidth,
          [styles.button_reverse]: iconBefore,
        },
        className,
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {
        isLoading
          ? (
              <Loader
                color={color === 'secondary' ? 'dark-blue' : 'white'}
                width={30}
                height={30}
              />
            )
          : (
              <>
                {label ? <span>{label}</span> : null}
                {icon
                  ? (
                      <span
                        className={styles.button__icon}
                      >
                        {icon}
                      </span>
                    )
                  : (
                      ''
                    )}
              </>
            )
      }
    </button>
  );
};

export default Button;
