import React, { ChangeEvent } from 'react';
import { Controller as ReactHookFormController, ControllerRenderProps, FieldValues, InputState, UseControllerOptions } from 'react-hook-form';

type ControllerProps<T extends FieldValues, FieldName extends keyof T> = UseControllerOptions<T> & {
  render: (
    field: Omit<ControllerRenderProps<T>, 'value' | 'onChange'> & {
      value: T[FieldName];
      onChange: (value: T[FieldName] | ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void;
    },
    state: InputState
  ) => React.ReactElement<T, string>;
};

export const Controller = <T extends FieldValues, FieldName extends keyof T>(
  props: ControllerProps<T, FieldName> & { name: FieldName },
) => <ReactHookFormController {...props} />;
