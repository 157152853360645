import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { de, enUS as en, es } from 'date-fns/locale';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import DateRange from '../../../components/Calendar/DateRange';
import { Championship } from '../../../store/championships/championships.types';

import './ChampionshipItem.scss';

interface ChampionshipItem {
  championship: Championship;
}

const langs = {
  de,
  en,
  es,
};

const ChampionshipItem = ({ championship }: ChampionshipItem) => {
  const [t, i18n] = useTranslation();
  const [showCalendar, setShowCalendar] = useState<boolean>(false);

  const toggleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  return (
    <div className="ChampionshipItem">
      <img src={championship.featured_image_medium} alt={championship.title} />
      <div className="ChampionshipItem-header">
        <h3>{championship.title}</h3>
        <span onClick={toggleCalendar}>
          {t(`events.${showCalendar ? 'close' : 'view'}`)}
        </span>
      </div>
      {showCalendar && (
        <div
          className={`ChampionshipItem-calendar ${
            showCalendar ? 'is-visible' : ''
          }`}
        >
          <DateRange championship={championship} />
        </div>
      )}

      <div className="ChampionshipItem-time">
        <div>
          {/* <span>{t('events.lounge')}</span> */}
          <span>{t('events.starts')}</span>
          <span>{t('events.ends')}</span>
        </div>
        <div>
          <span>
            {format(
              new Date(championship.date_from),
              'ccc, dd MMM yyyy @h:mma',
              {
                locale: langs[i18n.language as keyof typeof langs],
              },
            )}
          </span>
          <span>
            {format(new Date(championship.date_to), 'ccc, dd MMM yyyy @h:mma', {
              locale: langs[i18n.language as keyof typeof langs],
            })}
          </span>
        </div>
      </div>
      <div className="ChampionshipItem-body">
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
          {championship.description}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default ChampionshipItem;
