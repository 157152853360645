const dashboard = {
  title: 'Dashboard',
  memberSince: 'Mitglied seit',
  upcomingRides: 'Nächste  Experiences',
  dateTime: 'Datum / Zeit',
  experience: 'Experience',
  experiences: 'Experiences',
  lounge: 'Lounge',
  viewAll: 'Alle Anzeigen',
  laps: 'Runden',
  tracks: 'Rennstrecken',
};

export default dashboard;
