import { WretchError } from 'wretch';
import { TUseApiQueryProps, useApiClient, useApiQuery } from '@network/api/client';
import * as Models from '@network/api/models';

export type PaymentInfoResponse = {
  data: Models.PaymentMethod.Data;
};

export type TUsePaymentInfoQuery = Omit<TUseApiQueryProps<PaymentInfoResponse['data'], WretchError>, 'queryKey' | 'queryFn'> & {};

export function usePaymentInfoQuery(props?: TUsePaymentInfoQuery) {
  const apiClient = useApiClient();

  const { data, ...restQuery } = useApiQuery({
    placeholderData: {
      id: '',
      card_brand: '',
      card_expired: false,
      card_expires_on: '',
      card_number_fragment: '',
      customer_id: '',
    },
    ...props,
    queryKey: ['paymentInfo'],
    queryFn: () => apiClient.url(`/payment_info`)
      .get()
      .json<PaymentInfoResponse>()
      .then(({ data }) => data),
  });

  return {
    data: data ?? {
      id: '',
      card_brand: '',
      card_expired: false,
      card_expires_on: '',
      card_number_fragment: '',
      customer_id: '',
    },
    ...restQuery,
  };
}
