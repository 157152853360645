import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import DayPicker, { DayModifiers, FunctionModifier } from 'react-day-picker';
import { format, formatISO, isAfter, parseISO, endOfDay, isValid } from 'date-fns';
import { DateSelectInputNavbar } from './DateSelectInputNavbar';
import { DateSelectInputWeekday } from './DateSelectInputWeekday';
import {
  DateSelectInputDay,
  EDateSelectInputDayModifier,
  TDateSelectInputDayModifiers,
} from './DateSelectInputDay';
import { useMergedClassNames } from '@utils/styling';
import styles from './DateSelectInput.module.scss';

const VoidComponent = () => null;

export interface IDateSelectInputProps {
  className?: string;
  value?: string | null;
  onChange?: (value: string | null) => void;
  outputFormat?: string;
  futureDisabled?: boolean;
}

export const DateSelectInput = (props: IDateSelectInputProps) => {
  const { value, onChange, outputFormat, futureDisabled = false, className } = props;

  const dayPickerRef = useRef<DayPicker | null>(null);

  const checkIfDayDisabled = useCallback<FunctionModifier>((date: Date) => {
    const today = new Date();

    if (isAfter(date, endOfDay(today))) {
      return futureDisabled;
    };

    return false;
  }, [futureDisabled]);

  const valueAsDate = useMemo(() => value ? parseISO(value) : null, [value]);

  const selectedDays = useMemo(
    () => {
      if (!valueAsDate) return [];
      const isDayDisabled = checkIfDayDisabled(valueAsDate);
      return isDayDisabled ? [] : [valueAsDate];
    },
    [valueAsDate, checkIfDayDisabled],
  );

  const initialMonth = useMemo(
    () => {
      if (!valueAsDate || !isValid(valueAsDate)) return new Date();
      const isDayDisabled = checkIfDayDisabled(valueAsDate);
      if (isDayDisabled) return new Date();
      return valueAsDate;
    },
    [valueAsDate, checkIfDayDisabled],
  );

  const handleDayClick = useCallback((date: Date) => {
    const isDayDisabled = checkIfDayDisabled(date);
    if (isDayDisabled) return;
    const isoDate = outputFormat ? format(date, outputFormat) : formatISO(date);
    onChange?.(isoDate);
  }, [onChange, outputFormat, checkIfDayDisabled]);

  const renderDay = useCallback((date: Date, modifiers: DayModifiers) => (
    <DateSelectInputDay date={date} modifiers={modifiers as TDateSelectInputDayModifiers} />
  ), []);

  useEffect(() => {
    if (!dayPickerRef.current || !valueAsDate || !isValid(valueAsDate)) return;
    const isDayDisabled = checkIfDayDisabled(valueAsDate);
    if (isDayDisabled) return;
    dayPickerRef.current.showMonth(valueAsDate);
  }, [valueAsDate, checkIfDayDisabled]);

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [className || '']: true,
  });

  const classNames = useMemo(() => ({
    container: rootClassName,
    wrapper: styles.wrapper,
    interactionDisabled: '',
    navBar: '',
    navButtonPrev: '',
    navButtonNext: '',
    navButtonInteractionDisabled: '',
    months: styles.months,
    month: styles.month,
    caption: '',
    weekNumber: '',
    weekdays: styles.weekdays,
    weekdaysRow: styles.weekdaysRow,
    weekday: styles.weekday,
    body: styles.body,
    week: styles.week,
    day: styles.day,
    footer: '',
    todayButton: '',
    today: EDateSelectInputDayModifier.Today,
    selected: EDateSelectInputDayModifier.Selected,
    disabled: EDateSelectInputDayModifier.Disabled,
    outside: EDateSelectInputDayModifier.Outside,
  }), [rootClassName]);

  return (
    <DayPicker
      classNames={classNames}
      navbarElement={DateSelectInputNavbar}
      captionElement={VoidComponent}
      weekdayElement={DateSelectInputWeekday}
      renderDay={renderDay}
      initialMonth={initialMonth}
      disabledDays={checkIfDayDisabled}
      selectedDays={selectedDays}
      onDayClick={handleDayClick}
      ref={dayPickerRef}
    />
  );
};
