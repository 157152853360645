import React, { Fragment, useCallback, useMemo } from 'react';
import { usePageTitle } from '@features/metadata';
import { Outlet, useLocation } from 'react-router-dom';
import { TMenuLinkProps, Menu } from '@generics/navigation';
import { accountSettingsProfileRoute } from './profile';
import { accountSettingsChangePasswordRoute } from './change-password';
import { accountSettingsPaymentHistoryRoute } from './payment-history';
import { accountSettingsPaymentMethodsRoute } from './payment-methods';
import { accountSettingsRoute } from './route';
import styles from './SettingsPage.module.scss';
import { Card } from '@generics/surfaces';
import { Typography } from '@generics/content';
import { useBreakpoints } from '@generics/responsiveness';
import { useTranslation } from 'react-i18next';
import { useMergedClassNames } from '@utils/styling';
import { useConfirmationDialog } from '@generics/ConfirmationDialog/useConfirmationDialog';
import { useDeleteCustomerMutation } from '@network/api/endpoints/customers/useDeleteCustomerMutation';
import { accountSettingsLanguageRoute } from './language';
import { ChevronRightIcon } from '@images/icons';

// TODO: Dry menus.

export const SettingsPage = () => {
  const [t] = useTranslation();
  const location = useLocation();
  const { isDesktop, isMobile } = useBreakpoints();
  const isSettingsIndex = location.pathname.endsWith(accountSettingsRoute.path);
  const { openDialog, dialog } = useConfirmationDialog();
  const deleteAccountMutation = useDeleteCustomerMutation();

  // TODO: Display different page titles on mobile.

  const confirmAccountDeletion = useCallback(() => {
    openDialog({
      type: 'delete',
      title: 'labels.deleteAccount',
      text: (
        <div className={styles.confirmation__content}>
          <Typography type="body/medium/l">
            labels.deleteAccountConfirmation.title
          </Typography>
          <Typography type="body/regular/m">
            labels.deleteAccountConfirmation.message
          </Typography>
        </div>
      ),
      cancelText: 'labels.cancel',
      okText: 'labels.delete',
      okIcon: null,
    }).then((isConfirm) => {
      if (!isConfirm) return;
      deleteAccountMutation.mutate(undefined);
    });
  }, [openDialog, deleteAccountMutation]);

  const profileDataMenuLinks = useMemo(
    () => {
      const links = [
        accountSettingsProfileRoute,
        accountSettingsChangePasswordRoute,
      ].map((route) => route.toMenuLinkProps())
        .map((link) => ({
          ...link,
          to: link.to ? link.to.replace(accountSettingsRoute.path, '.') : undefined,
          children: t(link.children),
        })) as TMenuLinkProps[];

      links.push({
        uid: 'delete-account',
        children: t('labels.deleteAccount'),
        onClick: confirmAccountDeletion,
      });

      return links.map((link) => ({
        ...link,
        className: isDesktop ? undefined : styles.mobileMenuLink,
        children: isDesktop
          ? link.children
          : (
              <>
                <div className={styles.mobileMenuLink__content}>
                  {link.children}
                </div>
                <ChevronRightIcon />
              </>
            ),
      }));
    },
    [confirmAccountDeletion, t, isDesktop],
  );

  const paymentsMenuLinks = useMemo(
    () => [
      accountSettingsPaymentMethodsRoute,
      accountSettingsPaymentHistoryRoute,
    ].map((route) => route.toMenuLinkProps())
      .map((link) => ({
        ...link,
        to: link.to.replace(accountSettingsRoute.path, '.'),
        className: isDesktop ? undefined : styles.mobileMenuLink,
        children: isDesktop
          ? t(link.children)
          : (
              <>
                <div className={styles.mobileMenuLink__content}>
                  {t(link.children)}
                </div>
                <ChevronRightIcon />
              </>
            ),
      })),
    [t, isDesktop],
  );

  const applicationMenuLinks = useMemo(
    () => [
      accountSettingsLanguageRoute,
    ].map((route) => route.toMenuLinkProps())
      .map((link) => ({
        ...link,
        to: link.to.replace(accountSettingsRoute.path, '.'),
        className: isDesktop ? undefined : styles.mobileMenuLink,
        children: isDesktop
          ? t(link.children)
          : (
              <>
                <div className={styles.mobileMenuLink__content}>
                  {t(link.children)}
                </div>
                <ChevronRightIcon />
              </>
            ),
      })),
    [t, isDesktop],
  );

  usePageTitle(accountSettingsRoute.title);

  const MenuContainer = useMemo(() => isDesktop ? Card : 'div', [isDesktop]);

  const SubmenuContainer = useMemo(() => isDesktop ? Fragment : Card, [isDesktop]);

  const contentClassName = useMergedClassNames({
    [styles.content]: true,
    [styles.content_hidden]: isSettingsIndex,
  });

  return (
    <div className={styles.root}>
      {dialog}
      {isDesktop || isSettingsIndex
        ? (
            <MenuContainer className={styles.menu}>
              <div className={styles.section}>
                <Typography type="body/medium/s" color="trinary">labels.profileData</Typography>
                <SubmenuContainer>
                  <Menu className={styles.submenu} size="small" links={profileDataMenuLinks} />
                </SubmenuContainer>
              </div>
              <div className={styles.section}>
                <Typography type="body/medium/s" color="trinary">labels.payments</Typography>
                <SubmenuContainer>
                  <Menu className={styles.submenu} size="small" links={paymentsMenuLinks} />
                </SubmenuContainer>
              </div>
              {isMobile
                ? (
                    <div className={styles.section}>
                      <Typography type="body/medium/s" color="trinary">labels.application</Typography>
                      <SubmenuContainer>
                        <Menu className={styles.submenu} size="small" links={applicationMenuLinks} />
                      </SubmenuContainer>
                    </div>
                  )
                : null}
            </MenuContainer>
          )
        : null}
      <div className={contentClassName}>
        <Outlet />
      </div>
    </div>
  );
};
