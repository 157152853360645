const terms = {
  data: `
  <h2>Consent, terms and conditions of use</h2>

  <h2 class="TermsPolicy-margin">Please read the following terms and conditions carefully.</h2>

  <p class="TermsPolicy-margin">By accessing the website of Racing Unleashed AG (www.racing-unleashed.com,
    “Racing Unleashed”), you are declaring that you understand and agree to be bound
    by the terms and conditions of use and legal information set out below in respect of
    the website of Racing Unleashed and all elements contained therein. If you do not
    wish to be bound by the terms and conditions specified below, access to the website
    of Racing Unleashed is expressly prohibited.</p>

  <h2>Ownership of the website, contents and copyrighted material</h2>

  <p class="TermsPolicy-margin">All elements and content included in the website of Racing Unleashed, and in
  particular elements that are protected by copyright and/or other rights, are the
  exclusive and comprehensive property of Racing Unleashed or its licensers,
  provided no information stating otherwise is mentioned. Such elements and content
  are available for browsing purposes only. Information may (in addition to any
  technically necessary temporary cached copy) be downloaded, printed and
  reproduced in any form provided the source is stated in full. Any other act or
  behavior not explicitly mentioned herein, including without limitation the modification,
  publication or public display of any element or content included in the website of
  Racing Unleashed, requires the prior consent of Racing Unleashed. The
  downloading or copying of the website of Racing Unleashed does not confer any
  rights in respect of the software, elements or content of the website of Racing
  Unleashed.</p>

  <h2>Trademarks</h2>

  <p class="TermsPolicy-margin">The Racing Unleashed logo is a registered trademark of Racing Unleashed. The
  website of Racing Unleashed or its use do not confer any rights whatsoever to the
  Racing Unleashed logo.</p>

  <h2>Prohibition of use as point of contact for legal documents and declarations</h2>

  <p class="TermsPolicy-margin">Under no circumstances may the e-mail addresses featured on the website of
  Racing Unleashed be used as an addressee or point of contact for documents,
  notifications or any declaration of a legal nature in respect of Racing Unleashed or its
  employees.</p>

  <h2>No representations, warranties and guarantees</h2>

  <p class="TermsPolicy-margin">While Racing Unleashed takes reasonable steps to ensure the reliability or accuracy
  of the information or content it presents on its website, neither Racing Unleashed nor
  any third-party information or content provider can extend any representations,
  warranties or guaranties, either express or implied, with regard to the accuracy,
  reliability, completeness or up-to-date nature of any information or content on the
  website of Racing Unleashed. Any representations, warranties or guarantees, either
  express or implied, regarding the website of Racing Unleashed, its reliability or
  accuracy and the information and content published on the website are fully
  disclaimed. The information and content published on the website are provided by
  Racing Unleashed exclusively for informational purposes only and are not to be
  reused for commercial purposes. They may change at any time without notice.</p>

  <h2>Exclusion of liability</h2>

  <p class="TermsPolicy-margin">To the fullest extent permitted by law, Racing Unleashed excludes any liability for
  any loss or damage (including indirect and consequential loss or damage) resulting
  from or in any way relating to the website of Racing Unleashed, the accessing of the
  website or of individual elements thereof, the use of the website or its elements, or a
  user being unable to access or prevented from accessing the website or its
  elements.</p>

  <h2>Links to other websites</h2>

  <p class="TermsPolicy-margin">The website of Racing Unleashed may include links to third-party websites. Racing
  Unleashed has not verified all content and elements of such linked websites and,
  consequently, assumes no liability at all in relation to such websites.</p>

  <h2>Applicable law and jurisdiction</h2>
  
  <p>These terms and conditions are governed by, and construed in accordance with, the
  laws of Switzerland, without regard to the conflict of law provisions thereof. Any
  dispute arising out of or in relation to these terms and conditions shall be submitted
  to the exclusive jurisdiction of the courts of Zug, Switzerland.</p>
  `,
};

export default terms;
