import React, { forwardRef, useCallback, useEffect, useMemo, useRef } from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { EInputMode } from '@generics/inputs/types';
import { Box, TBoxProps } from '@generics/surfaces';
import { Typography } from '@generics/content';
import { mergeRefs } from '@utils/merge-refs.utils';
import { useMergedClassNames } from '@utils/styling';
import styles from './StringInput.module.scss';

export enum EStringInputType {
  Plain = 'plain',
  Password = 'password',
}

export type TStringInputProps = Omit<TBoxProps, 'type' | 'value' | 'onChange'> & {
  type?: `${EStringInputType}`;
  value?: string | null;
  guide?: MaskedInputProps['guide'];
  onChange?: (value: string | null) => void;
  onNativeChange?: HTMLProps<HTMLInputElement>['onChange'];
  inputProps?: Omit<HTMLProps<HTMLInputElement>, 'id' | 'ref'>;
  inputRef?: React.Ref<HTMLInputElement>;
  appendix?: React.ReactNode;
  placeholder?: string;
  mode?: `${EInputMode}`;
  readOnly?: boolean;
  mask?: MaskedInputProps['mask'];
  showMask?: MaskedInputProps['showMask'];
  invalid?: boolean;
};

export const StringInput = forwardRef<HTMLDivElement, TStringInputProps>(
  function StringInputWithForwardedRef(props, ref) {
    const {
      type = EStringInputType.Plain,
      guide,
      id,
      value,
      onChange,
      onNativeChange,
      onClick,
      inputMode,
      inputProps,
      inputRef: externalInputRef,
      appendix,
      placeholder,
      mode = EInputMode.Input,
      mask = false,
      showMask = false,
      readOnly = false,
      invalid = false,
      className,
      ...restRootProps
    } = props;

    const internalInputRef = useRef<HTMLInputElement | null>(null);

    const mergeInputRef = useMemo(
      () => mergeRefs([internalInputRef, externalInputRef]),
      [internalInputRef, externalInputRef],
    );

    const valueAsString = useMemo(() => {
      if (typeof value === 'string') return value;
      if ([null, undefined].includes(value)) return '';
      return String(value);
    }, [value]);

    const valueRepresentation = useMemo(() => valueAsString || '-', [valueAsString]);

    const nativeType = useMemo(() => {
      if (type === EStringInputType.Password) return 'password';
      return 'text';
    }, [type]);

    const handleValueChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value.length > 0
          ? event.target.value
          : null;
        onChange?.(newValue);
        onNativeChange?.(event);
      },
      [onChange, onNativeChange],
    );

    const renderMaskedInput = useCallback<Required<MaskedInputProps>['render']>(
      (ref, props) => <input {...props} ref={mergeRefs([ref, mergeInputRef])} />,
      [mergeInputRef],
    );

    useEffect(() => {
      if (!internalInputRef.current) return;
      internalInputRef.current.value = valueAsString;
    }, [valueAsString, mode]);

    const rootClassName = useMergedClassNames({
      [styles.root]: true,
      [styles.root_invalid]: invalid,
      [className || '']: true,
    });

    if (mode === EInputMode.Representation) {
      return (
        <Typography type="body/regular/m">{valueRepresentation}</Typography>
      );
    }

    return (
      <Box
        {...restRootProps}
        className={rootClassName}
        onClick={onClick}
        size="input"
        ref={ref}
      >
        <MaskedInput
          inputMode={inputMode}
          {...inputProps}
          mask={mask}
          showMask={showMask}
          guide={guide}
          id={id}
          className={styles.input}
          type={nativeType}
          placeholder={placeholder}
          onChange={handleValueChange}
          readOnly={readOnly}
          render={renderMaskedInput}
        />
        {appendix}
      </Box>
    );
  },
);
