import React, { forwardRef } from 'react';
import { useMergedClassNames } from '@utils/styling';
import styles from './Box.module.scss';

export enum EBoxSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Input = 'input',
}

export type TBoxExposures = HTMLDivElement;

export type TBoxProps = Extend<HTMLProps<HTMLDivElement>, {
  size?: `${EBoxSize}`;
}>;

export const Box = forwardRef<TBoxExposures, TBoxProps>(
  function BoxWithForwardedRef(props, ref) {
    const {
      size = EBoxSize.Medium,
      children,
      className,
      ...restRootProps
    } = props;

    const rootClassName = useMergedClassNames({
      [styles.root]: true,
      [styles.root_input]: size === EBoxSize.Input,
      [styles.root_small]: size === EBoxSize.Small,
      [styles.root_medium]: size === EBoxSize.Medium,
      [styles.root_large]: size === EBoxSize.Large,
      [className || '']: true,
    });

    return (
      <div
        {...restRootProps}
        className={rootClassName}
        ref={ref}
      >
        {children}
      </div>
    );
  },
);
