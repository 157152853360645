import { WretchError } from 'wretch';
import { TUseApiQueryProps, useApiClient, useApiQuery } from '@network/api/client';
import * as Models from '@network/api/models';

export type SimulatorsResponse = {
  data: Models.Simulator.Data[];
};

export type TUseSimulatorsQuery = Omit<TUseApiQueryProps<SimulatorsResponse['data'], WretchError>, 'queryKey' | 'queryFn'> & {
  loungeId?: string | null;
};

export function useSimulatorsQuery(props?: TUseSimulatorsQuery) {
  const { loungeId } = props ?? {};

  const apiClient = useApiClient();

  const { data, ...restQuery } = useApiQuery({
    placeholderData: [],
    ...props,
    enabled: !!loungeId,
    queryKey: ['simulators', 'all', { loungeId }],
    queryFn: () => apiClient.url(`/simulators/by_lounge/${loungeId}`)
      .get()
      .json<SimulatorsResponse>()
      .then(({ data }) => data),
  });

  return {
    data: data ?? [],
    ...restQuery,
  };
}
