import React from 'react';
import { usePageTitle } from '@features/metadata';
import { accountRankingRoute } from './route';
import Ranking from '@routes/Ranking';

export const RankingPage = () => {
  usePageTitle(accountRankingRoute.title);

  return (
    <Ranking />
  );
};
