import React, { forwardRef, useCallback, useRef } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { StripeCardNumberElement, StripeError } from '@stripe/stripe-js';
import { IStripeCardExposures, TStripeCardProps, StripeCard } from './StripeCard';
import { IStripeCardFieldExposures } from './StripeCardField';

export interface IStripeCardTokenGeneratorExposures extends IStripeCardExposures {};

export interface IStripeCardTokenGeneratorProps extends Omit<TStripeCardProps, 'onChange' | 'onReady' | 'onError'> {
  onChange?: (token: string | null) => void;
  onReady?: () => void;
  onError?: (error: StripeError) => void;
}

export const StripeCardTokenGenerator = forwardRef<IStripeCardTokenGeneratorExposures, IStripeCardTokenGeneratorProps>(
  function StripeCardTokenGeneratorWithForwardedRef(props, ref) {
    const { onChange, onReady, onError, ...restRootProps } = props;

    const stripe = useStripe();
    const numberRootRef = useRef<IStripeCardFieldExposures | null>(null);

    const generateCardToken = useCallback(() => {
      const element = numberRootRef.current?.getElement() as StripeCardNumberElement | null;
      if (!element) return;
      stripe?.createToken(element).then((result) => {
        if (result.error) {
          onError?.(result.error);
          onChange?.(null);
        }
        else {
          onChange?.(result.token?.id ?? null);
        }
      });
    }, [onChange, onError, stripe]);

    const handleComplete = useCallback((complete: boolean) => {
      if (complete) {
        generateCardToken();
      }
      else {
        onChange?.(null);
      }
    }, [generateCardToken, onChange]);

    return (
      <StripeCard
        {...restRootProps}
        onComplete={handleComplete}
        onReady={onReady}
        numberRootRef={numberRootRef}
        ref={ref}
      />
    );
  },
);
