import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

// TODO: Use routes data instead of hardcoded
// TODO: Think about opening notifications popup via query param?.

export const Redirects = () => {
  return (
    <Routes>
      {/* Re-direct legacy routes to new ones */}
      <Route path="/profile" element={<Navigate to="/account/settings/profile" />} />
      <Route path="/change-password" element={<Navigate to="/account/settings/change-password" />} />
      <Route path="/billing" element={<Navigate to="/account/settings/payment-methods" />} />
      <Route path="/membership" element={<Navigate to="/account/membership" />} />
      <Route path="/notifications" element={<Navigate to="/account" />} />

      {/* Not Found */}
      <Route path="*" element={<Navigate to="/account" />} />
    </Routes>
  );
};
