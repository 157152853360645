import React, {
  createContext,
  useContext,
  useRef,
  useCallback,
  useMemo,
} from 'react';

export interface IUpdateIntersectionStatusProps<Value> {
  isIntersecting: boolean;
  identifier: string;
  value: Value;
}

export interface IOnChangeProps<Value> {
  items: Map<string, Value>;
}

export interface IMultipleIntersectionObserverContextValue<Value = unknown> {
  updateIntersectionStatus?: (
    props: IUpdateIntersectionStatusProps<Value>
  ) => void;
}

export const MultipleIntersectionObserverContext = createContext<
  IMultipleIntersectionObserverContextValue
>({});

export function useMultipleIntersectionObserver() {
  return useContext(MultipleIntersectionObserverContext);
}

export interface IMultipleIntersectionObserverProps<Value> {
  children?: React.ReactNode;
  onChange?: (props: IOnChangeProps<Value>) => void;
}

function MultipleIntersectionObserver<Value>(
  props: IMultipleIntersectionObserverProps<Value>,
) {
  const { children, onChange } = props;

  const intersectedItemsMapRef = useRef(new Map<string, Value>());

  const updateIntersectionStatus = useCallback(
    ({
      isIntersecting,
      identifier,
      value,
    }: IUpdateIntersectionStatusProps<Value>) => {
      if (isIntersecting) {
        intersectedItemsMapRef.current.set(identifier, value);
      }
      else {
        intersectedItemsMapRef.current.delete(identifier);
      }

      onChange?.({
        items: new Map(intersectedItemsMapRef.current),
      });
    },
    [onChange],
  );

  const value = useMemo(
    () => ({ updateIntersectionStatus }),
    [updateIntersectionStatus],
  );

  return (
    <MultipleIntersectionObserverContext.Provider value={value as IMultipleIntersectionObserverContextValue}>
      {children as React.ReactElement}
    </MultipleIntersectionObserverContext.Provider>
  );
};

export default MultipleIntersectionObserver;
