import React, { useCallback, useId, useMemo } from 'react';
import get from 'lodash/get';
import { FieldError, useController, useFormContext } from 'react-hook-form';
import { ISelectInputProps, SelectInput } from '@generics/inputs/select';
import { TPasswordInputProps, PasswordInput } from '@generics/inputs/password';
import { StringInput, TStringInputProps } from '@generics/inputs/string';
import { DateInput, IDateInputProps } from '@generics/inputs/date';
import { DateSelectInput, IDateSelectInputProps } from '@generics/inputs/date-select';
import { NumberInput, INumberInputProps } from '@generics/inputs/number';
import { IPhoneInputProps, PhoneInput } from '@generics/inputs/phone';
import { RadioGroupInput, TRadioGroupInputProps } from '@generics/inputs/radio-group';
import { AvatarInput, TAvatarInputProps } from '@generics/inputs/avatar';
import { useTranslate } from '@features/multi-language';
import { Field } from '@generics/surfaces';
import { useFormMode } from './FormModeProvider';

export enum EFormFieldType {
  String = 'string',
  Password = 'password',
  Number = 'number',
  Phone = 'phone',
  Select = 'select',
  Date = 'date',
  DateSelect = 'date-select',
  RadioGroup = 'radio-group',
  Avatar = 'avatar',
}

const fieldsTypesComponents = {
  [EFormFieldType.String]: StringInput,
  [EFormFieldType.Password]: PasswordInput,
  [EFormFieldType.Number]: NumberInput,
  [EFormFieldType.Phone]: PhoneInput,
  [EFormFieldType.Select]: SelectInput,
  [EFormFieldType.Date]: DateInput,
  [EFormFieldType.DateSelect]: DateSelectInput,
  [EFormFieldType.RadioGroup]: RadioGroupInput,
  [EFormFieldType.Avatar]: AvatarInput,
};

type TFormFieldGenericProps = {
  name: string;
  label?: React.ReactNode;
  placeholder?: string;
  onChange?: (value: string | number | null | undefined) => void;
  required?: boolean;
  hideLabel?: boolean;
};

type TFormFieldProps =
  TFormFieldGenericProps & (
    | { type: `${EFormFieldType.String}` } & Omit<TStringInputProps, keyof TFormFieldGenericProps | 'type' | 'ref'>
    | { type: `${EFormFieldType.Password}` } & Omit<TPasswordInputProps, keyof TFormFieldGenericProps | 'type' | 'ref'>
    | { type: `${EFormFieldType.Number}` } & Omit<INumberInputProps, keyof TFormFieldGenericProps | 'type' | 'ref'>
    | { type: `${EFormFieldType.Phone}` } & Omit<IPhoneInputProps, keyof TFormFieldGenericProps | 'type' | 'ref'>
    | { type: `${EFormFieldType.Select}` } & Omit<ISelectInputProps, keyof TFormFieldGenericProps | 'type' | 'ref'>
    | { type: `${EFormFieldType.Date}` } & Omit<IDateInputProps, keyof TFormFieldGenericProps | 'type' | 'ref'>
    | { type: `${EFormFieldType.DateSelect}` } & Omit<IDateSelectInputProps, keyof TFormFieldGenericProps | 'type' | 'ref'>
    | { type: `${EFormFieldType.RadioGroup}` } & Omit<TRadioGroupInputProps, keyof TFormFieldGenericProps | 'type' | 'ref'>
    | { type: `${EFormFieldType.Avatar}` } & Omit<TAvatarInputProps, keyof TFormFieldGenericProps | 'type' | 'ref'>
  );

export const FormField = (props: TFormFieldProps) => {
  const {
    name,
    label,
    placeholder,
    type,
    onChange: onFieldChange,
    hideLabel = false,
    required = false,
    ...restRootProps
  } = props;

  const id = useId();
  const t = useTranslate();
  const { formState } = useFormContext();
  const { field } = useController({
    name,
  });
  const { current: mode } = useFormMode();

  const { errors, touched, isSubmitted } = formState;
  const error = get(errors, name) as FieldError;
  const isTouched = get(touched, name) as boolean;
  const isInvalid = (isSubmitted || isTouched) && Boolean(error);
  const { ref: inputRef, onChange: onInputChange, ...restFieldProps } = field;

  const errorMessage = isInvalid ? error?.message : undefined;

  const translatedLabel = useMemo(
    () => t(label) ?? t(`fields.${name}.label`),
    [t, label, name],
  );

  const translatedPlaceholder = useMemo(
    () => t(placeholder) ?? t(`fields.${name}.placeholder`),
    [t, placeholder, name],
  );

  const handleInputChange = useCallback((value: TFormFieldProps['value']) => {
    onInputChange(value);
    onFieldChange?.(value ?? null);
  }, [onFieldChange, onInputChange]);

  const rootProps = useMemo(
    () => ({ ...restRootProps, ...restFieldProps }),
    [restRootProps, restFieldProps],
  );

  const InputComponent = useMemo(() => fieldsTypesComponents[type], [type]);

  return (
    <Field
      inputId={id}
      label={translatedLabel}
      error={errorMessage}
      required={required}
      hideLabel={hideLabel}
    >
      <InputComponent
        {...rootProps as unknown as Record<string, unknown>}
        id={id}
        inputRef={inputRef}
        onChange={handleInputChange}
        placeholder={translatedPlaceholder}
        invalid={isInvalid}
        mode={mode}
      />
    </Field>
  );
};
