import React, { useMemo } from 'react';
import { stripeClient } from '../../config/api.config';
import { Elements } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { Stripe, StripeElementLocale } from '@stripe/stripe-js';

export type TStripeInternalFetchLocaleProps = {
  locale: string;
};

export type TStripeInternalLocalizeErrorProps = {
  type: string;
  code: string;
};

export type TStripeInternalLocalizeErrorResponseProps =
  {
    error: TStripeInternalLocalizeErrorProps & {
      message: string;
    };
    localized: boolean;
  };

export type TStripeInternalControllerAction = {
  fetchLocale: (props: TStripeInternalFetchLocaleProps) => Promise<void>;
  localizeError: (props: TStripeInternalLocalizeErrorProps) => Promise<TStripeInternalLocalizeErrorResponseProps>;
};

export type TStripeInternalController = {
  action: TStripeInternalControllerAction;
};

export type TStripeWithSomeInternals = Stripe & {
  _controller: TStripeInternalController;
};

export interface IStripeClientProviderProps {
  children?: React.ReactNode;
}

export const StripeClientProvider = (props: IStripeClientProviderProps) => {
  const { children } = props;

  const [, { language }] = useTranslation();

  const stripe = useMemo(
    () => stripeClient.instance ?? stripeClient.promise,
    [],
  );

  const options = useMemo(() => ({
    locale: language as StripeElementLocale,
  }), [language]);

  return (
    <Elements stripe={stripe} options={options}>
      {children}
    </Elements>
  );
};
