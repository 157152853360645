export const GET_CHAMPIONSHIPS = 'GET_CHAMPIONSHIPS';

export interface Championship {
  id: string;
  title: string;
  description: string;
  description_excerpt: string;
  date_from: string;
  date_to: string;
  championship_type: string;
  car_id: string;
  track_id: string;
  featured_image_original: string;
  featured_image_large: string;
  featured_image_medium: string;
  featured_image_small: string;
  active: boolean;
  mobile_url: string;
  championship_phases: ChallegePhase[];
}

export interface ChallegePhase {
  id: string;
  championship_id: string;
  date_from: string;
  date_to: string;
  color: string;
  created_at: string;
  updated_at: string;
  title: string;
}

export interface ChampionshipState {
  championships: Championship[];
}

interface GetChampionshipsAction {
  type: typeof GET_CHAMPIONSHIPS;
  payload: Championship[];
}

export type ChampionshipActionType = GetChampionshipsAction;
