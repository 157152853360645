export default {
  relative: {
    lastWeek: '\'Letzte\' eeee',
    yesterday: '\'Gestern\', HH:mm',
    today: '\'Heute\', HH:mm',
    tomorrow: '\'Morgen\', HH:mm',
    nextWeek: '\'Nächste\' eeee',
    other: 'dd.MM.yyyy, HH:mm',
  },
  membership: {
    expiringAt: '\'läuft ab am\' dd MMMM yyyy',
    expiredAt: '\'abgelaufen am\' dd MMMM yyyy',
  },
  bankCard: {
    expirationDate: '\'Ablaufdatum\' MM / yy',
  },
};
