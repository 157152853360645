import { AxiosError } from 'axios';
import api from '../../config/api.config';

export const updateSubscriptionConfirmation = async (
  token: string,
  controller?: AbortController,
) => {
  try {
    const response = await api.post<TRumpApiResponseData>(
      '/registrations/confirm_subscription',
      {
        token,
      },
      {
        signal: controller && controller.signal,
      },
    );

    return response.data.meta;
  }
  catch (err) {
    const error = err as AxiosError<TRumpApiRequestError>;
    return Promise.reject(
      'response' in error ? error.response?.data : error,
    );
  }
};
