import React from 'react';
import { ChampionshipsIcon } from '@images/icons';
import { createRouteDefinition } from '@utils/routing/createRouteDefinition';

export const accountChampionshipsRoute = createRouteDefinition({
  uid: 'championships',
  path: '/account/championships',
  icon: <ChampionshipsIcon />,
  title: 'labels.championships',
});
