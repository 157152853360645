import React from 'react';
import { StringInput, TStringInputProps } from '@generics/inputs/string';

export type TPasswordInputProps = Extend<TStringInputProps, {}>;

export const PasswordInput = (props: TPasswordInputProps) => {
  const { ...restRootProps } = props;

  return (
    <StringInput
      {...restRootProps}
      type="password"
    />
  );
};
