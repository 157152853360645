import React, { useMemo } from 'react';
import { useTranslate } from '@features/multi-language';
import { useMergedClassNames } from '@utils/styling';
import styles from './Typography.module.scss';

export enum ETypographyType {
  Button = 'button',
  ButtonS = 'button/s',
  HeadingTitle = 'heading/title',
  Heading4 = 'heading/4',
  BodyRegularS = 'body/regular/s',
  BodyRegularM = 'body/regular/m',
  BodyRegularL = 'body/regular/l',
  BodyMediumS = 'body/medium/s',
  BodyMediumM = 'body/medium/m',
  BodyMediumL = 'body/medium/l',
}

export enum ETypographyColor {
  Primary = 'primary',
  Secondary = 'secondary',
  Trinary = 'trinary',
  Danger = 'danger',
  Current = 'current',
}

export type TTypographyProps<RootElementType extends React.ElementType> = {
  as?: RootElementType;
  type?: `${ETypographyType}`;
  color?: `${ETypographyColor}`;
};

export const Typography = <RootElementType extends React.ElementType = 'div'>(
  props: PolymorphicComponentPropsWithoutRef<RootElementType, TTypographyProps<RootElementType>>,
) => {
  const {
    as,
    type,
    color = ETypographyColor.Primary,
    children,
    className,
    ...restRootProps
  } = props;

  const t = useTranslate();

  const RootComponent = useMemo(() => as || 'div', [as]);

  const translatedChildren = useMemo(
    () => t(children as string),
    [t, children],
  );

  const rootClassName = useMergedClassNames({
    [styles.root]: true,

    [styles.root_primaryColor]: color === ETypographyColor.Primary,
    [styles.root_secondaryColor]: color === ETypographyColor.Secondary,
    [styles.root_trinaryColor]: color === ETypographyColor.Trinary,
    [styles.root_dangerColor]: color === ETypographyColor.Danger,
    [styles.root_currentColor]: color === ETypographyColor.Current,

    [styles.root_buttonType]: type === ETypographyType.Button,
    [styles.root_buttonSType]: type === ETypographyType.ButtonS,
    [styles.root_headingTitleType]: type === ETypographyType.HeadingTitle,
    [styles.root_heading4Type]: type === ETypographyType.Heading4,
    [styles.root_bodyRegularSType]: type === ETypographyType.BodyRegularS,
    [styles.root_bodyRegularMType]: type === ETypographyType.BodyRegularM,
    [styles.root_bodyRegularLType]: type === ETypographyType.BodyRegularL,
    [styles.root_bodyMediumSType]: type === ETypographyType.BodyMediumS,
    [styles.root_bodyMediumMType]: type === ETypographyType.BodyMediumM,
    [styles.root_bodyMediumLType]: type === ETypographyType.BodyMediumL,

    [className || '']: true,
  });

  return (
    <RootComponent {...restRootProps} className={rootClassName}>
      {translatedChildren}
    </RootComponent>
  );
};
