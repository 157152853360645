import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslate } from '@features/multi-language';

export type TMetadataContextProps = {
  pageTitle: string | null;
  updatePageTitle: (newPageTitle: string | null) => void;
  backButtonLocation: string | null;
  updateBackButtonLocation: (newPageTitle: string | null) => void;
};

export const MetadataContext = createContext<TMetadataContextProps | undefined>(undefined);

export type TMetadataProviderProps = {
  children?: React.ReactNode;
};

export const MetadataProvider = (props: TMetadataProviderProps) => {
  const { children } = props;

  const t = useTranslate();
  const [pageTitle, setPageTitle] = useState<string | null>(null);
  const [backButtonLocation, setBackButtonLocation] = useState<string | null>(null);

  const updatePageTitle = useCallback((newPageTitle: string | null) => {
    setPageTitle(() => newPageTitle);
  }, []);

  const updateBackButtonLocation = useCallback((newBackButtonLocation: string | null) => {
    setBackButtonLocation(() => newBackButtonLocation);
  }, []);

  const value = useMemo(
    () => ({
      pageTitle: t(pageTitle),
      updatePageTitle,
      backButtonLocation,
      updateBackButtonLocation,
    }),
    [t, pageTitle, updatePageTitle, backButtonLocation, updateBackButtonLocation],
  );

  return (
    <MetadataContext.Provider value={value}>{children}</MetadataContext.Provider>
  );
};

export function useMetadata() {
  const context = useContext(MetadataContext);

  if (!context) {
    throw new Error('The useMetadata() must be used within a <MetadataProvider />');
  }

  return context;
}

export function usePageTitle(pageTitle: string) {
  const { updatePageTitle } = useMetadata();

  useEffect(() => {
    updatePageTitle(pageTitle);
    return () => updatePageTitle(null);
  }, [pageTitle, updatePageTitle]);
}

export function useBackButtonLocation(location: string) {
  const { updateBackButtonLocation } = useMetadata();

  useEffect(() => {
    updateBackButtonLocation(location);
    return () => updateBackButtonLocation(null);
  }, [updateBackButtonLocation, location]);
}
