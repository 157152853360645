import React, { useCallback, useMemo } from 'react';
import omit from 'lodash/omit';
import { CheckIcon } from '@images/icons';
import { useMergedClassNames } from '@utils/styling';
import styles from './SelectInputItem.module.scss';

export type TSelectInputItemProps = Extend<HTMLProps<HTMLDivElement>, {
  value?: string | null;
  onChange?: (value: string | null) => void;
  selected?: boolean;
  shortChildren?: React.ReactNode;
}>;

export const SelectInputItem = (props: TSelectInputItemProps) => {
  const {
    value,
    onChange,
    children,
    selected = false,
    className,
    ...restRootProps
  } = props;

  const restRootPropsWithoutUnusedOnes = useMemo(
    () => omit(restRootProps, ['shortChildren']),
    [restRootProps],
  );

  const handleClick = useCallback(() => {
    onChange?.(typeof value === 'string' ? value : null);
  }, [onChange, value]);

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [className || '']: true,
  });

  return (
    <div
      {...restRootPropsWithoutUnusedOnes}
      className={rootClassName}
      onClick={handleClick}
      tabIndex={0}
    >
      {children}
      {selected ? <CheckIcon /> : null}
    </div>
  );
};
