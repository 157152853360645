import React, { useCallback, useMemo } from 'react';
import omit from 'lodash/omit';
import { LocaleUtils } from 'react-day-picker';
import { Typography } from '@generics/content';
import { Button } from '@generics/inputs/button';
import { ChevronLeftIcon, ChevronRightIcon } from '@images/icons';
import { useFormatDatetime } from '@utils/localizing';
import { useMergedClassNames } from '@utils/styling';
import styles from './DateSelectInputNavbar.module.scss';

export type TDateSelectInputNavbarProps = Extend<HTMLProps<HTMLDivElement>, {
  classNames: Record<string, string>;
  localeUtils: LocaleUtils;
  locale: string;
  labels: string[];
  showPreviousButton: boolean;
  showNextButton: boolean;
  month: Date;
  previousMonth: Date;
  nextMonth: Date;
  onPreviousClick: () => void;
  onNextClick: () => void;
}>;

export const DateSelectInputNavbar = (props: TDateSelectInputNavbarProps) => {
  const {
    month,
    showPreviousButton,
    showNextButton,
    onPreviousClick,
    onNextClick,
    className,
    ...restRootProps
  } = props;

  const formatDatetime = useFormatDatetime();

  const restRootPropsWithoutUnusedOnes = useMemo(
    () => omit(
      restRootProps,
      [
        'previousMonth',
        'nextMonth',
        'classNames',
        'localeUtils',
        'locale',
        'labels',
      ],
    ),
    [restRootProps],
  );

  const monthLabel = useMemo(
    () => formatDatetime({ date: month, format: 'MMMM yyyy' }),
    [formatDatetime, month],
  );

  const handlePreviousButtonClick = useCallback(() => {
    onPreviousClick();
  }, [onPreviousClick]);

  const handleNextButtonClick = useCallback(() => {
    onNextClick();
  }, [onNextClick]);

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [className || '']: true,
  });

  return (
    <div {...restRootPropsWithoutUnusedOnes} className={rootClassName}>
      <Typography
        className={styles.label}
        type="heading/4"
      >
        {monthLabel}
      </Typography>

      <div className={styles.actions}>
        {showPreviousButton
          ? (
              <Button
                design="outline"
                size="small"
                icon={<ChevronLeftIcon />}
                onClick={handlePreviousButtonClick}
              />
            )
          : null}
        {showNextButton
          ? (
              <Button
                design="outline"
                size="small"
                icon={<ChevronRightIcon />}
                onClick={handleNextButtonClick}
              />
            )
          : null}
      </div>
    </div>
  );
};
