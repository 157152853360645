import { Country } from '../country/country.types';

export const GET_LOUNGE = 'GET_LOUNGE';

export interface Lounge {
  id: string;
  name: string;
  opens_at: string;
  closes_at: string;
  created_at: string;
  updated_at: string;
  max_group_size: number;
  max_event_participants: number;
  min_event_participants: number;
  available_simulators: number;
  accepts_cash_only: boolean;
  city: string;
  address1: string;
  country: string;
  zip_code: string;
  lounge_owner: {
    id: string;
    name: string;
    country: Country;
    locale: string;
    currency: string;
    vat: string;
  };
  lat: string;
  lng: string;
  features: LoungeFeature[];
  thank_you_page_text: string;
  thank_you_page_link: string;
  lounge_marketing: string;
  from_price_label: string;
}

export interface LoungeFeature {
  id: string;
  name: string;
  slug: string;
}

export interface LoungeWithDistance extends Lounge {
  distance: number | null;
}

export interface Supervisor {
  id: string;
  first_name: string;
  last_name: string;
  profile_pic_url: string;
  phone_number: string;
}

export interface LoungeState {
  lounge: Lounge[] | [];
}

interface GetLoungeAction {
  type: typeof GET_LOUNGE;
  payload: Lounge[];
}

export type LoungeActionTypes = GetLoungeAction;
