import React from 'react';
import styles from './SignPage.module.scss';
import { Link } from 'react-router-dom';
import Button from '../Button';
import { useTranslation } from 'react-i18next';
import logo from '../../images/ru-logo-blue-medium.svg';
import classNames from 'classnames';

interface ISignPageProps {
  isSignUp?: boolean;
  onlyButton?: boolean;
  children?: React.ReactNode;
}

const SignPage: React.FC<ISignPageProps> = ({ children, ...props }) => {
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <img className={styles.header__logo} src={logo} alt="Racing Unleashed" />
        <SignBlock {...props} />
      </div>
      <div className={styles.root__content}>
        {children}
        <div className={styles.root__divider} />
        <SignBlock isMobile {...props} />
      </div>
    </div>
  );
};

interface ISignBlockProps extends Pick<ISignPageProps, 'isSignUp' | 'onlyButton'> {
  isMobile?: boolean;
}

const SignBlock: React.FC<ISignBlockProps> = ({ isSignUp, onlyButton, isMobile }) => {
  const [t] = useTranslation();

  return (
    <div className={classNames(styles.signBlock, { [styles.signBlock_mobile]: isMobile })}>
      {!onlyButton && (
        <span className={styles.header__text}>
          {t(isSignUp ? 'auth.authAction.signIn' : 'auth.authAction.signUp')}
        </span>
      )}
      <Link
        to={`/auth/${isSignUp ? 'sign-in' : 'sign-up'}`}
      >
        <Button
          appearance="outline"
          label={t(
            isSignUp ? 'auth.authAction.btnSignIn' : 'auth.authAction.btnSignUp',
          )}
        />
      </Link>
    </div>
  );
};

export default SignPage;
