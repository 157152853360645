import React, { useMemo, useState } from 'react';
import styles from './LocationStep.module.scss';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../components/Button';
import { ReactComponent as MapIcon } from '../../../../../images/map.svg?tsx';
import { ReactComponent as ArrowIcon } from '../../../../../images/arrow-current-color.svg?tsx';
import SearchBox from '../../../../../components/SearchBox';
import { useLoungeSearch } from '../../../../../hooks/useLoungeSearch';
import MapDialog from '../../MapDialog/MapDialog';
import { Lounge } from '../../../../../store/lounge/lounge.types';
import Loader from '../../../../../components/Loader';
import LoungeCard from '../../../../../components/LoungeCard/LoungeCard';

type TLocationStepProps = {
  onSelectLounge: (lounge: Lounge) => void;
};

const LocationStep: React.FC<TLocationStepProps> = ({ onSelectLounge }) => {
  const { filteredLounges, search, setSearch } = useLoungeSearch();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [t] = useTranslation();

  const closeDialog = () => setDialogOpen(false);
  const openDialog = () => setDialogOpen(true);
  const onLoungeSelect = (lounge: Lounge) => {
    closeDialog();
    onSelectLounge(lounge);
  };

  const isLoading = useMemo(
    () => !filteredLounges.length && !search,
    [filteredLounges, search],
  );

  return (
    <div className={styles.locationStep}>
      <div className={styles.locationStep__header}>
        <h3 className={styles.locationStep__title}>
          {t('booking.steps.location.title')}
        </h3>
        <Button
          appearance="outline"
          label={t('booking.steps.location.mapButton')}
          icon={<MapIcon />}
          onClick={openDialog}
        />
      </div>
      <SearchBox
        className={styles.locationStep__search}
        value={search}
        onChange={setSearch}
      />
      <div className={styles.locationStep__loungeList}>
        {isLoading ? <Loader fullSize width={80} height={80} /> : ''}
        {filteredLounges.map((lounge) => (
          <LoungeCard
            key={lounge.id}
            lounge={lounge}
            priceValue={lounge.from_price_label}
            description={lounge.lounge_marketing}
            features={lounge.features}
            proceedButtonIcon={<ArrowIcon />}
            proceedButtonLabel={t('booking.steps.location.forwardButton')}
            onProceed={onLoungeSelect}
          />
        ))}
      </div>
      <MapDialog
        open={dialogOpen}
        onClose={closeDialog}
        onLoungeSelect={onLoungeSelect}
      />
    </div>
  );
};

export default LocationStep;
