import React, { useMemo } from 'react';
import { useCountriesQuery } from './useCountriesQuery';
import { Image, Typography } from '@generics/content';
import styles from './useCountriesSelection.module.scss';

export function useCountriesSelection() {
  const { data: countries } = useCountriesQuery();

  const countriesSelection = useMemo(() => countries.map((country) => ({
    value: country.id,
    children: (
      <div className={styles.item}>
        <Image
          className={styles.item__flag}
          aspectRatio={1}
          src={country.flag_picture_url}
          alt={`${country.label} Flag`}
        />
        <Typography type="body/regular/m">{country.label}</Typography>
      </div>
    ),
  })), [countries]);

  return { countriesSelection };
}
