import React, { useEffect, useRef, useState } from 'react';
import styles from './AppPhoneNumberField.module.scss';
import Select, { SelectRenderer } from 'react-dropdown-select';
import { Country } from '../../store/country/country.types';
import { List, AutoSizer } from 'react-virtualized';
import SearchBox from '../SearchBox';
import useWithSelection from '../../hooks/useWithSelection';
import { countrySelector } from '../../store/country/country.selectors';
import { isSubstring } from '../../utils/is-substring.utils';
import classNames from 'classnames';

interface IAppPhoneNumberFieldProps {
  value: string;
  defaultCountryId?: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
}

const searchHeight = 60;
const itemHeight = 40;

const AppPhoneNumberField: React.FC<IAppPhoneNumberFieldProps> = ({
  value,
  defaultCountryId,
  onChange,
  onBlur,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [search, setSearch] = useState<string>('');
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const countries: Country[] = useWithSelection(countrySelector);

  useEffect(() => {
    if (selectedCountry || !defaultCountryId) return;

    setSelectedCountry(
      countries.find(({ id }) => id === defaultCountryId) || null,
    );
    // eslint-disable-next-line
  }, [countries, defaultCountryId]);

  useEffect(() => {
    if (!value) return;

    const prefix = value.split(' ')[0];
    const country = countries.find(({ phone_prefix }) =>
      prefix.includes(phone_prefix),
    );
    setSelectedCountry(country || null);
  }, [value, countries]);

  const contentRenderer: (props: SelectRenderer<Country>) => JSX.Element = ({
    state,
  }) => {
    return state.values[0]
      ? (
          <img
            className={styles.option__image}
            src={state.values[0].flag_picture_url}
            alt=""
          />
        )
      : (
          <></>
        );
  };

  const dropdownRenderer: (props: SelectRenderer<Country>) => JSX.Element = ({
    methods,
  }) => {
    const filteredCountries = search
      ? countries.filter(({ label }) => isSubstring(label, search))
      : countries;

    const listHeight = filteredCountries.slice(0, 5).length * itemHeight;

    return (
      <div style={{ height: listHeight + searchHeight }}>
        <SearchBox
          className={styles.search}
          value={search}
          onChange={setSearch}
        />
        <AutoSizer style={{ height: '200px' }}>
          {({ width }) => (
            <List
              className={styles.list}
              height={listHeight}
              rowCount={filteredCountries.length}
              rowHeight={itemHeight}
              width={width - 2}
              rowRenderer={({ index, style, key }) => {
                const item = filteredCountries[index];

                return (
                  <div
                    key={key}
                    className={classNames(styles.option, {
                      [styles.option_selected]:
                        selectedCountry && selectedCountry.id === item.id,
                    })}
                    style={style}
                    onClick={() => methods.addItem(item)}
                  >
                    <img
                      className={styles.option__image}
                      src={item.flag_picture_url}
                      alt=""
                    />
                    {item.label}
                  </div>
                );
              }}
            />
          )}
        </AutoSizer>
      </div>
    );
  };

  const phoneNumber
    = value && selectedCountry
      ? value.replace(`+${selectedCountry.phone_prefix} `, '')
      : '';

  return (
    <div
      className={styles.appPhoneNumberField}
      onClick={() => inputRef.current && inputRef.current.focus()}
    >
      <div className={styles.appPhoneNumberField__selectWrapper}>
        <Select
          className={styles.appPhoneNumberField__select}
          placeholder=""
          searchable={false}
          contentRenderer={contentRenderer}
          dropdownRenderer={dropdownRenderer}
          options={countries}
          values={selectedCountry ? [selectedCountry] : []}
          onDropdownClose={() => setSearch('')}
          onChange={(selectedCountries) => {
            onChange(
              value
                ? `+${selectedCountries[0].phone_prefix} ${value.split(' ')[1]}`
                : '',
            );
            setSelectedCountry(selectedCountries[0]);
          }}
        />
      </div>
      <div className={styles.appPhoneNumberField__inputWrapper}>
        {selectedCountry
          ? (
              <span className={styles.appPhoneNumberField_code}>
                +
                {selectedCountry.phone_prefix}
              </span>
            )
          : (
              ''
            )}
        <input
          ref={inputRef}
          className={styles.appPhoneNumberField__input}
          value={phoneNumber}
          onChange={({ target: { value } }) =>
            onChange(
              selectedCountry && value
                ? `+${selectedCountry.phone_prefix} ${value}`
                : '',
            )}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
};

export default AppPhoneNumberField;
