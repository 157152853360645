import { customerSelector } from '../../store/customer/customer.selectors';
import useWithSelection from '../../hooks/useWithSelection';
import { useCallback, useEffect } from 'react';
import { subscribe, unsubscribe } from '../../events';
import { postMembershipConvert } from '../../store/membership/membership.actions';

const PIXEL_ID_1 = 4997252593682784;
const PIXEL_ID_2 = 1535302823623494;

interface FacebookEventData {
  detail: Record<string, unknown>;
}

const useFacebookTracker = () => {
  // Runs on every page load/change
  const customer = useWithSelection(customerSelector);

  const user_data = customer && customer.email
    ? {
        em: customer.email,
        external_id: customer.id,
        fn: customer.first_name,
        ln: customer.last_name,
      }
    : null;

  const handleInitiateCheckout = useCallback((data: FacebookEventData) => {
    window.fbq('track', 'InitiateCheckout', data.detail);
  }, []);
  const handlePurchase = useCallback((data: FacebookEventData) => {
    data.detail.event_id = new Date().getTime();
    data.detail.source_url = window.location.href;
    window.fbq('track', 'Purchase', data.detail, { eventID: data.detail.event_id });
    postMembershipConvert(data.detail);
  }, []);
  const handleLogin = useCallback((data: FacebookEventData) => {
    window.fbq('init', PIXEL_ID_1, {});
    window.fbq('addPixelId', PIXEL_ID_2, {});
    window.fbq('init', PIXEL_ID_1, data.detail);
    window.fbq('addPixelId', PIXEL_ID_2, data.detail);
    window.fbq('trackCustom', 'SignIn');
  }, []);
  const handleRegistration = useCallback((data: FacebookEventData) => {
    window.fbq('track', 'CompleteRegistration', data.detail);
  }, []);

  useEffect(() => {
    subscribe('InitiateCheckout', handleInitiateCheckout);
    subscribe('Purchase', handlePurchase);
    subscribe('Login', handleLogin);
    subscribe('Registration', handleRegistration);

    return () => {
      unsubscribe('InitiateCheckout', handleInitiateCheckout);
      unsubscribe('Purchase', handlePurchase);
      unsubscribe('Login', handleLogin);
      unsubscribe('Registration', handleRegistration);
    };
  }, [handleInitiateCheckout, handlePurchase, handleLogin, handleRegistration]);

  useEffect(() => {
    if (window.fbq && window.fbq.getState && window.fbq.getState().pixels.length === 0) {
      console.log('Customer is accessing site: ', user_data);
      window.fbq('init', PIXEL_ID_1, user_data);
      window.fbq('addPixelId', PIXEL_ID_2, user_data);
    }
    // eslint-disable-next-line
    }, []);

  useEffect(() => {
    window.fbq('track', 'PageView');
  });
};

export default useFacebookTracker;
