import React, { memo } from 'react';
import CalendarDay, { ICalendarDayProps } from './CalendarDay';
import { EnhancedDate } from '../../utils/enhanced-date.utils';
import MultipleIntersectionObserverItem from '../MultipleIntersectionObserver/MultipleIntersectionObserverItem';

export interface ILinearCalendarDayProps
  extends Omit<ICalendarDayProps, 'date'> {
  date: EnhancedDate;
}

const LinearCalendarDay: React.FC<ILinearCalendarDayProps> = memo(
  function LinearCalendarDay(props) {
    const { date } = props;

    return (
      <MultipleIntersectionObserverItem
        identifier={date.isoDate}
        value={date.isoMonth}
        threshold={0.5}
      >
        <CalendarDay {...props} date={date} />
      </MultipleIntersectionObserverItem>
    );
  },
);

export default LinearCalendarDay;
