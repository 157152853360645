import React from 'react';
import { usePageTitle } from '@features/metadata';
import { accountChampionshipsRoute } from './route';
import Championships from '@routes/Championships';

export const ChampionshipsPage = () => {
  usePageTitle(accountChampionshipsRoute.title);

  return (
    <Championships />
  );
};
