import React, { useEffect, useState } from 'react';
import { useFormik, FormikHelpers } from 'formik';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toastUtil } from '../../../utils/toast.utils';
import { signUp } from '../../../store/customer/customer.actions';
import { formModel, initialValues, ISignUpFormValues } from './formModel';
import FormField from '../../../components/FormField';
import CheckboxRadio from '../../../components/FormField/CheckboxRadio';
import { useParams } from 'react-router-dom';
import Button from '../../../components/Button';
import validationSchema from './validationSchema';
import mailbox from '../../../images/mailbox.svg';
import styles from './SignUpForm.module.scss';
import { publish } from '../../../events';
import validationSchemaSilentMember from './validationSchemaSilentMember';
import { loungeSelector } from '../../../store/lounge/lounge.selectors';
import useWithSelection from '../../../hooks/useWithSelection';
import { getLounge } from '../../../store/lounge/lounge.actions';
import useWithDispatch from '../../../hooks/useWithDispatch';
import { FormModelField } from '../../../types/form.types';
import AuthFormMain from '../../../components/AuthFormMain';

type ParamTypes = {
  customer_id: string;
  token: string;
};

const FORM_ID = 'signUpForm';

const SignUpForm = () => {
  const { customer_id, token } = useParams<ParamTypes>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [customerEmail, setCustomerEmail] = useState<string | null>(null);
  const [confirmText, setConfirmText] = useState<string>('');
  const loungeDispatch = useWithDispatch(getLounge);
  const lounges = useWithSelection(loungeSelector);
  const [, setRefresh] = useState(false);
  const [t] = useTranslation();
  let silentMemberRegistration = false;
  let schema;
  if (customer_id && token) {
    silentMemberRegistration = true;
    initialValues.customer_id = customer_id;
    initialValues.token = token;
    schema = validationSchemaSilentMember;
  }
  else {
    schema = validationSchema;
  }

  useEffect(() => {
    loungeDispatch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (lounges.length === 0) {
      return;
    }

    formModel.forEach((field: FormModelField) => {
      if (field.name === 'default_lounge_id') {
        field.list = [];
        lounges.forEach((lounge) => {
          if (field.list) {
            field.list.push({ id: lounge.id, label: lounge.name });
          }
        });
      }
    });

    // Trigger re-render
    setRefresh((prev) => !prev);
  }, [lounges]);

  const formik = useFormik<ISignUpFormValues>({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values, formMethods: FormikHelpers<ISignUpFormValues>) => {
      try {
        setSubmitting(true);
        const response = await signUp(values);
        const responseMeta = response?.meta;
        if (typeof responseMeta?.message === 'string')
          setConfirmText(responseMeta.message);
        setCustomerEmail(responseMeta.email);
        if (values) {
          publish('Registration', {
            em: values.email,
            fn: values.first_name,
            ln: values.last_name,
          });
        }
      }
      catch (error) {
        setSubmitting(false);
        const err = error as TRumpApiErrorResponseData;
        if (err?.meta?.errors) {
          err.meta.errors.forEach((e) => {
            return formMethods.setFieldError(e.field, e.messages[0]);
          });
        }
        if (typeof err?.meta?.message === 'string')
          toastUtil('error', err.meta.message);
      }
    },
  });
  const isValidForm = schema.isValidSync({ ...formik.values });

  return (
    <div className={styles.root}>
      {customerEmail
        ? (
            <div className={styles.root__success}>
              <img src={mailbox} alt="" />
              <p>
                {confirmText}
                {' '}
                <Link to="/auth/sign-in">{t('auth.backToSignIn')}</Link>
              </p>
            </div>
          )
        : (
            <AuthFormMain
              title={!silentMemberRegistration
                ? 'auth.signUp.title'
                : 'auth.signUp.titleSilentMember'}
              subtitle="auth.signUp.subtitle"
              footer={(
                <Button
                  form={FORM_ID}
                  type="submit"
                  isLoading={submitting}
                  disabled={submitting || !isValidForm}
                  label={
                    !silentMemberRegistration
                      ? t('field.signUp')
                      : t('field.signUpSilentMember')
                  }
                />
              )}
            >
              <form id={FORM_ID} className={styles.root__form} onSubmit={formik.handleSubmit}>
                {formModel.map((field) =>
                  (![
                    'first_name',
                    'last_name',
                    'email',
                    'customer_id',
                    'token',
                  ].includes(field.name)
                  && silentMemberRegistration)
                  || (!['customer_id', 'token'].includes(field.name)
                  && !silentMemberRegistration)
                    ? (
                        <FormField
                          className={styles.root__formField}
                          key={field.name}
                          i18nKey={field.i18nKey}
                          type={field.type}
                          errors={formik.errors}
                          touched={formik.touched}
                          list={field.list}
                          setFieldValue={formik.setFieldValue}
                          {...formik.getFieldProps(field.name)}
                        />
                      )
                    : (
                        false
                      ),
                )}
                <div className={styles.root__checkboxes}>
                  <CheckboxRadio
                    onChange={formik.handleChange}
                    name="age"
                    value={formik.values.age}
                    i18nKey="field.signUpAge"
                  />
                  <CheckboxRadio
                    onChange={formik.handleChange}
                    name="tos_accepted"
                    value={formik.values.tos_accepted}
                  >
                    <Trans
                      i18nKey="field.tos.label"
                      components={{
                        tosLink: <Link to="/terms-of-service" target="_blank" />,
                        privacyLink: <Link to="/privacy-policy" target="_blank" />,
                      }}
                    />
                  </CheckboxRadio>
                </div>
              </form>
            </AuthFormMain>
          )}
    </div>
  );
};

export default SignUpForm;
