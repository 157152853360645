import React, { useCallback, useMemo, useState } from 'react';
import { Card } from '@generics/surfaces';
import { UpcomingRideBox } from './UpcomingRideBox';
import { useUpcomingRidesQuery } from '@network/api';
import styles from './UpcomingRidesCard.module.scss';
import { Typography } from '@generics/content';
import { useBreakpoints } from '@generics/responsiveness';
import { Button } from '@generics/inputs/button';
import { ArrowDownIcon } from '@images/icons';
import { useTranslation } from 'react-i18next';

export const UpcomingRidesCard = () => {
  const { data: rides } = useUpcomingRidesQuery();

  const [t] = useTranslation();
  const { isDesktop } = useBreakpoints();
  const [isAllDisplayed, setIsAllDisplayed] = useState(false);

  const minAmountOfDisplayedRides = isDesktop ? 6 : 2;

  const displayedRides = useMemo(
    () => isAllDisplayed ? rides : rides.slice(0, minAmountOfDisplayedRides),
    [isAllDisplayed, rides, minAmountOfDisplayedRides],
  );

  const handleShowAllButtonClick = useCallback(() => {
    setIsAllDisplayed(true);
  }, []);

  if (!displayedRides.length) return null;

  return (
    <Card>
      <Typography type="body/medium/m">labels.yourUpcomingExperiences</Typography>
      <div className={styles.items}>
        {displayedRides.map((ride) => (
          <UpcomingRideBox key={ride.id} ride={ride} />
        ))}
      </div>
      {isAllDisplayed || rides.length <= minAmountOfDisplayedRides
        ? null
        : (
            <Button
              design="outline"
              label={t('draftEvents.viewAll')}
              icon={<ArrowDownIcon />}
              onClick={handleShowAllButtonClick}
              fullWidth
            />
          )}
    </Card>
  );
};
