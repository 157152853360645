const field = {
  identifier: {
    label: 'Correo electrónico',
    required: 'E-mail obligatorio',
  },
  email: {
    label: 'Correo electrónico*',
    labelWithoutStar: 'Correo electrónico',
    placeholder: 'Introduce tu e-mail...',
    required: 'E-mail obligatorio',
    invalidEmail: 'Correo electrónico inválido',
  },
  first_name: {
    label: 'Nombre*',
    required: 'Esta casilla es obligatoria.',
  },
  last_name: {
    label: 'Apellido*',
    required: 'Esta casilla es obligatoria.',
  },
  name_and_surname: {
    label: 'Nombre y apellidos',
    required: 'Nombre y apellidos obligatorios',
    placeholder: 'Introduzca nombre y apellidos...',
  },
  nickname: {
    label: 'Apodo*',
    required: 'Esta casilla es obligatoria.',
  },
  password: {
    label: 'Contraseña*',
    labelWithoutStar: 'Contraseña',
    placeholder: 'Introduzca su contraseña...',
    required: 'Contraseña obligatoria',
    minLength: 'La contraseña debe tener al menos 8 caracteres.',
  },
  password_confirmation: {
    label: 'Confirmar contraseña*',
    required: 'Esta casilla es obligatoria.',
    minLength: 'La contraseña debe tener al menos 8 caracteres.',
    matching: 'Las contraseñas no concordan.',
  },
  preferred_language: {
    label: 'Idioma preferido',
    required: 'Esta casilla es obligatoria.',
  },
  default_lounge_id: {
    label: 'Lounge*',
    required: 'Esta casilla es obligatoria.',
  },
  phone_number: {
    label: 'Número de teléfono',
    required: 'Número de teléfono obligatorio',
    invalid: 'El número de teléfono no es válido',
  },
  address1: {
    label: 'Dirección',
    required: 'Esta casilla es obligatoria.',
  },
  address2: {
    label: 'Línea de dirección 2',
    required: 'Esta casilla es obligatoria.',
  },
  country_id: {
    label: 'País',
    required: 'Se requiere provincia',
    companyPlaceholder: 'Seleccione el país de la empresa...',
  },
  city: {
    label: 'Ciudad',
    required: 'Ciudad requerida',
    companyPlaceholder: 'Introduzca la ciudad de la empresa...',
  },
  zip_code: {
    label: 'Código postal',
    companyPlaceholder: 'Introduzca el código postal de la empresa...',
  },
  region: {
    label: 'Región',
    companyPlaceholder: 'Introduzca la región de la empresa...',
  },
  pob_country_id: {
    label: 'País de nacimiento',
    required: 'Esta casilla es obligatoria.',
  },
  fav_lounge_id: {
    label: 'Salón favorito',
  },
  fav_simulator_id: {
    label: 'Simulador favorito',
  },
  body_weight: {
    label: 'Peso corporal (kg)',
  },
  body_height: {
    label: 'Altura del cuerpo (cm)',
  },
  company: {
    label: 'Compañía',
    placeholder: 'Introduzca el nombre de la empresa...',
    required: 'Nombre de la empresa obligatorio',
  },
  remember_me: {
    label: 'Permanecer conectado.',
  },
  tos: {
    label:
      'Acepto los <tosLink>Términos de servicio</tosLink> y <privacyLink>Política de privacidad y uso de cookies</privacyLink>',
  },
  lounge_id: {
    label: 'Lounge',
  },
  dob: {
    label: 'Fecha de nacimiento',
  },
  monthSelector: {
    label: 'Fecha',
  },
  carSelector: {
    label: 'Coche de carrera',
  },
  trackSelector: {
    label: 'Circuitos',
  },
  search: {
    placeholder: 'Buscar...',
  },
  saveCardInformation: 'Guarde la información de su tarjeta para más tarde',
  change: 'cambiar',
  update: 'Actualizar',
  signUpAge: 'Soy mayor que 13 años.',
  submit: 'Adoptar',
  submiting: 'En elaboración ...',
  signIn: 'Sign In',
  signUp: 'Sign Up',
  signUpSilentMember: 'Activar',
  updateProfile: 'Aceptar perfil',
  forgotPassword: 'Restablecer contraseña',
  resetPassword: 'Introducir nueva contraseña',
  signingIn: 'Signing In...',
  signingUp: 'Signing Up...',
  callToSignUp: '¿No tienes cuenta todavía? <1>Regístrate ahora!<1>',
  callToSignIn: '¿Ya tienes cuenta?<1>Ingresar ahora!<1>',
  firstLine: {
    label: 'Primera línea',
    placeholder: 'Introduzca el texto aquí...',
    required: 'Primera línea obligatoria',
  },
  secondLine: {
    label: 'Segunda línea',
    placeholder: 'Introduzca el texto aquí...',
    required: 'Segunda línea obligatoria',
  },
  fileUpload: {
    caption: 'Aceptamos archivos {{formats}}',
    uploaded: 'Archivo Cargado',
    delete: 'Borrar Archivo',
    required: 'Archivo necesario',
  },
  organiserName: {
    label: 'Nombre',
    placeholder: 'Introduzca el nombre del organizador...',
    required: 'Nombre del organizador obligatorio',
  },
  organiserEmail: {
    label: 'Correo electrónico',
    placeholder: 'Introduzca el correo electrónico del organizador...',
    required: 'Correo electrónico del organizador obligatorio',
    invalid: 'El correo electrónico del organizador no es válido',
  },
  companyAddressLine: {
    label: 'Adresse Zeile {{number}}',
    placeholder: 'Adresse eingeben...',
    required: 'Adresse ist erforderlich',
    companyLabel: 'Dirección de la empresa Línea {{number}}',
    companyPlaceholder: 'Introduzca la dirección de la empresa...',
    companyRequired: 'Dirección de la empresa',
  },
  taxNumber: {
    label: 'Número de identificación fiscal',
    placeholder:
      'Introduzca el número de identificación fiscal de la empresa...',
    required: 'Número de identificación fiscal obligatorio',
  },
  processignPersonalData: {
    label:
      'Autorizo la recogida y el tratamiento de mis datos personales con fines de organización de eventos.',
  },
  getUpdates: {
    label:
      'Me gustaría suscribirme a las actualizaciones de Racing Unleashed sobre próximos eventos y promociones. Confirmo que he revisado y acepto <1>las Condiciones de uso</1> y <2>la Política de privacidad</2>.',
  },
  processingBillingData: {
    label:
      'Doy mi consentimiento para que se recopilen y procesen mis datos de facturación con fines de pago.',
  },
  validations: {
    minLength: 'Longitud mínima {{length}} caracter(es)',
    maxLength: 'Longitud máxima {{length}} caracter(es)',
    invalidFileType: 'Tipo de archivo no válido',
    maxSize: 'Tamaño máximo del archivo {{size}}MB',
    emojisNotAllowed: 'Los emojis no están permitidos',
    max: 'No pueden ser más de {{max}}',
  },
  additionalRequest: {
    label: 'Solicitud adicional',
    placeholder:
      'Introduzca aquí su solicitud adicional y nos pondremos en contacto con usted al respecto.',
  },
  coupon: {
    label: 'Cupón',
    placeholder: 'Introduzca el código del cupón...',
  },
  privacyPolicy: {
    label: 'He leído y acepto <1>la Política de privacidad</1>.',
    link: 'https://www.racing-unleashed.com/files/publicimages/Privacy-Policy_V3_08.09.2023_Spanish.pdf',
  },
  termsOfUse: {
    label: 'He leído y acepto <1>las Condiciones de uso</1>.',
    link: 'https://www.racing-unleashed.com/files/publicimages/Ordnerstruktur/04_Dateien/20200124_General_Terms_and_Conditions_v2_ES.DOC',
  },
  privacyAndTos: {
    label:
      'He leído y acepto <0>la Política de privacidad</0> y <1>las Condiciones de uso</1>.',
  },
  cancelationPolicy: {
    label: 'He leído y acepto <1>la Política de cancelación</1>.',
  },
  additionalSpecialRequest: {
    label: 'Tengo otras peticiones especiales sobre la comida y las bebidas',
  },
  specialRequest: {
    label: 'Petición especial',
    placeholder: 'Introduzca aquí su solicitud especial',
    required: 'Se requiere una solicitud especial',
  },
  firstName: {
    label: 'Nombre',
    placeholder: 'Introduzca su nombre...',
    organiserPlaceholder: 'Introduzca el nombre del organizador...',
    required: 'El nombre es obligatorio',
  },
  lastName: {
    label: 'Apellido',
    placeholder: 'Introduzca sus apellidos...',
    organiserPlaceholder: 'Introduzca el apellido del organizador...',
    required: 'Apellido obligatorio',
  },
};

export default field;
