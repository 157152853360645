import React from 'react';
import { Routes, Route } from 'react-router-dom';
import TermsPrivacy from '../TermsPrivacy';
import MobileChampionshipDetails from './MobileChampionshipDetails';
import MobileChampionships from './MobileChampionships';

const Mobile = () => (
  <Routes>
    <Route path="/championships/:lang" element={<MobileChampionships />} />
    <Route
      path="/championships/:id/:lang"
      element={<MobileChampionshipDetails />}
    />
    <Route
      path="/terms-of-service/:lang"
      element={<TermsPrivacy isMobilePage isTerms />}
    />
    <Route
      path="/privacy-policy/:lang"
      element={<TermsPrivacy isMobilePage />}
    />
  </Routes>
);

export default Mobile;
