import React, { useState } from 'react';
import EventsHeader from './EventsHeader';
import EventList from './EventList';

const Events = () => {
  const [detailedList, setDetailedList] = useState<boolean>(true);

  return (
    <div className="Events">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <EventsHeader showDetails={detailedList} onSet={setDetailedList} />
          <EventList showDetails={detailedList} />
        </div>
      </div>
    </div>
  );
};

export default Events;
