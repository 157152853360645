import React from 'react';
import { Card } from '@generics/surfaces';
import { Typography } from '@generics/content';
import { InfoCircleIcon } from '@images/icons';
import { useBackButtonLocation } from '@features/metadata';
import { useAuthenticatedCustomer } from '@features/authentication';
import {
  CustomerAddressSection,
  CustomerAvatarSection,
  CustomerPersonalDataSection,
  CustomerRacingSection,
} from '@features/settings';
import styles from './SettingsProfilePage.module.scss';

export const SettingsProfilePage = () => {
  useBackButtonLocation('settings');

  const authenticatedCustomer = useAuthenticatedCustomer();

  return (
    <div className={styles.root}>
      {authenticatedCustomer?.profile_data_incomplete
        ? (
            <Card className={styles.banner}>
              <InfoCircleIcon className={styles.banner__icon} />
              <Typography type="body/regular/m" color="secondary">
                labels.incompleteProfileDataMessage
              </Typography>
            </Card>
          )
        : null}
      <CustomerAvatarSection />
      <CustomerPersonalDataSection />
      <CustomerAddressSection />
      <CustomerRacingSection />
    </div>
  );
};
