import header from './header';
import field from './field';
import link from './link';
import auth from './auth';
import billing from './billing';
import dates from './dates';
import booking from './booking';
import profile from './profile';
import notifications from './notifications';
import ranking from './ranking';
import terms from './terms';
import policy from './policy';
import dashboard from './dashboard';
import events from './events';
import membership from './membership';
import confirmation from './confirmation';
import draftEvents from './draftEvents';
import eventParticipantDriver from './eventParticipantDriver';
import * as common from './common';

const en = {
  ...common,
  auth,
  dashboard,
  header,
  link,
  field,
  billing,
  dates,
  booking,
  profile,
  notifications,
  ranking,
  terms,
  policy,
  events,
  membership,
  confirmation,
  draftEvents,
  eventParticipantDriver,
};

export default en;
