import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  getRegionCodeForCountryCode,
  getExample,
  parsePhoneNumber,
  getCountryCodeForRegionCode,
} from 'awesome-phonenumber';
import { Mask, conformToMask } from 'react-text-mask';
import { EInputMode } from '@generics/inputs/types';
import { Image, Typography } from '@generics/content';
import { GlobeIcon } from '@images/icons';
import { StringInput } from '@generics/inputs/string';
import { SelectInput } from '@generics/inputs/select';
import { Models, useCountriesQuery } from '@network/api';
import { mergeRefs } from '@utils/merge-refs.utils';
import { useMergedClassNames } from '@utils/styling';
import styles from './PhoneInput.module.scss';

export interface IPhoneInputProps {
  value?: string | null;
  onChange?: (value: string | null) => void;
  inputRef?: React.Ref<HTMLInputElement>;
  placeholder?: string;
  mode?: `${EInputMode}`;
  invalid?: boolean;
  className?: string;
}

export const PhoneInput = (props: IPhoneInputProps) => {
  const {
    inputRef: externalInputRef,
    value,
    onChange,
    placeholder,
    mode = EInputMode.Input,
    invalid = false,
    className,
  } = props;

  const { data: countries, isFetching } = useCountriesQuery();

  const internalInputRef = useRef<HTMLInputElement>(null);

  const simplifiedValue = useMemo(() => `${value?.split('_')?.[0]}`, [value]);

  const parsedPhone = useMemo(() => {
    if (typeof simplifiedValue !== 'string') return null;
    return parsePhoneNumber(simplifiedValue);
  }, [simplifiedValue]);

  const parsedPhoneCountryCode = useMemo(
    () => parsedPhone?.regionCode ? getCountryCodeForRegionCode(parsedPhone.regionCode) : null,
    [parsedPhone],
  );

  const [selectedCountry, setSelectedCountry] = useState<Models.Country.Data | null>(null);

  useEffect(() => {
    if (isFetching || !parsedPhoneCountryCode) {
      setSelectedCountry(null);
      return;
    }

    setSelectedCountry(
      countries.find((country) => country.phone_prefix === `${parsedPhoneCountryCode}`) ?? null,
    );
  }, [isFetching, parsedPhoneCountryCode, countries]);

  const valueRepresentation = useMemo(() => {
    if (!parsedPhone?.valid) return '-';
    return parsedPhone.number?.international;
  }, [parsedPhone]);

  const mergeInputRef = useMemo(
    () => mergeRefs([internalInputRef, externalInputRef]),
    [internalInputRef, externalInputRef],
  );

  const examplePhoneForSelectCountry = useMemo(() => {
    if (!selectedCountry) return null;
    const countryCodeAsNumber = selectedCountry.phone_prefix.startsWith('1-')
      ? 1
      : Number(selectedCountry.phone_prefix);
    if (Number.isNaN(countryCodeAsNumber)) return null;
    const regionCode = getRegionCodeForCountryCode(countryCodeAsNumber);
    return getExample(regionCode);
  }, [selectedCountry]);

  const staticPhonePrefix = '+';

  const staticPhonePrefixAsMask = useMemo(
    () => staticPhonePrefix.split('') satisfies Mask,
    [staticPhonePrefix],
  );

  const dynamicPhoneSuffixAsMask = useMemo(() => {
    const phone = examplePhoneForSelectCountry?.number?.international;
    if (!phone) return [] satisfies Mask;
    return phone.substring(staticPhonePrefix.length).split('').map((char) => {
      const charAsNumber = Number(char);
      return [' '].includes(char) || Number.isNaN(charAsNumber) ? char : /\d/;
    }) satisfies Mask;
  }, [examplePhoneForSelectCountry, staticPhonePrefix]);

  const mask = useMemo(
    () => {
      const composedMask = [...staticPhonePrefixAsMask, ...dynamicPhoneSuffixAsMask];
      return dynamicPhoneSuffixAsMask.length > 0
        ? composedMask
        : ['+', ...Array.from({ length: 17 }).map(() => /\d/)];
    },
    [staticPhonePrefixAsMask, dynamicPhoneSuffixAsMask],
  );

  const valueFormattedWithMask = useMemo(
    () => value ? conformToMask(value, mask).conformedValue : value,
    [value, mask],
  );

  const countriesAsSelectItems = useMemo(() => countries.map((country) => ({
    value: country.id,
    shortChildren: (
      <div className={styles.countrySelectItem}>
        <Image
          className={styles.countrySelectItem__flag}
          aspectRatio={1}
          src={country.flag_picture_url}
          alt={`${country.label} Flag`}
        />
      </div>
    ),
    children: (
      <div className={styles.countrySelectItem}>
        <Image
          className={styles.countrySelectItem__flag}
          aspectRatio={1}
          src={country.flag_picture_url}
          alt={`${country.label} Flag`}
        />
        <Typography type="body/regular/m">{country.label}</Typography>
        <Typography type="body/regular/m">
          +
          {country.phone_prefix}
        </Typography>
      </div>
    ),
  })), [countries]);

  const handleCountrySelect = useCallback((countryId: string | null) => {
    const country = countries.find((c) => c.id === countryId);
    if (!country) return;
    setSelectedCountry(country);
    onChange?.(`+${country.phone_prefix}`);
  }, [countries, onChange]);

  const handleMouseUp = useCallback((event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    setTimeout(() => {
      const target = event.target as HTMLInputElement;
      if (
        target.selectionStart === null
        || target.selectionStart !== target.selectionEnd
        || target.selectionStart > staticPhonePrefix.length
      ) return;
      target.setSelectionRange(
        staticPhonePrefix.length,
        staticPhonePrefix.length,
      );
    }, 0);
  }, [staticPhonePrefix]);

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const selectionStart = target.selectionStart;

    if (selectionStart === null) return;

    if (
      event.key === 'ArrowLeft'
      && selectionStart <= staticPhonePrefix.length
    ) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (
      event.key === 'Delete'
      && selectionStart <= staticPhonePrefix.length
    ) {
      setTimeout(() => {
        if (selectionStart > staticPhonePrefix.length) return;
        target.setSelectionRange(
          staticPhonePrefix.length,
          staticPhonePrefix.length,
        );
      }, 0);
    }

    if (
      event.key === 'Backspace'
      && selectionStart <= staticPhonePrefix.length + 1
    ) {
      setTimeout(() => {
        if (selectionStart > staticPhonePrefix.length) return;
        target.setSelectionRange(
          staticPhonePrefix.length,
          staticPhonePrefix.length,
        );
      }, 0);
    }
  }, [staticPhonePrefix]);

  const handlePhoneChange = useCallback((newPhone: string | null) => {
    if (newPhone === null) {
      onChange?.(null);
      return;
    }

    onChange?.(newPhone?.replace(/[^\d_+]/g, ''));
  }, [onChange]);

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [className || '']: true,
  });

  if (mode === EInputMode.Representation) {
    return (
      <Typography type="body/regular/m">{valueRepresentation}</Typography>
    );
  }

  return (
    <div className={rootClassName}>
      <SelectInput
        className={styles.selectInput}
        value={selectedCountry?.id}
        onChange={handleCountrySelect}
        items={countriesAsSelectItems}
        placeholder={(
          <div className={styles.selectInput__placeholder}>
            <GlobeIcon />
          </div>
        )}
        invalid={invalid}
      />
      <StringInput
        className={styles.stringInput}
        inputMode="tel"
        mask={mask}
        showMask
        value={valueFormattedWithMask}
        onChange={handlePhoneChange}
        inputRef={mergeInputRef}
        onMouseUp={handleMouseUp}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        invalid={invalid}
      />
    </div>
  );
};
