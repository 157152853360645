import {
  CustomerState,
  CustomerActionTypes,
  SET_MEMBER,
  SIGN_OUT,
  Customer,
} from './customer.types';

let customer = null;
if (window.localStorage) {
  const savedState = JSON.parse(localStorage.getItem('ruState')!) as { customer: Customer };
  customer = savedState ? savedState.customer : null;
}

const initialState: CustomerState = {
  customer,
};

export default (state = initialState, action: CustomerActionTypes) => {
  switch (action.type) {
    case SIGN_OUT:
      return {
        customer: null,
      };
    case SET_MEMBER:
      if (!localStorage.getItem('ruToken')?.length) return { ...state };
      return {
        ...state,
        customer: { ...action.payload },
      };

    default:
      return state;
  }
};
