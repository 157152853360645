import { useMemo, useState } from 'react';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

export type ApiValidationErrors = Required<TRumpApiErrorResponseData['meta']>['errors'];

export interface IUseApiMutationProps<Data = unknown, Error = unknown, Variables = unknown, Context = unknown>
  extends UseMutationOptions<Data, Error, Variables, Context> {
  onValidationErrors?: (errors: ApiValidationErrors) => void;
}

export function useApiMutation<Data = unknown, Error = unknown, Variables = unknown, Context = unknown>(
  props?: IUseApiMutationProps<Data, Error, Variables, Context>,
) {
  const { onMutate, onError, onValidationErrors, ...restHookProps } = props ?? {};

  const [validationErrors, setValidationErrors] = useState<ApiValidationErrors>([]);

  const mutation = useMutation({
    ...restHookProps,
    onMutate: (variables) => {
      setValidationErrors([]);
      return onMutate?.(variables);
    },
    onError: (error, variables, context) => {
      const data = error as TRumpApiErrorResponseData;

      if (data?.meta?.errors) {
        setValidationErrors(data.meta.errors);
        onValidationErrors?.(data.meta.errors);
      }

      onError?.(error, variables, context);
    },
  });

  return useMemo(
    () => ({ ...mutation, validationErrors }),
    [mutation, validationErrors],
  );
}
