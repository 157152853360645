import React, { useCallback, useMemo, useRef, useState } from 'react';
import styles from './CounterInput.module.scss';
import { useTranslation } from 'react-i18next';
import mergeClassNames from 'classnames';
import StyledTooltip, { IStyledTooltipProps } from '@generics/StyledTooltip/StyledTooltip';
import { ENumberInputLimitType, NumberInput } from '@generics/inputs/number';

interface ICounterInputProps extends Omit<React.HTMLProps<HTMLDivElement>, 'value' | 'onChange'> {
  value: number | null;
  /**
   * @default 1
   */
  step?: number;
  integer?: boolean;
  label?: string;
  min?: number;
  max?: number;
  required?: boolean;
  className?: string;
  /**
   * @default false
   */
  disabled?: boolean;
  decreaseButtonTooltipTitle?: string;
  increaseButtonTooltipTitle?: string;
  onChange: (value: number | null) => void;
  onBlur?: () => void;
}

export const CounterInput = ({
  label,
  value,
  min,
  max,
  integer,
  required,
  className,
  disabled,
  decreaseButtonTooltipTitle,
  increaseButtonTooltipTitle,
  step = 1,
  onChange,
  onBlur,
  ...restProps
}: ICounterInputProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [t] = useTranslation();

  const minAllowedValue = useMemo(() => min ?? -Infinity, [min]);
  const maxAllowedValue = useMemo(() => max ?? -Infinity, [max]);
  const allowedStep = useMemo(() => integer ? Math.ceil(step) : step, [integer, step]);

  const isIncreaseButtonDisabled = useMemo(
    () => value === maxAllowedValue || disabled,
    [value, maxAllowedValue, disabled],
  );

  const isDecreaseButtonDisabled = useMemo(
    () => value === minAllowedValue || disabled,
    [value, minAllowedValue, disabled],
  );

  const isIncreaseButtonTooltipDisabled = useMemo(
    () => !increaseButtonTooltipTitle || value !== maxAllowedValue || disabled,
    [increaseButtonTooltipTitle, value, maxAllowedValue, disabled],
  );

  const isDecreaseButtonTooltipDisabled = useMemo(
    () => !decreaseButtonTooltipTitle || value !== minAllowedValue || disabled,
    [decreaseButtonTooltipTitle, value, minAllowedValue, disabled],
  );

  const currentValue = useMemo(
    () => Number(value),
    [value],
  );

  const [isIncreaseButtonTooltipOpen, setIsIncreaseButtonTooltipOpen] = useState(false);
  const [isDecreaseButtonTooltipOpen, setIsDecreaseButtonTooltipOpen] = useState(false);

  const handleValueChange = useCallback((newValue: number | null) => {
    onChange?.(newValue);
  }, [onChange]);

  const handleIncreaseButtonClick = useCallback(() => {
    if (isIncreaseButtonDisabled) return;
    const newValue = currentValue + allowedStep;
    if (newValue >= maxAllowedValue) setIsIncreaseButtonTooltipOpen(true);
    if (newValue > maxAllowedValue) return;
    onChange?.(newValue);
  }, [isIncreaseButtonDisabled, currentValue, maxAllowedValue, onChange, allowedStep]);

  const handleDecreaseButtonClick = useCallback(() => {
    if (isDecreaseButtonDisabled) return;
    const newValue = currentValue - allowedStep;
    if (newValue <= minAllowedValue) setIsDecreaseButtonTooltipOpen(true);
    if (newValue < minAllowedValue) return;
    onChange?.(newValue);
  }, [isDecreaseButtonDisabled, currentValue, minAllowedValue, onChange, allowedStep]);

  const handleLimitExceed = useCallback((type: ENumberInputLimitType) => {
    if (type === ENumberInputLimitType.Max) {
      setIsIncreaseButtonTooltipOpen(true);
    };

    if (type === ENumberInputLimitType.Min) {
      setIsDecreaseButtonTooltipOpen(true);
    };
  }, []);

  const handleBoxClick = useCallback(() => {
    inputRef.current?.focus();
  }, []);

  const handleInputBlur = useCallback(() => {
    if (inputRef.current?.value === '') {
      inputRef.current.value = minAllowedValue.toString();
    }
  }, [minAllowedValue]);

  const handleBlur = useCallback(() => {
    setIsIncreaseButtonTooltipOpen(false);
    setIsDecreaseButtonTooltipOpen(false);
    onBlur?.();
  }, [onBlur]);

  const commonTooltipProps = useMemo(
    () => ({
      arrow: true,
      enterTouchDelay: 0,
      placement: 'top',
    }) satisfies Partial<IStyledTooltipProps>,
    [],
  );

  const rootClassName = useMemo(
    () => mergeClassNames({
      [styles.root]: true,
      [styles.root_disabled]: disabled,
    }, className),
    [className, disabled],
  );

  const labelClassName = useMemo(
    () => mergeClassNames({
      [styles.label]: true,
      [styles.label_required]: required,
    }, className),
    [className, required],
  );

  return (
    <div {...restProps} className={rootClassName} onBlur={handleBlur}>
      {label
        ? (
            <div className={labelClassName}>
              {t(label)}
            </div>
          )
        : null}
      <div className={styles.box} onClick={handleBoxClick}>
        <StyledTooltip
          {...commonTooltipProps}
          open={isDecreaseButtonTooltipOpen && !isDecreaseButtonTooltipDisabled}
          title={t(decreaseButtonTooltipTitle ?? '')}
          onMouseEnter={() => setIsDecreaseButtonTooltipOpen(true)}
          onMouseLeave={() => setIsDecreaseButtonTooltipOpen(false)}
        >
          <button
            className={styles.decreaseButton}
            disabled={isDecreaseButtonDisabled}
            onClick={handleDecreaseButtonClick}
          />
        </StyledTooltip>
        <NumberInput
          ref={inputRef}
          className={styles.stringInputBox}
          disabled={disabled}
          min={min}
          max={max}
          value={currentValue}
          onChange={handleValueChange}
          onLimitExceed={handleLimitExceed}
          onBlur={handleInputBlur}
          decimalScale={0}
          inputProps={{
            className: styles.stringInput,
          }}
        />
        <StyledTooltip
          {...commonTooltipProps}
          open={isIncreaseButtonTooltipOpen && !isIncreaseButtonTooltipDisabled}
          title={t(increaseButtonTooltipTitle ?? '')}
          onMouseEnter={() => setIsIncreaseButtonTooltipOpen(true)}
          onMouseLeave={() => setIsIncreaseButtonTooltipOpen(false)}
        >
          <button
            className={styles.increaseButton}
            disabled={isIncreaseButtonDisabled}
            onClick={handleIncreaseButtonClick}
          />
        </StyledTooltip>
      </div>
    </div>
  );
};
