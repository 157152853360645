export default {
  relative: {
    lastWeek: '\'Last\' eeee',
    yesterday: '\'Yesterday\', HH:mm',
    today: '\'Today\', HH:mm',
    tomorrow: '\'Tomorrow\', HH:mm',
    nextWeek: '\'Next\' eeee',
    other: 'dd.MM.yyyy, HH:mm',
  },
  membership: {
    expiringAt: '\'expiring at\' dd MMMM yyyy',
    expiredAt: '\'expired at\' dd MMMM yyyy',
  },
  bankCard: {
    expirationDate: '\'Exp. date\' MM / yy',
  },
};
