import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ClickAwayListener, Popper } from '@mui/material';
import { EInputMode } from '@generics/inputs/types';
import { Box, Card, Scroll } from '@generics/surfaces';
import { Typography } from '@generics/content';
import { SelectInputItem, TSelectInputItemProps } from './SelectInputItem';
import { ChevronDownIcon } from '@images/icons';
import { useMergedClassNames } from '@utils/styling';
import styles from './SelectInput.module.scss';

export interface ISelectInputProps extends Omit<HTMLProps<HTMLDivElement>, 'children' | 'value' | 'onChange'> {
  items?: Omit<TSelectInputItemProps, 'onChange'>[];
  value?: string | null;
  onChange?: (value: string | null) => void;
  inputRef?: React.Ref<HTMLInputElement>;
  placeholder?: React.ReactNode;
  mode?: `${EInputMode}`;
  disabled?: boolean;
  invalid?: boolean;
}

// TODO: Focus on first item when opening.

export const SelectInput = (props: ISelectInputProps) => {
  const {
    id,
    items,
    value,
    onChange,
    onClick,
    inputRef,
    placeholder,
    mode = EInputMode.Input,
    disabled = false,
    invalid = false,
    className,
    ...restRootProps
  } = props;

  const boxRootElementRef = useRef<HTMLDivElement | null>(null);
  const cardRootElementRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const selectedItem = useMemo(
    () => items?.find((item) => item.value === value),
    [items, value],
  );

  const valueRepresentation = useMemo(
    () => selectedItem?.shortChildren ?? selectedItem?.children ?? '-',
    [selectedItem],
  );

  const valueOrPlaceholder = useMemo(
    () => selectedItem?.shortChildren
    ?? selectedItem?.children
    ?? placeholder
    ?? null,
    [selectedItem, placeholder],
  );

  const isDisabled = useMemo(
    () => !items?.length || disabled,
    [items, disabled],
  );

  const handleAnchorClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (isDisabled) return;
      setIsOpen((previousIsOpen) => {
        if (!previousIsOpen) {
          setTimeout(() => cardRootElementRef.current?.focus(), 0);
        }

        return !previousIsOpen;
      });
      onClick?.(event);
    },
    [onClick, isDisabled],
  );

  const handleClickAway = useCallback(() => {
    setIsFocused(false);
    setIsOpen(false);
  }, []);

  const getAnchorEl = useCallback(
    () => boxRootElementRef.current ?? document.createElement('div'),
    [],
  );

  const handleItemSelect = useCallback((value: string | null) => {
    onChange?.(value);
    setIsOpen(false);
    boxRootElementRef.current?.focus();
  }, [onChange]);

  const handleRootFocus = useCallback(() => {
    if (isDisabled) return;
    setIsFocused(true);
  }, [isDisabled]);

  const handleRootBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleCardFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputFocus = useCallback(() => {
    if (isDisabled) return;
    boxRootElementRef.current?.focus();
  }, [isDisabled]);

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      setIsOpen(true);
      setTimeout(() => cardRootElementRef.current?.focus(), 0);
    }
  }, []);

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [styles.root_focused]: isFocused,
    [styles.root_invalid]: invalid,
    [styles.root_disabled]: isDisabled,
    [className || '']: true,
  });

  if (mode === EInputMode.Representation) {
    return (
      <Typography type="body/regular/m">{valueRepresentation}</Typography>
    );
  }

  return (
    <>
      <Box
        {...restRootProps}
        className={rootClassName}
        size="input"
        onClick={handleAnchorClick}
        onFocus={handleRootFocus}
        onKeyDown={handleKeyDown}
        onBlur={handleRootBlur}
        ref={boxRootElementRef}
        tabIndex={isDisabled ? -1 : 0}
      >
        <input
          className={styles.input}
          type="text"
          id={id}
          onFocus={handleInputFocus}
          ref={inputRef}
          tabIndex={-1}
          disabled={isDisabled}
        />
        <Typography
          className={styles.value}
          type="body/regular/l"
          color={value ? 'primary' : 'trinary'}
        >
          {valueOrPlaceholder}
        </Typography>
        <div className={styles.appendix}>
          <ChevronDownIcon />
        </div>
      </Box>
      <Popper
        open={isOpen}
        anchorEl={getAnchorEl}
        placement="bottom-end"
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Card className={styles.paper}>
            <Scroll
              className={styles.items}
              vertical
              tabIndex={-1}
              ref={cardRootElementRef}
              onFocus={handleCardFocus}
            >
              {items?.map((item) => (
                <SelectInputItem
                  key={item.value}
                  {...item}
                  onChange={handleItemSelect}
                  selected={value === item.value}
                />
              ))}
            </Scroll>
          </Card>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
