export const GET_EVENTS = 'GET_EVENTS';

export interface Event {
  id: string;
  title: string;
  description: string;
  description_excerpt: string;
  start_time: string;
  end_time: string;
  featured_image_original: string;
  featured_image_large: string;
  featured_image_medium: string;
  featured_image_small: string;
  link_title: string;
  link_url: string;
  mobile_url: string;
}

export interface EventState {
  events: Event[];
}

interface GetChampionshipsAction {
  type: typeof GET_EVENTS;
  payload: Event[];
}

export type EventActionType = GetChampionshipsAction;
