export default {
  unknown: {
    label: 'Das Feld',
  },
  profile_pic: {
    label: 'Avatar',
  },
  nickname: {
    label: 'Spitzname',
    placeholder: 'Spitzname eingeben',
  },
  gender_id: {
    label: 'Geschlecht',
    placeholder: 'Geschlecht auswählen',
  },
  first_name: {
    label: 'Vorname',
    placeholder: 'Vorname eingeben',
  },
  last_name: {
    label: 'Nachname',
    placeholder: 'Nachname eingeben',
  },
  email: {
    label: 'Email',
    placeholder: 'Email eingeben',
  },
  phone_number: {
    label: 'Telefonnummer',
    placeholder: 'Telefonnummer eingeben',
  },
  company: {
    label: 'Firma',
    placeholder: 'Firmennamen eingeben',
  },
  body_weight: {
    label: 'Körpergewicht',
    placeholder: 'Körpergewicht eingeben',
  },
  body_height: {
    label: 'Körpergröße',
    placeholder: 'Körpergröße eingeben',
  },
  dob: {
    label: 'Geburtsdatum',
    placeholder: 'Geburtsdatum auswählen',
  },
  pob_country_id: {
    label: 'Geburtsland',
    placeholder: 'Geburtsland auswählen',
  },
  default_lounge_id: {
    label: 'Standard-Lounge',
    placeholder: 'Standard-Lounge auswählen',
  },
  fav_lounge_id: {
    label: 'Lieblings-Lounge',
    placeholder: 'Lieblings-Lounge auswählen',
  },
  fav_simulator_id: {
    label: 'Lieblings-Simulator',
    placeholder: 'Lieblings-Simulator auswählen',
  },
  address1: {
    label: 'Adresse',
    placeholder: 'Erste Adresszeile eingeben',
  },
  address2: {
    label: 'Adresszeile 2',
    placeholder: 'Zweite Adresszeile eingeben',
  },
  country_id: {
    label: 'Land',
    placeholder: 'Land auswählen',
  },
  city: {
    label: 'Stadt',
    placeholder: 'Stadtname eingeben',
  },
  zip_code: {
    label: 'Postleitzahl',
    placeholder: 'Postleitzahl eingeben',
  },
  password: {
    label: 'Neues Passwort',
    placeholder: 'Neues Passwort eingeben',
  },
  password_confirmation: {
    label: 'Passwort bestätigen',
    placeholder: 'Neues Passwort bestätigen',
  },
  card_number: {
    label: 'Kartennummer',
    placeholder: 'Kartennummer eingeben',
  },
  card_expiry: {
    label: 'Ablaufdatum',
  },
  card_cvc: {
    label: 'CVC',
  },
};
