import React, { createContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createApiClient } from './createApiClient';

export const ApiClientContext = createContext<ReturnType<typeof createApiClient> | undefined>(undefined);

export interface IApiClientProviderProps {
  children?: React.ReactNode;
}

export const ApiClientProvider = (props: IApiClientProviderProps) => {
  const { children } = props;
  const [, { language }] = useTranslation();

  const client = useMemo(
    () => createApiClient()
      .headers({ 'x-locale': language }),
    [language],
  );

  return <ApiClientContext.Provider value={client}>{children}</ApiClientContext.Provider>;
};

export function useApiClient() {
  const context = React.useContext(ApiClientContext);

  if (!context) {
    throw new Error('The useApiClient() must be used within a <ApiClientProvider />');
  }

  return context;
}
