import { WretchError } from 'wretch';
import { TUseApiQueryProps, useApiClient, useApiQuery } from '@network/api/client';
import * as Models from '@network/api/models';

export type LoungesResponse = {
  data: Models.Lounge.Data[];
};

export type TUseLoungesQuery = Omit<TUseApiQueryProps<LoungesResponse['data'], WretchError>, 'queryKey' | 'queryFn'> & {
  eventsOnly?: boolean;
};

export function useLoungesQuery(props?: TUseLoungesQuery) {
  const { eventsOnly } = props ?? {};

  const apiClient = useApiClient();

  const searchParams = new URLSearchParams();

  if (eventsOnly) {
    searchParams.set('events_only', eventsOnly.toString());
  }

  const searchParamsAsString = searchParams.toString();

  const { data, ...restQuery } = useApiQuery({
    placeholderData: [],
    ...props,
    queryKey: ['lounges', 'all', { searchParamsAsString }],
    queryFn: () => apiClient.url(`/lounges?${searchParamsAsString}`)
      .get()
      .json<LoungesResponse>()
      .then(({ data }) => data),
  });

  return {
    data: data ?? [],
    ...restQuery,
  };
}
