import React, { useEffect, useRef } from 'react';
import { useMergedClassNames } from '@utils/styling';
import styles from './ImageBanner.module.scss';

export type TImageBannerProps = Extend<HTMLProps<HTMLDivElement>, {
  src: string;
}>;

export const ImageBanner = (props: TImageBannerProps) => {
  const { src, children, onClick, className, ...restRootProps } = props;

  const rootElementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!src) return;
    const rootElement = rootElementRef.current;

    rootElement?.style.setProperty('background-image', `url(${src})`);

    return () => {
      rootElement?.style.removeProperty('background-image');
    };
  }, [src]);

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [styles.root_clickable]: onClick,
    [className || '']: true,
  });

  return (
    <div {...restRootProps} className={rootClassName} onClick={onClick} ref={rootElementRef}>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  );
};
