import React, { useCallback, useMemo } from 'react';
import { Typography } from '@generics/content';
import { Button } from '@generics/inputs/button';
import styles from './Product.module.scss';

export type TProductCardProps<RootElementType extends React.ElementType> = {
  as?: RootElementType;
  id?: string;
  title?: React.ReactNode;
  currency?: string;
  price?: string;
  children?: React.ReactNode;
  onPurchaseButtonClick?: (id: string) => void;
};

// TODO: Renewal logic.

export const Product = <RootElementType extends React.ElementType>(
  props: PolymorphicComponentPropsWithoutRef<RootElementType, TProductCardProps<RootElementType>>,
) => {
  const { as, id, title, currency, price, children, onPurchaseButtonClick } = props;

  const RootComponent = useMemo(() => as ?? 'div', [as]);
  const composedPrice = `${currency} ${price}`;

  const handlePurchaseButtonClick = useCallback(() => {
    if (!id) return;
    onPurchaseButtonClick?.(id);
  }, [onPurchaseButtonClick, id]);

  return (
    <RootComponent className={styles.root}>
      <div className={styles.header}>
        <Typography type="body/medium/m">{title}</Typography>
        <Typography type="body/medium/s">{composedPrice}</Typography>
      </div>
      <div className={styles.content}>
        <Typography type="body/regular/s">{children}</Typography>
      </div>
      {onPurchaseButtonClick
        ? (
            <div className={styles.footer}>
              <Button label="labels.buyNow" onClick={handlePurchaseButtonClick} />
            </div>
          )
        : null}
    </RootComponent>
  );
};
