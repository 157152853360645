import { Dispatch } from 'react';
import api from '../../config/api.config';
import { toastUtil } from '../../utils/toast.utils';
import { Event, EventActionType, GET_EVENTS } from './events.types';

export const getEvents = async (
  dispatch: Dispatch<EventActionType>,
  lang?: string,
) => {
  try {
    const response = await api.get<{ data?: Event[] }>('/events', {
      headers: {
        'x-locale': lang || 'en',
      },
    });
    const events = response?.data?.data ?? [];

    dispatch({
      type: GET_EVENTS,
      payload: events,
    });

    return events;
  }
  catch (err) {
    const error = err as { data?: TRumpApiErrorResponseData };
    if (typeof error?.data?.meta?.message === 'string') toastUtil('error', error.data.meta.message);
  }
};

export const getEvent = async (
  id?: number | string,
  lang?: string,
) => {
  try {
    const event = await api.get<{ data?: Event }>(`/events/${id}`, {
      headers: {
        'x-locale': lang || 'en',
      },
    });
    return event.data.data;
  }
  catch (err) {
    const error = err as { data?: TRumpApiErrorResponseData };
    if (typeof error?.data?.meta?.message === 'string') toastUtil('error', error.data.meta.message);
  }
};
