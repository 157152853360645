import { IUseApiMutationProps, useApiClient, useApiMutation } from '@network/api/client';
import { useDispatch } from 'react-redux';
import { SET_MEMBER } from '@store/customer/customer.types';
import * as Models from '@network/api/models';
import { toastUtil } from '@utils/toast.utils';
import { useAuthentication } from '@features/authentication';

export type UseDeleteCustomerMutationResponse = { data: Models.Customer.Data; meta?: { message?: string } };

export type TUseDeleteCustomerMutationProps = Omit<IUseApiMutationProps<UseDeleteCustomerMutationResponse>, 'mutationKey' | 'mutationFn'>;

export function useDeleteCustomerMutation(props?: TUseDeleteCustomerMutationProps) {
  const { onSuccess, ...restHookProps } = props ?? {};

  const { logout } = useAuthentication();
  const dispatch = useDispatch();
  const apiClient = useApiClient();

  return useApiMutation({
    ...restHookProps,
    mutationKey: ['customers', 'delete'],
    mutationFn: () =>
      apiClient.url(`/activation/delete`)
        .post()
        .json<UseDeleteCustomerMutationResponse>()
        .then((response) => response),
    onSuccess: (response, variables, context) => {
      dispatch({
        type: SET_MEMBER,
        payload: null,
      });
      if (response?.meta?.message) toastUtil('info', response.meta.message);
      onSuccess?.(response, variables, context);
      logout();
    },
  });
}
