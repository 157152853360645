import React, { useCallback, useMemo, useState } from 'react';
import styles from './DraftEventsCard.module.scss';
import { Button } from '@generics/inputs/button';
import { Card } from '@generics/surfaces';
import { Typography } from '@generics/content';
import { useDraftEventsQuery } from '@network/api';
import { DraftEventBox } from './DraftEventBox';
import { ArrowDownIcon } from '@images/icons';

const MAX_EVENTS_LENGTH = 2;

export const DraftEventsCard = () => {
  const { data: draftEvents } = useDraftEventsQuery();
  const [isAllDisplayed, setIsAllDisplayed] = useState<boolean>(false);

  const displayedDraftEvents = useMemo(
    () => isAllDisplayed ? draftEvents : draftEvents.slice(0, MAX_EVENTS_LENGTH),
    [isAllDisplayed, draftEvents],
  );

  const handleShowAllButtonClick = useCallback(() => {
    setIsAllDisplayed(true);
  }, []);

  if (!draftEvents.length) return null;

  return (
    <Card>
      <Typography type="body/medium/m">labels.draftEvents</Typography>
      <div className={styles.items}>
        {displayedDraftEvents.map((event) => <DraftEventBox key={event.id} event={event} />)}
      </div>
      {isAllDisplayed || draftEvents.length <= MAX_EVENTS_LENGTH
        ? null
        : (
            <Button
              design="outline"
              label="labels.showAll"
              icon={<ArrowDownIcon />}
              onClick={handleShowAllButtonClick}
              fullWidth
            />
          )}
    </Card>
  );
};
