const field = {
  identifier: {
    label: 'Email',
    required: 'E-mail is required',
  },
  email: {
    label: 'E-mail*',
    labelWithoutStar: 'E-mail',
    placeholder: 'Enter your e-mail...',
    required: 'E-mail is required',
    invalidEmail: 'Invalid email',
  },
  first_name: {
    label: 'First Name*',
    required: 'This field is required',
  },
  name_and_surname: {
    label: 'Name and Surname',
    required: 'Name and Surname are required',
    placeholder: 'Enter name and surname...',
  },
  last_name: {
    label: 'Last Name*',
    required: 'This field is required',
  },
  nickname: {
    label: 'Nickname*',
    required: 'This field is required',
  },
  password: {
    label: 'Password*',
    labelWithoutStar: 'Password',
    placeholder: 'Enter your password...',
    required: 'This field is required',
    minLength: 'Password should be at least 8 characters',
  },
  password_confirmation: {
    label: 'Confirm Password*',
    required: 'This field is required',
    minLength: 'Password should be at least 8 characters',
    matching: 'Passwords do not match',
  },
  preferred_language: {
    label: 'Preferred Language',
    required: 'This field is required',
  },
  default_lounge_id: {
    label: 'Default Lounge*',
    required: 'This field is required',
  },
  phone_number: {
    label: 'Phone Number',
    required: 'Phone number is required',
    invalid: 'Phone number is invalid',
  },
  address1: {
    label: 'Address',
    required: 'This field is required',
  },
  address2: {
    label: 'Address line 2',
    required: 'This field is required',
  },
  country_id: {
    label: 'Country',
    required: 'County is required',
    companyPlaceholder: 'Select company country...',
  },
  city: {
    label: 'City',
    required: 'City is required',
    companyPlaceholder: 'Enter company city...',
  },
  zip_code: {
    label: 'Zip Code',
    companyPlaceholder: 'Enter company zip code...',
  },
  region: {
    label: 'Region',
    companyPlaceholder: 'Enter company region...',
  },
  pob_country_id: {
    label: 'Country of Birth',
    required: 'This field is required',
  },
  fav_lounge_id: {
    label: 'Favourite Lounge',
  },
  fav_simulator_id: {
    label: 'Favourite Simulator',
  },
  body_weight: {
    label: 'Body Weight (kg)',
  },
  body_height: {
    label: 'Body Height (cm)',
  },
  company: {
    label: 'Company Name',
    placeholder: 'Enter company name...',
    required: 'Company name is required',
  },
  gender_id: {
    label: 'Gender',
  },
  remember_me: {
    label: 'Remember Me',
  },
  tos: {
    label:
      'I accept the <tosLink>Terms of Service</tosLink> and <privacyLink>Privacy policy and use of cookies</privacyLink>',
  },
  lounge_id: {
    label: 'Lounge',
  },
  dob: {
    label: 'Date of birth',
  },
  monthSelector: {
    label: 'Event Date',
  },
  carSelector: {
    label: 'Cars',
  },
  trackSelector: {
    label: 'Tracks',
  },
  search: {
    placeholder: 'Search...',
  },
  saveCardInformation: 'Save your card information for later',
  change: 'change',
  update: 'Update',
  signUpAge: 'I am at least 13 years old',
  submit: 'Submit',
  submiting: 'Submitting...',
  signIn: 'Sign In',
  signUp: 'Sign Up',
  signUpSilentMember: 'Activate',
  updateProfile: 'Update Profile',
  forgotPassword: 'Reset Password',
  resetPassword: 'Create Password',
  signingIn: 'Signing In...',
  signingUp: 'Signing Up...',
  callToSignUp: 'Don\'t have an account? <1>Sign Up!<1>',
  callToSignIn: 'Have an account? <1>Sign In!<1>',
  firstLine: {
    label: 'First Line',
    placeholder: 'Enter text here...',
    required: 'First line is required',
  },
  secondLine: {
    label: 'Second Line',
    placeholder: 'Enter text here...',
    required: 'Second line is required',
  },
  fileUpload: {
    caption: 'We accept {{formats}} files',
    uploaded: 'File Uploaded',
    delete: 'Delete File',
    required: 'File is required',
  },
  organiserEmail: {
    label: 'E-mail',
    placeholder: 'Enter organiser e-mail...',
    required: 'Organiser e-mail is required',
    invalid: 'Organizer e-mail is invalid',
  },
  companyAddressLine: {
    label: 'Address Line {{number}}',
    placeholder: 'Enter address...',
    required: 'Address is required',
    companyLabel: 'Company Address Line {{number}}',
    companyPlaceholder: 'Enter company address...',
    companyRequired: 'Company address is required',
  },
  taxNumber: {
    label: 'Tax Number',
    placeholder: 'Enter company tax number...',
    required: 'Tax number is required',
  },
  processignPersonalData: {
    label:
      'I consent to the collection and processing of my personal data for event organization purposes.',
  },
  getUpdates: {
    label:
      'I would like to subscribe to updates from Racing Unleashed on upcoming events and promotions. I confirm that I have reviewed and agree to the <1>Terms of Use</1> and <2>Privacy Policy</2>.',
  },
  processingBillingData: {
    label:
      'I consent to the collection and processing of my billing information for payment purposes.',
  },
  validations: {
    minLength: 'Minimum length {{length}} character(s)',
    maxLength: 'Maximum length {{length}} character(s)',
    invalidFileType: 'Invalid file type',
    maxSize: 'Maximum file size {{size}}MB',
    emojisNotAllowed: 'Emojis are not allowed',
    max: 'Can\'t be greater than {{max}}',
  },
  additionalRequest: {
    label: 'Additional Request',
    placeholder:
      'Enter your additional request here and we\'ll contact you about it.',
  },
  coupon: {
    label: 'Coupon',
    placeholder: 'Enter coupon code...',
  },
  privacyPolicy: {
    label: 'I have read and agree to the <1>Privacy Policy</1>.',
    link: 'https://www.racing-unleashed.com/files/publicimages/Privacy-Policy_V3_08.09.2023_ENGL.pdf',
  },
  termsOfUse: {
    label: 'I have read and agree to the <1>Terms of Use</1>.',
    link: 'https://www.racing-unleashed.com/files/publicimages/Ordnerstruktur/04_Dateien/20200124_General_Terms_and_Conditions_v2.pdf',
  },
  privacyAndTos: {
    label:
      'I have read and agree to the <0>Privacy Policy</0> and <1>Terms of Use</1>.',
  },
  cancelationPolicy: {
    label: 'I have read and agree to the <1>Cancelation Policy</1>.',
  },
  additionalSpecialRequest: {
    label: 'I have additional special requests about the food and beverages',
  },
  specialRequest: {
    label: 'Special Request',
    placeholder: 'Enter your special request here',
    required: 'Special request required',
  },
  firstName: {
    label: 'First Name',
    placeholder: 'Enter your first name...',
    organiserPlaceholder: 'Enter organiser first name...',
    required: 'First name is required',
  },
  lastName: {
    label: 'Last Name',
    placeholder: 'Enter your last name...',
    organiserPlaceholder: 'Enter organiser last name...',
    required: 'Last name is required',
  },
  createUser: {
    label: 'I want to create an account.',
  },
};

export default field;
