import React, { ChangeEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { BasicField } from './FormField';
import classNames from 'classnames';

const TextInput = ({
  value,
  onChange = () => {},
  i18nKey,
  errors = {},
  touched = {},
  name,
  type,
  required = false,
  onBlur = () => {},
  disabled,
  className,
}: BasicField) => {
  const [t] = useTranslation();
  const errorTrans = typeof errors?.[name] === 'string' ? t(`${i18nKey}.${errors[name]}`) : '';
  const errorMsg
    = typeof errors?.[name] === 'string' && errorTrans === `${i18nKey}.${errors[name]}` ? errors[name] : errorTrans;

  return (
    <div className={classNames('FormField', name, { 'has-value': value, 'is-disabled': disabled }, className)} data-name={name}>
      <div className="FormField-input">
        {i18nKey && <label>{t(`${i18nKey}.label`)}</label>}
        <input
          value={value}
          onChange={onChange as ChangeEventHandler<HTMLInputElement>}
          onBlur={onBlur}
          name={name}
          type={type}
          disabled={disabled}
          required={required}
        />
        <span className="FormField-focusIndicator" />
      </div>
      {touched[name] && errors[name] && (
        <p className="FormField-error">{errorMsg}</p>
      )}
    </div>
  );
};

export default TextInput;
