import { IUseApiMutationProps, useApiClient, useApiMutation } from '@network/api/client';
import { useQueryClient } from '@network/queries';
import { toastUtil } from '@utils/toast.utils';
import * as Models from '@network/api/models';

export type TUseUpdatePaymentInfoMutationResponse = { data: Models.PaymentMethod.Data; meta?: { message?: string } };

export type TUseUpdatePaymentInfoMutationProps = Omit<IUseApiMutationProps<TUseUpdatePaymentInfoMutationResponse>, 'mutationKey' | 'mutationFn'>;

export function useUpdatePaymentInfoMutation(props?: TUseUpdatePaymentInfoMutationProps) {
  const { onSuccess, ...restHookProps } = props ?? {};

  const apiClient = useApiClient();
  const queryClient = useQueryClient();

  return useApiMutation({
    ...restHookProps,
    mutationKey: ['paymentInfo', 'update'],
    mutationFn: (data: { stripeToken?: string }) =>
      apiClient.url(`/payment_info`)
        .patch(data)
        .json<TUseUpdatePaymentInfoMutationResponse>()
        .then((response) => response),
    onSuccess: (response, variables, context) => {
      if (response?.meta?.message) toastUtil('info', response.meta.message);
      queryClient.refetchQueries({ queryKey: ['paymentInfo'], exact: false });
      onSuccess?.(response, variables, context);
    },
  });
}
