import React, { useEffect, useMemo, useRef } from 'react';
import type { AnimationConfigWithData } from 'lottie-web';
import lottie from 'lottie-web/build/player/lottie_light';

export interface ILottieSvgOptions extends Omit<AnimationConfigWithData<'svg'>, 'container' | 'renderer'> {}

export interface ILottieSvgProps extends React.HTMLProps<HTMLDivElement> {
  width?: string | number;
  height?: string | number;
  options: ILottieSvgOptions;
}

const LottieSvg = ({ width = '100%', height = '100%', options, ...restProps }: ILottieSvgProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const animation = lottie.loadAnimation({
      ...options,
      container: containerRef.current,
      renderer: 'svg',
    });

    return () => {
      animation.destroy();
    };
  }, [options]);

  const style = useMemo(() => ({
    display: 'flex',
    width,
    height,
  }), [width, height]);

  return <div {...restProps} style={style} ref={containerRef} />;
};

export default LottieSvg;
