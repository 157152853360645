import { useMemo } from 'react';
import { TUseSimulatorsQuery, useSimulatorsQuery } from './useSimulatorsQuery';

export type TUseSimulatorsSelection = TUseSimulatorsQuery;

export function useSimulatorsSelection(props?: TUseSimulatorsQuery) {
  const { data: simulators } = useSimulatorsQuery(props);

  const simulatorsSelection = useMemo(() => simulators.map((simulator) => ({
    value: simulator.value,
    children: simulator.label,
  })), [simulators]);

  return { simulatorsSelection };
}
