import React, { useCallback, useMemo } from 'react';
import mergeClassNames from 'classnames';
import { useWorldMap } from './WorldMap';
import { ReactComponent as MinusIcon } from '../../images/minus-current-color.svg?tsx';
import { ReactComponent as PlusIcon } from '../../images/plus-current-color.svg?tsx';
import styles from './WorldMapZoomControls.module.scss';

export interface IWorldMapZoomControlsClassNames {
  root?: string;
}

export interface IWorldMapZoomControlsProps {
  classNames?: IWorldMapZoomControlsClassNames;
}

const WorldMapZoomControls: React.FC<IWorldMapZoomControlsProps> = (props) => {
  const { classNames } = props;

  const map = useWorldMap();

  const handleZoomIn = useCallback(() => {
    if (!map.instance) return;
    map.instance.zoomIn();
  }, [map]);

  const handleZoomOut = useCallback(() => {
    if (!map.instance) return;
    map.instance.zoomOut();
  }, [map]);

  const rootClassNames = useMemo(
    () => mergeClassNames(styles.root, classNames ? classNames.root : null),
    [classNames],
  );

  return (
    <div className={rootClassNames}>
      <button className={styles.zoomInButton} onClick={handleZoomIn}>
        <PlusIcon />
      </button>
      <button className={styles.zoomOutButton} onClick={handleZoomOut}>
        <MinusIcon />
      </button>
    </div>
  );
};

export default WorldMapZoomControls;
