import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { toastUtil } from '../../../utils/toast.utils';
import { signIn } from '../../../store/customer/customer.actions';
import useWithDispatch from '../../../hooks/useWithDispatch';
import { formModel, initialValues, ISignInFormValues } from './formModel';
import FormField from '../../../components/FormField';
import CheckboxRadio from '../../../components/FormField/CheckboxRadio';
import Button from '../../../components/Button';
import validationSchema from './validationSchema';
import AuthFormMain from '../../../components/AuthFormMain';
import queryString from 'qs';

const FORM_ID = 'signInForm';

const SignInForm = () => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [t] = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const signInAction = useWithDispatch(signIn);
  const formik = useFormik<ISignInFormValues>({
    initialValues,
    validationSchema,
    onSubmit: async (values, formMethods) => {
      try {
        setSubmitting(true);
        await signInAction(values);
        handleQueryParams();
        if (!window.BroadcastChannel) return;
        new BroadcastChannel('onAuth').postMessage(true);
      }
      catch (error) {
        setSubmitting(false);
        const err = error as TRumpApiErrorResponseData;
        if (typeof err?.meta?.message === 'string')
          formMethods.setFieldError('password', err.meta.message);
        if (err?.meta?.errors) {
          err.meta.errors.forEach((e) => {
            return formMethods.setFieldError(e.field, e.messages[0]);
          });
        }
        if (typeof err?.meta?.message === 'string')
          toastUtil('error', err.meta.message);
      }
    },
  });

  const handleQueryParams = () => {
    const params = queryString.parse(location.search.replace('?', ''));

    if (params.redirectTo && typeof params.redirectTo === 'string') {
      navigate(params.redirectTo);
    }
  };

  const isValidForm = validationSchema.isValidSync({ ...formik.values });

  return (
    <AuthFormMain
      title="auth.signIn.title"
      subtitle="auth.signIn.subtitle"
      footer={(
        <>
          <Button
            form={FORM_ID}
            isLoading={submitting}
            type="submit"
            disabled={submitting || !isValidForm}
            label={t('field.signIn')}
          />
          <Link to="/auth/forgot-password">
            {t('auth.forgotPassword.linkToPage')}
          </Link>
        </>
      )}
    >
      <form id={FORM_ID} onSubmit={formik.handleSubmit}>
        {formModel.map((field) => (
          <FormField
            key={field.name}
            i18nKey={field.i18nKey}
            type={field.type}
            errors={formik.errors}
            touched={formik.touched}
            setFieldValue={formik.setFieldValue}
            {...formik.getFieldProps(field.name)}
          />
        ))}
        <CheckboxRadio
          onChange={formik.handleChange}
          name="remember_me"
          value={formik.values.remember_me}
          i18nKey="field.remember_me.label"
        />
      </form>
    </AuthFormMain>
  );
};

export default SignInForm;
