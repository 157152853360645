import { RegisterOptions } from 'react-hook-form';

type TErrors = {
  required?: string;
  minLength: string;
  maxLength: string;
  invalid: string;
};

export const emailValidator: (error: TErrors) => RegisterOptions = ({
  required,
  minLength,
  maxLength,
  invalid,
}: TErrors) => ({
  minLength: { value: 6, message: minLength },
  maxLength: { value: 64, message: maxLength },
  validate: (value: string) => {
    if ((required && !value) || (value && !value.trim())) {
      return required;
    }

    if (!value.includes('@') || value.includes('..')) {
      return invalid;
    }

    const splittedEmail = value
      .toLowerCase()
      .split('@')
      .filter((item) => !!item);

    if (splittedEmail.length < 2) {
      return invalid;
    }

    const local = splittedEmail[0];
    const domain = splittedEmail[1];

    if (
      local.length < 1
      && local.length > 64
      && domain.length < 4
      && domain.length > 255
    ) {
      return invalid;
    }

    if (
      local.startsWith('.')
      || local.endsWith('.')
      || !domain.includes('.')
      || domain.startsWith('-')
      || domain.endsWith('-')
    ) {
      return invalid;
    }

    const validChars = /[a-z0-9.]/;
    const specialChars = '!#$%&\'*+-/=?^_`{|}~"(),:;<>@[\\]';

    if (
      local
        .split('')
        .some((c) => !validChars.test(c) && !specialChars.includes(c))
        || domain.split('').some((c) => !validChars.test(c) && c !== '-')
    ) {
      return invalid;
    }

    const dotIndex = domain.lastIndexOf('.');

    if (dotIndex === -1) {
      return invalid;
    }

    const domainPreDot = domain.slice(0, dotIndex);
    const domainPostDot = domain.slice(dotIndex + 1);

    if (domainPreDot.length < 1 || domainPostDot.length < 2) {
      return invalid;
    }
  },
});
