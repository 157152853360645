import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getChampionships } from '../../store/championships/championships.actions';
import './MobileChampionshipDetails.scss';
import { toastUtil } from '../../utils/toast.utils';
import { Championship } from '../../store/championships/championships.types';

const MobileChampionshipDetails = () => {
  const { lang } = useParams();
  const [championships, setChampionships] = useState<Championship[]>([]);
  const _isMounted = useRef(true);

  const fetchChallanges = async () => {
    try {
      const response = await getChampionships(lang);
      if (_isMounted.current) setChampionships(response);
    }
    catch (err) {
      const error = err as TRumpApiRequestError;
      if ('message' in error && error.message === 'canceled') return;

      const msg = 'meta' in error ? error.meta.message : error.message;
      if (typeof msg === 'string') toastUtil('error', msg);
    }
  };

  useEffect(() => {
    fetchChallanges();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="ru-championships ru-championship-mobile">
      {championships.length > 0
      && championships.map((championship) => (
        <Link
          key={championship.id}
          to={`/mobile/championships/${championship.id}/${lang}`}
          className="ru-championship ru-championship--preview"
        >
          <h3>{championship.title}</h3>
          <img src={championship.featured_image_medium} alt="" />
          <div className="ru-championship-content">
            {championship.description_excerpt}
          </div>
        </Link>
      ))}
    </div>
  );
};

export default MobileChampionshipDetails;
