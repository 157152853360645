import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ImagePlaceholder } from './ImagePlaceholder';
import { useMergedClassNames } from '@utils/styling';
import styles from './Image.module.scss';

const defaults = {
  aspectRatio: 4 / 3,
};

enum EImageStatus {
  Loading = 'loading',
  Loaded = 'loaded',
  Error = 'error',
}

export type TImageProps = Extend<Omit<HTMLProps<HTMLImageElement>, 'placeholder'>, {
  src?: string | null;
  placeholderIcon?: React.ReactNode;
  aspectRatio?: number;
}>;

export const Image = (props: TImageProps) => {
  const {
    src,
    alt,
    width,
    height,
    srcSet,
    sizes,
    loading,
    decoding,
    referrerPolicy,
    crossOrigin,
    useMap,
    aspectRatio = defaults.aspectRatio,
    placeholderIcon,
    className,
    ...restRootProps
  } = props;

  const imageProps = {
    src: src ?? undefined,
    alt,
    width,
    height,
    srcSet,
    sizes,
    loading,
    decoding,
    referrerPolicy,
    crossOrigin,
    useMap,
  };

  const [status, setStatus] = useState(EImageStatus.Loading);

  const imageElementRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    imageElementRef.current?.style
      .setProperty('--Image__root--aspectRatio', aspectRatio?.toString());
  }, [status, aspectRatio]);

  const isLoading = status === EImageStatus.Loading && !imageElementRef.current?.complete;
  const isError = status === EImageStatus.Error;

  useEffect(() => {
    setStatus((prevStatus) => {
      if (src) return prevStatus ?? EImageStatus.Loading;
      return EImageStatus.Error;
    });
  }, [src]);

  const handleLoad = useCallback(() => {
    setStatus(EImageStatus.Loaded);
  }, []);

  const handleError = useCallback(() => {
    setStatus(EImageStatus.Error);
  }, []);

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [className || '']: true,
  });

  const imageClassName = useMergedClassNames({
    [rootClassName]: true,
    [styles.image]: true,
    [styles.image_loading]: isLoading,
  });

  const loadingPlaceholderClassName = useMergedClassNames({
    [rootClassName]: true,
    [styles.loadingPlaceholder]: true,
  });

  if (isError) {
    return (
      <ImagePlaceholder
        {...restRootProps}
        className={rootClassName}
        aspectRatio={aspectRatio}
        icon={placeholderIcon}
      />
    );
  }

  return (
    <>
      {isLoading
        ? (
            <ImagePlaceholder
              {...restRootProps}
              className={loadingPlaceholderClassName}
              aspectRatio={aspectRatio}
              icon={null}
            />
          )
        : null}
      <img
        {...restRootProps}
        {...imageProps}
        className={imageClassName}
        onLoad={handleLoad}
        onError={handleError}
        ref={imageElementRef}
      />
    </>
  );
};
