import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { EStripeCardBrand } from './StripeCardBrandIcon';
import { IStripeCardFieldExposures, StripeCardField } from './StripeCardField';
import { mergeRefs } from '@utils/merge-refs.utils';
import { useMergedClassNames } from '@utils/styling';
import styles from './StripeCard.module.scss';

export interface IStripeCardExposures {
  clear: () => void;
}

export type TStripeCardProps = Extend<Omit<HTMLProps<HTMLDivElement>, 'onChange'>, {
  numberPlaceholder?: string | null;
  brandPlaceholder?: `${EStripeCardBrand}`;
  expiryPlaceholder?: string | null;
  cvcPlaceholder?: string | null;
  onComplete?: (complete: boolean) => void;
  onReady?: () => void;
  numberRootRef?: MutableRef<IStripeCardFieldExposures | null>;
  expiryRootRef?: MutableRef<IStripeCardFieldExposures | null>;
  cvcRootRef?: MutableRef<IStripeCardFieldExposures | null>;
}>;

export const StripeCard = forwardRef<IStripeCardExposures, TStripeCardProps>(
  function StripeCardWithForwardedRef(props, ref) {
    const {
      numberPlaceholder,
      brandPlaceholder,
      expiryPlaceholder,
      cvcPlaceholder,
      onComplete,
      onReady,
      numberRootRef: externalNumberElementRef,
      expiryRootRef: externalExpiryElementRef,
      cvcRootRef: externalCvcElementRef,
      className,
      ...restRootProps
    } = props;

    const onCompleteRef = useRef(onComplete);
    onCompleteRef.current = onComplete;
    const onReadyRef = useRef(onReady);
    onReadyRef.current = onReady;

    const internalNumberRootRef = useRef<IStripeCardFieldExposures | null>(null);
    const internalExpiryRootRef = useRef<IStripeCardFieldExposures | null>(null);
    const internalCvcRootRef = useRef<IStripeCardFieldExposures | null>(null);

    const mergeNumberRootRef = useMemo(
      () => mergeRefs([internalNumberRootRef, externalNumberElementRef]),
      [externalNumberElementRef],
    );

    const mergeExpiryRootRef = useMemo(
      () => mergeRefs([internalExpiryRootRef, externalExpiryElementRef]),
      [externalExpiryElementRef],
    );

    const mergeCvcRootRef = useMemo(
      () => mergeRefs([internalCvcRootRef, externalCvcElementRef]),
      [externalCvcElementRef],
    );

    const [isNumberReady, setIsNumberReady] = useState(false);
    const [isExpiryReady, setIsExpiryReady] = useState(false);
    const [isCvcReady, setIsCvcReady] = useState(false);
    const isReady = isNumberReady && isExpiryReady && isCvcReady;

    const [isNumberComplete, setIsNumberComplete] = useState(false);
    const [isExpiryComplete, setIsExpiryComplete] = useState(false);
    const [isCvcComplete, setIsCvcComplete] = useState(false);
    const isComplete = isNumberComplete && isExpiryComplete && isCvcComplete;

    const handleFieldChange = useCallback(() => {
      if (!isComplete) return;
      onCompleteRef.current?.(isComplete);
    }, [isComplete]);

    const handleNumberReady = useCallback(() => {
      setIsNumberReady(true);
    }, []);

    const handleExpiryReady = useCallback(() => {
      setIsExpiryReady(true);
    }, []);

    const handleCvcReady = useCallback(() => {
      setIsCvcReady(true);
    }, []);

    useImperativeHandle(ref, () => ({
      clear: () => {
        internalNumberRootRef?.current?.clear();
        internalExpiryRootRef?.current?.clear();
        internalCvcRootRef?.current?.clear();
      },
    }), []);

    useEffect(() => {
      onCompleteRef.current?.(isComplete);
    }, [isComplete]);

    useEffect(() => {
      if (!isReady) return;
      onReadyRef.current?.();
    }, [isReady]);

    const rootClassName = useMergedClassNames({
      [styles.root]: true,
      [className || '']: true,
    });

    return (
      <div {...restRootProps} className={rootClassName}>
        <StripeCardField
          StripeElement={CardNumberElement}
          label="fields.card_number.label"
          placeholder={numberPlaceholder}
          brandPlaceholder={brandPlaceholder}
          onChange={handleFieldChange}
          onBlur={handleFieldChange}
          onComplete={setIsNumberComplete}
          onReady={handleNumberReady}
          rootRef={mergeNumberRootRef}
        />
        <div className={styles.expiryAndCvcRow}>
          <StripeCardField
            className={styles.field}
            StripeElement={CardExpiryElement}
            label="fields.card_expiry.label"
            placeholder={expiryPlaceholder}
            onChange={handleFieldChange}
            onBlur={handleFieldChange}
            onComplete={setIsExpiryComplete}
            onReady={handleExpiryReady}
            rootRef={mergeExpiryRootRef}
          />
          <StripeCardField
            className={styles.field}
            StripeElement={CardCvcElement}
            label="fields.card_cvc.label"
            placeholder={cvcPlaceholder}
            onChange={handleFieldChange}
            onBlur={handleFieldChange}
            onComplete={setIsCvcComplete}
            onReady={handleCvcReady}
            rootRef={mergeCvcRootRef}
          />
        </div>
      </div>
    );
  },
);
