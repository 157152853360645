import { toastUtil } from '@utils/toast.utils';
import merge from 'lodash/merge';
import createHttpClient from 'wretch';
import FormDataAddon from 'wretch/addons/formData';

export function createApiClient() {
  // TODO: Move env variables into fail-fast wrapper.
  return createHttpClient(import.meta.env.REACT_APP_API)
    .addon(FormDataAddon)
    .errorType('json')
    .middlewares([
      (next) => async (url, options) => {
        const token = localStorage.getItem('ruToken');

        if (!token) {
          return await next(url, options);
        }

        return await next(
          url,
          merge(options, {
            headers: { 'x-auth-token': token },
          }),
        );
      },
    ])
    .catcherFallback((error) => {
      const data = error.json as TRumpApiErrorResponseData;

      if (typeof data?.meta?.message === 'string') {
        toastUtil('error', data.meta.message);
      }

      if (data) {
        return Promise.reject(data);
      }

      return Promise.reject(error);
    });
}
