export default {
  application: 'Aplicación',
  home: 'Inicio',
  packages: 'Paquetes',
  membership: 'Membresía',
  ranking: 'Clasificación',
  championships: 'Campeonatos',
  events: 'Eventos',
  notifications: 'Notificaciones',
  noNotifications: 'Sin Notificaciones',
  userSettings: 'Configuración de Usuario',
  logOut: 'Cerrar Sesión',
  profileData: 'Datos del Perfil',
  profile: 'Perfil',
  changePassword: 'Cambiar Contraseña',
  deleteAccount: 'Eliminar Cuenta',
  payments: 'Pagos',
  paymentMethods: 'Métodos de Pago',
  paymentHistory: 'Historial de Pagos',
  newBooking: 'Nueva Reserva',
  yourProducts: 'Tus Productos',
  yourPackages: 'Tus Paquetes',
  yourMembership: 'Tu Membresía',
  yourStatistics: 'Tus Estadísticas',
  yourPaymentMethod: 'Tu Método de Pago',
  experience: 'Experiencia',
  track: 'Pista',
  markAllAsRead: 'Marcar Todo Como Leído',
  yourUpcomingExperiences: 'Tus Próximas Experiencias',
  draftEvents: 'Eventos Borradores',
  delete: 'Eliminar',
  resume: 'Reanudar',
  renew: 'Renovar',
  cancel: 'Cancelar',
  showAll: 'Mostrar Todo',
  buyNow: 'Comprar Ahora',
  payNow: 'Pagar Ahora',
  buyNew: 'Comprar Nuevo',
  bookARide: 'Reservar un Viaje',
  buyPackage: 'Comprar Paquete',
  buyMembership: 'Comprar Membresía',
  renewMembership: 'Renovar Membresía',
  payment: 'Pago',
  lounge: 'Salón',
  chosenProduct: 'Producto Elegido',
  chooseProduct: 'Elegir Producto',
  choosePaymentMethod: 'Elegir Método de Pago',
  addNewPaymentMethod: 'Agregar Nuevo Método de Pago',
  addPaymentMethod: 'Agregar Método de Pago',
  uploadNewPicture: 'Subir Nueva Imagen',
  otherCreditCard: 'Otra Tarjeta de Crédito',
  deleteAvatar: 'Eliminar Avatar',
  avatar: 'Avatar',
  personalData: 'Datos Personales',
  address: 'Dirección',
  racing: 'Carreras',
  edit: 'Editar',
  save: 'Guardar',
  backToEdit: 'Volver a Editar',
  language: 'Idioma',
  singleRide: 'Viaje Individual',
  groupRide: 'Viaje en Grupo',
  ridesLeft: '{{rides}} viajes restantes',
  durationInMinutes: '{{duration}} min',
  deletePaymentMethod: 'Eliminar Método de Pago',
  pricesAreIncludingVat: 'Los precios incluyen el {{vat}}% de IVA.',
  incompleteProfileDataMessage: 'Recientemente cambiamos nuestro sistema de plataforma, le pedimos amablemente que actualice su perfil con los detalles faltantes.',
  changesCancelConfirmation: {
    title: 'Los cambios no se guardarán',
    message: '¿Está seguro de que desea cancelar?',
  },
  deleteAccountConfirmation: {
    title: '¿Está seguro de que desea eliminar su cuenta?',
    message: 'Esta acción es irreversible y perderá todos sus datos y contenido relacionado con esta cuenta.',
  },
  deletePaymentMethodConfirmation: {
    title: '¿Está seguro de que desea eliminar su método de pago?',
    message: 'Esta acción es irreversible y ya no verá los detalles de su método de pago guardado.',
  },
};
