import React, { useMemo } from 'react';
import { Typography } from '@generics/content';
import { Card, TCardProps } from '@generics/surfaces';
import {
  MembershipIcon,
  PackagesIcon,
  PersonIcon,
  PersonsGroupIcon,
} from '@images/icons';
import * as Models from '@network/api/models';
import { useFormatDatetime } from '@utils/localizing';
import { useMergedClassNames } from '@utils/styling';
import styles from './PaymentCard.module.scss';

const paymentsPurposesIcons = {
  [Models.Invoice.ProductType.Membership]: <MembershipIcon />,
  [Models.Invoice.ProductType.Package]: <PackagesIcon />,
  [Models.Invoice.ProductType.SingleRide]: <PersonIcon />,
  [Models.Invoice.ProductType.GroupRide]: <PersonsGroupIcon />,
};

export type TPaymentCardProps = Extend<TCardProps, {
  purpose?: `${Models.Invoice.ProductType}`;
  title?: string;
  date?: string;
  currency?: string;
  price?: string;
  method?: string;
}>;

export const PaymentCard = (props: TPaymentCardProps) => {
  const {
    purpose,
    title,
    date,
    currency,
    price,
    method,
    className,
    ...restRootProps
  } = props;

  const formatDatetime = useFormatDatetime();

  const formattedDate = useMemo(
    () => formatDatetime({ date, format: 'dd MMMM yyyy' }),
    [formatDatetime, date],
  );

  const priceWithCurrency = useMemo(() => `${currency} ${price}`, [currency, price]);

  const iconClassName = useMergedClassNames({
    [styles.icon]: true,
    [styles.icon_membershipPurpose]: purpose === Models.Invoice.ProductType.Membership,
    [styles.icon_packagePurpose]: purpose === Models.Invoice.ProductType.Package,
    [styles.icon_singleRidePurpose]: purpose === Models.Invoice.ProductType.SingleRide,
    [styles.icon_groupRidePurpose]: purpose === Models.Invoice.ProductType.GroupRide,
  });

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [className || '']: true,
  });

  return (
    <Card {...restRootProps} className={rootClassName}>
      <div className={iconClassName}>
        {paymentsPurposesIcons[purpose as Models.Invoice.ProductType]}
      </div>
      <div className={styles.productDetails}>
        <Typography type="body/medium/m">{title}</Typography>
        <Typography type="body/regular/s" color="secondary">{formattedDate}</Typography>
      </div>
      <div className={styles.paymentDetails}>
        <Typography type="body/medium/m">{priceWithCurrency}</Typography>
        <Typography type="body/regular/s" color="secondary">{method}</Typography>
      </div>
    </Card>
  );
};
