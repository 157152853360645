export enum ERideBookingStep {
  location = 'location',
  experience = 'experience',
  summary = 'summary',
}

export enum ERideBookingNumericStep {
  Location,
  Experience,
  Summary,
}
