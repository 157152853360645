import classNames from 'classnames';
import { subYears } from 'date-fns';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-GB';
import es from 'date-fns/locale/es';
import { FormikErrors, FormikTouched, FormikValues } from 'formik';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';

interface Datepicker {
  name: string;
  i18nKey: string;
  value: string;
  errors?: FormikErrors<FormikValues>;
  errorMessage?: string;
  touched?: FormikTouched<FormikValues>;
  onChange?: (name: string, date: Date | null) => void;
  disabled?: boolean;
  selectedDate?: Date;
  className?: string;
}

const Datepicker = ({
  name,
  value,
  i18nKey,
  disabled,
  touched,
  errors = {},
  errorMessage,
  onChange,
  selectedDate,
  className,
}: Datepicker) => {
  // const [t] = useTranslation();

  const [t, i18next] = useTranslation();
  const errorTrans = typeof errors?.[name] === 'string' ? t(`${i18nKey}.${errors[name]}`) : '';
  const errorMsg
    = typeof errors?.[name] === 'string' && errorTrans === `${i18nKey}.${errors?.[name]}` ? errors[name] : errorTrans;

  let locale = null;
  switch (i18next.language) {
    case 'de':
      locale = de;
      break;
    case 'es':
      locale = es;
      break;
    default:
      locale = en;
      break;
  }

  return (
    <div
      className={classNames('FormField', name, { 'has-value': value, 'is-disabled': disabled }, className)}
      data-name={name}
    >
      {i18nKey && <label>{t(`${i18nKey}.label`)}</label>}
      <div className="FormField-datepicker">
        <DatePicker
          selected={selectedDate}
          onChange={(date) => { onChange?.(name, date); }}
          dateFormat="dd.MM.yyyy"
          locale={locale}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          maxDate={subYears(new Date(), 13)}
        />
      </div>
      {((touched?.[name] && errors?.[name]) || errorMessage) && (
        <p className="FormField-error">
          {errorMessage ? errorMessage : errorMsg}
        </p>
      )}
    </div>
  );
};

export default Datepicker;
