import React, { useEffect, useMemo, useCallback, useState } from 'react';
import BaseCard, { IBaseCardProps } from '../BaseCard/BaseCard';
import styles from './LoungeStep.module.scss';
import { Lounge } from '../../../store/lounge/lounge.types';
import SearchBox from '../../SearchBox';
import { useFormContext } from 'react-hook-form';
import { IEventBookForm } from '../../../views/EventBooking/EventBooking';
import { useLoungeSearch } from '../../../hooks/useLoungeSearch';
import LoungeCard from '../../LoungeCard/LoungeCard';
import Button from '../../Button';
import { useTranslation } from 'react-i18next';
import MapDialog from '../../../views/RideBooking/components/MapDialog/MapDialog';
import { ReactComponent as MapIcon } from '../../../images/map.svg?tsx';

export interface ILoungeStepProps
  extends Pick<
    IBaseCardProps,
    'collapsed' | 'onForward' | 'onBack' | 'invalid'
  > {}

const LoungeStep: React.FC<ILoungeStepProps> = (props) => {
  const { collapsed, onForward } = props;
  const [t] = useTranslation();
  const { lounges, filteredLounges, search, setSearch } = useLoungeSearch();
  const { watch, setValue } = useFormContext<IEventBookForm>();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const selectedLoungeId = watch('loungeId');

  const selectedLounge = useMemo(
    () => lounges.find(({ id }) => selectedLoungeId === id),
    [selectedLoungeId, lounges],
  );

  const selectLounge = useCallback(
    (lounge: Lounge) => {
      const { id, available_simulators } = lounge;

      setValue('loungeId', id);
      setValue('simulatorsAmount', available_simulators);
    },
    [setValue],
  );

  const onSelectedViaPopup = useCallback(
    (lounge: Lounge) => {
      selectLounge(lounge);
      closeDialog();
      if (onForward) onForward();
    },
    [selectLounge, onForward],
  );

  useEffect(() => {
    if (!selectedLounge) return;
    selectLounge(selectedLounge);
  }, [selectedLounge, selectLounge]);

  const closeDialog = () => setDialogOpen(false);
  const openDialog = () => setDialogOpen(true);

  const collapsedData = useMemo(
    () =>
      collapsed && selectedLounge
        ? (
            selectedLounge.name
          )
        : (
            <Button
              appearance="outline"
              label={t('booking.steps.location.mapButton')}
              icon={<MapIcon />}
              onClick={openDialog}
            />
          ),
    [collapsed, selectedLounge, t],
  );

  return (
    <BaseCard
      alwaysDisplayTitleData
      title="booking.steps.lounge.title"
      forwardTitle="booking.steps.lounge.forwardButton"
      collapseData={collapsedData}
      {...props}
      onForward={selectedLoungeId ? props.onForward : undefined}
    >
      <div className={styles.loungeStep}>
        <SearchBox
          className={styles.loungeStep__search}
          value={search}
          onChange={setSearch}
        />
        <div className={styles.loungeStep__list}>
          {filteredLounges.map((lounge) => (
            <LoungeCard
              key={lounge.id}
              lounge={lounge}
              description={lounge.lounge_marketing}
              features={lounge.features}
              selected={lounge.id === selectedLoungeId}
              onSelect={selectLounge}
            />
          ))}
        </div>
      </div>
      <MapDialog
        displayAddress={true}
        displayPrice={false}
        globalSelectionTitle={t('booking.steps.lounge.forwardButton')}
        open={dialogOpen}
        onClose={closeDialog}
        onLoungeSelect={onSelectedViaPopup}
      />
    </BaseCard>
  );
};

export default LoungeStep;
