import React from 'react';
import styles from './ThankYouStep.module.scss';
import { useTranslation } from 'react-i18next';

const ThankYouStep: React.FC = () => {
  const [t] = useTranslation();

  return (
    <div className={styles.thankYouStep}>
      <h1 className={styles.thankYouStep__title}>
        {t('eventParticipantDriver.thankYou.title')}
        {' '}
        <span role="img" aria-label="gift">
          🎁
        </span>
      </h1>
      <p className={styles.thankYouStep__info}>
        <b>{t('eventParticipantDriver.thankYou.details.firstLine')}</b>
        <br />
        <br />
        {t('eventParticipantDriver.thankYou.details.secondLine')}
        <br />
        <br />
        {t('eventParticipantDriver.thankYou.details.thirdLine')}
        {' '}
        <span role="img" aria-label="car">
          🏎️
        </span>
      </p>
    </div>
  );
};
export default ThankYouStep;
