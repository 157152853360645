import React, { forwardRef, useCallback, useMemo } from 'react';
import { Typography } from '@generics/content';
import { Loader, ELoaderColor } from '@generics/Loader';
import { useMergedClassNames } from '@utils/styling';
import styles from './Button.module.scss';

export enum EButtonSeverity {
  Primary = 'primary',
  Danger = 'danger',
}

export enum EButtonSize {
  Inline = 'inline',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum EButtonDesign {
  Solid = 'solid',
  Outline = 'outline',
  Text = 'text',
  Link = 'link',
}

export enum EButtonIconPlacement {
  Left = 'left',
  Right = 'right',
}

export type TButtonProps<RootElementType extends React.ElementType> = {
  as?: RootElementType;
  severity?: `${EButtonSeverity}`;
  size?: `${EButtonSize}`;
  design?: `${EButtonDesign}`;
  icon?: React.ReactNode;
  iconPlacement?: `${EButtonIconPlacement}`;
  label?: string;
  children?: React.ReactNode;
  fullWidth?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
};

const withForwardedRef: (render: <RootElementType extends React.ElementType = 'button'>(
  props: TButtonProps<RootElementType>
  & Omit<React.ComponentPropsWithoutRef<RootElementType>, keyof TButtonProps<RootElementType>>,
  ref: React.ForwardedRef<RootElementType>) => React.ReactNode) => <RootElementType extends React.ElementType = 'button'>(props: TButtonProps<RootElementType>
  & Omit<React.ComponentPropsWithRef<RootElementType>, keyof TButtonProps<RootElementType>>) => React.ReactNode = forwardRef;

export const Button = withForwardedRef(
  function Button(
    props,
    ref,
  ) {
    const {
      as,
      type,
      severity = `${EButtonSeverity.Primary}`,
      size = `${EButtonSize.Medium}`,
      design = `${EButtonDesign.Solid}`,
      icon,
      iconPlacement = `${EButtonIconPlacement.Right}`,
      label,
      onClick,
      fullWidth = false,
      isLoading = false,
      disabled = false,
      className,
      ...restRootProps
    } = props;

    const RootComponent = useMemo(() => as || 'button', [as]);

    const computedType = useMemo(() => {
      if (RootComponent !== 'button') return undefined;
      return (type ?? 'button') as HTMLProps<HTMLButtonElement>['type'];
    }, [RootComponent, type]);

    const hasOnlyIcon = useMemo(() => !label, [label]);

    const wrappedIcon = useMemo(
      () => icon ? <span className={styles.icon}>{icon}</span> : null,
      [icon],
    );

    const loaderColor = useMemo(() => {
      if (design === EButtonDesign.Solid) return ELoaderColor.White;
      return ELoaderColor.MediumBlue;
    }, [design]);

    const isDisabled = isLoading || disabled;

    const rootClassName = useMergedClassNames({
      [styles.root]: true,

      [styles.root_primarySeverity]: severity === EButtonSeverity.Primary,
      [styles.root_dangerSeverity]: severity === EButtonSeverity.Danger,

      [styles.root_inlineSize]: size === EButtonSize.Inline,
      [styles.root_smallSize]: size === EButtonSize.Small,
      [styles.root_mediumSize]: size === EButtonSize.Medium,
      [styles.root_largeSize]: size === EButtonSize.Large,

      [styles.root_solidDesign]: design === EButtonDesign.Solid,
      [styles.root_outlineDesign]: design === EButtonDesign.Outline,
      [styles.root_textDesign]: design === EButtonDesign.Text,
      [styles.root_linkDesign]: design === EButtonDesign.Link,

      [styles.root_fullWidth]: fullWidth,
      [styles.root_iconOnly]: hasOnlyIcon,
      [styles.root_disabled]: isDisabled,
      [className || '']: true,
    });

    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
      if (isDisabled) return;
      // TODO: Fix typings.
      (onClick as React.MouseEventHandler<HTMLButtonElement>)?.(event);
    }, [isDisabled, onClick]);

    const rootProps = useMemo(
      () => ({
        ...restRootProps,
        className: rootClassName,
        type: computedType,
        onClick: handleClick,
        disabled: isDisabled,
        ref,
      }) as React.ComponentPropsWithoutRef<typeof RootComponent>,
      [restRootProps, isDisabled, computedType, ref, rootClassName, handleClick],
    );

    return (
      <RootComponent {...rootProps}>
        {
          isLoading
            ? (
                <Loader
                  color={loaderColor}
                  width="2.25em"
                  height="2.25em"
                />
              )
            : (
                <>
                  {iconPlacement === EButtonIconPlacement.Left ? wrappedIcon : null}
                  {label ? <Typography type="button/s" color="current">{label}</Typography> : null}
                  {iconPlacement === EButtonIconPlacement.Right ? wrappedIcon : null}
                </>
              )
        }
      </RootComponent>
    );
  },
);
