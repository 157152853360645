type TAddEventListenerParams = Parameters<typeof document.addEventListener>;
type TRemoveEventListenerParams = Parameters<typeof document.removeEventListener>;

function subscribe<EventName extends string, EventListener>(name: EventName, listener: EventListener) {
  document.addEventListener(name as TAddEventListenerParams[0], listener as TAddEventListenerParams[1]);
}

function unsubscribe<EventName extends string, EventListener>(name: EventName, listener: EventListener) {
  document.removeEventListener(name as TRemoveEventListenerParams[0], listener as TRemoveEventListenerParams[1]);
}

function publish<EventName extends string, Data>(eventName: EventName, data: Data) {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
}

export { publish, subscribe, unsubscribe };
