import { usePageTitle } from '@features/metadata';
import EventDetails from '@routes/Events/EventDetails';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { accountEventRoute } from './route';

export interface IEventPageProps extends Omit<React.HTMLProps<HTMLDivElement>, 'children'> {}

export const EventPage = (props: IEventPageProps) => {
  const { ...restRootProps } = props;

  const [t] = useTranslation();
  usePageTitle(t(accountEventRoute.title));

  return (
    <div {...restRootProps}>
      <EventDetails />
    </div>
  );
};
