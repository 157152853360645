import React from 'react';
import { useBackButtonLocation } from '@features/metadata';
import { ChangePasswordForm } from '@features/settings';
import styles from './SettingsChangePasswordPage.module.scss';

export const SettingsChangePasswordPage = () => {
  useBackButtonLocation('settings');

  return <ChangePasswordForm className={styles.form} />;
};
