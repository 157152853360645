// class="TermsPolicy-margin"
const terms = {
  data: `<h2>Términos y condiciones generales</h2>
    <h2>Introducción</h2>
    <p class="TermsPolicy-margin">Términos y condiciones generales para el uso de las salas de Racing Unleashed</p>
    <h2>Ámbito</h2>
    <p>
    Los presentes Términos y condiciones resultan de aplicación a contratos para el alquiler a
    corto plazo de las instalaciones y otras infraestructuras de las salas de Racing Unleashed
    para la celebración de eventos corporativos y el uso de los simuladores de carreras
    disponibles en estas salas, para la organización de eventos corporativos, y cualquier otro
    servicio y producto relacionado.
    </p>
    <p >
    En general, el subarriendo o realquiler de las salas, áreas o infraestructuras provistas no
    está previsto y solo se permitirá con el consentimiento escrito de Racing Unleashed AG.</p>
    <p class="TermsPolicy-margin">La modificación de las disposiciones se aplicará únicamente si se acuerda de manera
    explícita y previa por escrito.
    </p>
    <h2>Celebración del contrato</h2>
    <p>
    El contrato de utilización y servicio (en adelante denominado el “contrato”) se formula
    mediante la aceptación escrita por parte del cliente de la oferta realizada por Racing
    Unleashed AG.
    </p>
    <p>
    Si el beneficiario no fuese la persona que realiza el pedido, el cliente será responsable de
    manera conjunta y solidaria con el beneficiario de todas las obligaciones resultantes del
    contrato. El cliente debe notificar a Racing Unleashed AG la identidad del cliente y el
    beneficiario y proporcionar a Racing Unleashed el nombre y dirección del beneficiario real
    con tiempo suficiente antes de la celebración del contrato. En este caso, el cliente debe
    presentar a Racing Unleashed AG una declaración adecuada del beneficiario.
    </p>
    <p class="TermsPolicy-margin">
    Si un contrato se celebrase con más de un socio contractual, estos Términos y condiciones
    generales resultarán de aplicación a todos los socios contractuales. En particular, todos los
    socios contractuales de Racing Unleashed AG serán responsables de manera conjunta y
    solidaria de las obligaciones contractuales.
    </p>
    <h2>Servicios</h2>
    <p>Racing Unleashed AG se compromete a prestar los servicios solicitados por el socio
    contractual (el cliente) y prometidos por Racing Unleashed AG.</p>
    <p>El socio contractual se compromete a abonar los precios acordados para estos y otros
    servicios disfrutados. </p>
    <p>Los servicios incluyen:</p>
    <ol class="TermsPolicy-margin">
      <li>Uso de la sala especificada en el contrato</li>
      <li>Instrucción y coordinación de diferentes terceros (supervisores de simulador y servicios de
        catering).</li>
      <li>Un miembro del equipo de gestión de eventos actuará como persona de contacto
      responsable durante el evento.</li>
      <li>Limpieza final de la sala tras el evento.</li>
    </ol>
    <h2>Duración de los servicios</h2>
    <p class="TermsPolicy-margin">Las instalaciones de Racing Unleashed AG pueden utilizarse por horas, para eventos de
    media jornada (mañana o tarde) y para eventos que duren todo el día. Si se excediese la
    duración acordada para el evento, Racing Unleashed AG podría facturar los costes internos
    y externos resultantes de manera adicional.</p>
    <h2>Estipulación de precios</h2>
    <p class="TermsPolicy-margin">Los precios indicados en la oferta o confirmación se basan en el estado de conocimiento o
    estado del proyecto en el momento de realizar la oferta o confirmación. Los precios
    indicados en el momento de realización de la oferta resultarán de aplicación. En caso de que
    el ámbito cambie tras la celebración del contrato, estos cambios se facturarán a las tarifas
    aplicables en el momento de dicha celebración del contrato.</p>
    <h2>Facturación</h2>
    <p>Todo servicio adicional o deseo in situ solicitado por el socio contractual que no esté incluido
    en la oferta básica, por ejemplo, extensión de catering o trabajo adicional encargado por el
    socio contractual, será provisto por Racing Unleashed AG en la medida de lo posible. Estos
    se facturarán a precio de coste. </p>
    <p>Los servicios acordados asociados con el uso serán facturados por Racing Unleashed AG
    tras el evento correspondiente y deberán ser abonados en su totalidad en un periodo de 30
    días tras la fecha de facturación. En caso de demora, Racing Unleashed AG tendrá derecho
    a cobrar un 5% de interés por retraso en el pago. </p>
    <p>La compensación por reclamaciones realizadas por el socio contractual solo será posible en
    caso de acuerdo previo por escrito. </p>
    <p>En caso de que en la oferta no se indique IVA, los precios acordados no incluirán IVA. </p>
    <p class="TermsPolicy-margin">Racing Unleashed AG tiene derecho a exigir el pago de un anticipo adecuado tras la
    celebración del contrato. El importe del anticipo y las fechas de pago deberán acordarse por
    escrito.</p>
    <h2>Cancelación por parte del socio contractual</h2>
    <p >
    En caso de que el socio contractual cancelase el contrato antes de la celebración del
    evento, deberá abonar las compensaciones siguientes:<br/>
    </p>
    <ol class="TermsPolicy-margin">
        <li>30 días antes del evento: 80% del precio del paquete (precio total)</li>
        <li>15 días antes del evento: 100% del precio del paquete (precio total)</li>
        <li> Nos reservamos el derecho de exigir otras compensaciones.</li>
    </ol>
    <h2>Cancelación por parte de Racing Unleashed AG</h2>
    <p >En caso de que el socio contractual incumpliese los plazos o fechas de pago acordados
    contractualmente, Racing Unleashed AG tendrá derecho a cancelar el contrato con efecto
    inmediato</p>
    <p >Racing Unleashed AG tendrá asimismo derecho a cancelar el contrato de manera
    fundamentada conforme a las disposiciones legales o en caso de violación por parte del
    socio contractual de una o varias de las disposiciones de los presentes Términos y
    condiciones generales y/o el contrato entre Racing Unleashed AG y el socio contractual,
    principalmente en caso de:<br/>
    a) Causa de fuerza mayor u otras circunstancias más allá del control de Racing Unleashed
    AG que imposibiliten el cumplimiento del contrato.<br/>
    b) Que los eventos se reserven en base a declaraciones falsas o engañosas sobre hechos
    materiales, por ejemplo, el organizador o el propósito real.<br/>
    c) Que Racing Unleashed AG cuente con fundamentos razonables para considerar que el
    evento podría comprometer la adecuada ejecución de sus operaciones empresariales,
    seguridad o la reputación de la compañía. </p>
    <p class="TermsPolicy-margin">En caso de cancelación justificada por parte de Racing Unleashed AG, el socio contractual
    no tendrá derecho a reclamar compensación alguna.</p>
    <h2>Cambios en el número de participantes, salas o periodos de uso</h2>
    <p>Los cambios en el número de participantes se deben indicar a Racing Unleashed AG al
    menos cinco días hábiles antes del inicio del evento. En caso de cambio, se aplicarán los
    puntos siguientes:</p>
    <ol class="TermsPolicy-margin">
        <li>Los aumentos en el número de participantes requieren el consentimiento escrito previo de
        Racing Unleashed AG. Todo coste adicional resultante de los mismos correrá a cargo del
        socio contractual.</li>
        <li>En caso de una reducción en el número de participantes, Racing Unleashed AG tendrá
        derecho a cobrar los costes confirmados por el socio contractual al aceptar la oferta de
        Racing Unleashed AG.</li>
        <li> Toda solicitud de cambio por parte del socio contractual/organizador en relación con las
        salas, su uso y/o equipamiento, incluyendo infraestructura técnica, realizada con poca
        antelación antes o durante el evento estará sujeta a cargos.</li>
    </ol>
    <h2>Catering/alimentos y bebidas del exterior</h2>
    <p>El catering será encargado por Racing Unleashed AG.</p>
    <p class="TermsPolicy-margin">En general, el socio contractual no puede traer alimentos o bebidas a los eventos. Las
    excepciones requieren el acuerdo escrito de Racing Unleashed AG. En estos casos, el coste
    de poner la infraestructura a disposición se cobrará según lo acordado.</p>
    <h2>Asistencia técnica adicional</h2>
    <p class="TermsPolicy-margin">En caso de que la tecnología existente no fuese suficiente, toda instalación técnica adicional
    será llevada a cabo exclusivamente por Racing Unleashed AG o sus correspondientes
    colaboradores. Si Racing Unleashed AG debiese procurar equipamiento técnico adicional u
    otros servicios al socio contractual, lo hará en favor del socio contractual y corriendo dicho
    socio contractual con los gastos correspondientes. El socio contractual se compromete a
    reembolsar a Racing Unleashed AG todos los gastos y desembolsos en los que esta incurra
    para el adecuado cumplimiento del contrato y a eximir a Racing Unleashed AG de toda
    responsabilidad asociada.</p>
    <h2>Responsabilidad</h2>
    <p>El socio contractual es responsable ante Racing Unleashed AG de cualquier pérdida y daño
    causados en construcciones, simuladores o inventario por los participantes en el evento o
    visitantes, empleados, otros terceros asociados con el socio contractual o sus
    representantes legales.</p>
    <p>Racing Unleashed AG podría exigir al socio contractual la provisión de garantías adecuadas
    (por ejemplo, seguros, depósitos o avales) para cubrir posibles daños</p>
    <p>Racing Unleashed AG no acepta responsabilidad alguna asociada con pérdidas, robos o
    daños en prendas u objetos, especialmente los pertenecientes al socio contractual, sus
    empleados, asistentes o invitados.</p>
    <p>Las instalaciones puestas a su disposición por Racing Unleashed AG están sujetas a
    requisitos relacionados con la seguridad, incendios, policía y cuestiones operativas. El socio
    contractual debe asimismo cumplir con los mismos en todo momento.</p>
    <p>Todo objeto u otras pertenencias, incluyendo efectos personales que pudiera traer al evento
    el socio contractual, serán responsabilidad del socio contractual durante la estancia en las
    instalaciones. Racing Unleashed AG no acepta responsabilidad alguna asociada con
    pérdidas o daños, excepto en caso de negligencia manifiesta o intención maliciosa. Todo
    material aportado a un evento, por ejemplo, rotafolios y notas manuales, artículos
    promocionales, documentos de todo tipo, etc., deberán retirarse de manera inmediata y
    adecuada al finalizar el evento</p>
    <p>Cualquier daño o suciedad excesiva causado por el socio contractual será reparado o
    limpiado por Racing Unleashed AG a cargo del socio contractual tras la finalización del
    evento. Cualquier objeto olvidado tras el evento puede ser retirado y almacenado por Racing
    Unleashed AG a cargo del socio contractual.</p>
    <p class="TermsPolicy-margin">Si la retirada implica un gasto o esfuerzo desproporcionadamente elevado, Racing
    Unleashed AG podrá almacenar los artículos y cobrar el alquiler de la sala hasta su
    recuperación. Racing Unleashed AG se reserva el derecho a demostrar que los daños
    mayores se han producido. Cualquier documento olvidado se conservará durante un periodo
    máximo de dos días, periodo tras el cual se desecharán a cargo del socio contractual.</p>
    <h2>Seguro</h2>
    <p class="TermsPolicy-margin">El socio contractual se compromete a contratar un seguro de propiedad y a terceros para
    protección ante cualquier daño personal o material. Racing Unleashed AG tendrá derecho a
    revisar la póliza correspondiente. En caso de considerar que la cobertura del seguro no
    resulta suficiente, podría exigir la ampliación de las coberturas en consecuencia. La
    normativa de protección de datos de Racing Unleashed AG (ver apéndice) resultará de
    aplicación.</p>
    <h2>Disposiciones finales</h2>
    <p>Toda modificación o adición a los contratos establecidos entre Racing Unleashed AG y el
    socio contractual, la aceptación de ofertas realizadas por Racing Unleashed AG o estos
    Términos y condiciones resultará válida únicamente si se realiza por escrito y requiere el
    consentimiento de todas las partes contractuales</p>
    <p>El lugar de ejecución y pago será la sede social de Racing Unleashed AG, situada en 6330
    Cham, Suiza. </p>
    <p>El lugar de jurisdicción será 6300 Zug, Suiza.</p>
    <p>Se aplicará la legislación suiza. </p>
    <p>En caso de que alguna disposición individual de los presentes Términos y condiciones
    generales carezca de eficacia o validez, esto no afectará a la eficacia del resto de
    disposiciones de estos. En todos los demás aspectos, se aplicarán las disposiciones legales.</p>
  `,
};

export default terms;
