import { TUseApiQueryProps, useApiClient, useApiQuery } from '@network/api/client';
import { WretchError } from 'wretch';

export type CustomerStatisticResponse = {
  data: {
    experiences: number;
    laps: number;
    tracks: number;
  };
};

export type TUseCustomerStatisticQuery = Omit<TUseApiQueryProps<CustomerStatisticResponse['data'], WretchError>, 'queryKey' | 'queryFn'>;

export function useCustomerStatisticQuery(props?: TUseCustomerStatisticQuery) {
  const apiClient = useApiClient();

  const { data, ...restQuery } = useApiQuery({
    placeholderData: { experiences: 0, laps: 0, tracks: 0 },
    ...props,
    queryKey: ['customers', 'me', 'statistic'],
    queryFn: () => apiClient.url('/dashboard/customer_info').get().json<CustomerStatisticResponse>().then(({ data }) => data),
  });

  return {
    data: data ?? { experiences: 0, laps: 0, tracks: 0 },
    ...restQuery,
  };
}
