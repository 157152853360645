import React, {
  useMemo,
  useEffect,
  useCallback,
  useState,
  useRef,
  TouchEvent,
  useLayoutEffect,
} from 'react';
import mergeClassNames from 'classnames';
import {
  SwipeableDrawer,
  SwipeableDrawerProps,
  PaperProps as TPaperProps,
} from '@mui/material';
import styles from './SwipableEdgeBottomDrawer.module.scss';
import { createPortal } from 'react-dom';
import { ReactComponent as CancelIcon } from '../../images/cancel-current-color.svg?tsx';

export type TSwipableEdgeBottomDrawerClasses =
  SwipeableDrawerProps['classes'] & {
    swipeArea?: string;
    edgeContent?: string;
  };

export enum ESwipeableEdgeBottomDrawerState {
  Opened = 'opened',
  Opening = 'opening',
  Closing = 'closing',
  Closed = 'closed',
}

export interface ISwipableEdgeBottomDrawerProps
  extends Omit<
    SwipeableDrawerProps,
    | 'anchor'
    | 'keepMounted'
    | 'onOpen'
    | 'onClose'
    | 'SwipeAreaProps'
    | 'classes'
  > {
  classes?: TSwipableEdgeBottomDrawerClasses;
  edgeChildren?: React.ReactNode;
  edgePinned?: boolean;
  offset?: number;
  onOpened?: () => void;
  onOpening?: () => void;
  onClosing?: () => void;
  onClosed?: () => void;
  SwipeAreaProps?: Record<string, unknown>;
}

const edgeHandleHeight = 14;

const SwipableEdgeBottomDrawer: React.FC<ISwipableEdgeBottomDrawerProps> = (
  props,
) => {
  const {
    classes,
    open,
    onOpened,
    onClosed,
    onOpening,
    onClosing,
    edgeChildren,
    edgePinned,
    children,
    hysteresis = 0.25,
    disableSwipeToOpen = false,
    offset = 0,
    PaperProps,
    SlideProps,
    SwipeAreaProps,
    ...restProps
  } = props;

  const {
    swipeArea: swipeAreaClassName,
    edgeContent: edgeContentClassName,
    ...restClasses
  } = useMemo(() => classes || {}, [classes]);

  const edgeElementRef = useRef<HTMLDivElement | null>(null);
  const [isEdgeElementMounted, setIsEdgeElementMounted] = useState(false);
  const [measuredEdgeHeight, setMeasuredEdgeHeight] = useState<number | null>(
    null,
  );

  const handleEdgeResize = useCallback((entries: ResizeObserverEntry[]) => {
    for (const entry of entries) {
      setMeasuredEdgeHeight(entry.borderBoxSize[0].blockSize);
      return;
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!edgeElementRef.current) return;
      setIsEdgeElementMounted(true);
      clearInterval(intervalId);
    }, 100);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!isEdgeElementMounted || !edgeElementRef.current) return;
    const edgeElement = edgeElementRef.current;
    const resizeObserver = new ResizeObserver(handleEdgeResize);
    resizeObserver.observe(edgeElement);

    return () => {
      resizeObserver.unobserve(edgeElement);
    };
  }, [isEdgeElementMounted, handleEdgeResize]);

  const swipeAreaSize = useMemo(
    () => (measuredEdgeHeight ? measuredEdgeHeight : 20),
    [measuredEdgeHeight],
  );

  const drawerElementRef = useRef<HTMLDivElement | null>(null);
  const paperElementRef = useRef<HTMLDivElement | null>(null);

  const queryPaperElement = useCallback(() => {
    if (!drawerElementRef.current) return null;
    if (!paperElementRef.current) {
      paperElementRef.current
        = drawerElementRef.current.querySelector<HTMLDivElement>('[data-paper]');
    }
    return paperElementRef.current;
  }, []);

  const {
    ...restSlideProps
  } = useMemo(() => SlideProps || {}, [SlideProps]);

  const handleOpened = useCallback(() => {
    onOpened?.();
  }, [onOpened]);

  const handleIntermediateState = useCallback(
    ({
      state,
      isTouch,
    }: {
      state:
        | ESwipeableEdgeBottomDrawerState.Closing
        | ESwipeableEdgeBottomDrawerState.Opening;
      isTouch: boolean;
    }) => {
      const paperElement = queryPaperElement();
      if (!paperElement) return;
      if (paperElement.dataset.state === state) return;
      paperElement.dataset.swiping = isTouch ? 'true' : 'false';
      paperElement.dataset.state = state;
      paperElement.dataset.transitioning = 'true';

      if (state === ESwipeableEdgeBottomDrawerState.Opening) {
        onOpening?.();
      }

      if (state === ESwipeableEdgeBottomDrawerState.Closing) {
        onClosing?.();
      }
    },
    [queryPaperElement, onOpening, onClosing],
  );

  const handleClosed = useCallback(() => {
    onClosed?.();
  }, [onClosed]);

  const handleTransitionEntered = useCallback(() => {
    const paperElement = queryPaperElement();
    if (!paperElement) return;
    if (paperElement.dataset.swiping === 'true') return;
    paperElement.dataset.state = ESwipeableEdgeBottomDrawerState.Opened;
    paperElement.dataset.transitioning = 'false';
  }, [queryPaperElement]);

  const handleTransitionExited = useCallback(() => {
    const paperElement = queryPaperElement();
    if (!paperElement) return;
    if (paperElement.dataset.swiping === 'true') return;
    paperElement.dataset.state = ESwipeableEdgeBottomDrawerState.Closed;
    paperElement.dataset.transitioning = 'false';
  }, [queryPaperElement]);

  const handleTransitionCancel = useCallback(() => {
    const paperElement = queryPaperElement();
    if (!paperElement) return;
    paperElement.dataset.transitioning = 'false';

    if (
      paperElement.dataset.state === ESwipeableEdgeBottomDrawerState.Closing
    ) {
      handleOpened();
      paperElement.dataset.state = ESwipeableEdgeBottomDrawerState.Opened;
      return;
    }

    if (
      paperElement.dataset.state === ESwipeableEdgeBottomDrawerState.Opening
    ) {
      handleClosed();
      paperElement.dataset.state = ESwipeableEdgeBottomDrawerState.Closed;
      return;
    }
  }, [handleOpened, handleClosed, queryPaperElement]);

  const handleSwipeAreaTouchStart = useCallback(() => {
    handleIntermediateState({
      state: ESwipeableEdgeBottomDrawerState.Opening,
      isTouch: true,
    });
  }, [handleIntermediateState]);

  const handleSwipeAreaTouchEnd = useCallback(() => {
    const paperElement = queryPaperElement();
    if (!paperElement) return;
    paperElement.dataset.swiping = 'false';
    if (paperElement.dataset.transitioning === 'false') return;
    handleTransitionCancel();
  }, [queryPaperElement, handleTransitionCancel]);

  const handleSwipeAreaClick = useCallback(() => {
    handleOpened();
  }, [handleOpened]);

  const handlePaperTouchStart = useCallback(() => {
    handleIntermediateState({
      state: ESwipeableEdgeBottomDrawerState.Closing,
      isTouch: true,
    });
  }, [handleIntermediateState]);

  const handlePaperTouchEnd = useCallback(() => {
    const paperElement = queryPaperElement();
    if (!paperElement) return;
    paperElement.dataset.swiping = 'false';
    if (paperElement.dataset.transitioning === 'false') return;
    handleTransitionCancel();
  }, [queryPaperElement, handleTransitionCancel]);

  useEffect(() => {
    const paperElement = queryPaperElement();
    if (!paperElement) return;
    paperElement.dataset.state = open
      ? ESwipeableEdgeBottomDrawerState.Opened
      : ESwipeableEdgeBottomDrawerState.Closed;
  }, [queryPaperElement, open]);

  const [isTransitionEnabled, setIsTransitionEnabled] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsTransitionEnabled(true);
    }, 100);
  }, []);

  const mergedClasses = useMemo(
    () => ({
      ...restClasses,
      root: mergeClassNames(styles.root, restClasses ? restClasses.root : null),
      paper: mergeClassNames(
        styles.paper,
        {
          [styles.paper_disabledTransitions]: !isTransitionEnabled,
        },
        restClasses ? restClasses.paper : null,
      ),
    }),
    [restClasses, isTransitionEnabled],
  );

  const mergedSwipeAreaProps = useMemo(
    () => ({
      ...SwipeAreaProps,
      className: mergeClassNames(
        styles.swipeArea,
        swipeAreaClassName,
        SwipeAreaProps ? SwipeAreaProps.className! : null,
      ),
      onClick: handleSwipeAreaClick,
      onTouchStart: handleSwipeAreaTouchStart,
      onTouchEnd: handleSwipeAreaTouchEnd,
    }),
    [
      swipeAreaClassName,
      SwipeAreaProps,
      handleSwipeAreaClick,
      handleSwipeAreaTouchStart,
      handleSwipeAreaTouchEnd,
    ],
  );

  const mergedPaperProps = useMemo(
    () =>
      ({
        ...PaperProps,
        onTouchStart: handlePaperTouchStart,
        onTouchMove: handlePaperTouchStart,
        onTouchEnd: handlePaperTouchEnd,
        'data-swiping': 'true',
        'data-state': ESwipeableEdgeBottomDrawerState.Closed,
        'data-paper': '',
      } as TPaperProps),
    [PaperProps, handlePaperTouchStart, handlePaperTouchEnd],
  );

  const mergedSlideProps = useMemo(
    () => ({
      ...restSlideProps,
      onEntered: handleTransitionEntered,
      onExited: handleTransitionExited,
    }),
    [restSlideProps, handleTransitionEntered, handleTransitionExited],
  );

  useLayoutEffect(() => {
    if (!drawerElementRef.current) return;
    drawerElementRef.current.style.setProperty(
      '--SwipableEdgeBottomDrawer--swipeAreaSize',
      `${swipeAreaSize}px`,
    );
    drawerElementRef.current.style.setProperty(
      '--SwipableEdgeBottomDrawer--offset',
      `${offset}px`,
    );
    drawerElementRef.current.style.setProperty(
      '--SwipableEdgeBottomDrawer--closedHeight',
      '100dvh',
    );
    drawerElementRef.current.style.setProperty(
      '--SwipableEdgeBottomDrawer--openedHeight',
      '95dvh',
    );
  }, [swipeAreaSize, offset]);

  const handleCloseButtonTouchStart = useCallback(
    (event: TouchEvent<HTMLButtonElement>) => {
      event.stopPropagation();
    },
    [],
  );

  const handleCloseButtonClick = useCallback(() => {
    handleClosed();
  }, [handleClosed]);

  const mergedEdgeContentClassName = useMemo(
    () =>
      mergeClassNames(
        {
          [styles.edge__content]: true,
          [styles.edge__content_pinned]: edgePinned,
        },
        edgeContentClassName || '',
      ),
    [edgePinned, edgeContentClassName],
  );

  const mergedContentClassName = useMemo(
    () =>
      mergeClassNames({
        [styles.content]: true,
        [styles.content_withPinnedEdge]: edgePinned,
      }),
    [edgePinned],
  );

  const edgeContent = useMemo(
    () => (
      <div className={mergedEdgeContentClassName} ref={edgeElementRef}>
        {edgeChildren}
        <div style={{ height: offset }} />
      </div>
    ),
    [mergedEdgeContentClassName, edgeChildren, offset],
  );

  return (
    <SwipeableDrawer
      {...restProps}
      open={open}
      onOpen={handleOpened}
      onClose={handleClosed}
      anchor="bottom"
      keepMounted
      disableSwipeToOpen={disableSwipeToOpen}
      disableDiscovery
      swipeAreaWidth={swipeAreaSize + edgeHandleHeight}
      classes={mergedClasses}
      SwipeAreaProps={mergedSwipeAreaProps}
      hysteresis={hysteresis}
      ref={drawerElementRef}
      PaperProps={mergedPaperProps}
      SlideProps={mergedSlideProps}
    >
      <div className={styles.container}>
        <div className={styles.edge}>
          <div className={styles.edge__handle} />
          {edgePinned ? createPortal(edgeContent, document.body) : edgeContent}
          {edgePinned ? <div style={{ height: swipeAreaSize }} /> : null}
        </div>
        <div className={mergedContentClassName}>
          <button
            className={styles.closeButton}
            onTouchStart={handleCloseButtonTouchStart}
            onClick={handleCloseButtonClick}
          >
            <CancelIcon />
          </button>
          {children}
        </div>
      </div>
    </SwipeableDrawer>
  );
};

export default SwipableEdgeBottomDrawer;
