import React, { useCallback, useMemo, useRef } from 'react';
import { Avatar } from '@generics/content';
import { Button } from '@generics/inputs/button';
import { UploadIcon } from '@images/icons';
import { useMergedClassNames } from '@utils/styling';
import styles from './AvatarInput.module.scss';

export type TAvatarInputProps = Extend<HTMLProps<HTMLDivElement>, {
  value?: string | null;
  onChange?: (value: string | null) => void;
}>;

// TODO: Properly handle inputRef.

export const AvatarInput = (props: TAvatarInputProps) => {
  const { value, onChange, className, ...restRootProps } = props;

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const fileTypes = useMemo(() => ['jpg', 'jpeg', 'png'], []);

  // TODO: Rework to handle validation with Yup.
  const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;

    if (!files?.length) return;

    const fileExtension
      = files[0].name
        .toLowerCase()
        .split('.')
        .pop();

    if (fileExtension && !fileTypes.includes(fileExtension)) {
      console.log('error file type');
      return;
    }
    if (files[0].size / Math.pow(1024, 2) > 5) {
      console.log('error size of img');
      return;
    }

    const reader = new FileReader();
    reader.addEventListener('load', (e) => {
      if (e?.target?.result instanceof ArrayBuffer) {
        const decoder = new TextDecoder('utf-8');
        const decodedResult = decoder.decode(e.target.result);
        onChange?.(decodedResult);
      }

      if (typeof e?.target?.result === 'string') {
        onChange?.(e.target.result);
      }

      if (fileInputRef.current) fileInputRef.current.value = '';
    });
    reader.readAsDataURL(files[0]);
  }, [fileTypes, onChange]);

  const handleUploadButtonClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [className || '']: true,
  });

  return (
    <div {...restRootProps} className={rootClassName}>
      <input
        className={styles.fileInput}
        type="file"
        onChange={handleFileChange}
        ref={fileInputRef}
      />
      <Avatar
        className={styles.avatar}
        src={value}
      />
      <Button
        label="labels.uploadNewPicture"
        icon={<UploadIcon />}
        design="outline"
        onClick={handleUploadButtonClick}
      />
    </div>
  );
};
