import { WretchError } from 'wretch';
import { TUseApiQueryProps, useApiClient, useApiQuery } from '@network/api/client';
import * as Models from '@network/api/models';

export type PaymentHistoryResponse = {
  data: Models.Invoice.Data[];
};

export type TUsePaymentHistoryQuery = Omit<TUseApiQueryProps<PaymentHistoryResponse['data'], WretchError>, 'queryKey' | 'queryFn'>;

export function usePaymentHistoryQuery(props?: TUsePaymentHistoryQuery) {
  const apiClient = useApiClient();

  const { data, ...restQuery } = useApiQuery({
    placeholderData: [],
    ...props,
    queryKey: ['invoices', 'all'],
    queryFn: () => apiClient.url('/invoices/payments_history')
      .get()
      .json<PaymentHistoryResponse>()
      .then(({ data }) => data),
  });

  return {
    data: data ?? [],
    ...restQuery,
  };
}
