import React from 'react';
import { useTranslation } from 'react-i18next';
import Copy from '../../../components/Copy';
import './SideInfo.scss';
import logo from '../../../images/racing-unleashed-logo.svg';

const SideInfo = () => {
  const [t] = useTranslation();

  return (
    <div className="SideInfo">
      <div className="SideInfo-content">
        <img src={logo} alt="Racing Unleashed" />
        <h2>{t('auth.sideInfo.title')}</h2>
        <p>{t('auth.sideInfo.p5')}</p>
        <p>{t('auth.sideInfo.p1')}</p>
        <p>{t('auth.sideInfo.p2')}</p>
        <p>{t('auth.sideInfo.p3')}</p>
        <p>{t('auth.sideInfo.p4')}</p>

        <Copy white />
      </div>
    </div>
  );
};

export default SideInfo;
