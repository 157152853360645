import { EventActionType, GET_EVENTS, EventState } from './events.types';

const initialState: EventState = {
  events: [],
};

export default (state = initialState, action: EventActionType) => {
  switch (action.type) {
    case GET_EVENTS:
      return {
        events: action.payload,
      };
    default:
      return state;
  }
};
