import React from 'react';
import { EventsIcon } from '@images/icons';
import { createRouteDefinition } from '@utils/routing';

export const accountEventsRoute = createRouteDefinition({
  uid: 'events',
  path: '/account/events',
  icon: <EventsIcon />,
  title: 'labels.events',
});
