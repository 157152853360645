import React, { useCallback } from 'react';
import { useBackButtonLocation } from '@features/metadata';
import styles from './SettingsLanguagePage.module.scss';
import { useAuthentication } from '@features/authentication';
import { useTranslation } from 'react-i18next';
import { GermanyFlagIcon, SpainFlagIcon, UnitedKingdomFlagIcon } from '@images/flags';
import { CheckIcon } from '@images/icons';
import { Card } from '@generics/surfaces';
import { Typography } from '@generics/content';

const languages = [
  {
    id: 'en',
    name: 'English',
    icon: <UnitedKingdomFlagIcon />,
  },
  {
    id: 'es',
    name: 'Spanish',
    icon: <SpainFlagIcon />,
  },
  {
    id: 'de',
    name: 'German',
    icon: <GermanyFlagIcon />,
  },
];

export const SettingsLanguagePage = () => {
  useBackButtonLocation('settings');

  const { updateAuthenticatedCustomer } = useAuthentication();
  const [, i18next] = useTranslation();

  const getLanguageChangeHandler = useCallback(
    (languageId: string) => () => {
      updateAuthenticatedCustomer({ preferred_language: languageId });
      i18next.changeLanguage(languageId);
    },
    [updateAuthenticatedCustomer, i18next],
  );

  return (
    <div className={styles.root}>
      {languages.map((language) => (
        <Card className={styles.language} key={language.id} onClick={getLanguageChangeHandler(language.id)}>
          <Typography className={styles.language__label} type="body/regular/m">
            {language.icon}
            <span>{language.name}</span>
          </Typography>
          {i18next.language === language.id ? <CheckIcon /> : null}
        </Card>
      ))}
    </div>
  );
};
