import React from 'react';
import SignUpForm from './SignUpForm';
import AuthLinks from '../../components/AuthLinks';
import SignPage from '../../components/SignPage';

const SignUp = () => {
  return (
    <>
      <SignPage isSignUp>
        <SignUpForm />
      </SignPage>
      <AuthLinks />
    </>
  );
};

export default SignUp;
