import React from 'react';
import styles from './BookingDetailsList.module.scss';
import { useTranslation } from 'react-i18next';

export type BookingDetail = {
  label: string;
  value: string | number;
  caption?: string;
};

type TBookingDetailsListProps = {
  details: BookingDetail[];
};

const BookingDetailsList: React.FC<TBookingDetailsListProps> = ({
  details,
}) => {
  const [t] = useTranslation();

  return (
    <div className={styles.details}>
      {details.map(({ label, value, caption }) => (
        <div key={label} className={styles.detail}>
          <span className={styles.detail__title}>{t(label)}</span>
          <span className={styles.detail__value}>{value}</span>
          {
            caption
              ? (

                  <span className={styles.detail__caption}>{caption}</span>
                )
              : ''
          }
        </div>
      ))}
    </div>
  );
};

export default BookingDetailsList;
