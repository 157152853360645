import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import policyEn from '../../i18n/en/policy';
import policyDe from '../../i18n/de/policy';
import policyEs from '../../i18n/sp/policy';
import termsEn from '../../i18n/en/terms';
import termsDe from '../../i18n/de/terms';
import termsEs from '../../i18n/sp/terms';
import './TermsPrivacy.scss';
import { useParams } from 'react-router-dom';

interface ITermsPrivacyProps {
  isMobilePage?: boolean;
  isTerms?: boolean;
}

interface TermsPrivacyContent {
  [key: string]: {
    [key: string]: {
      data: string;
    };
  };
}

const termsPrivacyContent: TermsPrivacyContent = {
  terms: {
    en: termsEn,
    de: termsDe,
    es: termsEs,
  },
  policy: {
    en: policyEn,
    de: policyDe,
    es: policyEs,
  },
};

const TermsPrivacy = ({
  isMobilePage,
  isTerms,
}: ITermsPrivacyProps) => {
  const params = useParams<{ lang?: string }>();
  const [t] = useTranslation();

  const source
    = params.lang
      ? termsPrivacyContent[isTerms ? 'terms' : 'policy'][params.lang].data
      : t(`${isTerms ? 'terms' : 'policy'}.data`);

  return (
    <div className={`TermsPolicy ${isMobilePage ? 'TermsPolicy-mobile' : ''}`}>
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>{source}</ReactMarkdown>
    </div>
  );
};

export default TermsPrivacy;
