import React, { useCallback, useMemo } from 'react';
import { useValidator } from '@utils/validation';
import { Card } from '@generics/surfaces';
import { Button } from '@generics/inputs/button';
import { Form, FormField, useForm } from '@generics/forms';
import { useUpdateCustomerMutation } from '@network/api';
import styles from './ChangePasswordForm.module.scss';

interface IChangePasswordData {
  password: string | null;
  password_confirmation: string | null;
}

export interface IChangePasswordFormProps {
  className?: string;
}

export const ChangePasswordForm = (props: IChangePasswordFormProps) => {
  const { className } = props;

  const validator = useValidator();

  const validationSchema = useMemo(() => validator.object({
    password: validator
      .string()
      .required()
      .min(8),
    password_confirmation: validator
      .string()
      .required()
      .same('password'),
  }), [validator]);

  const form = useForm({
    defaultValues: {
      password: null,
      password_confirmation: null,
    } as IChangePasswordData,
    validationSchema,
  });

  const { reset: resetForm, setApiValidationErrors: setFormApiValidationErrors } = form;

  const updateCustomerMutation = useUpdateCustomerMutation({
    onValidationErrors: setFormApiValidationErrors,
  });

  const handleValidSubmit = useCallback(async (data: IChangePasswordData) => {
    await updateCustomerMutation.mutateAsync(data);
    resetForm();
    setTimeout(() => {
      updateCustomerMutation.reset();
    }, 0);
  }, [updateCustomerMutation, resetForm]);

  return (
    <Card className={className}>
      <Form className={styles.form} form={form} onValidSubmit={handleValidSubmit}>
        <FormField
          name="password"
          type="password"
        />
        <FormField
          name="password_confirmation"
          type="password"
        />
        <Button
          type="submit"
          label="labels.changePassword"
          fullWidth
          disabled={updateCustomerMutation.isSuccess}
          isLoading={updateCustomerMutation.isPending}
        />
      </Form>
    </Card>
  );
};
