import React from 'react';
import styles from './OptionCard.module.scss';
import classNames from 'classnames';

interface IOptionCardProps {
  selected: boolean;
  className: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

const OptionCard: React.FC<IOptionCardProps> = ({
  selected,
  className,
  children,
  onClick,
}) => {
  return (
    <div
      className={classNames(styles.card, className)}
      data-selected={selected}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default OptionCard;
