import React, { useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import SideInfo from './SideInfo';
import ActivationMessage from './ActivationMessage';
import SignIn from '../SignIn';
import SignUp from '../SignUp';
import ForgotPassword from '../ForgotPassword';
import ResetPassword from '../ResetPassword';
import AccountActivation from '../AccountActivation';
import useWithSelection from '../../hooks/useWithSelection';
import { customerSelector } from '../../store/customer/customer.selectors';

import './Auth.scss';

const Auth = () => {
  const navigate = useNavigate();
  const customer = useWithSelection(customerSelector);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState<string>('');

  const closeDialog = () => {
    navigate('/auth/sign-in');
    setIsOpen(false);
  };

  const openDialog = (msg: string) => {
    setIsOpen(true);
    setSuccessMsg(msg);
  };

  if (customer) {
    if (customer.profile_data_incomplete) {
      return (
        <Navigate
          to={{
            pathname: '/profile',
          }}
          state={{ data_incomplete: true }}
        />
      );
    }
    else {
      return <Navigate to="/" />;
    }
  }

  return (
    <div className="Auth">
      <Dialog onClose={closeDialog} open={isOpen} className="ru-dialog">
        <ActivationMessage msg={successMsg} onClose={closeDialog} />
      </Dialog>
      <SideInfo />
      <div className="Auth-content">
        <Routes>
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route
            path="/forgot-password"
            element={<ForgotPassword />}
          />
          <Route
            path="/reset-password/:customer_id/:token"
            element={<ResetPassword />}
          />
          <Route
            path="/activation/:customer_id/:token"
            element={<AccountActivation openDialog={openDialog} />}
          />
          <Route
            path="/silent-member-activation/:customer_id/:token"
            element={<SignUp />}
          />
          <Route index element={<SignIn />} />
        </Routes>
      </div>
    </div>
  );
};

export default Auth;
