import React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { useMergedClassNames } from '@utils/styling';
import styles from './Flex.module.scss';

export enum EFlexDirection {
  Row = 'row',
  Column = 'column',
}

export enum EFlexGap {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum EFlexJustify {
  Start = 'start',
  End = 'end',
  Center = 'center',
  SpaceBetween = 'between',
  SpaceAround = 'around',
  SpaceEvenly = 'evenly',
  Stretch = 'stretch',
}

export enum EFlexAlign {
  Start = 'start',
  End = 'end',
  Center = 'center',
  Stretch = 'stretch',
  Baseline = 'baseline',
}

export enum EFlexAlignSelf {
  Start = 'start',
  End = 'end',
  Center = 'center',
  Stretch = 'stretch',
  Baseline = 'baseline',
}

export type TFlexProps = Extend<HTMLProps<HTMLDivElement>, {
  asChild?: boolean;
  direction?: `${EFlexDirection}`;
  gap?: `${EFlexGap}`;
  justify?: `${EFlexJustify}`;
  align?: `${EFlexAlign}`;
  alignSelf?: `${EFlexAlignSelf}`;
  grow?: boolean;
}>;

export const Flex = (props: TFlexProps) => {
  const {
    asChild,
    direction,
    gap,
    justify,
    align,
    alignSelf,
    grow,
    children,
    className,
    ...restRootProps
  } = props;

  const RootComponent = asChild ? Slot : 'div';

  const rootClassName = useMergedClassNames({
    [styles.root]: true,

    [styles.root_rowDirection]: direction === EFlexDirection.Row,
    [styles.root_columnDirection]: direction === EFlexDirection.Column,

    [styles.root_smallGap]: gap === EFlexGap.Small,
    [styles.root_mediumGap]: gap === EFlexGap.Medium,
    [styles.root_largeGap]: gap === EFlexGap.Large,

    [styles.root_startJustify]: justify === EFlexJustify.Start,
    [styles.root_endJustify]: justify === EFlexJustify.End,
    [styles.root_centerJustify]: justify === EFlexJustify.Center,
    [styles.root_betweenJustify]: justify === EFlexJustify.SpaceBetween,
    [styles.root_aroundJustify]: justify === EFlexJustify.SpaceAround,
    [styles.root_evenlyJustify]: justify === EFlexJustify.SpaceEvenly,

    [styles.root_startAlign]: align === EFlexAlign.Start,
    [styles.root_endAlign]: align === EFlexAlign.End,
    [styles.root_centerAlign]: align === EFlexAlign.Center,
    [styles.root_stretchAlign]: align === EFlexAlign.Stretch,
    [styles.root_baselineAlign]: align === EFlexAlign.Baseline,

    [styles.root_startAlignSelf]: alignSelf === EFlexAlignSelf.Start,
    [styles.root_endAlignSelf]: alignSelf === EFlexAlignSelf.End,
    [styles.root_centerAlignSelf]: alignSelf === EFlexAlignSelf.Center,
    [styles.root_stretchAlignSelf]: alignSelf === EFlexAlignSelf.Stretch,
    [styles.root_baselineAlignSelf]: alignSelf === EFlexAlignSelf.Baseline,

    [styles.root_grow]: grow,
    [className || '']: true,
  });

  return (
    <RootComponent {...restRootProps} className={rootClassName}>
      {children}
    </RootComponent>
  );
};
