import React, { useMemo } from 'react';
import { ScaleStep } from './ScaleStep';
import { useMergedClassNames } from '@utils/styling';
import styles from './Scale.module.scss';

export type TScaleProps = Extend<HTMLProps<HTMLDivElement>, {
  total: number;
  active?: number;
}>;

export const Scale = (props: TScaleProps) => {
  const { total, active = 0, className, ...restRootProps } = props;

  const steps = useMemo(() => Array.from({ length: total }).map((_, index) => ({
    key: Math.random(),
    active: index < active,
  })), [total, active]);

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [className || '']: true,
  });

  return (
    <div {...restRootProps} className={rootClassName}>
      {steps.map((step) => (
        <ScaleStep {...step} key={step.key} />
      ))}
    </div>
  );
};
