import React from 'react';
import { LogoutIcon } from '@images/icons';
import { createRouteDefinition } from '@utils/routing';

export const logoutRoute = createRouteDefinition({
  uid: 'logout',
  path: '/logout',
  icon: <LogoutIcon />,
  title: 'labels.logOut',
});
