import React, { useMemo, useCallback, useRef, useEffect, useTransition, useState } from 'react';
import { format, formatISO, parseISO } from 'date-fns';
import styles from './LinearCalendar.module.scss';
import { EnhancedDate } from '../../utils/enhanced-date.utils';
import { ICalendarDayProps } from './CalendarDay';
import HorizontalScrollContainer from '../HorizontalScrollContainer/HorizontalScrollContainer';
import IntersectionObserver from '@researchgate/react-intersection-observer';
import MultipleIntersectionObserver, {
  IOnChangeProps,
} from '../MultipleIntersectionObserver/MultipleIntersectionObserver';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { dateLocales } from '../../constants/dateLocales';
import { useDisableableEffect } from '../../hooks/useDisableableEffect';
import { useVirtualizer } from '@tanstack/react-virtual';
import Loader from '../Loader';
import { useCalendarYears } from './CalendarYearsProvider';
import { LinearCalendarChunk } from './LinearCalendarChunk';
import { chunk } from 'lodash';

export interface ILinerCalendarOnGenerateChunkProps {
  from: Date;
  to: Date;
}

export interface ILinearCalendarProps {
  startDate?: Date;
  amountDatesPerChunk?: number;
  selectedDate?: Date;
  onDateSelect?: (date: Date) => void;
  datesData?: Record<
    string,
    Omit<ICalendarDayProps, 'date' | 'selected' | 'onSelect'>
  >;
  onDatesRangeDataRequest?: (props: ILinerCalendarOnGenerateChunkProps) => void;
  defaultGeneratedCalendarDayProps?: Omit<
    ICalendarDayProps,
    'date' | 'onSelect'
  >;
  isLoading?: boolean;
  isDataRequestsAllowed?: boolean;
}

const defaults = {
  startDate: new Date(),
  amountDatesPerChunk: 10,
  selectedDate: null,
  datesData: {},
  onDateSelect: () => {},
  onDatesRangeDataRequest: () => {},
  defaultGeneratedCalendarDayProps: {},
  isLoading: false,
  isDataRequestsAllowed: true,
} as unknown as Required<ILinearCalendarProps>;

const approximateCalendarDateWidthWithGapes = 78 + 16;

const LinearCalendar = (props: ILinearCalendarProps) => {
  const {
    amountDatesPerChunk = defaults.amountDatesPerChunk,
    startDate = defaults.startDate,
    onDateSelect = defaults.onDateSelect,
    selectedDate = defaults.selectedDate,
    datesData = defaults.datesData,
    onDatesRangeDataRequest = defaults.onDatesRangeDataRequest,
    defaultGeneratedCalendarDayProps = defaults.defaultGeneratedCalendarDayProps,
    isLoading = defaults.isLoading,
    isDataRequestsAllowed = defaults.isDataRequestsAllowed,
  } = props;

  const { dates, generateNextYear } = useCalendarYears();

  const [, i18n] = useTranslation();
  const [isGeneratingDates, setIsGeneratingDates] = useState(false);

  const handleGenerationTriggerIntersect = useCallback(
    (entry: IntersectionObserverEntry) => {
      if (isGeneratingDates || !entry.isIntersecting) return;
      setIsGeneratingDates(true);
      generateNextYear();
      setIsGeneratingDates(false);
    },
    [generateNextYear, isGeneratingDates],
  );

  const enhancedSelectedDate = useMemo(
    () => (selectedDate ? new EnhancedDate(selectedDate) : null),
    [selectedDate],
  );

  const horizontalScrollContainerContentElementRef
    = useRef<HTMLDivElement | null>(null);

  const datesElementRef = useRef<HTMLDivElement | null>(null);
  const datesOffsetElementRef = useRef<HTMLDivElement | null>(null);

  const [, startTransition] = useTransition();
  const [mergedDatesData, setMergedDatesData] = useState<Record<string, Omit<ICalendarDayProps, 'date' | 'onSelect'>>>({});
  const selectedDateAsIsoString = useMemo(() => selectedDate ? formatISO(selectedDate) : undefined, [selectedDate]);

  const formatToYearMonth = useCallback(
    (date: Date) =>
      format(date, 'MMMM yyyy', {
        locale: dateLocales[i18n.language],
      }),
    [i18n],
  );

  const initialTitle = useMemo(
    () => formatToYearMonth(startDate),
    [formatToYearMonth, startDate],
  );

  const titleElementRef = useRef<HTMLDivElement | null>(null);

  const handleCalendarDaysIntersection = useMemo(
    () =>
      debounce(({ items }: IOnChangeProps<string>) => {
        if (!titleElementRef.current) return;

        const intersectionRates = Array.from(items.values()).reduce(
          (rates, isoMonth) => {
            rates[isoMonth] = (rates[isoMonth] || 0) + 1;
            return rates;
          },
          {} as Record<string, number>,
        );

        const isoMonthWithHighestRate = Object.keys(intersectionRates).reduce(
          (maxIsoMonth, isoMonth) =>
            !maxIsoMonth
            || intersectionRates[isoMonth] > intersectionRates[maxIsoMonth]
              ? isoMonth
              : maxIsoMonth,
          null as string | null,
        );

        if (!isoMonthWithHighestRate) return;

        titleElementRef.current.innerText = formatToYearMonth(
          parseISO(isoMonthWithHighestRate),
        );
      }, 250),
    [formatToYearMonth],
  );

  const datesAvailableToDisplay = useMemo(() => {
    const startDateIndex = dates.findIndex((date) => date.isoDate === new EnhancedDate(startDate)?.isoDate);
    return dates.slice(startDateIndex);
  }, [startDate, dates]);

  const datesChunks = useMemo(
    () => chunk(datesAvailableToDisplay, amountDatesPerChunk)
      .map((chunkDates, index) => ({
        index,
        key: chunkDates.map((date) => date.isoDate).join('|'),
        dates: chunkDates,
      })),
    [datesAvailableToDisplay, amountDatesPerChunk],
  );

  const datesChunksVirtualizer = useVirtualizer({
    count: datesChunks.length,
    getScrollElement: () => horizontalScrollContainerContentElementRef.current,
    estimateSize: () => approximateCalendarDateWidthWithGapes * amountDatesPerChunk,
    horizontal: true,
    overscan: 2,
  });

  const virtualChunksLength = datesChunksVirtualizer.getVirtualItems()?.length;
  const firstVirtualDateIndex = datesChunksVirtualizer.getVirtualItems()?.[0]?.index;

  const datesToDisplay = useMemo(
    () => datesChunksVirtualizer.getVirtualItems().flatMap((virtualChunk) => datesChunks[virtualChunk.index].dates),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [datesChunksVirtualizer, datesChunks, firstVirtualDateIndex, virtualChunksLength],
  );

  useEffect(() => {
    startTransition(() => {
      const mergedData = datesToDisplay.reduce((mergedData, date) => {
        const data
          = datesData[date.isoDate] || defaultGeneratedCalendarDayProps || {};

        return { ...mergedData, [date.isoDate]: { ...data } };
      }, {} as Record<string, Omit<ICalendarDayProps, 'date' | 'onSelect'>>);

      const enhancedSelectedDate = selectedDateAsIsoString ? new EnhancedDate(selectedDateAsIsoString) : undefined;

      if (enhancedSelectedDate?.isoDate && enhancedSelectedDate?.isoDate in mergedData) {
        mergedData[enhancedSelectedDate.isoDate].selected = !isLoading && !mergedData[enhancedSelectedDate.isoDate].loading;
      }

      setMergedDatesData(mergedData);
    });
  }, [
    isLoading,
    datesData,
    defaultGeneratedCalendarDayProps,
    datesToDisplay,
    selectedDateAsIsoString,
  ]);

  const scrollToSelectedDate = useCallback(() => {
    if (!enhancedSelectedDate) return true;
    if (!horizontalScrollContainerContentElementRef.current || isLoading)
      return false;

    const targetChunk = datesChunks.find((chunk) => chunk.key.includes(enhancedSelectedDate.isoDate));
    if (!targetChunk) return false;

    const [virtualChunkOffset] = datesChunksVirtualizer.getOffsetForIndex(targetChunk.index, 'start') as [number, string];
    if (Number.isNaN(Number(virtualChunkOffset))) return false;
    datesChunksVirtualizer.scrollToOffset(virtualChunkOffset);

    const dateElement
      = horizontalScrollContainerContentElementRef.current.querySelector<HTMLElement>(
        `[data-iso-date="${enhancedSelectedDate.isoDate}"]`,
      );
    if (!dateElement) return false;
    horizontalScrollContainerContentElementRef.current.scrollTo({
      left: dateElement.offsetLeft,
    });

    return true;
  }, [isLoading, enhancedSelectedDate, datesChunksVirtualizer, datesChunks]);

  useDisableableEffect(
    (disableEffect) => {
      const isScrolled = scrollToSelectedDate();
      if (isScrolled) disableEffect();
    },
    [scrollToSelectedDate, firstVirtualDateIndex],
  );

  datesElementRef.current?.style.setProperty('width', `${datesChunksVirtualizer.getTotalSize()}px`);

  return (
    <div className={styles.root}>
      <h4 className={styles.title} ref={titleElementRef}>
        {initialTitle}
      </h4>
      <HorizontalScrollContainer
        contentElementRef={horizontalScrollContainerContentElementRef}
      >
        <div className={styles.datesChunks} ref={datesElementRef}>
          <div className={styles.datesChunksOffset} ref={datesOffsetElementRef} />
          <MultipleIntersectionObserver onChange={handleCalendarDaysIntersection}>
            {datesChunksVirtualizer.getVirtualItems().map((virtualChunk, i) => {
              const chunk = datesChunks[virtualChunk.index];

              if (i === 0) {
                const [offset, position] = datesChunksVirtualizer.getOffsetForIndex(virtualChunk.index) as [number, string];
                if (position === 'start') {
                  datesOffsetElementRef.current?.style.setProperty(
                    'width',
                    `${offset}px`,
                  );
                }
              }

              return (
                <LinearCalendarChunk
                  key={chunk.key}
                  dates={chunk.dates}
                  datesData={datesData}
                  mergedDatesData={mergedDatesData}
                  isDataRequestAllowedWithoutIntersection={chunk.index === 0}
                  onDatesDataRequest={onDatesRangeDataRequest}
                  onDateSelect={onDateSelect}
                  isDataRequestsAllowed={isDataRequestsAllowed}
                />
              );
            })}
          </MultipleIntersectionObserver>
        </div>
        <IntersectionObserver
          threshold={0.25}
          onChange={handleGenerationTriggerIntersect}
        >
          <div className={styles.generationTrigger} />
        </IntersectionObserver>
      </HorizontalScrollContainer>
      {isLoading ? <Loader fullSize width={80} height={80} /> : null}
    </div>
  );
};

export default LinearCalendar;
