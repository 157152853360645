import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ClickAwayListener, Popper } from '@mui/material';
import { isValid, parseISO } from 'date-fns';
import { EInputMode } from '@generics/inputs/types';
import { Card } from '@generics/surfaces';
import { Typography } from '@generics/content';
import { StringInput } from '@generics/inputs/string';
import { DateSelectInput } from '@generics/inputs/date-select';
import { CalendarIcon } from '@images/icons';
import { useFormatDatetime } from '@utils/localizing';
import styles from './DateInput.module.scss';

export interface IDateInputProps {
  value?: string | null;
  onChange?: (value: string | null) => void;
  inputRef?: React.Ref<HTMLInputElement>;
  placeholder?: string;
  mode?: `${EInputMode}`;
  futureDisabled?: boolean;
  invalid?: boolean;
}

export const DateInput = (props: IDateInputProps) => {
  const {
    value,
    onChange,
    inputRef,
    placeholder,
    mode = EInputMode.Input,
    futureDisabled = false,
    invalid = false,
  } = props;

  const formatDatetime = useFormatDatetime();
  const boxElementRef = useRef<HTMLDivElement | null>(null);
  const [isSelectInputOpen, setIsSelectInputOpen] = useState(false);

  const valueRepresentation = useMemo(
    () => value && isValid(parseISO(value))
      ? formatDatetime({ date: value, format: 'yyyy-MM-dd' })
      : '-',
    [value, formatDatetime],
  );

  const handleSelectInputChange = useCallback((newValue: string | null) => {
    onChange?.(newValue);
    setIsSelectInputOpen(false);
  }, [onChange]);

  const handleOpenSelectInputButtonClick = useCallback(() => {
    setIsSelectInputOpen(true);
  }, []);

  const getAnchorEl = useCallback(() => boxElementRef.current ?? document.createElement('div'), []);

  const handleClickAway = useCallback(() => {
    setIsSelectInputOpen(false);
  }, []);

  if (mode === EInputMode.Representation) {
    return (
      <Typography type="body/regular/m">{valueRepresentation}</Typography>
    );
  }

  return (
    <>
      <StringInput
        mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
        guide={false}
        value={value}
        onChange={onChange}
        inputMode="numeric"
        inputRef={inputRef}
        ref={boxElementRef}
        placeholder={placeholder}
        appendix={(
          <div
            className={styles.appendix}
            onClick={handleOpenSelectInputButtonClick}
          >
            <CalendarIcon />
          </div>
        )}
        invalid={invalid}
      />
      <Popper
        className={styles.popup}
        open={isSelectInputOpen}
        anchorEl={getAnchorEl}
        placement="bottom-end"
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Card>
            <DateSelectInput
              value={value}
              onChange={handleSelectInputChange}
              outputFormat="yyyy-MM-dd"
              futureDisabled={futureDisabled}
            />
          </Card>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
