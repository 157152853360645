import React, { useMemo } from 'react';
import { useFormatDatetime } from '@utils/localizing';
import { EStripeCardBrand, StripeCardBrandIcon } from '@generics/stripe/StripeCardBrandIcon';
import { AspectRatio, Typography } from '@generics/content';
import { Button } from '@generics/inputs/button';
import { TrashIcon } from '@images/icons';
import { useMergedClassNames } from '@utils/styling';
import styles from './PaymentMethod.module.scss';

export type TPaymentMethodProps<RootElementType extends React.ElementType> = {
  as?: RootElementType;
  brand?: `${EStripeCardBrand}`;
  numberFragment?: string;
  expirationDate?: string;
  onDeleteButtonClick?: () => void;
  isDeleteButtonDisabled?: boolean;
  isDeleteButtonLoading?: boolean;
};

export const PaymentMethod = <RootElementType extends React.ElementType>(
  props: PolymorphicComponentPropsWithoutRef<RootElementType, TPaymentMethodProps<RootElementType>>,
) => {
  const {
    as,
    brand,
    numberFragment,
    expirationDate,
    onDeleteButtonClick: onDeleteClick,
    isDeleteButtonDisabled = false,
    isDeleteButtonLoading = false,
    className,
    ...restRootProps
  } = props;

  const formatDatetime = useFormatDatetime();

  const RootComponent = useMemo(() => as ?? 'div', [as]);

  const number = useMemo(() => `**** **** **** ${numberFragment}`, [numberFragment]);
  const expiration = useMemo(
    () => formatDatetime({ date: expirationDate, format: 'datetime.bankCard.expirationDate' }),
    [formatDatetime, expirationDate],
  );

  const rootClassName = useMergedClassNames({
    [styles.root]: true,
    [className || '']: true,
  });

  return (
    <RootComponent {...restRootProps} className={rootClassName}>
      <AspectRatio className={styles.brand} aspectRatio={1}>
        <StripeCardBrandIcon brand={brand as EStripeCardBrand} />
      </AspectRatio>
      <div className={styles.details}>
        <Typography type="body/medium/m">{number}</Typography>
        <Typography type="body/regular/s" color="secondary">{expiration}</Typography>
      </div>
      {onDeleteClick
        ? (
            <div className={styles.actions}>
              {onDeleteClick
                ? (
                    <Button
                      icon={<TrashIcon />}
                      severity="danger"
                      design="text"
                      onClick={onDeleteClick}
                      disabled={isDeleteButtonDisabled}
                      isLoading={isDeleteButtonLoading}
                    />
                  )
                : null}
            </div>
          )
        : null}
    </RootComponent>
  );
};
