import React, { useMemo } from 'react';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  NavLink as RouterNavLink,
  NavLinkProps as RouterNavLinkProps,
} from 'react-router-dom';

export type TLinkProps =
  | { navigational?: false } & RouterLinkProps
  | { navigational: true } & RouterNavLinkProps;

export const Link = (props: TLinkProps) => {
  const { navigational, ...restRootProps } = props;

  const LinkComponent = useMemo(() => navigational ? RouterNavLink : RouterLink, [navigational]);

  return <LinkComponent {...restRootProps as RouterLinkProps & RouterNavLinkProps} />;
};
