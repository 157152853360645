import React, { useCallback, useRef, useState, useMemo, useEffect } from 'react';
import mergeClassNames from 'classnames';
import styles from './LoungePicker.module.scss';
import useGeolocation from '../../hooks/useGeolocation';
import LoungesMapMarker from '../LoungesMap/LoungesMapMarker';
import { Lounge, LoungeWithDistance } from '../../store/lounge/lounge.types';
import {
  featureGroup as createMapGroup,
  Marker as LeafletMapMarker,
  icon as createMapMarkerIcon,
} from 'leaflet';
import Button from '../Button';
import { ReactComponent as ArrowIcon } from '../../images/arrow-current-color.svg?tsx';
import LoungeCard from '../LoungeCard/LoungeCard';
import { useTranslation } from 'react-i18next';
import SearchBox from '../SearchBox';
import { useLoungeSearch } from '../../hooks/useLoungeSearch';
import useInstantiatedWorldMap from '../../hooks/useInstantiatedWorldMap';
import { useMediaQuery } from '@mui/material';
import PersonPin from '../../images/ru-pin-person.svg';
import WorldMapMarker from '../WorldMap/WorldMapMarker';

const personMarkerIcon = createMapMarkerIcon({
  iconUrl: PersonPin,
  iconSize: [32, 38],
  iconAnchor: [16, 38],
  popupAnchor: [0, -36],
});

export interface ILoungePickerClassNames {
  root?: string;
}

export interface ILoungePickerProps {
  classNames?: ILoungePickerClassNames;
  globalSelectionTitle?: string;
  /**
   * @default true
   */
  displayPrice?: boolean;
  /**
   * @default false
   */
  displayAddress?: boolean;
  onPick?: (lounge: Lounge) => void;
  onClose?: () => void;
}

const LoungePicker: React.FC<ILoungePickerProps> = (props) => {
  const {
    displayPrice = true,
    displayAddress = false,
    classNames,
    globalSelectionTitle,
    onPick,
    onClose,
  } = props;

  const loungesListElementRef = useRef<HTMLDivElement | null>(null);
  const [t] = useTranslation();
  const { coordsAsTuple: geolocationCoordsAsTuple, isResolved: isGeolocationResolved, isAccessAllowed: isGeolocationAccessAllowed } = useGeolocation();
  const [selectedLounge, setSelectedLounge] = useState<Lounge | null>(null);
  const { lounges, filteredLounges, search, setSearch } = useLoungeSearch();
  const isMobile = useMediaQuery('only screen and (max-width: 768px)');

  const { WorldMap, worldMapInstance } = useInstantiatedWorldMap();

  const closestLounge = useMemo(() => lounges.reduce((closestLounge, lounge) => {
    if (!closestLounge || !closestLounge.distance) return lounge;
    if (!lounge.distance) return closestLounge;
    return closestLounge.distance < lounge.distance ? closestLounge : lounge;
  }, null as LoungeWithDistance | null), [lounges]);

  const closestLoungeAndGeolocationMarkersGroup = useMemo(() => {
    if (!closestLounge || !geolocationCoordsAsTuple) return null;

    return createMapGroup([
      new LeafletMapMarker([Number(closestLounge.lat), Number(closestLounge.lng)]),
      new LeafletMapMarker(geolocationCoordsAsTuple),
    ]);
  }, [closestLounge, geolocationCoordsAsTuple]);

  useEffect(() => {
    if (!worldMapInstance || !isGeolocationAccessAllowed || !closestLoungeAndGeolocationMarkersGroup) return;

    worldMapInstance.fitBounds(closestLoungeAndGeolocationMarkersGroup.getBounds(), {
      paddingTopLeft: [50, isMobile ? 125 : 50],
      paddingBottomRight: [50, isMobile ? 175 : 25],
    });
  }, [worldMapInstance, isGeolocationAccessAllowed, closestLoungeAndGeolocationMarkersGroup, isMobile]);

  const handleLoungeSelect = useCallback(
    (lounge: Lounge) => {
      setSelectedLounge(lounge);

      if (!worldMapInstance) return;
      worldMapInstance.setView([Number(lounge.lat), Number(lounge.lng)]);

      if (!loungesListElementRef.current) return;
      const loungeListItemElement
        = loungesListElementRef.current.querySelector<HTMLDivElement>(
          `#lounge-${lounge.id}`,
        );

      if (!loungeListItemElement) return;
      loungeListItemElement.scrollIntoView({
        block: 'center',
        inline: 'center',
      });
    },
    [worldMapInstance],
  );

  const rootClassName = useMemo(
    () => mergeClassNames(styles.root, classNames ? classNames.root : null),
    [classNames],
  );

  return (
    <div className={rootClassName} data-display-address={displayAddress}>
      <div className={styles.content}>
        <div className={styles.main}>
          <WorldMap
            classNames={{
              zoomControls: { root: styles.map__zoomControls },
            }}
            loading={!isGeolocationResolved}
            initialCenter={geolocationCoordsAsTuple}
            initialZoom={13}
            fit
          >
            {geolocationCoordsAsTuple
              ? (
                  <WorldMapMarker
                    id="person-geolocation"
                    latitude={geolocationCoordsAsTuple[0]}
                    longitude={geolocationCoordsAsTuple[1]}
                    markerIcon={personMarkerIcon}
                  />
                )
              : null}
            {lounges.map((lounge) => (
              <LoungesMapMarker
                selected={selectedLounge?.id === lounge.id}
                key={lounge.id}
                lounge={lounge}
                onSelect={handleLoungeSelect}
              />
            ))}
          </WorldMap>
        </div>
        <div className={styles.sidebar}>
          <div className={styles.toolbar}>
            <Button
              appearance="outline"
              className={styles.closeButton}
              icon={<ArrowIcon className={styles.closeButton__icon} />}
              onClick={onClose}
            />
            <SearchBox
              className={styles.itemFullWidth}
              value={search}
              onChange={setSearch}
            />
          </div>
          <div
            className={styles.list}
            ref={loungesListElementRef}
            data-global-selection={!!globalSelectionTitle}
          >
            <div className={styles.list__content}>
              {filteredLounges.map((lounge) => (
                <LoungeCard
                  id={`lounge-${lounge.id}`}
                  key={lounge.id}
                  lounge={lounge}
                  priceValue={
                    displayPrice ? lounge.from_price_label : undefined
                  }
                  description={lounge.lounge_marketing}
                  features={lounge.features}
                  proceedButtonIcon={<ArrowIcon />}
                  proceedButtonLabel={t('booking.steps.location.forwardButton')}
                  onSelect={handleLoungeSelect}
                  onProceed={globalSelectionTitle ? undefined : onPick}
                  selected={Boolean(
                    selectedLounge && selectedLounge.id === lounge.id,
                  )}
                />
              ))}
            </div>
          </div>
          {globalSelectionTitle && onPick
            ? (
                <div className={styles.globalButton}>
                  <Button
                    className={styles.itemFullWidth}
                    disabled={!selectedLounge}
                    icon={<ArrowIcon />}
                    label={globalSelectionTitle}
                    onClick={() => selectedLounge && onPick(selectedLounge)}
                  >
                  </Button>
                </div>
              )
            : (
                ''
              )}
        </div>
      </div>
    </div>
  );
};

export default LoungePicker;
