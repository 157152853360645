export function objectToFormData<Data extends Record<string, unknown>>(
  data: Data,
  formData: FormData,
  subkey: string = '',
): void {
  Object.entries(data).forEach(([key, value]) => {
    const subKeyString = subkey ? `${subkey}[${key}]` : key;
    if (value === '' || value === null || value === undefined)
      return formData.append(subKeyString, '');

    if (typeof value === 'boolean')
      return formData.append(subKeyString, value ? '1' : '0');

    if (typeof value === 'object' && !(value instanceof File))
      return objectToFormData(value as Record<string, unknown>, formData, subKeyString);

    formData.append(
      subKeyString,
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      value instanceof File ? value : value.toString(),
    );
  });
}
