import { AxiosError } from 'axios';
import api, { fixedEncodeURIComponent } from '../../config/api.config';
import i18n from '../../i18n';
import { objectToFormData } from '../../utils/object-to-form-data.utils';
import { AvailableDate, BookingPayload, SummaryType, TimeSlot } from './booking.types';
import qs from 'qs';

export const getTimeSlots = async (
  isGroupRide?: boolean,
  lounge?: string,
  duration?: number,
  controller?: AbortController,
): Promise<TimeSlot[]> => {
  const params = {
    group_ride: isGroupRide || null,
    lounge_id: lounge || null,
    duration_in_minutes: duration || null,
  };
  try {
    const response = await api.get<TRumpApiResponseData<TimeSlot[]>>('/time_slots', {
      params,
      signal: controller && controller.signal,
    });
    return response.data.data.sort(
      (a: TimeSlot, b: TimeSlot) => +a.price - +b.price,
    );
  }
  catch (err) {
    const error = err as AxiosError<TRumpApiRequestError>;
    return Promise.reject(
      'response' in error ? error.response?.data : error,
    );
  }
};

export const getProductTimeSlots = async (
  controller?: AbortController,
): Promise<TimeSlot[]> => {
  try {
    const response = await api.get<TRumpApiResponseData<TimeSlot[]>>('/time_slots/products', {
      signal: controller && controller.signal,
    });
    return response.data.data.sort(
      (a: TimeSlot, b: TimeSlot) => +a.price - +b.price,
    );
  }
  catch (err) {
    const error = err as AxiosError<TRumpApiRequestError>;
    return Promise.reject(
      'response' in error ? error.response?.data : error,
    );
  }
};

export const getAvailableDates = async (
  simulators: number,
  time_slot_id: string,
  lounge_id: string,
  date_from: string,
  date_to: string,
  selected_time: string,
  controller?: AbortController,
) => {
  try {
    const response = await api.get<TRumpApiResponseData<AvailableDate[]>>('/available_dates', {
      params: {
        date_from,
        date_to,
        simulators,
        time_slot_id,
        lounge_id,
        selected_time: selected_time || null,
      },
      signal: controller && controller.signal,
    });
    return response.data.data;
  }
  catch (err) {
    const error = err as AxiosError<TRumpApiRequestError>;
    return Promise.reject(
      'response' in error ? error.response?.data : error,
    );
  }
};

export interface IGetDatesDiscountsProps {
  loungeId: string;
  timeSlotId: string;
  fromDate: string;
  toDate: string;
  isBooking?: boolean;
  selectedDate?: string;
  abortController?: AbortController;
}

export interface IDiscountData {
  active: boolean;
  date_from: string;
  date_to: string;
  description: string;
  id: string;
  name: string;
  plan_type: string;
  priority: string;
  unit: string;
  tag: string;
  value: number;
}

export interface IGetDatesDiscountsResponse {
  data: IDiscountData[];
  meta: {
    dates: string[];
  };
}

export interface IGetDatesDiscountsReturn
  extends Record<string, IDiscountData & { weekday: string }> {}

export const getDatesDiscounts = async (props: IGetDatesDiscountsProps) => {
  const {
    loungeId,
    timeSlotId,
    fromDate,
    toDate,
    isBooking,
    selectedDate,
    abortController,
  } = props;

  try {
    const response = await api.get<IGetDatesDiscountsResponse>(
      '/discounts/discounts_by_dates',
      {
        params: {
          lounge_id: loungeId,
          time_slot_id: timeSlotId,
          date_from: fromDate,
          date_to: toDate,
          date: selectedDate,
          booking: isBooking,
        },
        signal: abortController && abortController.signal,
      },
    );

    const discountsByDates = response.data.data;

    const datesDiscounts = response.data.meta.dates.reduce(
      (datesDiscounts, isoDateWithWeekday, dateIndex) => {
        const [isoDate, weekday] = isoDateWithWeekday.split(' ');
        return {
          ...datesDiscounts,
          [isoDate]: { ...discountsByDates[dateIndex], weekday },
        };
      },
      {} as IGetDatesDiscountsReturn,
    );

    return datesDiscounts;
  }
  catch (err) {
    const error = err as AxiosError<TRumpApiErrorResponseData>;
    return Promise.reject(
      typeof error.response !== 'undefined' ? error.response.data : error,
    );
  }
};

export const getFirstAvailableDate = async (
  simulators: number,
  time_slot_id: string,
  lounge_id: string,
  controller?: AbortController,
) => {
  try {
    const response = await api.get<{ first_available_date: string }>('/available_dates/first_available_slot', {
      params: {
        simulators,
        time_slot_id,
        lounge_id,
      },
      signal: controller && controller.signal,
    });
    return response.data && response.data.first_available_date
      ? response.data.first_available_date
      : null;
  }
  catch (err) {
    const error = err as AxiosError<TRumpApiErrorResponseData>;
    return Promise.reject(
      typeof error.response !== 'undefined' ? error.response.data : error,
    );
  }
};

export const getLoungeAvailableTimes = async (
  lounge_id: string | number,
  date: string,
  isEvent?: boolean,
  controller?: AbortController,
): Promise<string[]> => {
  try {
    const response = await api.get<TRumpApiResponseData<string[]>>(`/lounges/${lounge_id}/available_times`, {
      params: {
        date,
        event_times: !!isEvent,
      },
      signal: controller && controller.signal,
    });
    return response.data.data;
  }
  catch (err) {
    const error = err as AxiosError<TRumpApiErrorResponseData>;
    return Promise.reject(
      typeof error.response !== 'undefined' ? error.response.data : error,
    );
  }
};

export const getAvailableTimes = async (
  lounge_id: string,
  time_slot_id: string,
  date: string,
  duration: number,
  simulators: number,
  controller?: AbortController,
): Promise<string[]> => {
  try {
    const response = await api.get<TRumpApiResponseData<string[]>>(`/available_times`, {
      params: {
        lounge_id,
        time_slot_id,
        date,
        duration,
        simulators,
      },
      signal: controller && controller.signal,
    });
    return Array.isArray(response.data.data) ? response.data.data : [];
  }
  catch (err) {
    const error = err as AxiosError<TRumpApiErrorResponseData>;
    return Promise.reject(
      typeof error.response !== 'undefined' ? error.response.data : error,
    );
  }
};

export const createReservation = async (
  booking: Partial<BookingPayload>,
  isRide: boolean = false,
) => {
  const formData = new FormData();

  objectToFormData(booking, formData);

  const body = isRide ? booking : formData;
  const params = isRide
    ? undefined
    : {
        headers: { 'Content-Type': 'multipart/form-data' },
      };

  try {
    const response = await api.post<TRumpApiResponseData<{ invoice: { currency: string; total: number }; number_of_simulators: number }>>('/reservations', body, params);
    return response.data;
  }
  catch (err) {
    const error = err as AxiosError<TRumpApiRequestError>;
    return Promise.reject(
      'response' in error ? error.response?.data : error,
    );
  }
};

export const fetchSummary = async (
  {
    start_time,
    time_slot_id,
    simulators,
    lounge_id,
    coupon_code,
    rides_sharing,
    event_information,
  }: Partial<BookingPayload>,
  controller?: AbortController,
) => {
  const languageParams = {
    headers: {
      'x-locale': i18n.language,
    },
  };

  try {
    const response = await api.get<{ data?: SummaryType }>('/reservations/summary_info', {
      ...languageParams,
      params: {
        start_time,
        time_slot_id,
        simulators,
        lounge_id,
        coupon_code: coupon_code
          ? fixedEncodeURIComponent(coupon_code)
          : undefined,
        rides_sharing,
        event_information,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'brackets' });
      },
      signal: controller && controller.signal,
    });

    return response.data;
  }
  catch (err) {
    const error = err as AxiosError<TRumpApiRequestError>;
    return Promise.reject(
      'response' in error ? error.response?.data : error,
    );
  }
};
