import React from 'react';
import { useBreakpoints } from './useBreakpoints';

export enum EBreakpoint {
  Mobile = 'mobile',
  Desktop = 'desktop',
}

export type TBreakpointProps = {
  is: 'mobile' | 'desktop';
  children?: React.ReactNode;
};

export const Breakpoint = (props: TBreakpointProps) => {
  const { is, children } = props;

  const { isMobile, isDesktop } = useBreakpoints();

  if (is === EBreakpoint.Desktop && isDesktop) {
    return <>{children}</>;
  }

  if (is === EBreakpoint.Mobile && isMobile) {
    return <>{children}</>;
  }

  return null;
};
