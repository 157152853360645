import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import './CheckboxRadio.scss';
import classNames from 'classnames';

interface CheckboxRadio {
  name: string;
  value: string | boolean;
  required?: boolean;
  primary?: boolean;
  checked?: boolean;
  disabled?: boolean;
  i18nKey?: string;
  i18nTrans?: React.ReactNode;
  isRadio?: boolean;
  onChange?: (evt: Omit<ChangeEvent<HTMLInputElement>, 'target'> & { target: ChangeEvent<HTMLInputElement>['target'] & { checked: boolean } }) => void;
  children?: React.ReactNode;
}

const CheckboxRadio = ({
  name,
  onChange,
  value,
  required,
  primary,
  checked,
  disabled,
  i18nKey,
  i18nTrans,
  isRadio,
  children,
}: CheckboxRadio) => {
  const [t] = useTranslation();

  return (
    <div
      className={classNames('CheckboxRadio', {
        CheckboxRadio_primary: primary,
      })}
    >
      <input
        name={name}
        id={`CheckboxRadio-${name}`}
        type="checkbox"
        disabled={disabled}
        checked={typeof value === 'boolean' ? value : checked}
        onChange={onChange}
        value={typeof value === 'string' ? value : undefined}
      />
      <label htmlFor={`CheckboxRadio-${name}`}>
        <span className={`CheckboxRadio-box ${isRadio ? 'is-radio' : ''}`} />
        {(i18nKey || i18nTrans) && (
          <span>
            {i18nKey ? t(i18nKey) : i18nTrans}
            {' '}
            {required ? <span className="CheckboxRadio__required">*</span> : ''}
          </span>
        )}
      </label>
      <span>{children}</span>
    </div>
  );
};

export default CheckboxRadio;
