const profile = {
  connections: 'Social Networks',
  connectionsSubtitles:
    'Verbinde dich mit uns auf deinen Sozialen Netzwerken, und teile deine Ranglisten, Experiences und mehr.',
  profileDetails: 'Profil Details',
  deleteCustomer: 'Profil löschen',
  deleteTitle: 'Bist du sicher?',
  deleteSubtitle: 'Bist du sicher, dass du dein Konto löschen willst? Das kann man nicht rückgängig machen und du verlierst alle deine Daten und Inhalte, die damit zusammenhängen.',
  yesConfirm: 'Löschen',
  profileUpdateDetails: 'Profilaktualisierung',
  profileUpdateInfo: 'Wir haben kürzlich unser Plattformsystem geändert. In diesem Zusammenhang bitten wir Sie, Ihr Profil mit den unten aufgeführten fehlenden Details zu aktualisieren.',
  updatePasswordTitle: 'Passwort ändern',
};

export default profile;
